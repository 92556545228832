import { React, $, ClassNames } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';
import { provsData } from '../../../../common/larea-data';

//确认支付页支付金额 add by wendy.gu 2017 Nov.21
class LabelTextRight extends AtomicComponentThing {
    renderValue() {
        //单位
        const unit = this.getLayout().unit || "";
        let value = this.getValueFromModel();
        if (value === undefined || value === null || value === -1 || value === "") {
            return <div className='sth-label-value'>{ 0 + '' + unit }</div>;
        } else {
            return <div className='sth-label-value'>{ value + '' + unit }</div>;
        }
    }

    render() {
        return (
            <div { ...this.getPrototypes() } ref='me'>
                <div className='sth-label-name'>{ this.getLayout().label }：</div>
                { this.renderValue() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-label-text-r';
    }

    getPanelLayout() {
        return this.getLayout();
    }
}


export default LabelTextRight;