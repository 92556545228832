import { React, ClassNames, $ } from '../../../common/3rd';
import { Lang, Consts, Validation } from '../../../common/common';
import { Widget, ReactRouterContextWidget } from '@/component/components';
import {
  Toast,
  Button,
  Text,
  Dropdown,
  Date as DateTime,
  Integer,
} from '../../components';

import './policy.scss';
import layout from './layout.json';
import messages from './messages.json';
Lang.installMessages(messages, 'desk-policy');

/**
 * sort layout by keys, return array
 * @param {*} layout
 */
const sortKeys = function (layout) {
  return Object.keys(layout)
    .sort((key1, key2) => {
      const pos1 = layout[key1].position;
      const pos2 = layout[key2].position;
      const diff = pos1.row - pos2.row;
      return diff < 0 ? -1 : diff > 0 ? 1 : pos1.column - pos2.column;
    })
    .reduce((array, key) => {
      array.push(key);
      return array;
    }, []);
};

const formatFunctions = {
  left: function (value, params, model) {
    return value.substr(0, params);
  },
};

/**
 * render value
 * @param {*} value
 * @param {*} format
 * @param {*} model
 */
const renderValue = function (value, format, model, layout) {
  if (value == null) {
    return null;
  } else if (format) {
    return formatFunctions[format.by].call(this, value, format.params, model);
  } else if (layout.codes) {
    // TODO how to get from object when codes defines it?
    const from = Consts;
    const key = Object.keys(from[layout.codes.set]).find((key) => {
      return Consts[layout.codes.set][key] == value;
    });

    return Lang.messages.common[layout.codes.lang][key];
  } else {
    return value;
  }
};

/**
 * render by layout
 * @param {*} model
 * @param {*} layout
 */
const renderByLayout = function (model, layout, refIndex = 0) {
  const keys = sortKeys(layout);
  let lastRow = 0;
  return keys.map((key, index) => {
    const config = layout[key];
    const width = config.position.width;
    const row = config.position.row;
    let className = ClassNames('form-row', width ? `grab-${width}` : null, {
      'new-row': row !== lastRow,
    });
    lastRow = row;
    if (config.type === 'object') {
      return renderByLayout.call(this, model[config.id], config.body, refIndex);
    }
    if (config.type === 'array_panel') {
      return renderArrayPanel.call(this, model[config.id], config);
    }
    if (config.type === 'array_table') {
      return renderArrayTable.call(
        this,
        model[config.id],
        config.body,
        refIndex
      );
    }
    return (
      <div className={className} key={`cell-${index}`}>
        {renderCell.call(this, model, config, refIndex)}
      </div>
    );
  });
};
const renderArrayPanel = function (modelArray, layout) {
  return modelArray.map((modelItem, index) => {
    return (
      <ArrayPanel
        ref={`array-panel-${index}`}
        key={`${layout.type}-${index}`}
        model={modelItem}
        modelArray={modelArray}
        index={index}
        layout={layout}
        parentComponent={this.getComponent()}
      />
    );
    // return <div className={ClassNames("form-row grab-row array-panel-section")}
    // 	key={`${layout.type}-${index}`}>
    // 	<div className={ClassNames("array-panel-section-header")}>
    // 		{Lang.messages['desk-policy'][layout.title]}
    // 		<div style={{float: "right"}}>X</div>
    // 	</div>
    // 	<div className={ClassNames("array-panel-section-body")}>
    // 		{renderByLayout.call(this, modelItem, layout.body, index)}
    // 	</div>
    // </div>
  });
};

const renderArrayTable = function (modelArray, layout, refIndex) {
  return (
    <div
      className={ClassNames('form-row new-row grab-row form-table')}
      key="array-table"
    >
      {renderTableHead.call(this, layout)}
      {renderTableBody.call(this, modelArray, layout, refIndex)}
    </div>
  );
};
const renderTableHead = function (layout) {
  return (
    <div className={ClassNames('form-table-header-group')}>
      <div className={ClassNames('form-table-row')}>
        {renderTableHeaderCell.call(this, layout)}
      </div>
    </div>
  );
};
const renderTableHeaderCell = function (layout) {
  const keys = sortKeys(layout);
  return keys.map((key, index) => {
    const config = layout[key];
    if (config.type === 'addAndDelete') {
      return (
        <div
          id="addArrayRow"
          className={ClassNames('form-table-cell', 'button')}
          key={`table-header-cell-${config.options.ref}-${index}`}
          onClick={this[config.options.addFunction]}
        >
          {Lang.messages['desk-policy'][config.options.addTitle]}
        </div>
      );
    }
    return (
      <div
        className={ClassNames('form-table-cell')}
        key={`table-header-cell-${config.options.ref}-${index}`}
      >
        {Lang.messages['desk-policy'][config.options.title]}
      </div>
    );
  });
};
const renderTableBody = function (modelArray, layout, refIndex) {
  return (
    <div className={ClassNames('form-table-row-group')}>
      {renderTableBodyRow.call(this, modelArray, layout, refIndex)}
    </div>
  );
};
const renderTableBodyRow = function (modelArray, layout, refIndex) {
  if (!modelArray) {
    return;
  }
  return modelArray.map((modelItem, index) => {
    return (
      <ArrayTableRow
        key={`table-body-row-${layout.ref}-${index}`}
        ref={`array-table-row-${index}`}
        modelArray={modelArray}
        model={modelItem}
        layout={layout}
        refIndex={refIndex}
        index={index}
        parentComponent={this.getComponent()}
      />
    );
  });
};
const renderTableBodyCell = function (model, layout, parentRefIndex, refIndex) {
  const keys = sortKeys(layout);
  return keys.map((key, index) => {
    const config = layout[key];
    const type = config.type;
    switch (type) {
      case 'addAndDelete':
        return renderTableCellInputDelete.call(this, config.options);
      case 'inputText':
        return renderTableCellInputText.call(
          this,
          model,
          config.options,
          parentRefIndex,
          refIndex
        );
      case 'dropDown':
        return renderTableCellDropDown.call(
          this,
          model,
          config.options,
          parentRefIndex,
          refIndex
        );
      case 'inputDate':
        return renderTableCellInputDate.call(
          this,
          model,
          config.options,
          parentRefIndex,
          refIndex
        );
      case 'inputInteger':
        return renderTableCellInputInteger.call(
          this,
          model,
          config.options,
          parentRefIndex,
          refIndex
        );
      default:
        return renderTableCellInputText.call(
          this,
          model,
          config.options,
          parentRefIndex,
          refIndex
        );
    }
  });
};
const renderTableCellInputDelete = function (layout) {
  return (
    <div
      id="deleteArrayRow"
      className="form-table-cell button"
      key="input"
      onClick={this.onDeleteClicked}
    >
      {Lang.messages['desk-policy'][layout.deleteTitle]}
    </div>
  );
};
const renderTableCellInputText = function (
  model,
  layout,
  parentRefIndex,
  refIndex
) {
  return [
    <div className="form-table-cell" key="input">
      <Text
        ref={`${layout.ref}-${parentRefIndex}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      />
    </div>,
  ];
};
const renderTableCellDropDown = function (
  model,
  layout,
  parentRefIndex,
  refIndex
) {
  return [
    <div className="form-table-cell" key="input">
      <Dropdown
        ref={`${layout.ref}-${parentRefIndex}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      >
        {Object.keys(Consts[layout.codes.set]).map((key, keyIndex) => {
          return (
            <option
              value={Consts[layout.codes.set][key]}
              key={`type-${keyIndex}`}
            >
              {Lang.messages.common[layout.codes.lang][key]}
            </option>
          );
        })}
      </Dropdown>
    </div>,
  ];
};
const renderTableCellInputDate = function (
  model,
  layout,
  parentRefIndex,
  refIndex
) {
  return [
    <div className="form-table-cell" key="input">
      <DateTime
        a-model={model}
        ref={`${layout.ref}-${parentRefIndex}-${refIndex}`}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      />
    </div>,
  ];
};
const renderTableCellInputInteger = function (
  model,
  layout,
  parentRefIndex,
  refIndex
) {
  return [
    <div className="form-table-cell" key="input">
      <Integer
        ref={`${layout.ref}-${parentRefIndex}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
      />
    </div>,
  ];
};

const filterValues = function (filter, values) {
  if (!filter) {
    return values;
  }
  return values.filter((item) => {
    const notMatchPropName = Object.keys(filter).find((propName) => {
      return filter[propName] != item[propName];
    });
    if (notMatchPropName) {
      return false;
    } else {
      return true;
    }
  });
};

/**
 * render cell
 * @param {*} model
 * @param {*} config
 */
const renderCell = function (model, config, refIndex) {
  const type = config.type;
  const isArray = config.array === true;
  switch (type) {
    case 'panel':
      const values = model[config.id] || model;
      if (isArray) {
        const filter = config.filter;
        return values.length
          ? filterValues.call(this, filter, values).map((item, index) => {
              return renderPanel.call(
                this,
                item,
                config.options,
                index,
                values
              );
            })
          : null;
      } else {
        return renderPanel.call(this, values, config.options, 0);
      }
    case 'button':
      return renderButtun.call(this, model, config.options, refIndex);
    case 'inputText':
      return renderInputText.call(this, model, config.options, refIndex);
    case 'dropDown':
      return renderDropDown.call(this, model, config.options, refIndex);
    case 'inputDate':
      return renderInputDate.call(this, model, config.options, refIndex);
    case 'inputInteger':
      return renderInputInteger.call(this, model, config.options, refIndex);
    default:
      return renderLabel.call(this, model, config.options, refIndex);
  }
};

const renderButtun = function (model, layout, index) {
  return (
    <div className="button addArrayPanel" onClick={this[layout.clickFun]}>
      {Lang.messages['desk-policy'][layout.title]}
    </div>
  );
};
/**
 * render panel
 * @param {*} model
 * @param {*} layout
 */
const renderPanel = function (model, layout, index, modelArray) {
  return (
    <Panel
      ref={`${layout.title}-${index}`}
      key={`panel-${index}`}
      model={model}
      modelArray={modelArray}
      layout={layout}
      parentComponent={this.getComponent()}
    />
  );
};

/**
 * render label for cell input
 * @param {*} model
 * @param {*} layout
 */
const renderInputLabel = function (model, layout) {
  return (
    <div className="form-label" key="label">
      <span>{Lang.messages['desk-policy'][layout.title]}</span>
    </div>
  );
};

const renderInputInteger = function (model, layout, refIndex) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <Integer
        ref={`${layout.ref}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
      />
    </div>,
  ];
};

const renderInputDate = function (model, layout, refIndex) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <DateTime
        a-model={model}
        ref={`${layout.ref}-${refIndex}`}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      />
    </div>,
  ];
};

const renderDropDown = function (model, layout, refIndex) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <Dropdown
        ref={`${layout.ref}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      >
        {Object.keys(Consts[layout.codes.set]).map((key, keyIndex) => {
          return (
            <option
              value={Consts[layout.codes.set][key]}
              key={`type-${keyIndex}`}
            >
              {Lang.messages.common[layout.codes.lang][key]}
            </option>
          );
        })}
      </Dropdown>
    </div>,
  ];
};

const renderInputText = function (model, layout, refIndex) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <Text
        ref={`${layout.ref}-${refIndex}`}
        a-model={model}
        a-propName={layout.id}
        a-required={layout.required}
        disabled={layout.disabled}
        a-validator={
          layout.validator &&
          layout.validator.split(',').map((validator) => {
            return Validation[validator];
          })
        }
      />
    </div>,
  ];
};

/**
 * render cell as label
 * @param {*} model
 * @param {*} layout
 */
const renderLabel = function (model, layout) {
  return [
    renderInputLabel.call(this, model, layout),
    <div className="form-input" key="input">
      <span>
        {renderValue.call(
          this,
          getValue(model, layout.id),
          layout.format,
          model,
          layout
        )}
      </span>
    </div>,
  ];
};

const getValue = function (model, id) {
  return id.split('.').reduce((model, id) => {
    if (model) {
      return model[id];
    } else {
      return null;
    }
  }, model);
};

class ArrayTableRow extends Widget {
  render() {
    return (
      <div className={ClassNames('form-table-row')}>
        {renderTableBodyCell.call(
          this,
          this.getModel(),
          this.getLayout(),
          this.getRefIndex(),
          this.getIndex()
        )}
      </div>
    );
  }
  onDeleteClicked = () => {
    let modelArray = this.getModelArray();
    if (modelArray) {
      let model = this.getModel();
      model.delete = true;
      let deleteIndex = modelArray.findIndex((model) => {
        return model.delete;
      });
      modelArray.splice(deleteIndex, 1);
      this.getParentComponent().forceUpdate();
    }
  };
  getComponent() {
    return this;
  }
  getParentComponent() {
    return this.props.parentComponent;
  }
  getModelArray() {
    return this.props.modelArray;
  }
  getModel() {
    return this.props.model;
  }
  getLayout() {
    return this.props.layout;
  }
  getIndex() {
    return this.props.index;
  }
  getRefIndex() {
    return this.props.refIndex;
  }
}

/**
 * arrayPanel, 数组面板容器
 */
class ArrayPanel extends Widget {
  render() {
    return (
      <div className={ClassNames('form-row grab-row array-panel-section')}>
        <div className={ClassNames('array-panel-section-header')}>
          {Lang.messages['desk-policy'][this.getLayout().title]}
          <div
            className="button deleteArrayPanel"
            style={{ float: 'right' }}
            onClick={this.onDeleteClicked}
          >
            X
          </div>
        </div>
        <div className={ClassNames('array-panel-section-body')}>
          {renderByLayout.call(
            this,
            this.getModel(),
            this.getLayout().body,
            this.getIndex()
          )}
        </div>
      </div>
    );
  }
  onDeleteClicked = () => {
    let modelArray = this.getModelArray();
    if (modelArray) {
      let model = this.getModel();
      model.delete = true;
      let deleteIndex = modelArray.findIndex((model) => {
        return model.delete;
      });
      modelArray.splice(deleteIndex, 1);
      this.getParentComponent().forceUpdate();
    }
  };
  addFeeAmountToCoverageFee = () => {
    this.getParentComponent()
      .getParentComponent()
      .getParentComponent()
      .addFeeAmountItemToFee(this.getModel().coverageFee);
    this.getParentComponent().forceUpdate();
  };
  getComponent() {
    return this;
  }
  getParentComponent() {
    return this.props.parentComponent;
  }
  getModelArray() {
    return this.props.modelArray;
  }
  getModel() {
    return this.props.model;
  }
  getLayout() {
    return this.props.layout;
  }
  getIndex() {
    return this.props.index;
  }
}

/**
 * panel, 容器
 */
class Panel extends Widget {
  renderBody() {
    const layout = this.getLayout().body;
    if (layout) {
      return (
        <div className="policy-section-body">
          {renderByLayout.call(this, this.getModel(), layout)}
        </div>
      );
    }
  }
  renderDeleteButton() {
    if (this.getLayout().deleteTitle) {
      return (
        <span
          className="button deletePanel"
          onClick={this.onDeletedClicked}
          style={{ float: 'right' }}
        >
          X
        </span>
      );
    } else {
      return;
    }
  }
  render() {
    return (
      <div className="policy-section">
        <div className="policy-section-header">
          <div className="policy-section-header-title">
            <span onClick={this.onDeletedClicked}>{this.getTitle()}</span>
            {this.renderDeleteButton()}
          </div>
        </div>
        {this.renderBody()}
        <div className="policy-section-footer"></div>
      </div>
    );
  }
  onDeletedClicked = () => {
    let modelArray = this.getModelArray();
    let model = this.getModel();
    let parentComponent = this.getParentComponent();
    if (modelArray) {
      model.delete = true;
      let deleteIndex = modelArray.findIndex((model) => {
        return model.delete;
      });
      modelArray.splice(deleteIndex, 1);
      parentComponent.forceUpdate();
    }
  };

  //添加佣金明细到佣金
  addCommissionToCommissions = () => {
    this.getParentComponent()
      .getParentComponent()
      .addCommissionItemToCommissions();
    this.getParentComponent().forceUpdate();
  };

  //添加费用明细到保单费用
  addFeeAmountToPolicyFee = () => {
    this.getParentComponent()
      .getParentComponent()
      .addFeeAmountItemToFee(this.getModel());
    this.getParentComponent().forceUpdate();
  };

  //添加费用明细到标的费用
  addFeeAmountToInsuredFee = () => {
    this.getParentComponent()
      .getParentComponent()
      .addFeeAmountItemToFee(this.getModel().insuredFee);
    this.getParentComponent().forceUpdate();
  };
  //添加险种列表到标的
  onAddCoverageClicked = () => {
    this.getParentComponent()
      .getParentComponent()
      .addCoverageItemToInsuredItem(this.getModel());
    this.getParentComponent().forceUpdate();
  };
  getParentComponent() {
    return this.props.parentComponent;
  }
  getComponent() {
    return this;
  }
  getModelArray() {
    return this.props.modelArray;
  }
  getModel() {
    return this.props.model;
  }
  getLayout() {
    return this.props.layout;
  }
  getTitle() {
    return Lang.messages['desk-policy'][this.getLayout().title];
  }
}

/**
 * 保单
 */
class DeskPolicyInput extends ReactRouterContextWidget {
  constructor(props, context) {
    super(props, context);
    this.state.layout = layout;
    const productLayout = props.layout;
    if (productLayout) {
      this.state.layout = $.extend({}, layout, productLayout);
    }
  }
  renderConfirmButton() {
    return (
      <Button
        className="form-btn primary"
        style={{ float: 'right' }}
        onClick={this.onConfirmClicked}
      >
        {this.getConfirmLabel()}
      </Button>
    );
  }
  render() {
    return (
      <div className="desk-policy desk-form four-cols no-border">
        <div className="form-header">
          <div
            className="button addPanel"
            onClick={this.onAddInsuredClicked}
            style={{ float: 'left', marginLeft: '20px', marginRight: '40px' }}
          >
            添加标的
          </div>
          <div
            className="button addPanel"
            onClick={this.onAddPolicyCustomerInsuredClicked}
            style={{ float: 'left', marginRight: '40px' }}
          >
            添加被保人
          </div>
          <div
            className="button addPanel"
            onClick={this.onAddPolicyCustomerBeneficiaryClicked}
            style={{ float: 'left', marginRight: '40px' }}
          >
            添加受益人
          </div>
        </div>
        <div className="form-body">
          {renderByLayout.call(this, this.getPolicy(), this.getLayout())}
        </div>
        <div className="form-footer">
          <div
            className="form-row product-form-button"
            style={{ float: 'right', width: '50%' }}
          >
            <div className="form-button">
              <Button
                className="form-btn waive"
                style={{ float: 'right' }}
                onClick={this.onCancelClicked}
              >
                {Lang.messages.common.back}
              </Button>
              {this.renderConfirmButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }
  onAddInsuredClicked = () => {
    this.getParentComponent().addInsuredItem();
  };
  onAddPolicyCustomerInsuredClicked = () => {
    this.getParentComponent().addPolicyCustomerInsured();
  };
  onAddPolicyCustomerBeneficiaryClicked = () => {
    this.getParentComponent().addPolicyCustomerBeneficiary();
  };
  getParentComponent() {
    return this.props.parentComponent;
  }
  getComponent = () => {
    return this;
  };
  getConfirmLabel() {
    return Lang.messages.common.confirm;
  }
  onConfirmClicked = () => {
    this.validateAndSubmitData();
  };

  componentValidate(component, refName, validation, shouldFocus) {
    if (refName.startsWith('array-')) {
      Object.keys(component.refs).forEach((itemRefName) => {
        let itemComponent = component.refs[itemRefName];
        this.componentValidate(
          itemComponent,
          itemRefName,
          validation,
          shouldFocus
        );
      });
    } else {
      component.doValidate(true);
      const componentValidation = component.getValidationResult();
      if (shouldFocus.component === null && componentValidation.hasFailure()) {
        shouldFocus.component = component;
      }
      validation.mergeFrom(component.getValidationResult());
    }
  }

  onCancelClicked = () => {
    Toast.hide();
    this.historyBack();
  };
  submitData() {
    const policy = this.getPolicy();
    this.doPost('/rest/v1/policy/create', policy).done((data) => {
      Toast.showSuccess();
    });
  }
  validateAndSubmitData() {
    if (this.validate().hasFailure()) {
      Toast.showValidationFail();
      this.forceUpdate();
      return;
    }
    this.submitData();
  }
  validate() {
    const validation = new Validation();
    let shouldFocus = {
      component: null,
    };

    Object.keys(this.refs).forEach((panelRef, index) => {
      Object.keys(this.refs[panelRef].refs).forEach((lableRef, index) => {
        this.componentValidate(
          this.refs[panelRef].refs[lableRef],
          lableRef,
          validation,
          shouldFocus
        );
      });
    });
    if (shouldFocus.component != null) {
      shouldFocus.component.focus();
    }
    return validation;
  }
  getPolicy() {
    return this.props.model;
  }
  getLayout() {
    return this.state.layout;
  }
}

export default DeskPolicyInput;
