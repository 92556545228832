import { React, $ } from '../../../../common/3rd';
import { Lang, Utils } from '../../../../common/common';

import AtomicComponentThing from '../atomic-component';
import Dater from './date';
import Dropdown from './dropdown';

/**
 * 生日
 */
class Birth extends Dater {
  getDefaultClassName() {
    return 'sth-date sth-birth';
  }
  getMaxDate() {
    let sTime = 27 * 1000 * 60 * 60 * 24;
    let model = this.props.model;
    let root = this.context.root;
    let insuredName = null;
    if (root.insureds && root.insureds.length > 0) {
      insuredName = root.insureds[0].insuredName;
    }
    if (insuredName && insuredName !== '京康护宝少儿重大疾病保险') {
      sTime = 0;
    }
    return Utils.getOriginFormatToday(sTime);
  }
  getMinDate() {
    let model = this.props.model;
    let root = this.context.root;
    let insuredName = null;
    if (root.insureds && root.insureds.length > 0) {
      insuredName = root.insureds[0].insuredName;
    }
    if (insuredName && insuredName == '京康护宝少儿重大疾病保险') {
      return Utils.getOriginFormatFrom(18);
    } else {
      return Utils.getOriginFormatFrom(100);
    }
  }
  getPlaceholder() {
    return super.getPlaceholder() || Lang.messages.common.birth;
  }
  setValueToModel(value, callback, force, id) {
    const ageId = this.getAgeId();
    if (super.setValueToModel(value) !== false && value != null && ageId) {
      const age = Utils.age(value).toString();
      super.setValueToModel(age, null, false, ageId);
    }
  }
  getAgeId() {
    return this.getLayout().ageId;
  }
}

/**
 * 年龄
 */
class Age extends Dropdown {
  getDefaultClassName() {
    return 'sth-dropdown sth-age';
  }
  getCodes() {
    const layout = this.getLayout();

    let carrier = layout;
    if (layout.codes) {
      carrier = super.getCodes();
    }

    if (Array.isArray(carrier)) {
      return carrier.map((age) => {
        if (typeof age === 'number') {
          return { v: age, l: age };
        } else {
          return age;
        }
      });
    } else {
      const max = carrier.max || 100;
      let min = carrier.min || 0;
      const options = [];
      for (; min <= max; min++) {
        options.push({ v: min, l: min });
      }
      return options;
    }
  }
  translateCodes(codes) {
    return codes;
  }
  getPlaceholder() {
    return super.getPlaceholder() || Lang.messages.common.age;
  }
}

/**
 * 生日和年龄
 */
class BirthAge extends AtomicComponentThing {
  shouldInstallDefaultIdToEventEmitter() {
    return false;
  }
  render() {
    return (
      <div {...this.getPrototypes()}>
        <Age
          model={this.getModel()}
          layout={this.getAgeLayout()}
          parent={this}
        />
        <Birth
          model={this.getModel()}
          layout={this.getBirthLayout()}
          parent={this}
        />
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-birth-age';
  }
  getAgeLayout() {
    const layout = this.getLayout();
    return $.extend({}, layout, {
      id: layout.ageId || layout.id,
    });
  }
  getBirthLayout() {
    const layout = this.getLayout();
    return $.extend({}, layout, {
      id: layout.birthId,
      ageId: layout.ageId || layout.id,
    });
  }
}

export { Age, Birth, BirthAge };
