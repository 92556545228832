import { React, ReactDOM, $ } from '../../common/3rd';

import { Widget } from '@/component/components';

import './widgets.scss';

/**
 * search text
 */
let TIMER = null;
class Search extends Widget {
  render() {
    return (
      <div className={this.getWidgetClassName('search')}>
        <input
          type="search"
          id={this.getDomId()}
          placeholder={this.getPlaceholder()}
          onFocus={this.onFocus}
          onKeyUp={this.onKeyUp}
          onChange={this.onChanged}
          ref="text"
        />
        <span className="fa fa-fw fa-search" onClick={this.onSearchClicked} />
      </div>
    );
  }
  getDomId() {
    return this.getPropsValue('id');
  }
  getPlaceholder() {
    return this.getPropsValue('placeholder');
  }

  onFocus = (evt) => {
    $(evt.target).select();
  };
  onKeyUp = (evt) => {
    if (evt.keyCode === 13) {
      evt.preventDefault();
      evt.stopPropagation();
      this.search();
    }
  };
  onChanged = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.change();
  };
  change() {
    let value = $(ReactDOM.findDOMNode(this.refs.text)).val();
    let change = this.props.change;
    clearTimeout(TIMER);
    if (change) {
      TIMER = setTimeout(() => {
        change.call(this, value);
      }, 300);
    }
  }
  onSearchClicked = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.search();
  };
  search() {
    let search = this.props.search;
    let value = $(ReactDOM.findDOMNode(this.refs.text)).val();
    if (search) {
      search.call(this, value);
    }
  }

  setValue(value) {
    if (value) {
      $(ReactDOM.findDOMNode(this.refs.text)).val(value);
    }
  }
  getValue() {
    return $(ReactDOM.findDOMNode(this.refs.text)).val().trim();
  }
}

export default Search;
