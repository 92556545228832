import { React, $, ReactDOM } from '../../../../common/3rd';
import { Lang } from '../../../../common/common';
import ContainerThing from '../container';
import {
  PageBody,
  PageHeader,
  PageFooter,
  BottomButton,
  TopBar,
  PageLoading,
  SearchBar,
} from '../../../components';

import messages from '../../../../mobile/me/messages.json';

Lang.installMessages(messages, 'me');
let CUSTOMER_LIST_DATA = [];
let CURRENT_CUSTOMER = [];
let CUSTOMER = {};

class importCustomer extends ContainerThing {
  render() {
    return (
      <div className="sth-customer-import">
        <span onClick={() => this.onCustomerImportClick()}>客户导入</span>
        <div className="sth-import-customer" ref="content">
          <TopBar
            title={'我的客户'}
            back={true}
            backAction={this.onBackClicked}
          />
          <PageBody>
            {this.renderCustomerListWrap()}
            {this.renderCustomerIndex()}
          </PageBody>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.onChanged('');
    this.refs.content.style.display = 'none';
    this.refs.content.style.left = '-100vw';
  }

  fetchData() {
    PageLoading.start();
    let url = '/rest/v1/client/list';
    this.doGet(url)
      .done((data) => {
        CUSTOMER_LIST_DATA = data.body;
        CUSTOMER_LIST_DATA.forEach((item, index) => {
          // item.fullName = item.fullName.trim();
          item.firstName = item.firstName.trim();
        });
        CURRENT_CUSTOMER = this.getCurrentCustomerGroup();
        this.forceUpdate();
      })
      .always(() => {
        PageLoading.end();
      });
  }

  getCurrentCustomerGroup(value) {
    if (this.pinyin) {
      return this.getCurrentCustomer(value).reduce((preValue, curValue) => {
        let customerName = curValue.firstName || curValue.fullName;
        if (/^[\u2E80-\u9FFF]+/.test(customerName)) {
          customerName = this.pinyin.normalize(
            this.pinyin(customerName).join('')
          );
        }
        let index =
          customerName.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
        if (index < 0 || index > 25) {
          preValue['sharp'] = preValue['sharp'] || [];
          preValue['sharp'].push(curValue);
        } else {
          preValue[index] = preValue[index] || [];
          preValue[index].push(curValue);
        }

        return preValue;
      }, []);
    }
    import('pinyin.js').then((res) => {
      this.pinyin = res.default;
      CURRENT_CUSTOMER = this.getCurrentCustomerGroup(value);
      this.forceUpdate();
    });
    return [];
  }

  onCustomerImportClick = (evt) => {
    this.refs.content.style.display = 'block';
    this.refs.content.style.left = '0';
    // if (CUSTOMER_LIST_DATA.length === 0) {
    this.fetchData();
    // }
    // 从左边移入
    // $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: 0 }, 400);
    // $(ReactDOM.findDOMNode(this.refs.indexWrap)).animate({ position: 'fixed' }, 400);
    this.refs.indexWrap.style.position = 'fixed';
  };
  onBackClicked = () => {
    this.refs.content.style.display = 'none';
    this.refs.content.style.left = '-100vw';
    //右边划出
    // this.refs.indexWrap.style.position = 'absolute'
    // $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: '100vw' }, 400);
  };

  getCurrentCustomer(value) {
    if (!value || value === '_') {
      return CUSTOMER_LIST_DATA;
    }
    return CUSTOMER_LIST_DATA.filter((customer) => {
      return customer.firstName.indexOf(value) >= 0;
    });
  }

  renderCustomerIndex() {
    return (
      <div className="customer-index-wrap" ref="indexWrap">
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20, 21, 22, 23, 24, 25,
        ].map((value) => {
          return (
            <div
              className="customer-index"
              key={`customer-index-${value}`}
              ref={`customer-index-${value}`}
              data-value={value}
              onClick={this.onCustomerIndexClicked}
            >
              {String.fromCharCode(value + 'A'.charCodeAt(0))}
            </div>
          );
        })}
        <div
          className="customer-index"
          key="customer-index-sharp"
          ref="customer-index-sharp"
          data-value="sharp"
          onClick={this.onCustomerIndexClicked}
        >
          #
        </div>
      </div>
    );
  }

  onCustomerIndexClicked = (event) => {
    let index = $(event.target).data('value');
    $(event.target).siblings().removeClass('active');
    $(event.target).addClass('active');
    if (this.refs[`customer-group-${index}`]) {
      ReactDOM.findDOMNode(this.refs[`customer-group-${index}`]).scrollIntoView(
        true
      );
    }
  };

  renderCustomerListWrap() {
    return (
      <div className="customer-list-wrap">
        {this.renderCustomerListHeader()}
        {this.renderCustomerGroupWrap()}
        {this.renderCustomerGroupWrapEnd()}
      </div>
    );
  }

  renderCustomerListHeader() {
    return (
      <div className="customer-group-wrap">
        <div className="customer-list-header">
          {[
            {
              label: Lang.messages.me.allCustomers,
              value: 0,
            },
            {
              label: Lang.messages.me.prospectiveCustomers,
              value: 1,
            },
            {
              label: Lang.messages.me.customers,
              value: 2,
            },
          ].map((item, index) => {
            if (item.value === 0) {
              return (
                <span
                  className="active"
                  key={index}
                  onClick={(e) => this.onHeaderTagClick(e.target, item.value)}
                >
                  {item.label}
                </span>
              );
            } else {
              return (
                <span
                  key={index}
                  onClick={(e) => this.onHeaderTagClick(e.target, item.value)}
                >
                  {item.label}
                </span>
              );
            }
          })}
        </div>
        <SearchBar
          search={this.onSearchClicked}
          change={this.onChanged}
          placeholder={Lang.messages.me.searchCustomer}
        />
      </div>
    );
  }

  onChanged = (value) => {
    CURRENT_CUSTOMER = this.getCurrentCustomerGroup(value);
    this.forceUpdate();
  };
  onSearchClicked = (value) => {
    CURRENT_CUSTOMER = this.getCurrentCustomerGroup(value);
    this.forceUpdate();
  };

  onHeaderTagClick(node, value) {
    if (node.className !== 'active') {
      if (value > 0) {
        this.fetchCustomerList(value);
      } else {
        this.fetchData();
      }
    }
    node.parentNode.childNodes.forEach((child) => {
      child.className = '';
    });
    node.className = 'active';
  }

  fetchCustomerList(value) {
    PageLoading.start();
    let url = `/rest/v1/client/list/policyCustomerType?policyCustomerType=${value}`;
    this.doGet(url)
      .done((data) => {
        CUSTOMER_LIST_DATA = data.body;
        CURRENT_CUSTOMER = this.getCurrentCustomerGroup();
        this.setState({ loadMore: false });
        this.forceUpdate();
      })
      .always(() => {
        PageLoading.end();
      });
  }

  renderCustomerGroupWrapEnd() {
    if (CURRENT_CUSTOMER['sharp']) {
      return (
        <div
          className="customer-group-wrap"
          key="customer-group-sharp"
          ref="customer-group-sharp"
        >
          <div className="customer-group-title">#</div>
          <div className="customer-group-content">
            {this.renderCustomerItem(CURRENT_CUSTOMER['sharp'], 0)}
          </div>
        </div>
      );
    }
  }

  renderCustomerGroupWrap() {
    return CURRENT_CUSTOMER.map((customerGroup, index) => {
      return (
        <div
          className="customer-group-wrap"
          key={`customer-group-${index}`}
          ref={`customer-group-${index}`}
        >
          <div className="customer-group-title">
            {String.fromCharCode(index + 'A'.charCodeAt(0))}
          </div>
          <div className="customer-group-content">
            {this.renderCustomerItem(customerGroup, index)}
          </div>
        </div>
      );
    });
  }

  renderCustomerItem(customerGroup, groupIndex) {
    return customerGroup.map((customerItem, index) => {
      return (
        <div
          className="customer-item"
          key={`customer-item-${groupIndex}-${index}`}
          ref={`customer-item-${groupIndex}-${index}`}
          onClick={() => this.onCustomerClicked(customerItem)}
        >
          {customerItem.firstName}
        </div>
      );
    });
  }

  onCustomerClicked(customerItem) {
    let { id, parent } = this.props;
    let model = parent.props.model;
    if (id === 'policyInsuredAdvice') {
      let insured = model.policyCustomers.filter((v) => v.customerType === 2);
      model = insured[0];
    }
    if (id === 'policyHolder') {
      model = model[id];
    }
    model['firstName'] = customerItem.firstName || customerItem.fullName;
    model['gender'] = customerItem.gender;
    model['dateOfBirth'] = customerItem.dateOfBirth;
    model['extensionData']['age'] = customerItem.extensionData
      ? customerItem.extensionData.age || null
      : null;
    model['idType'] = customerItem['idType'] || '';
    model['idNo'] = customerItem['idNo'] || '';
    model['extensionData']['idLongTermIndi'] =
      (customerItem.extensionData &&
        customerItem.extensionData['idLongTermIndi']) ||
      '';
    model['extensionData']['idValidTill'] =
      (customerItem.extensionData &&
        customerItem.extensionData['idValidTill']) ||
      '';
    model['extensionData']['maritalStatus'] =
      (customerItem.extensionData &&
        customerItem.extensionData['maritalStatus']) ||
      '';
    model['extensionData']['job1'] =
      (customerItem.extensionData && customerItem.extensionData['job1']) || '';
    model['extensionData']['job2'] =
      (customerItem.extensionData && customerItem.extensionData['job2']) || '';
    model['extensionData']['job3'] =
      (customerItem.extensionData && customerItem.extensionData['job3']) || '';
    model['extensionData']['job4'] =
      (customerItem.extensionData && customerItem.extensionData['job4']) || '';
    model['extensionData']['height'] =
      (customerItem.extensionData && customerItem.extensionData['height']) ||
      '';
    model['extensionData']['weight'] =
      (customerItem.extensionData && customerItem.extensionData['weight']) ||
      '';
    model['extensionData']['annualIncome'] =
      (customerItem.extensionData &&
        customerItem.extensionData['annualIncome']) ||
      '';
    model['extensionData']['workUnit'] =
      (customerItem.extensionData && customerItem.extensionData['workUnit']) ||
      '';
    model['mobile'] = customerItem['mobile'] || '';
    model['email'] = customerItem['email'] || '';
    model['provinceCode'] = customerItem['provinceCode'] || '';
    model['cityCode'] = customerItem['cityCode'] || '';
    model['regionCode'] = customerItem['regionCode'] || '';
    model['postCode'] = customerItem['postCode'] || '';
    model['addressLine1'] = customerItem['addressLine1'] || '';
    parent.props.parent.forceUpdate();
    this.onBackClicked();
  }
}

export default importCustomer;
