import { React, $ } from '../../../common/3rd';
import { Envs, Swiper } from '../../../common/common';
import './raiseOld.scss';
//引导
let leadTwo =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/banner/leadTwo.png';

class RaiseOld extends React.Component {
  constructor(context) {
    super();
    this.state = {
      projectShow: true,
    };
  }
  UNSAFE_componentWillMount() {
    this.waitTime();
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidMount() {}
  waitTime = () => {
    let wait = 6;
    this.timer = setInterval(() => {
      wait = wait - 1;
      if (wait <= 0) {
        this.setState({
          projectShow: false,
        });
        clearInterval(this.timer);
        this.forceUpdate();
      } else {
        $('.senMsgNew').html(wait);
      }
    }, 1000);
  };
  render() {
    return (
      <div className={this.state.projectShow ? 'leadWrap' : 'leadWrapNo'}>
        <div className="lead-banner">
          <img
            className="leftOne oneBom"
            src={leadTwo}
            onClick={this.gotoPhoto}
            alt=""
          />
          <div className="slideJump" onClick={this.gotoClose}>
            <span className="senMsgNew"></span>跳过
          </div>
        </div>
        <div className="leadMsk"></div>
      </div>
    );
  }
  gotoClose = () => {
    this.setState({
      projectShow: false,
    });
    this.forceUpdate();
  };
  gotoPhoto = () => {
    this.props.gotoProject();
  };
}

export default RaiseOld;
