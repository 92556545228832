/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 微信工具
 * @author LiuH
 */
import wx from 'weixin-js-sdk';
import {
  isWechat,
  isWechatMini,
  isWechatMiniSDKReady,
} from '@/util/precondition';
import LogUtil from '@/util/log';
import { AppError, AppErrorCode } from '@/error';

class Wechat {
  /**
   * 生成微信网页授权链接
   * @param {string} appid 公众号appid
   * @param {string} redirect_uri 重定向链接
   * @returns {string} 微信网页授权链接
   */
  static generateOAuthUrl(appid, redirect_uri) {
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
      redirect_uri
    )}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
  }
}

class Mini {
  constructor() {
    this.isMini = false;
  }

  /**
   * 初始化
   * @return {Promise<unknown>}
   */
  init() {
    return new Promise((resolve, reject) => {
      let self = this;
      // 微信Android/iOS >= 7.0.0
      if (isWechatMini()) {
        self.isMini = true;
        resolve(true);
        return;
      }
      if (!isWechat()) {
        self.isMini = false;
        // 非微信环境
        reject(new AppError(AppErrorCode.MINI_ENV_ERROR, '小程序运行环境异常'));
        return;
      }
      if (!isWechatMiniSDKReady()) {
        self.isMini = false;
        // 小程序SDK未就绪
        reject(
          new AppError(AppErrorCode.MINI_SDK_NOT_READY, '小程序SDK未就绪')
        );
        return;
      }
      wx.miniProgram.getEnv((res) => {
        if (res && res.miniprogram) {
          // 微信小程序环境
          self.isMini = true;
          resolve(true);
        } else {
          self.isMini = false;
          reject(
            new AppError(AppErrorCode.MINI_ENV_ERROR, '小程序运行环境异常')
          );
        }
      });
    });
  }

  /**
   * 判断是否为小程序
   * @return {boolean}
   */
  isMiniProgram() {
    return isWechatMini() || this.isMini;
  }

  /**
   * 小程序：关闭当前页面，返回 delta 级页面
   * @param {number} [delta=1] 回退的页面数
   */
  back(delta = 1) {
    if (isWechatMiniSDKReady()) {
      wx.miniProgram.navigateBack({
        delta: delta,
        fail: function (error) {
          LogUtil.error(error);
        },
      });
    }
  }

  /**
   * 小程序：保留当前页面，跳转到指定(非tabbar)页面
   * @param {string} page 小程序页面
   */
  push(page) {
    if (isWechatMiniSDKReady()) {
      wx.miniProgram.navigateTo({
        url: page,
        fail: function (error) {
          LogUtil.error(error);
        },
      });
    }
  }

  /**
   * 小程序：不保留当前页面，跳转到指定(非tabbar)页面
   * @param {string} page 小程序页面
   */
  replace(page) {
    if (isWechatMiniSDKReady()) {
      wx.miniProgram.redirectTo({
        url: page,
        fail: function (error) {
          LogUtil.error(error);
        },
      });
    }
  }

  /**
   * 小程序：关闭所有页面，打开指定页面
   * @param {string} page 小程序页面
   */
  relaunch(page) {
    if (isWechatMiniSDKReady()) {
      wx.miniProgram.reLaunch({
        url: page,
        fail: function (error) {
          LogUtil.error(error);
        },
      });
    }
  }
}

let mini = new Mini();

export { Wechat, mini };
