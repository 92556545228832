import { React, ReactDOM, ClassNames, $ } from '../../common/3rd';
import { CheckableWidget } from './widget';

/**
 * checkbox
 */
class CheckBox extends CheckableWidget {
  renderRealWidget(props) {
    return <input type="checkbox" {...props} ref="widget" />;
  }
  wrapOthers(props) {
    props.className = ClassNames(props.className, 'check-box');
    return this;
  }
  getValue() {
    return $(ReactDOM.findDOMNode(this.refs.widget)).prop('checked');
  }
  setValue(value) {
    this.setValueIntoModel(value);
    return value ? this.check() : this.uncheck();
  }
  check() {
    $(ReactDOM.findDOMNode(this.refs.widget)).prop('checked', true);
    return this;
  }
  uncheck() {
    $(ReactDOM.findDOMNode(this.refs.widget)).prop('checked', false);
    return this;
  }
}

export default CheckBox;
