import { React } from '../../../../common/3rd'

// import AtomicComponentThing from '../atomic-component'
import AtomicMutableComponentThing from '../atomic-mutable-component'

/**
 * 电子签名提示信息
 * 例：业务员签名页面
 * add by susan.wu 2017 Dec.14
 * 
 */
class SignaturePrompt extends AtomicMutableComponentThing {
	constructor(props, context){
		super(props, context);
		this.state.isConfirm = false;
		this.currentSignaturePerson = 3;//当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
	}
	render() {
		this.currentSignaturePerson = this.props.parent.props.parent.state.currentSignaturePerson || 3;
		// this.setIsConfirm();
		let readClassName = this.state.isConfirm ? "confirm confirm-ck" : "confirm";
		return <div className="signaturePrompt-content" onClick={this.clickRead}>
					<span className={readClassName}></span>
					本人同意并签名确认，此签名可同时用于{this.renderDocument()}文件的签署
			</div>
	}
	//判断签字人对应的单证是否都生成了
	setIsConfirm() {
		let needSignedDocumentTypes = [];
		if(this.currentSignaturePerson === 3) {
			if(this.getModel().productId === 327) {
				needSignedDocumentTypes = this.getLayout().agentDocumentTypesToJAS;
			}else {
				needSignedDocumentTypes = this.getLayout().agentDocumentTypes;
			}
		}else if(this.currentSignaturePerson === 1) {
			if(this.getModel().productId === 327) {
				needSignedDocumentTypes = this.getLayout().policDocumentTypesToJAS;
			}else {
				needSignedDocumentTypes = this.getLayout().policDocumentTypes;
			}
			needSignedDocumentTypes = this.getLayout().policDocumentTypes;
		}else if(this.currentSignaturePerson === 2) {
			if(this.getModel().productId === 327) {
				needSignedDocumentTypes = this.getLayout().insuredDocumentTypesToJAS;
			}else {
				needSignedDocumentTypes = this.getLayout().insuredDocumentTypes;
			}
		}
		let signedDocuments = this.getModel().extensionData.documents;
		let signedLength = 0;
		needSignedDocumentTypes.map((needSignedDocumentType) => {
			signedDocuments.map((signedItem) => {
				if(signedItem.documentType && signedItem.documentType === needSignedDocumentType) {
					signedLength = signedLength + 1;
				}
				return null;
			})
			return null;
        });
		if(signedLength === needSignedDocumentTypes.length) {
			this.state.isConfirm = true;
		}else {
			this.state.isConfirm = false;
		}
	}
	renderDocument() {
		let describe = "";
		if(this.currentSignaturePerson === 3) {
			if(this.getModel().productId === 327) {
				describe = this.getLayout().agentDocumentsToJAS;
			}else {
				describe = this.getLayout().agentDocuments;
			}
		}else if(this.currentSignaturePerson === 1) {
			if(this.getModel().productId === 327) {
				describe = this.getLayout().policyDocumentsToJAS;
			}else {
				describe = this.getLayout().policyDocuments;
			}
		}else if(this.currentSignaturePerson === 2) {
			if(this.getModel().productId === 327) {
				describe = this.getLayout().insuredDocumentsToJAS;
			}else {
				describe = this.getLayout().insuredDocuments;
			}
		}
		return describe;
	}
	clickRead = () => {
		this.setState({
			isConfirm: !this.state.isConfirm
		})
	}
	getValueFromModel() {
		return this.state.isConfirm;
	}
}

export default SignaturePrompt