import { React, ClassNames, $ } from '../../../common/3rd'

import { ComponentThing, CODES_KEY, DECLARATIONS_KEY, PRODUCT_KEY, LOG_IMAGE,LIMIT_KEY } from './component'

// 定义
const DEFAULT_ROW_INDEX = 9999;
const DEFAULT_COLUMN_INDEX = 9999;
const DEFAULT_POSITION = { col: DEFAULT_COLUMN_INDEX, row: DEFAULT_ROW_INDEX };

const COMP_TEXT = 'text';

const COMPONENTS = {};

const VisibleThing = (SuperThing) => class extends SuperThing {
	/**
	 * didMount最后增加事件监听
	 */
	postDidMount() {
		super.postDidMount();
		this.installFilterIds('visible');
	}
	/**
	 * willUpdate最前去掉事件监听
	 */
	preWillUpdate(nextProps, nextState, nextContext) {
		super.preWillUpdate(nextProps, nextState, nextContext);
		this.uninstallFilterIds('visible');
	}
	/**
	 * didUpdate最后增加事件监听
	 */
	postDidUpdate(prevProps, prevState, prevContext) {
		super.postDidUpdate(prevProps, prevState, prevContext);
		this.installFilterIds('visible');
	}
	/**
	 * willUnmount最前去掉事件监听
	 */
	preWillUnmount() {
		super.preWillUnmount();
		this.uninstallFilterIds('visible');
	}
	render() {
		if (!this.isVisible()) {
			return null;
		} else {
			return super.render();
		}
	}
}

/**
 * 容器组件
 */
class ContainerThing extends ComponentThing {
	static registerComponents(components) {
		Object.keys(components).forEach(key => {
			COMPONENTS[key] = VisibleThing(components[key]);
		});
	}
	/**
	 * 容器默认不监听当前id
	 */
	shouldInstallDefaultIdToEventEmitter() {
		return false;
	}
	isHidden(layout, flag) {
		const hidden = layout.hidden;
		if (!hidden) {
			return false;
		}
		if (!flag && hidden.hideBodyLayout) {
			return false;
		}
		const hiddenValue = hidden.value;
		const hiddenValue2 = hidden.value2;
		let valueOfById = undefined;
		let valueOfById2 = undefined;
		if (hidden.rootModel && hidden.byId) {
			valueOfById = this.getRootComponent().getValueFromModel(hidden.byId);
		} else if (hidden.byId) {
			valueOfById = this.getValueFromModel(hidden.byId);
		}
		if (hidden.rootModel2 && hidden.byId2) {
			valueOfById2 = this.getRootComponent().getValueFromModel(hidden.byId2);
		} else if (hidden.byId2) {
			valueOfById2 = this.getValueFromModel(hidden.byId2);
		}
		if(hidden.byId && hidden.greaterThen && valueOfById > hiddenValue){
			return true;
		}else if (hidden.byId && (valueOfById == hiddenValue)) {
			return true;
		} else if (hidden.byId2 && (valueOfById2 === hiddenValue2)) {
			return true;
		} else {
			return false;
		}
	}
	renderCell(model, layout) {
		const hidden = this.isHidden(layout);
		if (hidden) {
			return null;
		}
		if (layout.type.endsWith('.c')) {
			// Cell组件
			return React.createElement(this.matchComponentType('cell'), {
				model: model,
				layout: $.extend({}, layout, { type: layout.type.substring(0, layout.type.length - 2) }),
				parent: this,
				key: layout.id,
				ref: layout.id
			});
		} else {
			// 标准组件
			return React.createElement(this.matchComponentType(layout.type), {
				model: model,
				layout: layout,
				parent: this,
				key: layout.id,
				ref: layout.id
			});
		}
	}
	renderHeader() {
		return null;
	}
	renderBody() {
		const model = this.getModel();
		const className = ClassNames('sth-container-body', this.getBodyClassName());

		if (!this.getLayout().layout) {
			return null;
		}
		return <div className={className}
			ref='body'>
			{this.getSortedLayout(this.getInternalLayout()).map(cell => {
				return this.renderCell(model, cell);
			})}
		</div>
	}
	renderFooter() {
		return null;
	}
	render() {
		if (this.isHidden(this.getLayout(), true)) {
			return null;
		}
		return <div {...this.getPrototypes() }
			ref='me'>
			{this.renderHeader()}
			{this.renderBody()}
			{this.renderFooter()}
		</div>
	}
	/**
	 * get body class name, default return null.
	 */
	getBodyClassName() {
		return null;
	}
	/**
	 * 获取排好序的布局定义.
	 * 获取组件的行和列, 如果没有定义, 默认为DEFAULT_ROW_INDEX, DEFAULT_COLUMN_INDEX.
	 * 同时如果没有定义过id, 则将key作为id. 没有定义过type, 则使用COMP_TEXT作为默认类型
	 * 
	 * @param {*} layout 
	 */
	getSortedLayout(layout) {
		return Object.keys(layout).filter(key => {
			return [CODES_KEY,DECLARATIONS_KEY,PRODUCT_KEY,LOG_IMAGE,LIMIT_KEY].indexOf(key) === -1;
		}).sort((keyA, keyB) => {
			const a = layout[keyA];
			const b = layout[keyB];
			const posOfA = a.pos || DEFAULT_POSITION;
			const posOfB = b.pos || DEFAULT_POSITION;
			const rowOfA = posOfA.row || DEFAULT_ROW_INDEX;
			const columnOfA = posOfA.col || DEFAULT_COLUMN_INDEX;
			const rowOfB = posOfB.row || DEFAULT_ROW_INDEX;
			const columnOfB = posOfB.col || DEFAULT_COLUMN_INDEX;
			return rowOfA === rowOfB ? columnOfA - columnOfB : rowOfA - rowOfB;
		}).map(key => {
			let cell = layout[key];
			cell.id = cell.id || key;
			// 设置默认为输入框
			cell.type = cell.type || COMP_TEXT;
			return cell;
		});
	}
	/**
	 * 首字母大写
	 * @param {*} type 
	 */
	matchComponentType(type) {
		return COMPONENTS[type];
	}
	/**
	 * 获取内部布局
	 */
	getInternalLayout() {
		return this.getLayout().layout || {};
	}
}

export default ContainerThing