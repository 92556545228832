const STATE = Symbol('_state');
const IS_INITIALIZED = Symbol('_isInitialized');

/**
 * 物理返回键🔒
 * added by yanzhi.mo
 *
 * @class BackLcok
 */
class BackLcok {
  constructor(lockPage, lockState, backPage) {
    this[STATE] = 'x - back';
    this[IS_INITIALIZED] = true;
    this.backPage = backPage;
    this.lockPage = lockPage;
    this.lockState = lockState;
    this.init();
  }

  /**
   * 初始化
   *
   * @memberof BackLcok
   */
  init() {
    window.addEventListener('popstate', this.onPopState);
    this.record(this[STATE]);
  }

  record = (state) => {
    // console.log('this.lockState => ', this.lockState)
    // console.log('this[IS_INITIALIZED] => ', this[IS_INITIALIZED])
    // if (this.lockState && !this[IS_INITIALIZED]) {
    //   window.history.pushState(state, null, this.lockPage);
    // }
    if (this.lockState) {
      window.history.pushState(state, null, this.lockPage);
    }

    // if (this[IS_INITIALIZED]) {
    //   this[IS_INITIALIZED] = false;
    // }
  };

  onPopState = (event) => {
    this.record(this[STATE]);
  };

  /**
   * 加锁路由
   *
   * @memberof BackLcok
   */
  lockRoute() {
    this.lockState = true;
  }

  /**
   * 解锁路由
   *
   * @memberof BackLcok
   */
  unlockRoute() {
    this.lockState = false;
  }
}

export default BackLcok;