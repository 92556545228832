import { React, $ } from '../../../../common/3rd';

// import AtomicComponentThing from '../atomic-component'
// import { ComponentThing } from '../component'
// import { format } from '../something-utils'

/**
 * 加载中
 * 例：自动核保成功
 * add by wendy.gu 2017 Oct.19
 *
 */
class UnderwritingSuc extends React.Component {
  constructor() {
    super();
    this.state = {
      checkVal: 2,
    };
  }
  getSeletedValue() {
    return this.state.checkVal;
  }
  render() {
    let checkTypeArr = this.props.checkTypeArr;
    return (
      <div className="underwriting-fail">
        <div className="title">
          <span className="sucIcon"></span>
          自核通过，请验证身份！
        </div>
        <div className="failCause">
          {checkTypeArr.map((item, index) => {
            let radioClass =
              item.v === this.state.checkVal ? 'radio radio-ck' : 'radio';
            return (
              <p
                className="checkItem"
                key={index}
                data-val={item.v}
                onClick={this.clickCheckType}
              >
                <span className={radioClass}></span>
                {item.l}
              </p>
            );
          })}
        </div>
        <div className="btns-container">
          <div className="btn-con">
            <div className="btn btn2" onClick={this.next}>
              下一步
            </div>
          </div>
        </div>
      </div>
    );
  }
  clickCheckType = (e) => {
    let t = $(e.target);
    if (!t.hasClass('checkItem')) {
      t = t.parents('.checkItem');
    }
    let l = parseInt(t.attr('data-val'));
    this.setState({
      checkVal: l,
    });
  };
  next = () => {
    if (this.state.checkVal === 1) {
      this.props.parent.props.parent.props.parent.jumpTo('photoCheck');
    }
    if (this.state.checkVal === 2) {
      this.props.parent.props.parent.props.parent.jumpTo('messageCheck');
    }
    if (this.state.checkVal === 3) {
      this.props.parent.props.parent.props.parent.jumpTo('faceCheck');
    }
  };
}

export default UnderwritingSuc;
