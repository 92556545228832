import { React } from '../../../../common/3rd';
import { Envs } from '../../../../common/common';
import AtomicComponentThing from '../atomic-component';
import Share from '../../../../mobile/share/share';
import { isWechat } from '@/util';

/**
 * 按钮
 */
class Button extends AtomicComponentThing {
  state = {
    label: '',
  };
  componentDidMount() {
    /* add by cheng.fan on Jan.22 2018
     * 安卓微信浏览器不支持download属性，点击无反应
     */
    if (isWechat()) {
      this.refs.sthbutton.removeAttribute('download');
    } else if (Envs.isIOS() && !isWechat() && this.getLabel() === '下载') {
      this.setState({ label: '分享' });
    }
    /*  */
  }
  render() {
    return (
      <a {...this.getPrototypes()} onClick={this.onClick} ref="sthbutton">
        {this.state.label || this.getLabel()}
      </a>
    );
  }
  onClick = (evt) => {
    if (Envs.isIOS() && !isWechat() && this.getLabel() === '下载') {
      evt.preventDefault();
      let url = this.getPrototypes().href;
      let index = url.lastIndexOf('/');
      let str = url.substring(index + 1, url.length);
      Share.share(str, '', url, '');
      return;
    }
    if (this.needCheckBeforeAction()) {
      const ret = this.checkByPhase(this.getPhases());
      if (ret !== true) {
        if (!this.isContinueWhenCheckFailed()) {
          return;
        } else {
          ret.done(() => {
            this.doAction();
          });
        }
      } else {
        this.doAction();
      }
    } else {
      this.doAction();
    }
  };
  doAction() {
    const action = this.getAction();
    if (action) {
      action.call(this);
    }
  }

  getDefaultClassName() {
    return 'sth-button';
  }
  getAction() {
    return this.getLayout().action;
  }
  needCheckBeforeAction() {
    return this.getLayout().check !== false;
  }
  isContinueWhenCheckFailed() {
    return this.getLayout().continueWhenCheckFailed === true;
  }
}

export default Button;
