var htmlStr = '<div>' +
    '    <div id="hintPage" width="100%" style="background: #FFFFFF;">' +
    '        <div id="hintContent" width="100%" style="background: #FFFFFF;">' +
    '            <div style="position: relative; " class="page-content" >' +
    '               <div>' +
    '                    <table width="100%">' +
    '                        <tr>' +
    '                            <td valign="middle" width="25%" >' +
    '                                <img id="logImage" width="100%" src="" />' +
    '                            </td>' +
    '                            <td valign="middle" width="15%">' +
    '                            </td>' +
    '                            <td valign="middle" align="right"  width="45%">' +
    '                                <div id="personalTaxPaper_policy_id" style="font-size: 20px;"></div>' +
    '                            </td>' +
    '                        </tr>' +
    '                    </table>' +
    '                </div>' +
    '                <div align="center" style="width: 100%; font-size: 20px; margin-top: 15px">北京人寿保险股份有限公司</div>' +
    '                <div align="center" style="width: 100%; font-size: 17px; margin-top: 15px">个人税收居民身份声明文件</div>' +
    '                <br />' +
    '                <br />' +
    '                <div style="font-size: 17px;"><b>姓名：</b><span id="name" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>' +
    '                <div style="font-size: 17px;">' +
    '                    <b>本人声明：' +
    '                        <input id="tax_type_0" type="checkbox" name="" value="" style="margin-bottom: 3px" />1.仅为中国税收居民' +
    '                        <input id="tax_type_1" type="checkbox" name="" value="" style="margin-bottom: 3px" />2.仅为非居民' +
    '                        <input id="tax_type_2" type="checkbox" name="" value="" style="margin-bottom: 3px" />3.既是中国税收居民又是其他国家（地区）税收居民' +
    '                    </b>' +
    '                </div>' +
    '                <div>' +
    '                    <div>如在以上选项中勾选第2项或者第3项，请填写下列信息：</div>' +
    '                    <div>' +
    '                        姓（英文或拼音）：<span id="name_eng_0" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        名（英文或拼音）：<span id="name_eng_1" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                    </div>' +
    '                    <div>出生日期：<span id="birth_date" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>' +
    '                    <div>' +
    '                        现居地址（中文）：<span id="address_ch_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （国家）<span id="address_ch_province" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （省）<span id="address_ch_city" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （市）（境外地址可不填此项）' +
    '                    </div>' +
    '                    <div>' +
    '                        （英文或拼音）：<span id="address_eng_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （国家）<span id="address_eng_province" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （省）<span id="address_eng_city" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （市）' +
    '                    </div>' +
    '                    <div>' +
    '                        出生地（中文）：<span id="birthPlace_ch_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （国家）<span id="birthPlace_ch_province" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （省）<span id="birthPlace_ch_city" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （市）（境外地址可不填此项）' +
    '                    </div>' +
    '                    <div>' +
    '                        （英文或拼音）：<span id="birthPlace_eng_country" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （国家）<span id="birthPlace_eng_province" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （省）<span id="birthPlace_eng_city" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                        （市）' +
    '                    </div>' +
    '                </div>' +
    '                <div style="margin-top: 15px;">' +
    '                    <div><b>税收居民国（地区）及纳税人识别号：</b></div>' +
    '                    <div>1.<span id="taxPayerCode0" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>' +
    '                    <div>2.<span id="taxPayerCode1" style="text-decoration: underline;">（如有）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>' +
    '                    <div>3.<span id="taxPayerCode2" style="text-decoration: underline;">（如有）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>' +
    '                    <div>如不能提供居民国（地区）纳税人识别号，请选择原因：</div>' +
    '                    <div><input id="not_provide_reason_0" type="checkbox" name="" value="" style="margin-bottom: 3px;" />居民国（地区）不发放纳税人识别号</div>' +
    '                    <div><input id="not_provide_reason_1" type="checkbox" name="" value="" style="margin-bottom: 3px;" />' +
    '                        账户持有人未能取得纳税人识别号，如选此项，请解释具体原因：' +
    '                        <span id="not_provide_reason_detail" style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
    '                    </div>' +
    '                    <div><b>本人确认上述信息的真实、准确和完整，且当这些信息发生变更时，将在30日内通知贵机构，否则本人承' +
    '                        担由此造成的不利后果。</b></div>' +
    '                </div>' +
    '                <div style="overflow: hidden; margin-top: 15px;">' +
    '                    <div style="width: 50%; float: right; overflow: hidden;">' +
    '                        <div>' +
    '                            <span style="width: 8%;">签名：</span><img  id="imageApp1" style="width:15%;"/>' +
    '                            <span style="width: 8%;">日期：</span><span id="signDate"></span>' +
    '                        </div><br />' +
    '                        <div>' +
    '                            <span>签名人身份：</span>' +
    '                            <input name="self" id="self_sign" type="checkbox" name="" value="" style="margin-bottom: 3px;" />本人' +
    '                            <input name="agent" id="agent_sign" type="checkbox" name="" value="" style="margin-bottom: 3px;" />代理人' +
    '                        </div>' +
    '                    </div>' +
    '                </div>' +
    '                <div style="margin-top: 15px;">' +
    '                    <div><b>说明：</b></div>' +
    '                    <div>' +
    '                        1.本表所称中国税收居民是指在中国境内有住所， 或者无住所而在境内居住满一年的个人。在中国境内有住所是指因户籍、家庭、经济利' +
    '                        益关系而在中国境内习惯性居住。在境内居住满一年，是指在一个纳税年度中在中国境内居住365日。临时离境的，不扣减日数。临时离' +
    '                        境，是指在一个纳税年度中一次不超过30日或者多次累计不超过90日的离境。' +
    '                    </div>' +
    '                    <div>' +
    '                        2.本表所称非居民是指中国税收居民以外的个人。其他国家（地区）税收居民身份认定规则及纳税人识别号相关信息请参见国家税务总局网' +
    '                        站（http://www.chinatax.gov.cn/aeoi_index.html）。' +
    '                    </div>' +
    '                    <div>' +
    '                        3.军人、武装警察无需填写此声明文件。' +
    '                    </div>' +
    '                </div>' +
    '            </div>' +
    '            <br />' +
    '            <br />' +
    '        </div>' +
    '    </div>' +
    '</div>';
export default htmlStr;