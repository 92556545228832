import { React, ClassNames } from '../../common/3rd';
import { Lang } from '../../common/common';
import { CheckableWidget } from './widget';

/**
 * dropdown
 */
class Dropdown extends CheckableWidget {
  renderRealWidget(props) {
    return (
      <select {...props} ref="widget">
        <option value="">{Lang.messages.common.please_select}</option>
        {this.props.children}
      </select>
    );
  }
  wrapOthers(props) {
    props.className = ClassNames(props.className, 'select');
    return this;
  }
  setValue(value) {
    if (value) {
      value = value + '';
    } else {
      value = '';
    }
    return super.setValue(value);
  }
  getValue() {
    const value = super.getValue();
    return value === '' ? null : value;
  }
  setValueIntoModel(value) {
    if (value === '') {
      value = null;
    }
    super.setValueIntoModel(value);
  }
}

export default Dropdown;
