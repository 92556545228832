/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description App错误码
 * @author LiuH
 */
const AppErrorCode = Object.freeze({
  /** 取消请求 */
  ABROT: 'aborted',
  /** 无网络连接 */
  OFFLINE: 'offline',
  /** 超时 */
  TIMEOUT: 'timeout',
  /** 服务器内部错误 */
  SERVER: 'server',
  /** 其他错误 */
  OTHER: 'other',
  /** 路由错误 */
  ROUTE: 'route',
  /** 未知错误 */
  UNKNOWN: 'unknown',
  /** 超过最大次数 */
  MANY_TIMES: 'manytimes',
  /** 操作过于频繁 */
  OFTEN: 'often',
  /** 失败 */
  FAIL: 'fail',
  /** 成功 */
  SUCCESS: 'suc',
  MINI_ENV_ERROR: '800000',
  MINI_SDK_NOT_READY: '800001',
});

export default AppErrorCode;
