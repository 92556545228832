/*
 * @Author: cheng.fan
 * @Date: unknow
 * @Last Modified by: cheng.fan
 * @Last Modified time: 2018-03-28 14:08:09
 */
import { React, $, ReactDOM } from '../../../../common/3rd';
import { Utils } from '../../../../common/common';

import ContainerThing from '../container';
import {
  PageBody,
  PageFooter,
  BottomButton,
  PageLoading,
  Alert,
} from '../../../components';
import { birthdayFormat, getAgeByBirth } from '@/util';

export default class OCRImport extends ContainerThing {
  state = {
    imgSrc1: null,
  };

  UNSAFE_componentWillMount() {
    // document.title = 'OCR导入'
  }

  render() {
    return (
      <div className="sth-customer-import">
        <span>身份证信息识别</span>
        <input
          className="upload-input"
          onChange={(e) => this.onUploadImgChange(e.target, 1)}
          type="file"
          accept="image/*"
        />
        {/* <div className='sth-import-customer sth-ocr-import' ref='content'>
                 <PageBody>
                 {this.renderUpload()}
                 </PageBody>
                 <PageFooter>
                 {this.renderBottomButtons()}
                 </PageFooter>
                 </div> */}
      </div>
    );
  }

  onOCRImportClick = (evt) => {
    this.refs.content.style.display = 'block';
    this.refs.content.style.left = '0';
  };

  renderUpload() {
    return (
      <div className="upload">
        <div className="upload-container">
          <i
            onClick={() => this.onDeleteImgClick('imgSrc1')}
            className="delete"
          ></i>
          <div className="img-container">
            点击上传身份证正面
            <img src={this.state.imgSrc1} alt="" />
            <input
              onChange={(e) => this.onUploadImgChange(e.target, 1)}
              type="file"
              accept="image/*"
            />
          </div>
          <p>(身份证正面)</p>
        </div>
      </div>
    );
  }

  onDeleteImgClick(type) {
    type === 'imgSrc1' && this.setState({ imgSrc1: '' });
    type === 'imgSrc2' && this.setState({ imgSrc2: '' });
  }

  onUploadImgChange(node, which) {
    var reader = new FileReader();
    let imgFile = node.files[0];
    if (!imgFile) {
      return;
    }
    let oFReader = new FileReader();
    oFReader.readAsDataURL(imgFile);
    oFReader.onload = (evt) => {
      let formData = new FormData();
      formData.append('file', imgFile);
      let result = evt.target.result;
      let img = new Image();
      img.src = result;
      img.onload = () => {
        let data = Utils.compress(img);
        if (which === 1) {
          this.setState({ imgSrc1: data });
          this.readyToCertificate(data, 2);
        } else if (which === 2) {
          this.setState({ imgSrc2: data });
          this.readyToCertificate(data, 3);
        }
      };
    };
  }

  //进行ocr验证
  readyToCertificate(str, recType) {
    let imgtype = str.split(',')[0].split('/')[1].split(';')[0];
    let paramdata = str.split(',')[1] + '==##' + recType + '==##==##null';
    // console.log(paramdata);
    // console.log(imgtype);
    // console.log(imgtype);
    let host = window.location.host;
    let url;
    if (host.includes('localhost')) {
      url = 'http://172.25.16.214:80/cxfServerX/doAllCardRecon';
    } else {
      url = window.location.origin + '/cxfServerX/doAllCardRecon';
    }
    // let url = 'http://172.25.16.214:80/cxfServerX/doAllCardRecon'; //内网
    // let url = 'http://117.144.214.249:13800/cxfServerX/doAllCardRecon'; //外网
    // let url = 'http://uat-b2c.beijinglife.com.cn/cxfServerX/'; //uat
    let requestData = {
      username: 'test',
      signdata: 'NULL',
      imgtype: imgtype,
      paramdata: '$paramdata',
    };
    // console.log(JSON.stringify(requestData).replace('$paramdata', paramdata));
    PageLoading.start();
    $.ajax({
      type: 'POST',
      url: url,
      data: JSON.stringify(requestData).replace('$paramdata', paramdata),
      contentType: 'text/plain;charset=UTF-8',
      // dataType: "jsonp",
      // contentType: "application/json; charset=utf-8",
      success: (res) => {
        if (!res) {
          return;
        }
        let data = JSON.parse(res);
        if (data.data) {
          if (data.data.message.status === -1) {
            Alert.message('识别失败');
          } else if (data.data.message.status === recType && recType === 2) {
            if (data.data.cardsinfo) {
              let item = data.data.cardsinfo.card.item;
              let resName = item.find((i) => i.desc === '姓名').content;
              let residNo = item.find((i) => i.desc === '公民身份号码').content;
              let birthday = item.find((i) => i.desc === '出生').content;
              let gender = item.find((i) => i.desc === '性别').content;
              if (this.props.id === 'policyHolder') {
                let model = this.props.parent.props.model;
                model.policyHolder.firstName = resName;
                model.policyHolder.idNo = residNo;
                model.policyHolder.gender = gender === '男' ? 'M' : 'F';
                model.policyHolder.dateOfBirth = birthdayFormat(birthday);
                model.policyHolder.extensionData.age = getAgeByBirth(birthday);
                model.policyHolder.idType = '0';
              } else if (this.props.id === 'policyInsured') {
                let insured = this.props.parent.props.model;
                insured.firstName = resName;
                insured.idNo = residNo;
                insured.gender = gender === '男' ? 'M' : 'F';
                insured.dateOfBirth = birthdayFormat(birthday);
                insured.extensionData.age = getAgeByBirth(birthday);
                insured.idType = '0';
              }
              this.getRootComponent().forceUpdate();
            }
          }
        }
        PageLoading.end();
      },
      error: () => {
        PageLoading.end();
      },
    });
  }

  renderBottomButtons() {
    const bottomButtons = [
      {
        label: `返回`,
        action: this.onLeftButtonClick,
      },
      {
        label: '导入',
        action: this.onRightButtonClick,
      },
    ];
    const className = bottomButtons.length === 2 ? 'twins' : '';
    return bottomButtons.map((button, buttonIndex) => {
      return (
        <BottomButton
          className={className}
          onClick={button.action}
          key={buttonIndex}
        >
          {button.label}
        </BottomButton>
      );
    });
  }

  onLeftButtonClick = () => {
    this.refs.content.style.display = 'none';
    this.refs.content.style.left = '-100vw';
  };
  onRightButtonClick = () => {};
}
