/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 存储
 * @author LiuH
 */
import { DS_KEY, DS_IV, decrypt, encrypt } from '@/util/crypto';
import LogUtil from '@/util/log';

const profileKey = encrypt('Profile', DS_KEY, DS_IV);

/**
 * 读取
 * @param {...any} args
 * @returns {object}
 */
const getLocal = (...args) => {
  const storage = {};
  args.forEach((arg) => {
    storage[arg] = window.localStorage.getItem(arg) || null;
  });
  return storage;
};

/**
 * 写入
 * @param {object} data
 */
const setLocal = (data) => {
  Object.keys(data).forEach((prop) => {
    const el = data[prop];
    window.localStorage.setItem(prop, el);
  });
};

/**
 * 删除
 * @param {...any} args
 */
const removeLocal = (...args) => {
  args.forEach((arg) => {
    window.localStorage.removeItem(arg);
  });
};

/**
 * 获取用户信息
 * @returns {null|object}
 */
const getProfile = () => {
  try {
    const profile = getLocal(profileKey)[profileKey];
    return JSON.parse(decrypt(profile, DS_KEY, DS_IV));
  } catch (e) {
    LogUtil.error(e);
    return null;
  }
};

/**
 * 保存用户信息
 * @param {object} profile
 */
const setProfile = (profile) => {
  setLocal({ [profileKey]: encrypt(JSON.stringify(profile), DS_KEY, DS_IV) });
};

/**
 * 删除用户信息
 */
const removeProfile = () => {
  removeLocal(profileKey);
};

/**
 * 获取Authorization
 * @returns {null|string}
 */
const getAuth = () => {
  try {
    const authorization = getLocal('Authorization')['Authorization'];
    return authorization;
  } catch (e) {
    LogUtil.error(e);
    return null;
  }
};

/**
 * 保存Authorization
 * @param {string} authorization
 */
const setAuth = (authorization) => {
  setLocal({ Authorization: authorization });
};

/**
 * 删除Authorization
 */
const removeAuth = () => {
  removeLocal('Authorization');
};

/**
 * 获取openId
 * @returns {null|string}
 */
const getOpenID = () => {
  try {
    const openID = getLocal('openId')['openId'];
    return openID;
  } catch (e) {
    LogUtil.error(e);
    return null;
  }
};

/**
 * 保存openId
 * @param {string} openID
 */
const setOpenID = (openId) => {
  setLocal({ openId: openId });
};

/**
 * 删除openID
 */
const removeOpenID = () => {
  removeLocal('openId');
};

/**
 * 获取锁定保单信息
 * @returns {null|string}
 */
const getLockPolicy = () => {
  try {
    const lockPolicy = getLocal('lockPolicy')['lockPolicy'];
    return JSON.parse(lockPolicy);
  } catch (e) {
    LogUtil.error(e);
    return null;
  }
};

/**
 * 保存锁定保单信息
 * @param {string} openID
 */
const setLockPolicy = (lockPolicy) => {
  setLocal({ lockPolicy: JSON.stringify(lockPolicy) });
};

/**
 * 删除锁定保单信息
 */
const removeLockPolicy = () => {
  removeLocal('lockPolicy');
};

const getMiniRequestType = () => {
  try {
    const requestType = getLocal('miniRequestType')['miniRequestType'];
    return requestType;
  } catch (e) {
    LogUtil.error(e);
    return null;
  }
};

const setMiniRequestType = (requestType) => {
  setLocal({ miniRequestType: requestType });
};

const removeMiniRequestType = () => {
  removeLocal('miniRequestType');
};

export {
  getLocal,
  setLocal,
  removeLocal,
  getProfile,
  setProfile,
  removeProfile,
  getAuth,
  setAuth,
  removeAuth,
  getOpenID,
  setOpenID,
  removeOpenID,
  getLockPolicy,
  setLockPolicy,
  removeLockPolicy,
  getMiniRequestType,
  setMiniRequestType,
  removeMiniRequestType,
};
