import { React, ReactDOM, $ } from '../../../../common/3rd';
// import { Lang } from '../../../../common/common';

import { Toggle } from '../component/toggle';
import TextArea from '../component/text-area';
import AtomicComponentThing from '../atomic-component';

class DeclaraToggleRemark extends AtomicComponentThing {
    // shouldInstallDefaultIdToEventEmitter() {
    // 	return false;
    // }
    constructor(props, context) {
        super(props, context);
        this.state.textAreaStatus = false;
    }

    /**
     * didMount最后增加事件监听
     */
    postDidMount() {
        super.postDidMount();
        this.installInforToEmitter();
        if (this.getValueFromModel().remark) {
            $(ReactDOM.findDOMNode(this.refs.textArea)).css({ display: 'block' });
        } else {
            $(ReactDOM.findDOMNode(this.refs.textArea)).css({ display: 'none' });
        }
    }

    /**
     * willUpdate最前去掉事件监听
     */
    preWillUpdate(nextProps, nextState, nextContext) {
        super.preWillUpdate(nextProps, nextState, nextContext);
        this.uninstallInforFromEmitter();
    }

    /**
     * didUpdate最后增加事件监听
     */
    postDidUpdate(prevProps, prevState, prevContext) {
        super.postDidUpdate(prevProps, prevState, prevContext);
        this.installInforToEmitter();
    }

    /**
     * willUnmount最前去掉事件监听
     */
    preWillUnmount() {
        super.preWillUnmount();
        this.uninstallInforFromEmitter();
    }

    /**
     * 添加对于标题栏toggleId和rightId的监听
     */
    installInforToEmitter() {
        this.installToEventEmitter({
            event: this.getDeclaraToggleId(),
            func : this.onDeclaraToggleChange
        });
    }

    /**
     * 取消对于标题栏toggleId和rightId的监听
     */
    uninstallInforFromEmitter() {
        this.uninstallFromEventEmitter({
            event: this.getDeclaraToggleId(),
            func : this.onDeclaraToggleChange
        });
    }

    getDeclaraToggleId() {
        return this.getId() + ".confirm";
    }

    getDeclaraTextAreaId() {
        return this.getId() + ".remark";
    }

    renderDeclaraToggle = () => {
        const toggleId = this.getDeclaraToggleId();
        if (toggleId) {
            return <Toggle model={ this.getModel() }
                           layout={ {
                               id   : toggleId,
                               class: 'sth-declara-toggle'
                           } }
                           parent={ this }/>;
        }
    }
    rendertextArea = () => {
        const textAreaId = this.getDeclaraTextAreaId();
        return <TextArea model={ this.getModel() }
                         ref='textArea'
                         layout={ {
                             id   : textAreaId,
                             class: 'sth-declara-area'
                         } }
                         parent={ this }/>
    }

    render() {
        return (
            <div { ...this.getPrototypes() }>
                <div className='sth-delara-warp'>
                    <div className='sth-declara-label'>{ this.getLayout().index + 1 }. { this.getLabel() }</div>
                    { this.renderDeclaraToggle() }
                </div>
                { this.rendertextArea() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-cell sth-declara-toggle-remark';
    }

    onDeclaraToggleChange = () => {
        this.getValueFromModel().parameters = this.getValueFromModel().confirm ? '是' : '否';
        const textArea = $(ReactDOM.findDOMNode(this.refs.textArea));
        textArea.slideToggle();
        // console.log(this.getModel());
    }
}

export default DeclaraToggleRemark;