/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 日期、时间工具
 * @author LiuH
 */

import { isNotEmptyString } from '@/util/precondition';

/**
 * 将 Date 转化为指定格式的String
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @param {string} fmt Format pattern
 * @example
 *          formatNow("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *          formatNow("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
function formatNow(fmt) {
  const now = new Date();
  return format(now, fmt);
}

/**
 * 将 Date 转化为指定格式的String
 * @param {Date} date
 * @param {string} fmt Format pattern
 * @example
 *          formatNow(new Date(), "yyyy-MM-dd hh:mm:ss.S")
 *          formatNow(new Date(), "yyyy-M-d h:m:s.S")
 */
function format(date, fmt) {
  const o = {
    //月份
    'M+': date.getMonth() + 1,
    //日
    'd+': date.getDate(),
    //小时
    'h+': date.getHours(),
    //分
    'm+': date.getMinutes(),
    //秒
    's+': date.getSeconds(),
    //季度
    'q+': Math.floor((date.getMonth() + 3) / 3),
    //毫秒
    S: date.getMilliseconds(),
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      );
    }
  }
  return fmt;
}

/**
 * 获取时间
 * @param {number|string|Date} d
 * @return {string}
 * @example
 *          同一天 ==> 小时:分钟
 *          同一周 ==> 星期N
 *          同一年 ==> 月-日
 *          不同年 ==> 年-月-日
 */
function getTimeLabel(d) {
  const today = new Date();
  const target = new Date(d);
  const year = target.getFullYear();
  const month = target.getMonth();
  const date = target.getDate();
  if (
    today.getFullYear() === year &&
    today.getMonth() === month &&
    today.getDate() === date
  ) {
    // 同一天
    return `${date.getHours()}:${date.getMinutes()}`;
  }
  if (isSameWeek(target, today)) {
    // 同一周
    switch (target.getDay()) {
      case 1:
        return '星期一';
      case 2:
        return '星期二';
      case 3:
        return '星期三';
      case 4:
        return '星期四';
      case 5:
        return '星期五';
      case 6:
        return '星期六';
      default:
        return '星期日';
    }
  }
  if (today.getFullYear() !== year) {
    // 不同年
    return `${year}-${month + 1}-${date}`;
  }
  return `${month + 1}-${date}`;
}

/**
 * 是否在同一周
 * @param {number|string|Date} d1
 * @param {number|string|Date} d2
 * @return {boolean}
 */
function isSameWeek(d1, d2) {
  const A = new Date(d1).setHours(0, 0, 0, 0);
  const B = new Date(d2).setHours(0, 0, 0, 0);
  const oneDayMillis = 1000 * 60 * 60 * 24;
  const A1 = Math.floor(A / oneDayMillis);
  const B1 = Math.floor(B / oneDayMillis);
  return Math.floor((A1 + 4) / 7) === Math.floor((B1 + 4) / 7);
}

/**
 * 返回"年-月-日 时:分"
 * @param {Date} date
 * @returns {string}
 */
function getFullDate(date) {
  return `${getDate(date)} ${getTime(date)}`;
}

/**
 * 返回年月日
 * @export
 * @param {Date} date
 * @param {string} [splitor='-']
 * @returns
 */
function getDate(date, splitor = '-') {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}${splitor}${addZeroPrefix(month)}${splitor}${addZeroPrefix(
    day
  )}`;
}

/**
 * 返回时分秒/时分
 * @export
 * @param {Date} date
 * @param {boolean} [withSecond=false]
 * @returns
 */
function getTime(date, withSecond = false) {
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  return withSecond
    ? `${addZeroPrefix(hour)}:${addZeroPrefix(minute)}:${addZeroPrefix(second)}`
    : `${hour}:${addZeroPrefix(minute)}`;
}

/**
 * 个位数添加0前缀
 * @param {number} number
 */
function addZeroPrefix(number) {
  return number < 10 ? `0${number}` : number;
}

/**
 * 格式化
 * @param {number} secondTime
 * @returns {string}
 */
function formatTime(secondTime) {
  let time = secondTime;
  let newTime, hour, minute, seconds;
  if (time >= 3600) {
    hour =
      Math.floor(time / 3600) < 10
        ? '0' + Math.floor(time / 3600)
        : Math.floor(time / 3600);
    minute =
      Math.floor((time % 60) / 60) < 10
        ? '0' + Math.floor((time % 60) / 60)
        : Math.floor((time % 60) / 60);
    seconds = time % 3600 < 10 ? '0' + (time % 3600) : time % 3600;
    if (seconds > 60) {
      minute =
        Math.floor(seconds / 60) < 10
          ? '0' + Math.floor(seconds / 60)
          : Math.floor(seconds / 60);
      seconds = seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60;
    }
    newTime = hour + ':' + minute + ':' + seconds;
  } else if (time >= 60 && time < 3600) {
    minute =
      Math.floor(time / 60) < 10
        ? '0' + Math.floor(time / 60)
        : Math.floor(time / 60);
    seconds = time % 60 < 10 ? '0' + (time % 60) : time % 60;
    newTime = '00:' + minute + ':' + seconds;
  } else if (time < 60) {
    seconds = time < 10 ? '0' + time : time;
    newTime = '00:00:' + seconds;
  }
  return newTime;
}

/**
 * 替换日期分隔符
 * @param {string} date 日期
 * @param {RegExp} pattern 正则表达式
 * @param {string} separator 分隔符
 * @example
 *          replaceDateSeparator('2022-11-30', /-/g, '/')
 */
function replaceDateSeparator(date, pattern, separator) {
  return date.replace(pattern, separator);
}

/**
 * 是否是今天
 * @param {Date} date
 * @returns {boolean}
 */
function isToday(date) {
  return date.toDateString() === new Date().toDateString();
}

/**
 * 获取当前时间段
 * @returns {string} 返回下列之一<ul><li>凌晨</li><li>早上</li><li>上午</li><li>中午</li><li>下午</li><li>傍晚</li><li>晚上</li></ul>
 */
function getDayPart() {
  let hour = new Date().getHours();
  if (hour < 6) {
    return '凌晨';
  } else if (hour < 9) {
    return '早上';
  } else if (hour < 12) {
    return '上午';
  } else if (hour < 14) {
    return '中午';
  } else if (hour < 17) {
    return '下午';
  } else if (hour < 19) {
    return '傍晚';
  } else {
    return '晚上';
  }
}

/**
 * 获取籍贯
 * @param {string} idNo 身份证号
 */
function getBirthPlace(idNo) {
  if (!isNotEmptyString(idNo)) {
    return;
  }
  const data = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外',
  };
  const code = idNo.substring(0, 2);
  return data[parseInt(code)];
}

function birthdayFormat(date) {
  return date.split('-').join('/') + ' 00:00:00';
}

function getAgeByBirth(strBirthday) {
  let returnAge;
  let strBirthdayArr = strBirthday.split('-');
  let birthYear = strBirthdayArr[0];
  let birthMonth = strBirthdayArr[1];
  let birthDay = strBirthdayArr[2];

  let d = new Date();
  let nowYear = d.getFullYear();
  let nowMonth = d.getMonth() + 1;
  let nowDay = d.getDate();

  if (nowYear == birthYear) {
    returnAge = 0; //同年 则为0岁
  } else {
    let ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        let dayDiff = nowDay - birthDay; //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        let monthDiff = nowMonth - birthMonth; //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge; //返回周岁年龄
}

/**
 * 根据身份证号计算生日和年龄
 * @param {string} idNo 身份证号
 * @returns [birthday, sex, age] sex: 0-男，1-女
 */
function calculateBirthAndAge(idNo) {
  const birthAndAge = [null, null, null];
  if (!isNotEmptyString(idNo)) {
    return birthAndAge;
  }
  let idNumber = idNo;
  idNumber = idNumber.trim();
  if (isNotEmptyString(idNumber)) {
    const temp = idNumber.split('');
    if (temp.length === 18) {
      birthAndAge[0] =
        temp[6] +
        temp[7] +
        temp[8] +
        temp[9] +
        '-' +
        temp[10] +
        temp[11] +
        '-' +
        temp[12] +
        temp[13];
      if (temp[16] % 2 === 1) {
        birthAndAge[1] = 0;
      } else {
        birthAndAge[1] = 1;
      }
      birthAndAge[2] =
        temp[6] +
        temp[7] +
        temp[8] +
        temp[9] +
        '-' +
        temp[10] +
        temp[11] +
        '-' +
        temp[12] +
        temp[13];
      birthAndAge[2] = calculateAge(birthAndAge[2]);
    } else if (temp.length === 15) {
      birthAndAge[0] =
        '19' +
        temp[6] +
        temp[7] +
        '-' +
        temp[8] +
        temp[9] +
        '-' +
        temp[10] +
        temp[11];
      if (temp[14] % 2 === 1) {
        birthAndAge[1] = 0;
      } else {
        birthAndAge[1] = 1;
      }
      birthAndAge[2] =
        '19' +
        temp[6] +
        temp[7] +
        '-' +
        temp[8] +
        temp[9] +
        '-' +
        temp[10] +
        temp[11];
      birthAndAge[2] = calculateAge(birthAndAge[2]);
    }
  }
  return birthAndAge;
}

/**
 * 计算年龄
 * @param {string} date
 */
function calculateAge(date) {
  const today = new Date();
  const birth = new Date(date);
  let age = today.getFullYear() - birth.getFullYear();
  const m = today.getMonth() - birth.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age;
}

/**
 * 计算耗时
 * @param {number} begin
 * @param {number} end
 */
function calculateUsedTime(begin, end) {
  const usedTime = end - begin;
  // 天数
  const days = Math.floor(usedTime / (24 * 3600 * 1000));
  // 计算天数后剩余的毫秒数
  let leftTime = usedTime % (24 * 3600 * 1000);
  // 小时数
  const hours = Math.floor(leftTime / (3600 * 1000));
  // 计算小时数后剩余的毫秒数
  leftTime = leftTime % (3600 * 1000);
  // 计算相差分钟数
  let minutes = Math.floor(leftTime / (60 * 1000));
  // 计算分钟数后剩余的毫秒数
  // leftTime = leftTime % (60 * 1000)
  if (days > 0) {
    return days + '天' + hours + '小时' + minutes + '分钟';
  }
  return hours + '小时' + minutes + '分钟';
}

export {
  formatNow,
  format,
  getTimeLabel,
  isSameWeek,
  getFullDate,
  getDate,
  getTime,
  formatTime,
  replaceDateSeparator,
  isToday,
  getDayPart,
  getBirthPlace,
  birthdayFormat,
  getAgeByBirth,
  calculateBirthAndAge,
  calculateAge,
  calculateUsedTime,
};
