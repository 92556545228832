import { React } from '../../../../common/3rd';
import AtomicComponentThing from '../atomic-component';
import { Label } from './label';

/**
 * 表格, 只读
 */
class Spread extends AtomicComponentThing {
  renderSpreadHeader() {
    const columnNames = this.getColumnNames();
    return (
      <div className="sth-spread-header">
        {columnNames.map((name, nameIndex) => {
          return (
            <div
              className="sth-spread-header-cell"
              style={{ width: this.getColumnWidth(nameIndex) }}
              key={nameIndex}
            >
              {name}
            </div>
          );
        })}
      </div>
    );
  }
  renderBodyCell(item, layout, columnIndex) {
    return <Label model={item} layout={layout} parent={this} />;
  }
  renderSpreadBody() {
    const columnIds = this.getColumnIds();
    const items = this.getValueFromModel();
    const mapCodes = this.getCodes();
    return items.map((item, itemIndex) => {
      return (
        <div className="sth-spread-body-row" key={itemIndex}>
          {columnIds.map((id, idIndex) => {
            if (mapCodes.length > 0) {
              return (
                <div
                  className="sth-spread-body-cell"
                  style={{ width: this.getColumnWidth(idIndex) }}
                  key={idIndex}
                >
                  {this.renderBodyCell(
                    item,
                    { id: id, codes: mapCodes[idIndex] },
                    idIndex
                  )}
                </div>
              );
            } else {
              return (
                <div
                  className="sth-spread-body-cell"
                  style={{ width: this.getColumnWidth(idIndex) }}
                  key={idIndex}
                >
                  {this.renderBodyCell(item, { id: id }, idIndex)}
                </div>
              );
            }
          })}
        </div>
      );
    });
  }
  render() {
    return (
      <div {...this.getPrototypes()} ref="me">
        {this.renderSpreadHeader()}
        {this.renderSpreadBody()}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-spread';
  }
  getColumnIds() {
    return this.getLayout().columnIds;
  }
  getColumnNames() {
    return this.getLayout().columnNames;
  }
  getColumnWidth(columnIndex) {
    const width = this.getLayout().columnWidth;
    if (width) {
      return width[columnIndex] + '%';
    } else {
      return 1 / this.getColumnIds().length + '%';
    }
  }
  //add by wendy.gu 2017 Oct.17
  getCodes() {
    return this.getLayout().codes || [];
  }
}

export { Spread };
