import { React, ClassNames } from '../../common/3rd';

import Text from './text';

/**
 * date input
 */
class Date extends Text {
  renderRealWidget(props) {
    return <input min={this.getMin()} {...props} ref="widget" />;
  }
  wrapOthers(props) {
    props.type = this.getType();
    props.className = ClassNames(props.className, props.type);
    return this;
  }
  getType() {
    return this.getPropsValue('type', 'date');
  }
  getMin() {
    let model = this.getModel();
    if (model) {
      let propName = this.props['a-minDate'];
      return (
        model[propName] && model[propName].split(' ')[0].replace(/\//g, '-')
      );
    } else {
      return null;
    }
  }
  getValueFromModel() {
    let model = this.getModel();
    if (model) {
      let propName = this.getPropertyName();
      return (
        model[propName] && model[propName].split(' ')[0].replace(/\//g, '-')
      );
    } else {
      return null;
    }
  }
  setValueIntoModel(value) {
    value = value ? value.replace(/-/g, '/') + ' 00:00:00' : null;
    let model = this.getModel();
    if (model) {
      const currentValue = this.getValueFromModel();
      if (currentValue !== value) {
        let propName = this.getPropertyName();
        model[propName] = value;
        this.props['a-parent'] && this.props['a-parent'].forceUpdate();
      }
    }
  }
}

export default Date;