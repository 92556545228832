import { React, ClassNames } from '../../../../common/3rd';
import { Lang } from '../../../../common/common';

import AtomicComponentThing from '../atomic-component';

// 保险公司LOGO
import CPIC from '../../../../images/mobile/logo.png';

/**
 * 产品信息头
 */
class ProductInfoHeader extends AtomicComponentThing {
    render() {
        /*const likedClassName = ClassNames('sth-product-info-liked', {
         liked: this.isLiked()
         })*/
        if (!this.getModel()) return null;
        return (
            <div { ...this.getPrototypes() }>
                <div className='sth-product-info-header-name'>
                    { this.getProductName() }
                    <span>{ this.getPolicyNo() && (Lang.messages.sth.policyNo + '：' + this.getPolicyNo()) }</span>
                </div>
                <div className='sth-product-vendor-logo'>
                    <img src={ this.getVendorLogo() } alt=''/>
                </div>
                {/*<div className={likedClassName}>
                 <span className='fa fa-fw fa-heart-o'
                 onClick={this.onLikeClick} />
                 <span onClick={this.onLikeClick}>
                 {this.getLikedCount()}
                 </span>
                 </div>*/ }
            </div>
        );
    }

    // 固定组件, 不需要layout, 重写为空JSON对象
    // getLayout() {
    // 	return {};
    // }
    getDefaultClassName() {
        return 'sth-product-info-header';
    }

    getProductName() {
        return this.getModel().productName || this.getModel().advertisementName;
    }

    getVendorLogo() {
        // TODO 需要根据Vendor Code获取logo图片, 目前固定为一张图片
        return CPIC;
    }

    getPolicyNo() {
        return this.getModel().policyNo;
    }

    getLikedCount() {
        return this.getModel().likeCount || 0;
    }

    isLiked() {
        return this.getModel().liked;
    }

    // 事件响应
    onLikeClick = (evt) => {
        // TODO 添加或删除收藏, 参考产品卡的做法
    };

    getModel() {
        return super.getProductModel();
    }
}

export default ProductInfoHeader;