import { React, ClassNames } from '../../../common/3rd';
import { Utils } from '../../../common/common';
import { Widget } from '@/component/components';

import HotProduct from './hot-product';
import PlainProduct from './plain-product';
import FavProduct from './fav-product';
import StoreProduct from './store-product';
import ProductAdd from './product-add';

import './product.scss';

/**
 * product list title
 */
class ProductListTitle extends Widget {
  render() {
    const title = this.getTitle();
    if (Utils.isBlank(title)) {
      return null;
    }

    return (
      <div className="product-list-title">
        <span>{this.getTitle()}</span>
      </div>
    );
  }
  getTitle() {
    return this.getPropsValue('title');
  }
}

/**
 * product list
 */
class ProductList extends Widget {
  static HOT = 'hot';
  static PLAIN = 'plain';
  static FAV = 'fav';
  static STORE = 'store';
  static PRODUCT_ADD = 'product_add';
  constructor(props, context) {
    super(props, context);
    this.state.removedProductIds = [];
  }
  renderProduct(product, index) {
    const props = {
      product: product,
      selectable: this.isSelectable(),
      ref: `product-${product.productId}`,
      key: `product-${product.productId}`,
      parent: this,
    };
    switch (this.getType()) {
      case ProductList.HOT:
        return <HotProduct {...props} />;
      case ProductList.FAV:
        return <FavProduct {...props} />;
      case ProductList.STORE:
        return <StoreProduct {...props} />;
      case ProductList.PRODUCT_ADD:
        return <ProductAdd {...props} />;
      case ProductList.PLAIN:
      default:
        return <PlainProduct {...props} />;
    }
  }
  render() {
    const items = this.getItems();
    const className = this.getWidgetClassName(
      ClassNames('product-list', {
        selectable: this.isSelectable(),
      })
    );
    if (items == null || items.length === 0) {
      return <div className={className} />;
    } else {
      return (
        <div className={className}>
          <ProductListTitle title={this.getTitle()} />
          <div className={this.isRecommend() ? 'hot-product-list-con' : ''}>
            <div className={this.isRecommend() ? 'hot-product-list' : ''}>
              {this.getItems().map((item, index) => {
                return this.renderProduct(item, index);
              })}
            </div>
          </div>
        </div>
      );
    }
  }
  getTitle() {
    return this.getPropsValue('title');
  }
  getType() {
    return this.getPropsValue('type');
  }
  getItems() {
    let items = this.getPropsValue('items');
    return (items == null ? [] : items).filter((item) => {
      return this.state.removedProductIds.indexOf(item.productId) < 0;
    });
  }
  isRecommend() {
    return this.getPropsValue('isRecommend');
  }
  getSelectedProductIds() {
    return Object.keys(this.refs)
      .filter((key) => {
        return key.startsWith('product-');
      })
      .reduce((selectedIds, key) => {
        let component = this.refs[key];
        if (component.isSelected()) {
          selectedIds.push(component.getProductId());
        }
        return selectedIds;
      }, []);
  }
  getSelectedStoreProductIds() {
    return Object.keys(this.refs)
      .filter((key) => {
        return key.startsWith('product-');
      })
      .reduce((selectedIds, key) => {
        let component = this.refs[key];
        if (component.isSelected()) {
          selectedIds.push(component.getStoreProductId());
        }
        return selectedIds;
      }, []);
  }
  isSelectable() {
    return this.props.selectable;
  }
  removeSelectedProducts() {
    this.setState({
      removedProductIds: this.state.removedProductIds.concat(
        this.getSelectedProductIds()
      ),
    });
  }
  shouldGoToDetail() {
    Utils.notifyParentShouldGoToDetail(this);
  }
}

export default ProductList;
