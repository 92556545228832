import { React } from '../common/3rd';
import { Alert } from '@/component/components';
import LogUtil from '@/util/log';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    componentDidMount() {
      importComponent()
        .then((cmp) => {
          this.setState({ component: cmp.default });
        })
        .catch((error) => {
          LogUtil.error(error);
          const pattern1 = /Loading chunk (.)+ failed/g; // /Loading chunk (\d)+ failed/g;
          const pattern2 = /CSS_CHUNK(.)+_FAILED/g;
          const isChunkLoadFailed =
            error.message?.match(pattern1) || error.code?.match(pattern2);
          LogUtil.error('isChunkLoadFailed', isChunkLoadFailed);
          // 加载失败，是否重新加载
          // isChunkLoadFailed &&
          //   window.confirm('网络异常，页面加载失败，是否重试？') &&
          //   window.location.reload();
          isChunkLoadFailed &&
            Alert.ask('网络异常，页面加载失败，是否重试？', (type) => {
              if (type === '2') {
                window.location.reload();
              }
            });
        });
    }

    render() {
      // let appLoading = document.getElementById('app-loading');
      // if (appLoading) {
      //   appLoading.remove();
      // }
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
      // return C ? <C {...this.props} /> : <div className="app-loading">页面加载中</div>;
    }
  }
  return AsyncComponent;
}
