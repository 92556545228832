import { React } from '../../../../common/3rd';
import { ReactRouterContextWidget } from '@/component/components';
import { Envs } from '../../../../common/common';
// import AtomicComponentThing from '../atomic-component'
// import { ComponentThing } from '../component'
// import { format } from '../something-utils'

class ButtonJump extends ReactRouterContextWidget {
  getModel() {
    return this.props.model;
  }
  onClickButtoned = () => {
    this.pushRedirect(Envs.PATH.MOBILE_LIFE_HOME);
  };
  render() {
    return (
      <div className="btnBack" onClick={this.onClickButtoned}>
        返回首页
      </div>
    );
  }
}
/**
 * 状态组件
 * 例：进入人核 、投保成功
 * add by wendy.gu 2017 Oct.19
 *
 */
class StatusPage extends React.Component {
  constructor() {
    super();
    this.state = {
      statusType: 1, //状态类型 1成功
    };
  }
  getSeletedValue() {
    return this.state.checkVal;
  }
  render() {
    // let stateType = this.props.stateType;
    return (
      <div className="status-container2">
        <div className="statusImg"></div>
        <p className="msg-p">该订单已成功进入线下走人工核保流程</p>
        <p className="msg-p">请及时关注最新进度</p>
        <ButtonJump />
      </div>
    );
  }
  goHome = () => {
    let parent = this.props.parent;
    while (true) {
      if (parent.props && parent.props.history) {
        parent.props.history.goBack();
        break;
      }
      parent = parent.props.parent;
    }
    // this.historyBack();
  };
}

export default StatusPage;
