import { React } from '../../common/3rd';
import { Widget } from '@/component/components';

/**
 * menu group
 */
class MenuGroup extends Widget {
  render() {
    return <div className="menu-group">{this.props.children}</div>;
  }
}

export default MenuGroup;
