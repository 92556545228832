import { React } from '../../../../common/3rd';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import Button from '../component/button';

class AddBeneficiary extends AtomicMutableComponentThing {
  doAction = () => {
    this.getRootComponent().addBeneficiary();
  };

  render() {
    return (
      <a {...this.getPrototypes()} onClick={this.doAction}>
        {this.getLabel()}
      </a>
    );
  }

  getDefaultClassName() {
    return 'sth-add-beneficiary';
  }
}

export default AddBeneficiary;
