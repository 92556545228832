import { React } from '../../../../common/3rd';

import AtomicMutableComponentThing from '../atomic-mutable-component';

/**
 * 多行输入框
 */
class TextArea extends AtomicMutableComponentThing {
  render() {
    return (
      <textarea
        {...this.getPrototypes()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.doCheckAndAlert}
        ref="me"
      />
    );
  }

  // 23/01/2018 - TAG: by yanzhi.mo - 解决回车产生的数据问题
  /**
   * edit by cheng.fan on 2018-05-04
   *
   * @param {any} evt
   */
  onChange = (evt) => {
    const delay = this.getChangeHandlingDelay();
    let value = this.getValueFromDOM();
    if (delay > 0) {
      if (this.state.changeDelay) {
        clearTimeout(this.state.changeDelay);
        delete this.state.changeDelay;
      }

      this.state.changeDelay = setTimeout(() => {
        this.setValueToModel(value, () => {
          this.getRootComponent().forceUpdate();
        });
        this.fireEvent('change');
      }, delay);
    } else {
      this.setValueToModel(value);
      this.fireEvent('change');
    }
  };

  // 事件
  /**
   * 得到焦点
   */
  onFocus = (evt) => {
    console.log(this.getModel());
    this.selectAll();
  };
  getChangeHandlingDelay() {
    return 300;
  }
  /**
   * 全选
   */
  selectAll() {
    this.me().select();
  }
  /**
   * 获取支持的原生属性名称, 不包含事件
   */
  getPrototypeNames() {
    return ['placeholder'];
  }
  getPrototypeNames() {
    return ['readOnly'];
  }
  getDefaultClassName() {
    return 'sth-textarea';
  }
}

export default TextArea;
