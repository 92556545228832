import { React, $ } from '../../../../common/3rd';

// import { AtomicArrayComponentThing, AtomicArrayItemThing } from '../atomic-array-component';
// import { Panel, LeftPanel } from './panel';
// import Something from '../something';
import Conainering from '../container';

class Declares extends Conainering {
    /**
     * 通过code同步layout的告知数据到model中
     * 并删除model无用的告知json
     */
    synchronizeLayoutModel() {
        const layout = this.getPanelLayout().layout;
        let dataArray = this.getValueFromModel();
        if (!dataArray) {
            dataArray = [];
            this.setValueToModel(dataArray);
        }
        const declaraCodes = layout.reduce((codes, declaraItem, index) => {
            if (declaraItem.noCode) {
                return codes;
            }
            if (this.isHidden(declaraItem)) {
                return codes;
            }
            //若layout的告知code在告知model中不存在，则model插入对应该code的json
            if (dataArray.every(dataItem => dataItem.code + "" !== declaraItem.code + "")) {
                if (declaraItem.inphant) {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        noToggle  : true,
                        confirm   : false,
                        parameters: '否',
                        remark    : '//否'
                    }));
                } else if (declaraItem.notConfirm) {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : false,
                        parameters: '否',
                        remark    : ''
                    }));
                    //有是否选项和输入选项的
                } else if (declaraItem.type === "declara-children" && declaraItem.declaraInput && !declaraItem.noToggle) {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : false,
                        parameters: '否',
                        remark    : ''
                    }));
                    //没有是否选项有输入选项的
                } else if (declaraItem.type === "declara-children" && declaraItem.declaraInput && declaraItem.noToggle) {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : true,
                        parameters: '是',
                        noToggle  : true,
                        remark    : ''
                    }));
                    //有是否选项没有输入选项的
                } else if (declaraItem.type === "declara-children" && !declaraItem.declaraInput && !declaraItem.noToggle) {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : false,
                        parameters: '是',
                        remark    : ''
                    }));
                    //单选框类型
                } else if (declaraItem.type === "declara-radio") {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : true,
                        parameters: '是',
                        noToggle  : true,
                        remark    : declaraItem.radios[0].radioValue
                    }));
                } else if (declaraItem.type === "declara-checkbox") {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : true,
                        parameters: '是',
                        noToggle  : true,
                        remark    : declaraItem.checkboxs[0].checkboxValue
                    }));
                } else {
                    dataArray.push($.extend({}, {
                        code      : declaraItem.code,
                        version   : declaraItem.version,
                        confirm   : false,
                        parameters: '',
                        remark    : ''
                    }));
                }
            }
            codes.push(declaraItem.code);
            return codes;
        }, []);

        //如果model里告知的code不存在于layout的code集合里，则删掉。
        for (let i = dataArray.length - 1; i >= 0; i--) {
            if (declaraCodes.every(code => code + "" !== dataArray[i].code + "")) {
                dataArray.splice(i, 1);
            }
        }
    }

    render() {
        this.synchronizeLayoutModel();
        const layout = this.getPanelLayout().layout;
        let dataArray = this.getValueFromModel();
        const model = this.getModel();
        return (
            <div { ...this.getPrototypes() } ref='me'>
                { layout.map((cell, index) => {
                    if (cell.noCode) {
                        cell = $.extend({}, cell, {
                            id   : "parent-" + index,
                            index: index
                        });
                        return this.renderCell(model, cell);
                    }
                    let dataIndex = dataArray.findIndex(dataItem => {
                        return dataItem.code === cell.code;
                    });
                    if (dataIndex > -1 && cell.version) {
                        dataArray[dataIndex].version = cell.version;
                    }
                    cell = $.extend({}, cell, {
                        id   : this.getPanelLayout().id + `[${ dataIndex }]`,
                        label: this.getGlobalDeclarations(cell.code),
                        index: index
                    });
                    return this.renderCell(model, cell);
                }) }

            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-declares';
    }

    getPanelLayout() {
        return this.getLayout();
    }
}

export default Declares;