import { React } from '../../../common/3rd';
import { Envs, Swiper } from '../../../common/common';
import './carefreeAge.scss';
import closeImg from '../../../images/wechat/footer/icon-close.png';

class carefreeAge extends React.Component {
  constructor(context) {
    super();
    this.state = {
      active: false,
    };
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {}
  componentDidMount() {
    if (this.props.ageType == 'bottom') {
      document.getElementById('age1').scrollIntoView();
    } else {
      document.getElementById('age0').scrollIntoView();
    }
    if (this.freeWrap) {
      //this.freeWrap.addEventListener('scroll', this.onScrollHandle);
      this.freeWrap.addEventListener('scroll', (e) => {
        const clientHeight = e.target.clientHeight;
        const scrollHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop + 100;
        const isBottom = clientHeight + scrollTop >= scrollHeight;
        if (isBottom) {
          console.log('到底');
          this.setState({
            active: true,
          });
        } else {
          this.setState({
            active: false,
          });
        }
        this.forceUpdate();
      });
    }
  }
  render() {
    return (
      <div className="leadWrap">
        <div className="age-banner" ref={(e) => (this.freeWrap = e)}>
          <div className="knowledge-container" id="age0">
            <div className="note-title note-center">
              “好孕无忧”保险保障计划赠险参保须知
            </div>
            <div>
              1、“好孕无忧”保险保障计划赠险（以下简称“本产品”）由北京人寿保险股份有限公司北京分公司（以下简称“本公司”）承保。
            </div>
            <div>
              2、投保时年龄在20周岁（含）至45周岁（含）、有基本医疗保险、未怀孕或已怀孕20周以内且无胎儿神经管缺陷筛查结果异常的女性，均可作为本产品的被保险人。上述被保险人在保险期间内分娩的的新生儿，可作为本产品约定的附属被保险人。
            </div>
            <div>
              3、本产品为团体保险，被保险人已了解本产品的保障责任和各项约定，并同意和授权投保人北京斯利安药业有限公司投保本产品。
            </div>
            <div>
              4、本产品适用条款为《北京人寿补充住院团体医疗保险条款》（北京人寿[2018]医疗保险
              044
              号）、《北京人寿一年期团体重大疾病保险（B款）》（北京人寿[2021]疾病保险
              002号），保障计划及特别约定应结合条款一起解读、理解，特别约定与条款约定不一致的，以特别约定为准，保障计划及特别约定未涉及部分，以条款内容为准。
            </div>
            <div>
              5、本产品的保险期间为1年，自被被保险人领取赠险后次月1日零时生效。
            </div>
            <div>
              6、保障计划
              <table cellSpacing="0" border="1">
                <tr>
                  <td className="freeTd freeTdNew" rowSpan="3">
                    保障责任
                  </td>
                  <td className="freeTd">责任描述</td>
                  <td className="freeTd">免赔额</td>
                  <td className="freeTd">赔付比例</td>
                  <td className="freeTd">保额</td>
                </tr>
                <tr>
                  <td className="freeTd">
                    诊断胎儿神经管缺陷需引产，赔付基本医疗保险范围内、经医保报销后的引产医疗费用
                  </td>
                  <td className="freeTd">0 </td>
                  <td className="freeTd">80%</td>
                  <td className="freeTd">
                    胎儿神经管缺陷引产医疗费用保险金额：5,000元
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">诊断新生儿为神经管缺陷，给付保险金</td>
                  <td className="freeTd">——</td>
                  <td className="freeTd">——</td>
                  <td className="freeTd">新生儿神经管缺陷保险金额：5,000元</td>
                </tr>
                <tr>
                  <td className="freeTd">被保险人</td>
                  <td colSpan="4" className="freeTd">
                    女性，20-45周岁，未怀孕或已怀孕20周以内
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">犹豫期</td>
                  <td colSpan="4" className="freeTd">
                    无
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">等待期</td>
                  <td colSpan="4" className="freeTd">
                    无
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">保障期间</td>
                  <td colSpan="4" className="freeTd">
                    1年
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">医院范围</td>
                  <td colSpan="4" className="freeTd">
                    二级及以上公立医院
                  </td>
                </tr>
                <tr>
                  <td className="freeTd">其他说明</td>
                  <td colSpan="4" className="freeTd note-blod">
                    如上两项保险金，本公司仅给付其中一项，给付其中任意一项后，保险合同终止
                  </td>
                </tr>
              </table>
              注：本产品约定神经管缺陷，指由于胚胎发育早期神经管闭合不全所引起的先天缺陷，包括无脑、脊柱裂和脑膨出。需经临床专科医生诊断并经超声检查证实。
            </div>
            <div>
              7、责任免除
              <p>
                因以下情形之一，导致被保险人发生胎儿神经管缺陷需进行引产，或附属被保险人发生神经管缺陷，
                <span className="note-blod">
                  ，我们不承担给付保险金的责任：
                </span>
              </p>
              <p>（1）被保险人患有先天性畸形、变形或染色体异常；</p>
              <p>（2）被保险人未遵医嘱，私自服用、涂用、注射药物；</p>
              <p>
                （3）被保险人采用或计划采用辅助生殖技术（包括人工授精、试管婴儿、配子输卵管内移植及受精卵输卵管植入术等）怀孕。{' '}
              </p>
            </div>
            <div>
              8、本产品为团体保险，提供电子个人保险凭证。被保险人可通过关注“北京人寿京保通”微信公众号查询、下载电子个人保险凭证。
            </div>
            <div>
              9、除法律另有规定外，被保险人同意本公司将参保信息用于北京人寿保险股份有限公司及因服务必要、根据法律法规及监管规定委托的第三方合作机构的以下活动：包括但不限于为本人提供服务、推荐产品、产品开发和服务优化、安全保障、开展市场调查与信息数据分析等。
            </div>
            <div>
              10、本公司严格遵守现行的关于信息、数据及隐私保护的法律法规，采取充分的技术手段和制度管理，保护被保险人提供的个人信息、数据及隐私不受到非法的泄露或披露给未获授权的第三方。
            </div>
            <div>
              11、被保险人应如实填写参保信息，并就本公司提出的询问据实告知，否则北京人寿有权根据《中华人民共和国保险法》的规定及保险合同约定不承担保险责任。
            </div>
            <div>
              12、本公司全国统一客服热线为400-81-96677，被保险人有产品咨询、理赔报案、投诉争议等需求时，可以进行相关咨询。
            </div>
            <div>
              13、被保险人申请理赔，可通过拨打北京人寿客服热线：400-81-96677进行出险报案。
            </div>
            <div>
              14、按照监管要求，本公司会定期披露偿付能力相关信息，具体详见https://www.beijinglife.com.cn/publicInfo/solvency/（公开信息披露-专项信息-偿付能力专栏）。
            </div>
          </div>
          <div className="knowledge-container" id="age1">
            <div className="note-title note-center">
              “好孕无忧”保险保障计划授权承诺书
            </div>
            <div>
              1、被保险人（以下简称“您”）已充分阅读并理解《“好孕无忧”保险保障计划赠险参保须知》中关于“好孕无忧”保险保障计划赠险（以下简称“本产品”）的相关约定，并授权北京斯利安药业有限公司作为投保人，为您投保本产品。
            </div>
            <div>
              2、您承诺您在投保时满足如下条件：
              <p>（1）年龄在20周岁（含）至45周岁（含）的女性；</p>
              <p>（2）有基本医疗保险；</p>
              <p>
                （3）未怀孕，或已怀孕20周以内且无胎儿神经管缺陷筛查结果异常。{' '}
              </p>
            </div>
            <div>
              3、本授权承诺书将以电子形式签署，您点击“我已阅读并确认”按钮即视为理解同意签署本授权承诺书。
            </div>
            <div>
              4、本授权承诺书自您签署之日起生效，自下列情形发生之日终止：
              <p>（1）您提出终止本授权承诺书；</p>
              <p>（2）适用本授权承诺书的保险合同终止；</p>
              <p>（3）其他导致本授权承诺书终止的情形。 </p>
            </div>
          </div>
          <div className="bomNew">xxxxx</div>
          <div className="bomBtn">
            <div className="has-read" onClick={() => this.gotoPhoto('back')}>
              返回
            </div>
            <div
              className={this.state.active ? 'has-read' : 'has-read has-readNo'}
              onClick={() => this.gotoPhoto('read')}
            >
              我已阅读
            </div>
          </div>
        </div>
      </div>
    );
  }
  gotoPhoto = (type) => {
    if (type == 'back') {
      this.props.gotoAge(type);
    } else {
      if (this.state.active) {
        this.props.gotoAge(type);
      }
    }
  };
}

export default carefreeAge;
