import { ReactDOM ,$} from '../../../../common/3rd'

// import { format } from '../something-utils'
// import { PageLoading,Alert } from '../../../components'
import BaseSignatureOneToMoreView from './baseSignatureOneToMoreView'
import tpl from '../../signatureHTML/insuranceConfirmHtml'
/**
 * 电子投保确认书 签名单证
 * add by susan.wu 2017 Nov.8
 * 
 */
class InsuranceConfirm extends BaseSignatureOneToMoreView {
	constructor(props, context){
		super(props, context);
		this.currentSignaturePerson = 3;//当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
		this.currentIndex = 0;
		this.state.myIndex = 18;
		this.documentListIndex = 23;
	}
	UNSAFE_componentWillMount(){
		let self = this;
		let wizard = self.props.parent.props.parent;
		self.model = self.props.model;
		self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
		self.currentIndex = wizard.state.currentIndex || 0;
		if(self.currentIndex !== self.state.myIndex && self.currentIndex !== self.documentListIndex){
			return;
		}
		self.resetData(self.currentIndex);
		self.policyHolderMsg = [self.policyHolderType, "-20.1",true,"0","投保人签名:","3","10","",["-20.5","-20.6"]];
		self.insuredMsg = [self.insuredType, "-20.1",true,"1","被保人/监护人签名:","3","10","",["-20.6"]];		
		self.caContent = [{
			"keyWord": "投保人签名:",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "1"
		}, {
			"keyWord": "被保人/监护人签名:",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "2"
		}];
		super.initData(tpl, self.props.model, self.currentSignaturePerson, self.currentIndex, self.state.myIndex);		
	}
	// TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
	UNSAFE_componentWillUpdate(nextProps, nextState){
		let self = this;
		let wizard = self.props.parent.props.parent;
		self.model = self.props.model;
		self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
		self.currentIndex = wizard.state.currentIndex || 0;
		if(self.currentIndex !== self.state.myIndex && self.currentIndex !== self.documentListIndex){
			return;
		}
		self.resetData();
		self.policyHolderMsg = [self.policyHolderType, "-20.1",true,"0","投保人签名:","3","10","",["-20.5","-20.6"]];
		self.insuredMsg = [self.insuredType, "-20.1",true,"1","被保人/监护人签名:","3","10","",["-20.6"]];	
		self.caContent = [{
			"keyWord": "投保人签名:",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "1"
		}, {
			"keyWord": "被保人/监护人签名:",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "2"
		}];
		super.initData(tpl, self.props.model, self.currentSignaturePerson, self.currentIndex, self.state.myIndex);		
	}
	//render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
	componentDidUpdate(){
		if(this.currentIndex !== this.state.myIndex && this.currentIndex !== this.documentListIndex){
			return;
		}
		this.resetUI();
		this.addEvent();
		super.componentDidUpdate();
	}
	//render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
	componentDidMount(){
		if(this.currentIndex !== this.state.myIndex && this.currentIndex !== this.documentListIndex){
			return;
		}
		this.resetUI();
		this.addEvent();
		super.componentDidMount();
	}
	//重置数据
	resetData(){
		let self = this;
		super.resetData(self.model);
	}
	//初始化页面数据
	resetUI(){
		let self = this;
		self.parent = $(ReactDOM.findDOMNode(self.refs['signatureHtml']));
		self.policyHolderImageApp = self.parent.find("#imageApp");
		self.insuredImageApp = self.parent.find("#imageIns");
		self.parent.find("#logImage").attr("src",this.props.parent.props.parent.props.parent.state.policyLayout._logImage.bjlife);
		window.jQuery("#insuranceConfirm_policy_id").barcode(self.proposalCode, "code128",{barWidth:1, barHeight:50,showHRI:true});
		//设置投保单号
		self.parent.find("#proposalCode").html(self.proposalCode);
		//设置单证日期
		let applyDate = self.parent.find("#applyDate");
		let date = new Date();
		let month = parseInt(date.getMonth(), 10)+1;
		let time = date.getFullYear()+"年"+month+"月"+date.getDate()+"日";
		applyDate.html(time);
		self.resetBtn();
		super.resetUI();
	}
	resetBtn(){
		let self = this;
		self.parent.find("#sig-button").attr("class","onlyOneButton");
		self.policySigBtn = self.parent.find(".sig-button1");
		self.insuredSigBtn = self.parent.find(".sig-button2");
		if(self.currentSignaturePerson === self.policyHolderType){
			self.insuredSigBtn.css("display","none");
		}else if(self.currentSignaturePerson === self.insuredType){
			self.policySigBtn.css("display","none");
		}
	}
	addEvent(){
		let self = this;
		self.parent.find(".sig-button1").on("click",self.clickPolicySigBtnHandler);
		self.parent.find(".sig-button2").on("click",self.clickInsuredSigBtnHandler);
	}
	//各单证根据各自签名完成情况自定义 docType
	getPdfType(){
		let self = this;
		let pdfType = null;
		if(self.currentSignaturePerson === self.policyHolderType){
			pdfType = "-20.5";
		}
		if(self.policyHolderSaveSiged && self.insuredSaveSiged){
			pdfType = "-20.6";
		}
		//todo delete
		// if(self.currentSignaturePerson === self.insuredType) {
		// 	pdfType = "-20.6";
		// }
		return pdfType;
	}
	getDefaultClassName() {
		return '';
	}
}

export default InsuranceConfirm