import { $ } from './3rd';

import Storage from './storage';

import messages from './messages.json';

/**
 * language utilities
 */
class Language {
  constructor() {
    this.pool = {};
    this.messages = null;
  }

  getCurrentLanguage() {
    /*let language = Storage.Lang.getFromLocal('Language');
         if (language) {
         return language;
         } else if (window.navigator.language) {
         return window.navigator.language.replace('-', '_').toLowerCase();
         } else {*/
    return 'zh_cn';
    // }
  }

  switchLanguage(language) {
    let lang = this.convertLanguage(language);
    this.messages = this.pool[lang];
    if (!this.messages) {
      return this.switchLanguage(this.getParentLanguage(lang));
    } else {
      $('html')
        // remove the original language related class
        .removeClass((index, classnames) => {
          return classnames
            .split(' ')
            .filter((classname, index) => {
              return classname.startsWith('csms-lang-');
            })
            .join(' ');
        })
        // add new class according to current language
        // current language is actually applied, not the choosen one
        .addClass(`csms-lang-${lang.toLowerCase().replace('_', '-')}`);
      Storage.Lang.writeToLocal('Language', language);
      return this;
    }
  }

  convertLanguage(language) {
    if (language) {
      return language.toLowerCase().replace('-', '_');
    } else {
      return 'en';
    }
  }

  getParentLanguage(language) {
    let segments = language.split('_');
    if (segments.length === 1) {
      return 'en';
    } else {
      segments.splice(segments.length - 1, 1);
      return segments.join('_');
    }
  }

  install(language, messages) {
    // check the language already exists or not
    // if not, create one
    if (!this.pool[language]) {
      this.pool[language] = {};
    }
    // merge new messages into this language
    // note replace or not, depends on messages itself
    $.extend(true, this.pool[language], messages);

    return this;
  }

  installMessages(messagesOfRegions, namespace) {
    Object.keys(messagesOfRegions).forEach((key) => {
      let messages = {};
      messages[namespace] = messagesOfRegions[key];
      this.install(key, messages);
    });
  }

  getSupportedLanguages() {
    return [
      {
        en: 'English',
      },
      {
        zh: '简体中文',
      },
      {
        zh_tw: '繁體中文',
      },
    ];
  }
}

const language = new Language();
language.installMessages(messages, 'common');

export default language;
