import { React, $ } from '../../common/3rd';

import { Widget } from '@/component/components';

/**
 * 相机/相册
 */
class Camera extends Widget {
  render() {
    return (
      <input
        className="camera"
        type="file"
        accept="image/*"
        ref="imgInput"
        onChange={(e) => this.onImgChanged(e.currentTarget)}
      />
    );
  }
  onImgChanged = (e) => {
    let imgFile = this.refs.imgInput.files[0];
    if (!imgFile) {
      return;
    }
    let oFReader = new FileReader();
    oFReader.readAsDataURL(imgFile);
    oFReader.onload = (evt) => {
      const callBackAction = this.props.callBackAction;
      if (callBackAction) {
        $(e).val('');
        callBackAction(evt.target.result, e, this.props.obj, imgFile);
      }
    };
  };
}

export default Camera;
