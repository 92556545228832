import { React } from '@/common/3rd';
import { Router, Route, Switch } from '@/3rd';
import { appHistory } from '@/router';
import { Envs, Lang } from '@/common/common';
import { AsyncComponent } from '@/component/components';
// import '@/svg';
import '@/app.scss';

const Sign = AsyncComponent(() => import('@/both/sign/sign'));
const MobileIndex = AsyncComponent(() => import('@/mobile/mobile-index'));
const DeskHome = AsyncComponent(() => import('@/desk/desk-home/desk-home'));
const ApiDocs = AsyncComponent(() => import('@/api-docs/api-docs'));
const WechatSign = AsyncComponent(() => import('@/wechat/sign/sign'));
const WechatIndex = AsyncComponent(() => import('@/wechat/wechatIndex'));
const WechatReceipt = AsyncComponent(() =>
  import('@/wechat/sign/share-receipt')
);
const Viewer = AsyncComponent(() => import('@/both/viewer/viewer'));
const Feedback = AsyncComponent(() => import('@/both/feedback/feedback'));
const MiniIndex = AsyncComponent(() => import('@/mini/index'));

Lang.switchLanguage(Lang.getCurrentLanguage());

class App extends React.Component {
  componentDidMount() {
    Envs.application(this);
  }

  componentWillUnmount() {
    Envs.application(null);
  }

  renderApiDocs() {
    if (process.env.REACT_APP_API_DOCS_ENABLED) {
      return <Route path={Envs.PATH.API_DOCS} component={ApiDocs} />;
    }
  }

  render() {
    return (
      <Router history={appHistory}>
        <Switch>
          <Route path={Envs.PATH.SIGN} component={Sign} />
          <Route path={Envs.PATH.MOBILE} component={MobileIndex} />
          <Route path={Envs.PATH.DESK_HOME} component={DeskHome} />
          <Route
            path={Envs.PATH.WECHAT_SAHRE_RECEIPT}
            component={WechatReceipt}
          />
          <Route path={Envs.PATH.WECHAT_SIGN} component={WechatSign} />
          <Route path={Envs.PATH.WECHAT} component={WechatIndex} />
          <Route path={Envs.PATH.VIEWER} component={Viewer} />
          <Route path={Envs.PATH.FEEDBACK} component={Feedback} />
          <Route path={Envs.PATH.MINI} component={MiniIndex} />

          {this.renderApiDocs()}

          <Route path="/*" component={Sign} />
        </Switch>
      </Router>
    );
  }
}

export default App;
