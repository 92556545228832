import ContainerThing from '../container';

/**
 * 建议书发送给
 */
class AdviceTo extends ContainerThing {
  getDefaultClassName() {
    return 'sth-panel sth-advice-to';
  }

  getInternalLayout() {
    return {
      label1: {
        type: 'label',
        label: '该计划敬呈',
        pos: { row: 100 },
      },
      text: {
        id: this.getLayout().adviceTo,
        pos: { row: 200 },
      },
      title: {
        id: this.getTitleId() || 'adviceTitle',
        type: 'toggle-l',
        codes: 'human_title',
        pos: { row: 300 },
      },
    };
  }

  getTitleId() {
    return this.getLayout().titleId;
  }
}

export { AdviceTo };
