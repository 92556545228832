// import { React, ReactDOM, $ } from '../../common/3rd'
import Text from './text';

/**
 * email
 */
class EmailText extends Text {
  static PATTERN =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  getType() {
    return 'email';
  }
  getDefaultPlaceholder() {
    return 'account@web.com';
  }
  getDefaultValidators() {
    return (value) => {
      return EmailText.PATTERN.test(value);
    };
  }
}

export default EmailText;
