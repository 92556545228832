import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 资料上传温馨提示
 * 例：资料上传页
 * add by susan.wu 2017 Oct.19
 *
 */
class UploadPrompt extends AtomicComponentThing {
  render() {
    return (
      <div className="uploadPrompt">
        <div className="prompt-title">温馨提示</div>
        <div className="prompt-content">
          1、请确保影像清晰完整
          <br />
          2、请拍照原件
          <br />
          3、因影像件较大，上传可能需时较长，请耐心等待
          <br />
          4、请在WIFI或者4G环境上传，以保证速度
          <br />
        </div>
      </div>
    );
  }
}

export default UploadPrompt;
