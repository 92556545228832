/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description App错误
 * @author LiuH
 */
class AppError extends Error {
  constructor(code, message) {
    super(message);
    this.name = 'AppError';
    this.code = code;
    this.message = message;
  }

  toString() {
    return `AppError: [${this.code}], ${this.message}\n`;
  }
}

export default AppError;
