import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 文章
 */
class Article extends AtomicComponentThing {
  renderChapters() {
    return this.getChapters().map((chapter, chapterIndex) => {
      const converted = this.convertChapter(chapter);
      const text = converted.text.replace(/\{/g, '<b>').replace(/\}/g, '</b>');
      return (
        <span
          className={converted.class}
          dangerouslySetInnerHTML={{ __html: text }}
          key={chapterIndex}
        >
          {/* {text} */}
        </span>
      );
    });
  }

  render() {
    return <div {...this.getPrototypes()}>{this.renderChapters()}</div>;
  }

  getDefaultClassName() {
    return 'sth-article';
  }

  getChapters() {
    const chapters = this.getLayout().chapters;
    return chapters ? (Array.isArray(chapters) ? chapters : [chapters]) : [];
  }

  convertChapter(chapter) {
    const prefix = chapter.length > 3 ? chapter.substring(0, 2) : '';
    switch (prefix) {
      case 'h:':
        return {
          class: 'header',
          text: chapter.substring(2),
        };
      case 't:':
        return {
          class: 'title',
          text: chapter.substring(2),
        };
      case 'b:':
        return {
          class: 'bold',
          text: chapter.substring(2),
        };
      default:
        return {
          text: chapter,
        };
    }
  }
}

export default Article;
