import { React } from '../../common/3rd';

import { Widget } from '@/component/components';

/**
 * list
 */
class List extends Widget {
  renderItem(item, index) {
    return this.getItemRenderer().call(this, item, index);
  }
  render() {
    return (
      <div className={this.getWidgetClassName('list')}>
        {this.getItems().map((item, index) => {
          return this.renderItem(item, index);
        })}
      </div>
    );
  }
  getItems() {
    const items = this.getPropsValue('items');
    return items ? items : [];
  }
  getItemRenderer() {
    // it is a function, do not run it.
    return this.props.renderer;
  }
}

export default List;
