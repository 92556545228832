import { React } from '../../../../common/3rd';

import {
  AtomicArrayComponentThing,
  AtomicArrayItemThing,
} from '../atomic-array-component';
import { LeftPanel80 } from '../container/panel';

/**
 * 附加险.
 * 默认打开附加险的方式为该JSON的enabled字段为true.
 */
class AdditionalCoverage extends AtomicArrayItemThing(LeftPanel80) {
  getDefaultClassName() {
    return 'sth-panel sth-additional-coverage';
  }

  getHeaderToggleId() {
    if (this.getModel().extensionData.isRequire) {
      return;
    } else {
      return 'extensionData.displayPremIndi';
    }
  }
}

/**
 * 没有内容需要显示的附加险
 */
class AdditionalCoverageNoContent extends AdditionalCoverage {
  renderBody() {
    return null;
  }
}

// 预定义的附加险组件
const COVERAGE_COMPONENTS = {
  nvl: AdditionalCoverageNoContent,
};

/**
 * 附加险清单
 */
class AdditionalCoverageList extends AtomicArrayComponentThing {
  EVENT = null;

  renderCoverages() {
    return this.getValueFromModel().map((item, itemIndex) => {
      const layout = this.getCoverageLayout(item);
      if (layout) {
        // 找到布局
        if (typeof layout === 'function') {
          // 布局是一个方法, 即一个class
          return React.createElement(layout, {
            model: item,
            layout: {
              label: item.coverageName,
            },
            parent: this,
            key: itemIndex,
          });
        } else {
          // 布局是一个JSON对象,
          /*事件处理*/
          if (layout.evt) {
            this.EVENT = layout.evt;
            delete layout.evt;
          }
          return (
            <AdditionalCoverage
              model={item}
              layout={{
                label: item.coverageName,
                class: 'cells',
                layout: layout,
                evt: this.EVENT,
              }}
              parent={this}
              key={itemIndex}
            />
          );
        }
      } else {
        // 没有找到任何布局, 说明不需要输入
        return (
          <AdditionalCoverageNoContent
            model={item}
            layout={{
              label: item.coverageName,
            }}
            parent={this}
            key={itemIndex}
          />
        );
      }
    });
  }

  render() {
    return <div {...this.getPrototypes()}>{this.renderCoverages()}</div>;
  }

  getDefaultClassName() {
    return 'sth-additional-coverages';
  }

  getCoverageLayout(item) {
    const type = item.type;
    // 先从预定义里面找
    let layout = COVERAGE_COMPONENTS[type];
    if (layout) {
      return layout;
    } else {
      // 预定没找到, 则从传入的布局里面找
      const internalLayout = this.getLayout().layout;
      // return internalLayout ? internalLayout[type] : null;
      return internalLayout ? internalLayout : null;
    }
  }
}

export { AdditionalCoverage, AdditionalCoverageList };
