import { React, ReactDOM, $ } from '../../../../common/3rd';
import Utils from '../../../../common/utils';
import { PageLoading, Alert } from '@/component/components';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import Camera from '../../../../component/widgets/camera';

/**
 * 资料上传框
 * 例：资料上传页
 * add by susan.wu 2017 Oct.19
 *
 */
class UploadArea extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.documents = [];
    this.currentIndex = 0;
  }
  render() {
    this.currentIndex =
      this.props.parent.props.parent.props.parent.props.parent.state.currentIndex;
    return (
      <div {...this.getPrototypes()} ref="me">
        {this.renderUploadArea()}
        <div className="upload-title">({this.getLayout().label})</div>
      </div>
    );
  }
  renderUploadArea() {
    let uri = super.getValueFromModel().uri
      ? super.getValueFromModel().uri
      : this.getLayout().initialUri;
    let existImgClass = 'uploadArea' + (uri ? ' existImg' : ' noImg');
    return (
      <div className={existImgClass}>
        <div className="main-content">
          <div className="content">点击上传{this.getLayout().label}</div>
          <div className="upload-img">
            <div className="img-content">
              <img className="my-img" src={uri} alt="" />
            </div>
          </div>
          <Camera callBackAction={this.onUpload} obj={this} />
        </div>
        <div
          className="icon-content"
          onClick={(e) => this.deleteImg(e.currentTarget)}
        >
          <i className="icon icon-close"></i>
        </div>
      </div>
    );
  }
  componentDidMount() {
    super.componentDidMount();
    if (super.getValueFromModel().uri && this.currentIndex === 6) {
      let params = {
        imageUri: super.getValueFromModel().uri,
      };
      let self = this;
      PageLoading.start('资料加载中，请稍后...');
      this.doPost('/rest/v1/policy/loadImage', params)
        .done((data) => {
          if (data && data.body) {
            self.setImage(data.body);
          }
        })
        .always(() => {
          PageLoading.end();
        });
    }
  }
  componentDidUpdate() {
    super.componentDidUpdate();
    if (super.getValueFromModel().uri && this.currentIndex === 6) {
      let params = {
        imageUri: super.getValueFromModel().uri,
      };
      let self = this;
      PageLoading.start('资料加载中，请稍后...');
      this.doPost('/rest/v1/policy/loadImage', params)
        .done((data) => {
          if (data && data.body) {
            self.setImage(data.body);
          }
        })
        .always(() => {
          PageLoading.end();
        });
    }
  }
  setImage(imageData) {
    const me = $(ReactDOM.findDOMNode(this.refs.me));
    me.find('.my-img').attr('src', imageData);
  }
  onUpload(myCamera, target, obj) {
    if (myCamera) {
      Utils.compressImg(myCamera, function (compressCamera) {
        if (compressCamera) {
          let customerType = '';
          if (parseFloat(obj.getLayout().documentType) > -2) {
            customerType = '0';
          }
          // let vendorCode = obj.getModel().vendorCode || ""
          if (!obj.getModel().vendorCode) {
            Alert.message('获取vendorCode失败，无法上传资料！');
            return;
          }
          let documentType = obj.getLayout().documentType;
          //todo 调用后端接口保存
          let params = {
            businessNo: obj.getModel().quotationNo,
            customerType: customerType,
            document: compressCamera.substring(22, compressCamera.length),
            documentType: documentType,
            isFront: obj.getLayout().front,
            productId: '',
            uplaodType: '2',
            vendorCode: obj.getModel().vendorCode,
          };
          PageLoading.start('上传资料中，请稍后...');
          obj
            .doPost('/rest/v1/policy/uploadImage', params)
            .done((data) => {
              if (data && data.body) {
                let parent = $(target).parents('.uploadArea');
                //obj.setUriToModel(data.body.fileLocation,data.body.uploadTime);
                if (parent.hasClass('noImg')) {
                  parent.removeClass('noImg');
                  parent.addClass('existImg');
                }
                parent.find('.my-img').attr('src', compressCamera);
                let odata = obj.getModel();
                let docType = obj.props.layout.documentType;
                let docFront = obj.props.layout.front;
                var n = 0;
                let documentId = null;

                odata.policyCustomers.map((item) => {
                  if (!item.extensionData.documents) {
                    return;
                  }
                  if (item.customerType == '2') {
                    return;
                  }
                  item.extensionData.documents.map((value) => {
                    if (value.documentType == docType && n == 0) {
                      if (
                        value.documentId &&
                        value.documentId == obj.props.layout.id
                      ) {
                        value.uri = data.body.fileLocation;
                        value.uploadTime = data.body.uploadTime;
                        n++;
                      } else if (!value.uri) {
                        value.uri = data.body.fileLocation;
                        value.uploadTime = data.body.uploadTime;
                        value.documentId = obj.props.layout.id;
                        n++;
                      }
                    }
                  });
                });
                obj.setUriToModel(data.body.fileLocation, data.body.uploadTime);
              }
            })
            .always(() => {
              PageLoading.end();
            });
          // let backUri = compressCamera;
          // let parent = $(target).parents(".uploadArea");
          // obj.setUriToModel(backUri,new Date())
          // if(parent.hasClass("noImg")) {
          // 	parent.removeClass("noImg");
          // 	parent.addClass("existImg");
          // }
          // parent.find(".my-img").attr("src",backUri);
        }
      });
    }
  }
  setUriToModel(uri, uploadTime) {
    super.getValueFromModel().uri = uri;
    super.getValueFromModel().uploadTime = uploadTime;
  }
  deleteImg = (e) => {
    if (parseFloat(this.getLayout().documentType) > -2) {
      //删除投保人影像
      super.getValueFromModel().uri = '';
      super.getValueFromModel().uploadTime = '';
      let initialUri = this.getLayout().initialUri;
      if ($(e).parent().hasClass('existImg')) {
        if (initialUri) {
          //有默认图片
          $(e).parent().find('.my-img').attr('src', initialUri);
        } else {
          $(e).parent().removeClass('existImg');
          $(e).parent().addClass('noImg');
        }
      }
    } else {
      // $(e).parents(".sth-uploadItem").remove();
      this.getRootComponent().deleteOtherImage(
        super.getValueFromModel().documentType
      );
    }
  };
  getDefaultClassName() {
    return 'sth-uploadItem';
  }
  getPanelLayout() {
    return this.getLayout();
  }
  getValueFromModel() {
    if (super.getValueFromModel().uri) {
      return true;
    } else {
      return false;
    }
  }
}

export default UploadArea;
