import { React, ReactDOM, $ } from '../../../common/3rd';
import { Alert } from '../../components';
import './SearchBox.scss';

/**
    this.refs.searchBox.setState({isShow:true})
    this.refs.searchBox.refs.getCode.getAttribute('data-code')
    this.refs.searchBox.state.isShow
    <SearchBox placeHolder='请输入***' ref="searchBox"  callBackFlag="true"  dataInt={this.getItems} options={options} />
 */
let optionsSource;
let position;
class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      showValue: '',
      options: [],
      sureValue: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    if (this.props['data-btn'] !== 'true') {
      this.handleInputChange = this.handleInputChange.bind(this);
    }
    this.searchNotes = this.searchNotes.bind(this);
    this.submitV = this.submitV.bind(this);
  }

  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  UNSAFE_componentWillReceiveProps(nextProps) {
    optionsSource = nextProps.options;
    this.setState({
      options: optionsSource,
    });
  }

  UNSAFE_componentWillMount() {
    this.getItems();
  }
  getItems() {
    optionsSource = this.props.options;
    this.setState({
      options: optionsSource,
    });
    // console.log( optionsSource);
  }
  handleClick() {
    //初始化 数据选择列表 第一次展示 一定量数据
    let options = this.state.options;
    if (!options || options.length === 0) {
      this.props.dataInt();
      return;
    }
    let isShow = this.state.isShow;
    this.setState({
      isShow: isShow ? false : true,
      sureValue: false,
      options,
    });
  }
  closeWindow() {
    this.setState(
      {
        isShow: false,
        sureValue: false,
        showValue: '',
      },
      () => {
        //新增关闭窗口后的回调函数
        if (this.props.closeWindowCallBack) {
          this.props.closeWindowCallBack();
        }
      }
    );
  }
  handleOptionClick(id) {
    let selectedItem = this.state.options.filter((item, index) => {
      if (item.id === id) {
        position = index;
      }
      return item.id === id;
    })[0];
    if (this.props['data-btn'] !== 'true') {
      this.setState(
        {
          showValue: selectedItem.name,
          sureValue: true,
          isShow: false,
        },
        () => {
          //新增关闭窗口后的回调函数
          if (this.props.closeWindowCallBack) {
            this.props.closeWindowCallBack();
          }
        }
      );
    } else {
      this.setState(
        {
          //showValue: selectedItem.name,
          sureValue: true,
          isShow: false,
        },
        () => {
          //新增关闭窗口后的回调函数
          if (this.props.closeWindowCallBack) {
            this.props.closeWindowCallBack();
          }
        }
      );
      $('#hospital .searchClick').text(selectedItem.name);
    }
  }

  onChangeData(inputValue) {
    if (this.props.callBackFlag && this.props.callBackFlag == 'true') {
      this.props.dataInt(inputValue);
    }
  }
  handleInputChange(e) {
    let inputValue = e.target.value;
    //父组建回掉
    this.onChangeData(inputValue);
    //只允许输入中文 字母 数字 ' 空格
    let reg = /^[A-Za-z0-9\u4e00-\u9fa5\s\/']+$/;

    if (inputValue && !reg.test(inputValue)) {
      Alert.message('只可以输入中文 字母 和数字');
      return;
    }
    this.setState({
      showValue: inputValue,
    });
  }

  submitV() {
    //如果 选择过 或 有输入的值 则通过
    let matchOk = this.state.options.filter((item) => {
      return item.name === this.state.showValue;
    });

    if (this.state.sureValue || matchOk.length > 0) {
      this.setState({
        isShow: false,
      });
    } else {
      Alert.message('暂无该信息');
      return;
    }
  }
  clearInput() {
    position = '';
    this.setState({
      showValue: '',
    });
  }
  searchNotes() {
    if (!this.state.isShow && (position || position == '0')) {
      let aData = [];
      aData.push(this.state.options[position]);
      //position = "";
      return aData;
    }

    if (this.props.callBackFlag && this.props.callBackFlag === 'true') {
      console.log('服务器搜索');
    }
    let aData = [],
      data =
        this.props.callBackFlag && this.props.callBackFlag === 'true'
          ? this.props.options
          : this.state.options,
      k = 0,
      regStr = '',
      reg;

    let aSearch = this.state.showValue
      ? this.state.showValue.split(' ')
      : false;
    if (aSearch) {
    } else {
      return data;
    }
    //拼写正则
    for (let i = 0, lenR = aSearch.length; i < lenR; i++) {
      regStr += '(' + aSearch[i] + ')([\\s\\S]*)';
    }
    //console.log(regStr);
    reg = new RegExp(regStr);
    for (let i = 0, lenI = data.length; i < lenI; i++) {
      let name = data[i].name,
        regMatch = name.match(reg),
        searchData = {};
      k = 0;
      if (regMatch !== null) {
        var replaceReturn = '';
        for (let j = 1, lenJ = regMatch.length; j < lenJ; j++) {
          if (regMatch[j] === aSearch[k]) {
            replaceReturn += '<span class="hightlight">$' + j + '</span>';
            k++;
          } else {
            replaceReturn += '$' + j;
          }
        }

        for (let obj in data[i]) {
          if (data[i].hasOwnProperty(obj)) {
            searchData[obj] = data[i][obj];
          }
        }
        searchData.name = searchData.name.replace(reg, replaceReturn); //.replace(reg, replaceReturn);
        aData.push(searchData);
      }
    }

    return aData;
  }

  render() {
    let searchname = this.state.showValue.toLowerCase();
    let options = this.searchNotes();
    const menuItem = options.map((item) => (
      <li
        className="menuItem"
        key={item.id}
        onClick={this.handleOptionClick.bind(item, item.id)}
        dangerouslySetInnerHTML={{ __html: item.name }}
      ></li>
    ));

    let placeholder = this.state.showValue
      ? this.state.showValue
      : this.props.placeHolder;
    let fontColor = this.state.showValue ? 'focus' : 'blur';
    let code;
    if (options[0] && options.length === 1) {
      code = position;
    }
    let classArr = `${fontColor} searchClick`;
    //如果没输入 则显示取消按钮
    let subButton =
      this.state.showValue === '' ? (
        <span className="search-icon" onClick={this.closeWindow}>
          取消
        </span>
      ) : (
        <span className="search-icon" onClick={this.submitV}>
          确认
        </span>
      );
    return (
      <div className="search-wrap ">
        {/* 点击呼出弹窗的按钮 */}
        <div>
          <div
            ref="getCode"
            className={classArr}
            onClick={this.handleClick}
            data-code={code}
            data-val={JSON.stringify(options)}
          >
            {placeholder}{' '}
          </div>
        </div>
        {/*end */}
        <div
          className={`search-box-window ${this.state.isShow ? 'show' : 'hide'}`}
        >
          <div className="search-input">
            <div>
              {this.props['data-btn'] === 'true' && (
                <input
                  type="text"
                  autoComplete="off"
                  id="search-input"
                  placeholder={this.props.placeHolder}
                />
              )}
              {this.props['data-btn'] !== 'true' && (
                <input
                  type="text"
                  autoComplete="off"
                  value={this.state.showValue}
                  id="search-input"
                  onChange={this.handleInputChange}
                  placeholder={this.props.placeHolder}
                />
              )}
              <span
                className="clear-icon"
                onClick={this.clearInput.bind(this)}
              ></span>
            </div>
            {subButton}
          </div>
          {!(this.state.showValue.trim() == '' && this.props.hideAllItems) && (
            <ul className="menuList">{menuItem}</ul>
          )}
        </div>
      </div>
    );
  }
}

export default SearchBox;
