/*
 * @Author: cheng.fan
 * @Date: 2018-03-05 14:45:04
 * @Last Modified by: cheng.fan
 * @Last Modified time: 2018-03-09 11:37:36
 */
import { React } from '../../../../common/3rd';
import AtomicMutableComponentThing from '../atomic-mutable-component';

class Colunm extends AtomicMutableComponentThing {
  render() {
    return (
      <table className="sth-colunm">
        {this.renderHeader()}
        {this.renderBody()}
      </table>
    );
  }
  renderHeader() {
    const header = this.getLayout().layout.header;
    if (header) {
      return (
        <thead>
          <tr>
            {header.map((item, index) => {
              return <td key={index}>{item}</td>;
            })}
          </tr>
        </thead>
      );
    }
  }
  renderBody() {
    const body = this.getLayout().layout.body;
    if (body) {
      return (
        <tbody>
          {body.map((rowItem, rowIndex) => {
            return (
              <tr key={`row-${rowIndex}`}>
                {rowItem.map((colItem, colIndex) => {
                  return <td key={`col-${colIndex}`}>{colItem}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      );
    }
  }
}

export default Colunm;
