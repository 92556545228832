import { React, $ } from '../../../../common/3rd';

import Conainering from '../container';
import bankCardImage from '../../../../images/bankcard.jpg';
import identityYImage from '../../../../images/identityY.jpg';
import identityNImage from '../../../../images/identityN.jpg';

/**
 * 资料上传页
 * 例：资料上传页
 * add by susan.wu 2017 Oct.22
 *
 */
let myLayout = null;
//证件类型支持身份证1，户口本2，出生证3,军官证5，中国护照6，台湾通行证8，
const layout_identity = [
  {
    label: '身份证正面',
    type: 'uploadArea',
    documentType: '0',
    front: 'Y',
    initialUri: identityYImage,
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传身份证正面',
    },
  },
  {
    label: '身份证反面',
    type: 'uploadArea',
    documentType: '0',
    front: 'N',
    initialUri: identityNImage,
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传身份证反面',
    },
  },
  {
    label: '银行卡正面',
    type: 'uploadArea',
    documentType: '-1',
    front: 'Y',
    initialUri: bankCardImage,
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传银行卡正面',
    },
  },
];

class UploadAreaList extends Conainering {
  synchronizeLayoutModel() {
    let idType = this.getValueFromModel('policyHolder').idType;
    myLayout = [];
    if (idType && idType === '0') {
      myLayout = layout_identity;
    }
    let dataArray = this.getValueFromModel();
    if (!dataArray) {
      dataArray = [];
      this.setValueToModel(dataArray);
    }
    const uploadItems = myLayout.map((uploadItem) => {
      //若myLayout的影像在影像model中不存在，则model插入对应该影像的json
      if (
        dataArray.every(
          (dataItem) =>
            !(
              dataItem.documentType + '' === uploadItem.documentType + '' &&
              dataItem.front + '' === uploadItem.front + ''
            )
        )
      ) {
        dataArray.push(
          $.extend(
            {},
            {
              documentType: uploadItem.documentType,
              coverageCode: '',
              uri: '',
              front: uploadItem.front,
              uploadTime: '',
            }
          )
        );
      }
      return uploadItem;
    });
    //如果model里影像的documentType不存在于myLayout的documentType集合里，则删掉。
    for (let i = dataArray.length; i > 0; i--) {
      if (
        uploadItems.every(
          (uploadItem) =>
            !(
              uploadItem.documentType + '' ===
                dataArray[i - 1].documentType + '' &&
              uploadItem.front + '' === dataArray[i - 1].front + ''
            )
        )
      ) {
        if (parseFloat(dataArray[i - 1].documentType) > -2) {
          dataArray.splice(i - 1, 1);
        }
      }
    }
  }
  render() {
    this.synchronizeLayoutModel();
    const layout = myLayout;
    const dataArray = this.getValueFromModel();
    const model = this.getModel();
    return (
      <div {...this.getPrototypes()} ref="me">
        {layout.map((cell) => {
          let dataIndex = dataArray.findIndex((dataItem) => {
            return (
              dataItem.documentType === cell.documentType &&
              dataItem.front === cell.front
            );
          });
          cell = $.extend({}, cell, {
            id: this.getPanelLayout().id + `[${dataIndex}]`,
          });
          return this.renderCell(model, cell);
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-uploadAreaList';
  }
  getPanelLayout() {
    return this.getLayout();
  }
}

export default UploadAreaList;
