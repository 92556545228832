import { React, ReactDOM, ClassNames, $ } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';
import ContainerThing from '../container';
import TopBar from '../../../mobile/top-bar/top-bar';

/**
 * 步进行
 */
class StepInRow extends ContainerThing {
  render() {
    const contentClassName = ClassNames('sth-step-in-content', {
      narrow: this.isNarrowContent(),
      'with-bottom-button': this.isBottomButtonExists(),
    });
    return (
      <div {...this.getPrototypes()} ref="me">
        <span onClick={this.onClick}>{this.getLabel()}</span>
        <div className={contentClassName} ref="content">
          <TopBar
            title={this.getStepInContentTitle()}
            back={true}
            backAction={this.onBackClicked}
          />
          {this.renderBody()}
        </div>
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-step-in-row';
  }

  onClick = (evt) => {
    // 从左边移入
    $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: 0 }, 400);
  };
  onBackClicked = () => {
    // 从右边移出
    $(ReactDOM.findDOMNode(this.refs.content)).animate({ left: '100vw' }, 400);
  };
  getStepInContentTitle() {
    return this.getLayout().title || this.getLabel();
  }
  isNarrowContent() {
    return this.getLayout().narrow;
  }
  isBottomButtonExists() {
    return this.getLayout().bottomButton;
  }
}

/**
 * 单独的步进行, 增加一个class来控制样式
 */
class SingleStepInRow extends StepInRow {
  getDefaultClassName() {
    return super.getDefaultClassName() + ' sth-single-step-in-row';
  }
}

/**
 * 步进行集合
 */
class StepInRows extends AtomicComponentThing {
  shouldInstallDefaultIdToEventEmitter() {
    return false;
  }
  renderItems() {
    return this.getItems().map((item, itemIndex) => {
      return (
        <StepInRow
          model={this.getModel()}
          layout={item}
          parent={this}
          key={itemIndex}
        />
      );
    });
  }
  render() {
    return <div {...this.getPrototypes()}>{this.renderItems()}</div>;
  }
  getDefaultClassName() {
    return 'sth-step-in-rows';
  }
  getItems() {
    const items = this.getLayout().items;
    return items ? (Array.isArray(items) ? items : [items]) : [];
  }
}

export { StepInRow, StepInRows, SingleStepInRow };
