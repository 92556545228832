import { ReactDOM, $ } from '../../../../common/3rd';
import { Envs, Utils } from '../../../../common/common';
// import { format } from '../something-utils';
// import { PageLoading, Alert } from '../../../components';
import BaseSignatureOneToMoreView from './baseSignatureOneToMoreView';
import tpl from '../../signatureHTML/agentReportHtml';

const R = require('ramda');
// const mock = require('../../../../mock/mockData_dev.json');

// const spRange = ['原已相识'];

/**
 * 业务员告知书 签名单证
 * add by susan.wu 2017 Nov.9
 *
 */
class PersonalInsuranceTips extends BaseSignatureOneToMoreView {
  constructor(props, context) {
    super(props, context);
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.currentIndex = 0;
    this.state.myIndex = 19;
    this.documentListIndex = 23;
  }

  UNSAFE_componentWillMount() {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.agentMsg = [
      self.agentType,
      '-19.1',
      true,
      '-1',
      '业务人员签名：',
      '3',
      '10',
      '',
      ['-19.4'],
    ];
    self.caContent = [
      {
        keyWord: '业务人员签名：',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '0',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }

  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    let self = this;
    let wizard = self.props.parent.props.parent;
    self.model = self.props.model;
    self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
    self.currentIndex = wizard.state.currentIndex || 0;
    if (
      self.currentIndex !== self.state.myIndex &&
      self.currentIndex !== self.documentListIndex
    ) {
      return;
    }
    self.resetData();
    self.agentMsg = [
      self.agentType,
      '-19.1',
      true,
      '-1',
      '业务人员签名：',
      '3',
      '10',
      '',
      ['-19.4'],
    ];
    self.caContent = [
      {
        keyWord: '业务人员签名：',
        keyWordIndex: '1',
        offsetX: '50',
        offsetY: '0',
        key: '0',
      },
    ];
    super.initData(
      tpl,
      self.props.model,
      self.currentSignaturePerson,
      self.currentIndex,
      self.state.myIndex
    );
  }

  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidUpdate() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidUpdate();
  }

  //render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
  componentDidMount() {
    if (
      this.currentIndex !== this.state.myIndex &&
      this.currentIndex !== this.documentListIndex
    ) {
      return;
    }
    this.resetUI();
    this.addEvent();
    super.componentDidMount();
  }

  /**
   * 业务员告知
   *
   * @memberof PersonalInsuranceTips
   */
  renderTell() {
    let self = this;
    let dom = self.parent;
    let extensionData = self.model.extensionData;
    // let extensionData = mock.model.extensionData;
    // 深度克隆
    const pure = (options) => {
      return R.clone(options);
    };
    // 禁用checkbox
    const disabledAllCheckboxes = (options) => {
      const { tell } = options;
      tell && $(tell).find('input[type=checkbox]').attr('disabled', 'true');
      return options;
    };
    // 根据code排序
    const sortByCode = (options) => {
      let { declarations = [] } = options;
      declarations = R.sortWith([R.ascend(R.prop('code'))])(declarations);
      return {
        ...options,
        declarations,
      };
    };
    // 勾选的核心算法
    const doCheck = (options, qn /*#declaration索引*/, n /*#tr索引*/) => {
      let { declarations = [], tell, stat } = options;
      let declIndexes = Array.isArray(qn) ? qn : [qn];
      for (let declIdx of declIndexes) {
        let checkeds = declarations[declIdx].remark.split('/');
        for (let i = 1; i <= n; i++) {
          let tr = $(tell).find(`tr:nth-child(${stat + i})`);
          $(tr)
            .find('input[type=checkbox]')
            .each((key, el) => {
              if (
                checkeds.length > 0 &&
                !R.isEmpty(declarations[declIdx].remark) &&
                !R.isNil(declarations[declIdx].remark)
              ) {
                checkeds.forEach((checked, key) => {
                  const arr = checked.split(':');
                  if (arr[0] === $(el).data('remark')) {
                    $(el).attr('checked', 'true');
                    // 25/01/2018 - TAG: by yanzhi.mo - 描述信息紧贴的情况
                    // if (spRange.includes(arr[0])) {
                    // 	const spanNode = $(el).next()
                    // 	// 25/01/2018 - TAG: by yanzhi.mo - 同事写的bug吧，防止进来两次
                    // 	if (!$(spanNode).text().includes(arr[1])) {
                    // 		$(spanNode).text($(spanNode).text() + arr[1])
                    // 		return
                    // 	}
                    // }
                    // 25/01/2018 - TAG: by yanzhi.mo - 描述不紧贴的情况
                  }
                  if (arr[1]) {
                    $(tr).find('span[name=description]').html(arr[1]);
                  }
                });
              } else {
                const remark = declarations[declIdx].confirm ? '是' : '否';
                if (remark === $(el).data('remark')) {
                  $(el).attr('checked', 'true');
                }
              }
            });
        }
      }
      return {
        ...options,
        stat: options.stat + n,
      };
    };
    const Q1 = (options) => {
      return doCheck(options, 0, 3);
    };
    const Q2 = (options) => {
      return doCheck(options, 1, 1);
    };
    const Q3 = (options) => {
      return doCheck(options, [2, 3, 4], 3);
    };
    const Q4 = (options) => {
      return doCheck(options, 5, 2);
    };
    const Q5 = (options) => {
      return doCheck(options, 6, 1);
    };
    const Q6 = (options) => {
      return doCheck(options, 7, 1);
    };
    const Q7 = (options) => {
      return doCheck(options, 8, 1);
    };
    const Q8 = (options) => {
      return doCheck(options, 9, 1);
    };
    const Q9 = (options) => {
      const { declarations = [], tell, stat } = options;

      const descs = declarations[10].remark.split('/');
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#policy_this_income')
        .html(descs[0]);
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#policy_last_income')
        .html(descs[1]);
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#policy_ex_income')
        .html(descs[2]);

      options = doCheck(options, 10, 1);
      options = doCheck(options, 11, 2);
      return options;
    };
    const Q10 = (options) => {
      const { declarations = [], tell, stat } = options;

      const descs = declarations[12].remark.split('/');
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#insured_this_income')
        .html(descs[0]);
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#insured_last_income')
        .html(descs[1]);
      $(tell)
        .find(`tr:nth-child(${stat + 1})`)
        .find('#insured_ex_income')
        .html(descs[2]);

      options = doCheck(options, 12, 1);
      options = doCheck(options, 13, 2);
      return options;
    };
    const Q11 = (options) => {
      return doCheck(options, 14, 1);
    };
    const Q12 = (options) => {
      return doCheck(options, 15, 1);
    };
    // 获取Tell节点
    const getTell = function (options) {
      return options.tell;
    };

    const f1 = R.pipe(
      pure,
      sortByCode,
      disabledAllCheckboxes,
      Q1,
      Q2,
      Q3,
      Q4,
      Q5,
      Q6,
      Q7,
      Q8,
      Q9,
      Q10,
      Q11,
      Q12,
      getTell
    );

    // 启动渲染
    const run = function () {
      // 渲染投保人
      dom.find('#tell').replaceWith(
        f1({
          declarations: extensionData.declarations,
          tell: dom.find('#tell').clone(),
          stat: 0,
        })
      );
    };

    run.call(this);
  }

  //重置数据
  resetData() {
    let self = this;
    super.resetData(self.model);
  }

  //初始化页面数据
  resetUI() {
    let self = this;
    self.parent = $(ReactDOM.findDOMNode(self.refs['signatureHtml']));
    self.agentImageApp = self.parent.find('#imageApp1');
    self.parent
      .find('#logImage')
      .attr(
        'src',
        this.props.parent.props.parent.props.parent.state.policyLayout._logImage
          .bjlife
      );
    window
      .jQuery('#agentReport_policy_id')
      .barcode(self.proposalCode, 'code128', {
        barWidth: 1,
        barHeight: 50,
        showHRI: true,
      });
    //设置代理人电话
    let agentMobile = Envs.findAccount().mobile || '';
    if (
      Utils.getParameter(window.location.href, 'isShare') &&
      Utils.getParameter(window.location.href, 'agentMobile')
    ) {
      agentMobile = Utils.getParameter(window.location.href, 'agentMobile');
    }
    self.parent.find('#telephone').html(agentMobile);
    //设置单证日期
    let date = new Date();
    self.parent.find('#signYear').html(date.getFullYear());
    self.parent.find('#signMonth').html(parseInt(date.getMonth(), 10) + 1);
    self.parent.find('#signDay').html(date.getDate());
    //设置投保人姓名
    self.parent.find('#proposer_name').html(self.proposer.fullName);
    //设置产品名称
    self.mainProduct = {};
    if (self.model.insureds && self.model.insureds[0].coverages) {
      if (self.model.insureds[0].coverages.length === 1) {
        self.mainProduct = self.model.insureds[0].coverages[0] || {};
      } else {
        self.model.insureds[0].coverages.map((coverage, index) => {
          if (coverage.extensionData.displayPremIndi) {
            if (coverage.extensionData.sequence === 1) {
              self.mainProduct = coverage;
            }
          }
          return null;
        });
      }
    }
    self.parent.find('#product_name').html(self.mainProduct.coverageName);
    self.resetBtn();
    self.renderTell();
    super.resetUI();
  }

  resetBtn() {
    let self = this;
    self.parent.find('#sig-button').attr('class', 'onlyOneButton');
    self.agentSigBtn = self.parent.find('.sig-button3');
  }

  addEvent() {
    let self = this;
    self.parent.find('.sig-button3').on('click', self.clickAgentSigBtnHandler);
  }

  //各单证根据各自签名完成情况自定义 docType
  getPdfType() {
    let self = this;
    let pdfType = null;
    if (self.currentSignaturePerson === self.agentType) {
      pdfType = '-19.4';
    }
    return pdfType;
  }

  getDefaultClassName() {
    return '';
  }
}

export default PersonalInsuranceTips;
