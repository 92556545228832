import Lang from './lang';

const ALL = '--all';

/**
 * validation
 */
class Validation {
  static ALL = ALL;

  constructor() {
    this.failures = {};
  }

  hasFailure(key) {
    if (key) {
      return this.get(key).length > 0;
    } else {
      return (
        Object.keys(this.failures).find((key) => {
          return this.failures[key].length !== 0;
        }) != null
      );
    }
  }

  append(failure) {
    const key = failure.key;
    let fails = this.failures[key];
    if (!fails) {
      fails = [];
      this.failures[key] = fails;
    }
    let found = fails.find((fail) => {
      return fail.code === failure.code;
    });
    if (!found) {
      fails.push(failure);
    }
    return this;
  }

  remove(failure) {
    if (!failure) {
      // do nothing
      return this;
    }
    const key = failure.key;
    let fails = this.failures[key];
    if (!fails || fails.length === 0) {
      // no failure exists, do nothing
      return this;
    }
    let code = failure.code;
    if (code === ALL) {
      // remove all failures
      delete this.failures[key];
    } else {
      const index = fails.findIndex((fail) => {
        return fail.code === code;
      });
      if (index >= 0) {
        fails.splice(index, 1);
      }
    }
    return this;
  }

  get(key) {
    const fails = this.failures[key];
    return fails ? fails : [];
  }

  all() {
    return this.failures;
  }

  mergeFrom(validation) {
    if (validation) {
      Object.keys(validation.all()).forEach((key) => {
        const fails = validation.get(key);
        fails.forEach((fail) => {
          this.append(fail);
        });
      });
    }
    return this;
  }

  // validation utilities
  // static NAME_PATTERN = /[\u4e00-\u9fa5a-zA-Z0-9\s\.\-\_]+/;
  static NAME_PATTERN = /.+/;
  static CODE_PATTERN = /[a-zA-Z0-9\.\-\_]+/;
  static NUM_8_2 = /^\d{1,8}\.{1}\d{1,2}$|^\d{1,8}$/;
  static MOBILE_PATTERN = /^1[3|4|5|7|8][0-9]{9}$/;
  static EMAIL_PATTERN =
    /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  /**
   * 金额校验正则
   * @type {RegExp}
   */
  static MONEY_PATTERN = /^[0-9]+([.]{1}[0-9]{1,2})?$/;

  /**
   * 校验最大长度
   * @param {*} length
   */
  static checkMaxLength(length) {
    return function (value, model, component) {
      if (!length) {
        return;
      }
      var realLength = 0,
        charCode = -1;
      for (var i = 0; i < value.length; i++) {
        charCode = value.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) realLength += 1;
        else realLength += 3;
      }
      if (realLength > length) {
        const validation = this.getValidationResult();
        if (validation) {
          validation.append({
            key: this.getPropertyName(),
            code: 'lengthlimitExceeded',
            message: Lang.messages.common.validation[
              'lengthlimitExceeded'
            ].replace('{0}', length),
          });
        }
      }
    };
  }

  /**
   * 校验最大长度(包含中文)
   * @param {*} length
   */
  static checkMaxLengthWithChinese(length) {
    return function (value, model, component) {
      if (!length) {
        return;
      }
      const CHINESE_REG = /[\u4e00-\u9fa5]/g;
      const OTHER_REG = /[^\u4e00-\u9fa5]/g;
      let chineseLen = 0;
      let otherLen = 0;
      value.replace(CHINESE_REG, function ($0, $1) {
        chineseLen += $0.length * 3;
      });
      value.replace(OTHER_REG, function ($0, $1) {
        otherLen += $0.length;
      });
      if (chineseLen + otherLen > length) {
        const validation = this.getValidationResult();
        if (validation) {
          validation.append({
            key: this.getPropertyName(),
            code: 'lengthlimitExceeded',
            message: Lang.messages.common.validation[
              'lengthlimitExceeded'
            ].replace('{0}', length),
          });
        }
      }
    };
  }

  /**
   * 使用正则表达式校验
   * @param {*} regexp
   * @param {*} messageCode
   * @param {*} callTest
   */
  static checkRegexp(regexp, messageCode, callTest) {
    return function (value, model, component) {
      if (callTest) {
        if (!regexp.test(value)) {
          const validation = this.getValidationResult();
          if (validation) {
            validation.append({
              key: this.getPropertyName(),
              code: messageCode,
              message: Lang.messages.common.validation[messageCode],
            });
          }
        }
      } else {
        let length = 0;
        value.replace(regexp, function ($0, $1) {
          length += $0.length;
        });
        if (value.length !== length) {
          const validation = this.getValidationResult();
          if (validation) {
            validation.append({
              key: this.getPropertyName(),
              code: messageCode,
              message: Lang.messages.common.validation[messageCode],
            });
          }
        }
      }
    };
  }

  /**
   * 校验时间区间, to >= from
   * @param {*} fromPropName
   * @param {*} toPropName
   * @param {*} messageCode
   */
  static checkDateRange(fromPropName, toPropName, messageCode) {
    return function (value, model, component) {
      const from = model[fromPropName];
      const to = model[toPropName];
      if (from != null && to != null) {
        if (from > to) {
          const validation = this.getValidationResult();
          if (validation) {
            validation.append({
              key: this.getPropertyName(),
              code: messageCode,
              message: Lang.messages.common.validation[messageCode],
            });
          }
        }
      }
    };
  }

  /**
   * 校验名称/简称格式
   */
  static checkName() {
    return Validation.checkRegexp(
      Validation.NAME_PATTERN,
      'nameAbbrInvalid',
      false
    );
  }

  /**
   * 校验代码
   */
  static checkCode() {
    return Validation.checkRegexp(
      Validation.CODE_PATTERN,
      'codeInvalid',
      false
    );
  }

  /**
   * 校验手机号
   */
  static checkMobile() {
    return Validation.checkRegexp(
      Validation.MOBILE_PATTERN,
      'mobileInvalid',
      true
    );
  }

  /**
   * 校验邮箱
   */
  static checkEmail() {
    return Validation.checkRegexp(
      Validation.EMAIL_PATTERN,
      'emailInvalid',
      true
    );
  }

  /**
   * 金额校验
   * @returns {boolean}
   */
  static checkMoney(value) {
    return Validation.MONEY_PATTERN.test(value);
  }

  /**
   * 校验数字类型, 默认8.2格式
   * @param {*} regexp
   * @param {*} messageCode
   */
  static checkNumeric(regexp, messageCode) {
    // console.log(regexp, messageCode);
    return Validation.checkRegexp(
      regexp ? regexp : Validation.NUM_8_2,
      messageCode ? messageCode : 'numeric82',
      true
    );
  }

  static checkInteger() {
    const value = this.getValue();
    const REG = /^[0-9]\d*$/;
    if (value && !REG.test(value)) {
      this.raiseValidationFailure('notNum');
    }
  }

  static checkDecimal() {
    const value = this.getValue();
    const REG = /^\d+\.\d{1,2}$|^\d+$/;
    if (value && !REG.test(value)) {
      this.raiseValidationFailure('notFee');
    }
  }
}

export default Validation;
