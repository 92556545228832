import { React, ClassNames } from '../../common/3rd';
import { Widget } from '@/component/components';
import Toast from '../both/toast/toast';

/**
 * check menu item
 */
class CheckMenuItem extends Widget {
  render() {
    const className = ClassNames('check-menu-item', {
      checked: this.isChecked(),
    });
    return (
      <div className={className} onClick={this.onMenuItemClicked}>
        <span className="text">{this.getTitle()}</span>
        <span className="fa fa-fw fa-check check"></span>
        <hr />
      </div>
    );
  }
  getTitle() {
    return this.getPropsValue('title');
  }
  isChecked() {
    return this.getPropsValue('checked');
  }
  onMenuItemClicked = (evt) => {
    let action = this.props.action;
    if (action) {
      action.call(this);
    } else {
      Toast.showNotImpl();
    }
  };
}

export default CheckMenuItem;
