import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 状态页
 * 例：投保成功
 * add by wendy.gu 2017 Oct.20
 *
 */
class StatusPanel extends AtomicComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.iconType = this.props.layout.iconType || 0;
    this.state.msg1 = this.props.layout.msg1 || ''; //主提示内容
    this.state.msg2 = this.props.layout.msg2 || ''; //副提示内容
    this.state.msg3 = this.props.layout.msg3 || ''; //副提示内容
  }
  render() {
    return (
      <div className="status-container">
        {this.renderIcon()}
        {this.renderMsg1()}
        {this.renderMsg2()}
        {this.renderMsg3()}
      </div>
    );
  }
  renderIcon() {
    if (this.state.iconType === 1) {
      return <div className="statusImg"></div>;
    }
    if (this.state.iconType === 2) {
      return <div className="statusImgErr"></div>;
    }
    if (this.state.iconType === 3) {
      return <div className="statusImgErr"></div>;
    }
    return <div></div>;
  }
  renderMsg1() {
    if (this.state.msg1 !== '') {
      return <p className="mainMsg">{this.state.msg1}</p>;
    }
  }
  renderMsg2() {
    if (this.state.msg2 !== '') {
      // let policyId = this.props.model.policyId;
      let msg2 = this.state.msg2;
      let ids = this.props.layout.ids || [];
      if (ids.length > 0) {
        ids.map((currentValue, index, array) => {
          let val = this.getValueFromModel(currentValue);
          msg2 = msg2.replace('{' + index + '}', val);
        });
      }
      return (
        <div className="mainDesc" dangerouslySetInnerHTML={{ __html: msg2 }} />
      );
    }
  }
  renderMsg3() {
    if (this.state.msg3 !== '') {
      return <p className="mainDesc">{this.state.msg3}</p>;
    }
  }
  componentWillUnmount() {
    this.setState({
      iconType: 0,
      msg1: '',
      msg2: '',
    });
  }
}

export default StatusPanel;
