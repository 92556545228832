import { React, ReactDOM, $ } from '../../../common/3rd';
import { Envs, Lang, Storage } from '../../../common/common';
import { ReactRouterContextWidget } from '@/component/components';
import Logo from '../../both/logo/logo';
import Toast from '../../both/toast/toast';

import './desk-top-menu.scss';
import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'desk-top-menu');

const SYSTEM_ADMIN = 'sa';
const TENANT_ADMIN = 'ta';
const ORGAN_ADMIN = 'oa';
const NORMAL = 'na';
// 所有菜单定义
const ALL_MENUS = [
  {
    // 业务定义维护
    label: 'config',
    children: [
      {
        // 产品维护
        code: 'desk-product-maintain',
        label: 'product',
        path: Envs.PATH.DESK_PRODUCT_LIST,
        for: SYSTEM_ADMIN,
      },
      {
        // 产品热销维护(系统级/租户级)
        code: 'desk-product-hot-maintain',
        label: 'product_hot_recommend',
        path: Envs.PATH.DESK_PRODUCT_HOT,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
        // }, {	// 产品推荐维护(系统级/租户级)
        // 	code: "desk-product-recommend-maintain",
        // 	label: 'product_recommend',
        // 	path: Envs.PATH.DESK_PRODUCT_RECOMMEND,
        // 	for: [SYSTEM_ADMIN, TENANT_ADMIN]
      },
      {
        // 产品标签维护(系统级/租户级)
        code: 'desk-product-tag-maintain',
        label: 'product_tag',
        path: Envs.PATH.DESK_PRODUCT_TAG_LIST,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
      {
        // 产品标签关联关系维护(系统级/租户级)
        code: 'desk-product-tag-joint-maintain',
        label: 'product_tag_jonit',
        path: Envs.PATH.DESK_PRODUCT_TAG_JOINT,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
      {
        // 合约维护
        code: 'desk-agreement-maintain',
        label: 'agreement',
        path: Envs.PATH.DESK_AGREEMENT_LIST,
        for: SYSTEM_ADMIN,
      },
      {
        // 产品UI维护
        label: 'product_ui',
        children: [
          {
            // 模板维护(系统级)
            code: 'desk-product-ui-template-maintain',
            label: 'product_ui_temp',
            path: Envs.PATH.DESK_PRODUCT_TEMP,
            for: SYSTEM_ADMIN,
          },
          {
            // 产品UI定义维护(系统级)
            code: 'desk-product-ui-maintain',
            label: 'product_ui_maintain',
            path: Envs.PATH.DESK_PRODUCT_UI,
            for: SYSTEM_ADMIN,
          },
        ],
      },
      {
        //发现配置
        code: 'desk-explore-maintain',
        label: 'productExplore',
        path: Envs.PATH.DESK_PRODUCT_EXPLORE_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
      {
        //在线课程配置
        code: 'desk-course-config',
        label: 'onlineCourse',
        path: Envs.PATH.DESK_ONLINE_COURSE,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
      {
        //在线课程配置
        code: 'desk-quiz-config',
        label: 'quiz',
        path: Envs.PATH.DESK_QUIZ,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
      {
        //产说会维护
        code: 'desk-product-meeting-maintain',
        label: 'productMeeting',
        path: Envs.PATH.DESK_PRODUCT_MEETING,
        for: [SYSTEM_ADMIN, TENANT_ADMIN],
      },
    ],
  },
  {
    // 系统管理
    label: 'admin',
    children: [
      {
        // 租户管理
        label: 'tenant',
        children: [
          {
            // 本租户, 只读
            code: 'desk-tenant-current',
            label: 'currentTenant',
            path: Envs.PATH.DESK_TENANT_VIEW,
            for: [ORGAN_ADMIN, NORMAL],
            always: true,
          },
          {
            code: 'desk-tenant-current',
            label: 'currentTenant',
            path: Envs.PATH.DESK_TENANT_CURRENT,
            for: [TENANT_ADMIN, SYSTEM_ADMIN],
            always: true,
          },
          {
            // 租户列表
            code: 'desk-tenant-list',
            label: 'tenantList',
            path: Envs.PATH.DESK_TENANT_LIST,
            for: SYSTEM_ADMIN,
          },
          {
            // 租户菜单权限管理
            code: 'desk-tenant-menus',
            label: 'tenantMenus',
            path: Envs.PATH.DESK_TENANT_AUTHORIZATION,
            for: SYSTEM_ADMIN,
          },
        ],
      },
      {
        // 机构管理
        label: 'organ',
        children: [
          {
            code: 'desk-organ-current',
            label: 'currentOrgan',
            path: Envs.PATH.DESK_ORGAN_CURRENT,
            for: [ORGAN_ADMIN],
            always: true,
          },
          {
            code: 'desk-organ-current',
            label: 'currentOrgan',
            path: Envs.PATH.DESK_ORGAN_VIEW,
            for: [NORMAL],
            always: true,
          },
          {
            code: 'desk-organ-list',
            label: 'subOrgan',
            path: Envs.PATH.DESK_ORGAN_LIST,
            for: [ORGAN_ADMIN, TENANT_ADMIN, SYSTEM_ADMIN],
          },
        ],
      },
      {
        // 账户管理
        label: 'account',
        children: [
          {
            //本账户
            code: 'desk-account-current',
            label: 'currentAccount',
            path: Envs.PATH.DESK_ACCOUNT_CURRENT,
            always: true,
          },
          {
            // 账户列表
            code: 'desk-account-list',
            label: 'subAccount',
            path: Envs.PATH.DESK_SUB_ACCOUNT_LIST,
            for: [TENANT_ADMIN, ORGAN_ADMIN, SYSTEM_ADMIN],
          },
          {
            // API Key管理
            code: 'desk-account-apikey',
            label: 'apikey',
            path: Envs.PATH.DESK_API_KEY,
            for: TENANT_ADMIN,
          },
        ],
      },
      {
        //修改密码
        code: 'desk-account-changepwd',
        label: 'changePwd',
        path: Envs.PATH.DESK_CHANGE_PWD,
        always: true,
        // }, {	//语言设置
        // 	code: "desk-account-changelang",
        // 	label: 'changeLang',
        // 	path: Envs.PATH.DESK_CHANGE_LANG
      },
    ],
  },
  {
    // 业务操作
    label: 'biz',
    children: [
      {
        //订单查询
        code: 'desk-policy-query',
        label: 'policyQuery',
        path: Envs.PATH.DESK_POLICY_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
        // }, {	//统计
        // 	//label: 'statistics',
        // 	code: "desk-policy-download",
        // 	label: 'policyStat',
        // 	path: Envs.PATH.DESK_POLICY_STATISTICS,
        // 	for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN]

        // }, {	//保单日志查询
        // 	code: "desk-policy-log-query",
        // 	label: 'policyLogQuery',
        // 	path: Envs.PATH.DESK_POLICY_LOG_QUERY,
        // 	for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN]
      },
      {
        //客户查询
        code: 'desk-customer-query',
        label: 'customerQuery',
        path: Envs.PATH.DESK_CUSTOMER_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //支付查询
        code: 'desk-payment-query',
        label: 'paymentQuery',
        path: Envs.PATH.DESK_PAYMENT_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //在线课程培训查询
        code: 'desk-online-course-train-query',
        label: 'onlineCourseTrainQuery',
        path: Envs.PATH.DESK_ONLINE_COURSE_TRAIN_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //增员管理查询
        code: 'desk-member-query',
        label: 'memberQuery',
        path: Envs.PATH.DESK_MEMBER_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //在线考试情况查询
        code: 'desk-online-quiz-query',
        label: 'onlineQuizQuery',
        path: Envs.PATH.DESK_ONLINE_QUIZ_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //业绩查询
        code: 'desk-achievement-query',
        label: 'achievementQuery',
        path: Envs.PATH.DESK_ACHIEVEMENT_QUERY,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //代理人业务查询报表
        code: 'desk-agent-report',
        label: 'agentReportQuery',
        path: Envs.PATH.DESK_AGENT_REPORT,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //用户报表
        code: 'desk-account-report',
        label: 'accountReportQuery',
        path: Envs.PATH.DESK_ACCOUNT_REPORT,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
      {
        //产品报表
        code: 'desk-product-report',
        label: 'productReportQuery',
        path: Envs.PATH.DESK_PRODUCT_REPORT,
        for: [SYSTEM_ADMIN, TENANT_ADMIN, ORGAN_ADMIN],
      },
    ],
  },
];

/**
 * 菜单构造器
 */
class MenuBuilder {
  constructor() {
    const account = Envs.findAccount();
    let menus = account.menus || '';
    this.menus = menus.split(',');
  }
  isMenuAuthorized(code) {
    return this.menus.indexOf(code) !== -1;
  }
  build() {
    return ALL_MENUS.filter((menu) => {
      return this.filterMenuByRole(menu);
    }).map((menu) => {
      return this.wrapLabel(menu);
    });
  }
  /**
   * 国际化标签
   *
   * @param {menu} menu
   */
  wrapLabel(menu) {
    menu.labelKey = menu.labelKey || menu.label;
    menu.label = Lang.messages['desk-top-menu'].menus[menu.labelKey];
    if (menu.children) {
      menu.children.forEach((child) => {
        this.wrapLabel(child);
      });
    }
    return menu;
  }
  /**
   * 根据角色过滤
   *
   * @param {menu} menu
   */
  filterMenuByRole(menu) {
    if (Envs.isVirtualTenant()) {
      // 虚拟用户的账户, 没有任何权限
      return false;
    }
    menu.allChildren = menu.allChildren || menu.children;
    if (menu.allChildren) {
      menu.children = menu.allChildren.filter((child) => {
        return this.filterMenuByRole(child);
      });
      if (menu.children.length === 0 && !menu.path) {
        // 本身不是叶子菜单, 也没有子菜单
        return false;
      }
    }

    if (!menu.for) {
      // 没有定义角色, 都可以用
      return true;
    }
    const roles = Array.isArray(menu.for) ? menu.for : [menu.for];
    if (Envs.isSystemOwnerAdmin()) {
      // 系统管理员只要菜单定义中包含角色即可访问
      return roles.indexOf(SYSTEM_ADMIN) >= 0;
    } else if (Envs.isTenantAdmin()) {
      // 租户管理员需要菜单定义中包含角色
      // 并且菜单为始终可访问, 或者该菜单被授权
      return (
        roles.indexOf(TENANT_ADMIN) >= 0 &&
        (menu.always === true || this.isMenuAuthorized(menu.code))
      );
    } else if (Envs.isOrganAdmin()) {
      // 机构管理员需要菜单定义中包含角色
      // 并且菜单为始终可访问, 或者该菜单被授权
      return (
        roles.indexOf(ORGAN_ADMIN) >= 0 &&
        (menu.always === true || this.isMenuAuthorized(menu.code))
      );
    } else {
      // 一般需要菜单定义中包含角色
      // 并且菜单为始终可访问, 或者该菜单被授权
      return (
        roles.indexOf(NORMAL) >= 0 &&
        (menu.always === true || this.isMenuAuthorized(menu.code))
      );
    }
  }
}

/**
 * 菜单
 */
class Menu extends ReactRouterContextWidget {
  renderSecondarySubMenus(parentMenu, parentMenuIndex) {
    if (!parentMenu.children) {
      return;
    }
    return [
      <span
        className="fa fa-fw fa-chevron-right direction"
        key={`dir-${parentMenuIndex}`}
      />,
      <div className="secondary-sub-menus" key="sub">
        {parentMenu.children
          .filter((subMenu) => {
            return subMenu != null;
          })
          .map((subMenu, subMenuIndex) => {
            return (
              <div
                className="secondary-sub-menu"
                key={`secondary-sub-menu-${parentMenuIndex}-${subMenuIndex}`}
                onClick={this.onSecondaryMenuClicked.bind(this, subMenu)}
              >
                <span>{subMenu.label}</span>
              </div>
            );
          })}
      </div>,
    ];
  }
  renderSubMenus(parentMenu) {
    if (!parentMenu.children) {
      return;
    }
    return (
      <div className="desk-sub-menus">
        {parentMenu.children
          .filter((subMenu) => {
            return subMenu != null;
          })
          .map((subMenu, subMenuIndex) => {
            return (
              <div
                className="desk-sub-menu"
                onClick={this.onSubMenuClicked.bind(this, subMenu)}
                onMouseEnter={this.onSubMenuMouseEnter.bind(this, subMenu)}
                onMouseLeave={this.onSubMenuMouseLeave.bind(this, subMenu)}
                key={`sub-menu-${subMenuIndex}`}
              >
                <span>{subMenu.label}</span>
                {this.renderSecondarySubMenus(subMenu, subMenuIndex)}
              </div>
            );
          })}
      </div>
    );
  }
  render() {
    const menu = this.getMenu();
    return (
      <div
        className="desk-menu"
        onClick={this.onMenuClicked}
        onMouseEnter={this.onMenuMouseEnter}
        onMouseLeave={this.onMenuMouseLeave}
        ref="menu"
      >
        <span>{menu.label}</span>
        {this.renderSubMenus(menu)}
      </div>
    );
  }
  getMenu() {
    return this.props.menu;
  }
  onMenuClicked = () => {
    this.switchPath(this.getMenu());
  };
  onMenuMouseEnter = (evt) => {
    const menu = this.getMenu();
    if (menu.children) {
      const menuDOM = $(ReactDOM.findDOMNode(this.refs.menu));
      menuDOM.addClass('shown');
    }
  };
  onMenuMouseLeave = (evt) => {
    this.hideAllMenus();
  };
  onSubMenuClicked(menu) {
    this.switchPath(menu);
  }
  onSubMenuMouseEnter(menu, evt) {
    const menuDOM = $(evt.target);
    if (menu.children) {
      menuDOM.addClass('shown');
    }
    menuDOM.siblings().removeClass('shown');
  }
  onSubMenuMouseLeave(menu, evt) {
    if (menu.children) {
      $(evt.target).removeClass('shown');
    }
  }
  onSecondaryMenuClicked(menu) {
    this.switchPath(menu);
  }
  hideAllMenus() {
    const menuDOM = $(ReactDOM.findDOMNode(this.refs.menu));
    menuDOM.find('.shown').removeClass('shown');
    menuDOM.removeClass('shown');
  }
  switchPath(menu) {
    if (menu.path) {
      Toast.hide();
      this.pushRedirect(menu.path);
      this.hideAllMenus();
    } else if (!menu.children) {
      Toast.showNotImpl();
    }
  }
}

/**
 * 菜单集合
 */
class Menus extends React.Component {
  renderMenus() {
    const builder = new MenuBuilder();
    return builder.build().map((menu, menuIndex) => {
      return <Menu menu={menu} key={`menu-${menuIndex}`} />;
    });
  }
  render() {
    return <div className="desk-menus">{this.renderMenus()}</div>;
  }
}

/**
 * 右侧菜单
 */
class RightMenus extends ReactRouterContextWidget {
  render() {
    return (
      <div className="right-menus">
        <div className="who-am-i">
          <span>{Envs.findAccount().accountName}</span>
        </div>
        <div className="sign-out" onClick={this.onSignOutClicked}>
          <span className="fa fa-fw fa-sign-out" />
          <span>{Lang.messages['desk-top-menu'].signout}</span>
        </div>
      </div>
    );
  }
  onSignOutClicked = () => {
    Storage.clearAllSession();
    localStorage.clear();
    this.replaceRedirect(Envs.PATH.SIGN);
  };
}

/**
 * top menu for desktop
 */
class DeskTopMenu extends ReactRouterContextWidget {
  render() {
    return (
      <div className={this.getWidgetClassName('desk-top-menu')}>
        <Logo text={null} />
        <Menus />
        <RightMenus />
      </div>
    );
  }
}

export default DeskTopMenu;
