import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';
// import Label from '../component/label';
import { Panel } from '../container/panel';

/**
 * 保险责任
 */
class Duty extends Panel {
    getDefaultClassName() {
        return 'sth-duty';
    }
}

/**
 * 保险责任列表
 */
class Duties extends AtomicComponentThing {
    renderItems() {
        return this.getItems().map((duty, dutyIndex) => {
            return <Duty mode={ this.getModel() }
                         layout={ {
                             label      : duty.label,
                             collapsible: true,
                             expanded   : false,
                             layout     : {
                                 content: {
                                     type : 'label',
                                     label: duty.desc
                                 }
                             }
                         } }
                         parent={ this }
                         key={ dutyIndex }/>
        });
    }

    render() {
        return (
            <div { ...this.getPrototypes() }>
                { this.renderItems() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-duties';
    }

    getItems() {
        const items = this.getLayout().items;
        return items ? (Array.isArray(items) ? items : [ items ]) : [];
    }
}

export { Duty, Duties }
