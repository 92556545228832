import { React, ReactDOM, $ } from '../../../common/3rd';
import Lang from '../../../common/lang';
import './pageLoading.scss';
import messages from './messages.json';

Lang.installMessages(messages, 'pageLoading');

class PageLoading extends React.Component {
  state = {
    open: false,
    message: '',
    promise: null,
    callback: null,
  };

  UNSAFE_componentWillMount() {}

  open() {
    this.setState({ open: true });
  }

  //开始加载
  start(messages) {
    const promise = $.Deferred();
    let msg = messages || this.state.message;
    this.setState({
      message: msg,
      open: true,
      promise: promise,
    });
    return promise.promise();
  }

  //结束加载
  end() {
    this.setState(
      {
        message: Lang.messages.pageLoading.loading,
        open: false,
      },
      () => {
        this.state.promise && this.state.promise.resolve();
        delete this.state.promise;
      }
    );
  }

  render() {
    let { open, message } = this.state;
    return (
      <div style={{ display: open ? 'block' : 'none' }}>
        <div id="loading-container-mask">
          <div className="page-loading">
            <div className="loading"></div>
            <br />
            {message}
          </div>
        </div>
      </div>
    );
  }
}

let container = $('#loading-container');
if (container.length === 0) {
  container = $('<div id="loading-container">').appendTo(document.body);
}
let pageLoading = ReactDOM.render(
  <PageLoading />,
  document.getElementById('loading-container')
);

export default pageLoading;
