/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 兼容、适配
 * @author LiuH
 */
import {
  isAndroid,
  isEmptyString,
  isNull,
  isRegExp,
  isUndefined,
} from '@/util/precondition';

function getUserAgent() {
  return (typeof navigator !== 'undefined' && navigator.userAgent) || '';
}

function getPlatform() {
  return (typeof navigator !== 'undefined' && navigator.platform) || '';
}

function getMaxTouchPoints() {
  return (typeof navigator !== 'undefined' && navigator.maxTouchPoints) || 1;
}

/**
 * 获取App客户端版本号
 * @param {string} [defaultValue=""] 默认版本号
 * @returns {string}
 */
function getAppVersion(defaultValue = '') {
  // 京保通/营销作业平台
  let pattern = new RegExp(/(JingBaoTong|IIMP)\//, 'gi');
  // Android获取 ; 第一次出现的位置
  if (isAndroid()) {
    return getVersion(pattern, defaultValue, ';');
  }
  return getVersion(pattern, defaultValue, null);
}

/**
 * 获取微信客户端版本号
 * @param {string} [defaultValue=""] 默认版本号
 * @returns {string}
 */
function getWechatVersion(defaultValue = '') {
  let pattern = new RegExp(/MicroMessenger\//, 'gi');
  return getVersion(pattern, defaultValue, ' ');
}

/**
 * 获取版本号
 * @param {RegExp} pattern 正则表达式
 * @param {string} defaultValue 默认版本号
 * @param {string} endSeparator 结束分隔符
 * @return {string}
 */
function getVersion(pattern, defaultValue, endSeparator) {
  let ua = getUserAgent().toLowerCase();
  if (!isRegExp(pattern)) {
    throw new TypeError("pattern expected 'RegExp'");
  }
  let lastIndex = null;
  while (pattern.exec(ua) != null) {
    lastIndex = pattern.lastIndex;
  }
  if (lastIndex == null) {
    return defaultValue;
  }
  ua = ua.substring(lastIndex);
  let endIndex = ua.length;
  if (!isUndefined(endSeparator) && !isNull(endSeparator)) {
    endIndex = ua.indexOf(endSeparator);
  }
  return ua.substring(0, endIndex);
}

/**
 * 比较京保通App客户端版本号
 * @param {string} currentVersion 当前版本号
 * @param {string} targetVersion 目标版本号
 * @returns {boolean}
 */
function isAppGreaterOrEquals(currentVersion, targetVersion) {
  // if (!currentVersion || !targetVersion) return false;
  if (isEmptyString(currentVersion) || isEmptyString(targetVersion)) {
    return false;
  }
  const curr = currentVersion.split('.');
  const target = targetVersion.split('.');
  // Android测试App多一位版本号
  if (curr.length > target.length) {
    // 移除多余的部分
    curr.shift();
    if (curr.length > target.length) {
      return true;
    }
  }
  // 比较版本号
  for (let i = 0; i < curr.length; i++) {
    if (parseInt(curr[i]) > parseInt(target[i])) {
      return true;
    }
    if (parseInt(curr[i]) < parseInt(target[i])) {
      return false;
    }
  }
  return true;
}

/**
 * 比较客户端版本号
 * @param {string} currentVersion 当前版本号
 * @param {string} targetVersion 目标版本号
 * @returns {boolean}
 */
function isGreaterOrEquals(currentVersion, targetVersion) {
  // if (!currentVersion || !targetVersion) return false;
  if (isEmptyString(currentVersion) || isEmptyString(targetVersion))
    return false;
  const curr = currentVersion.split('.');
  const target = targetVersion.split('.');
  if (curr.length > target.length) {
    return true;
  }
  // 比较版本号
  for (let i = 0; i < curr.length; i++) {
    if (parseInt(curr[i]) > parseInt(target[i])) {
      return true;
    }
    if (parseInt(curr[i]) < parseInt(target[i])) {
      return false;
    }
  }
  return true;
}

export {
  getUserAgent,
  getPlatform,
  getMaxTouchPoints,
  getAppVersion,
  getWechatVersion,
  getVersion,
  isAppGreaterOrEquals,
  isGreaterOrEquals,
};
