import { React } from '../../../../common/3rd';

import AtomicCodesComponentThing from '../atomic-codes-component';

/**
 * 下拉框
 */
class Dropdown extends AtomicCodesComponentThing {
  renderPlaceholder() {
    const placeholder = this.getPlaceholder();
    if (placeholder) {
      return <option hidden>{placeholder}</option>;
    }
  }
  changeHandler = () => {
    this.props.changeHandler && this.props.changeHandler();
    //与投保人关系为本人
    /* if (this.getLayout().id === "extensionData.relationWithPH" && this.getValueFromModel() === "1") {
			this.getRootComponent().forceUpdate();
		}
		//与被保人关系为本人
		if ("extensionData.relationWithInsured" === this.getLayout().id && this.getValueFromModel() === "1") {
			this.getRootComponent().forceUpdate();
		} */
  };
  render() {
    return (
      <select
        {...this.getPrototypes()}
        onChange={this.changeHandler}
        onBlur={this.doCheckAndAlert}
        ref="me"
      >
        {this.renderPlaceholder()}
        {this.getCodes().map((code, codeIndex) => {
          return (
            <option
              value={code.v}
              data-value-type={typeof code.v}
              key={codeIndex}
            >
              {code.l}
            </option>
          );
        })}
      </select>
    );
  }
  getDefaultClassName() {
    return 'sth-dropdown';
  }
  getPlaceholder() {
    return this.getLayout().placeholder;
  }
  getPrototypeNames() {
    return ['readOnly', 'disabled'];
  }
  setValueToDOM(value) {
    //缴费频率赋值
    /* if (this.getLayout().paymentFreq) {
			//var val = this.getValueFromModel();
			if (this.getModel().insureds[0].coverages && this.getModel().insureds[0].coverages.length > 0) {
				this.getModel().insureds[0].coverages.forEach(item => {
					if (!item.extensionData) return;
					console.log(item.extensionData.installment)
					if (item.extensionData.installment == "Y1") {
						item.extensionData.paymentFreq = 5;
					} else {
						item.extensionData.paymentFreq = 1;
					}
				});
			}
		} */
    super.setValueToDOM(value);
    if ((value == null || value === '') && this.getPlaceholder()) {
      this.me()
        .addClass('sth-dropdown-no-value')
        .find('option')
        .first()
        .attr({ selected: true });
    } else {
      this.me().removeClass('sth-dropdown-no-value');
    }
  }
}

export default Dropdown;
