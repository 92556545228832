import { React } from '../../common/3rd';
import { Lang } from '../../common/common';
import { Widget } from '@/component/components';

import './widgets.scss';

class NoMore extends Widget {
  render() {
    return (
      <div className={this.getWidgetClassName('no-more')}>
        <span>{Lang.messages.widget.noMore}</span>
      </div>
    );
  }
}

class LoadMore extends Widget {
  render() {
    return (
      <div className={this.getWidgetClassName('load-more')}>
        <span>
          <span className="fa fa-fw fa-spin fa-spinner" />
          {Lang.messages.widget.loadMore}
        </span>
      </div>
    );
  }
}

export { NoMore, LoadMore };
