/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description 日志工具
 * @author LiuH
 */
import envs from '@/common/envs';
import { formatNow } from '@/util/date';

/* eslint-disable */
class LogUtil {
  /**
   * 打印日志(由日志开关控制)
   */
  static log() {
    if (envs.getConfiguration().debug) {
      console.log(
        envs.getConfiguration().title,
        formatNow('yyyy-MM-dd hh:mm:ss.S'),
        ...arguments
      );
    }
  }
  /**
   * 打印日志(不受日志开关控制)
   */
  static info() {
    console.log(
      envs.getConfiguration().title,
      formatNow('yyyy-MM-dd hh:mm:ss.S'),
      ...arguments
    );
  }

  /**
   * 打印日志(由日志开关控制)
   */
  static warn() {
    if (envs.getConfiguration().debug) {
      console.warn(
        envs.getConfiguration().title,
        formatNow('yyyy-MM-dd hh:mm:ss.S'),
        ...arguments
      );
    }
  }

  /**
   * 打印日志(不受日志开关控制)
   */
  static error() {
    console.log(
      envs.getConfiguration().title,
      formatNow('yyyy-MM-dd hh:mm:ss.S'),
      ...arguments
    );
  }
}

export default LogUtil;
