/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description Web路由页面
 * @author LiuH
 */
import { Envs } from '@/common/common';

/**
 * 页面别名
 * @type {{BB: string, DZFP: string, BC: string, HI: string, LN: string, PR: string, PT: string, NS: string, LR: string, ONLINE_VISIT: string, PL_2: string, BM: string, SA: string, SC: string, BS: string, XQJF: string, WT: string, AA: string, OP: string, AE: string, EI: string, AG: string, GM: string, IO: string, EN: string, EP: string, AM: string, AP: string, CT: string, PC: string, RE: string, RF: string, LC: string, LG: string, PL: string, PM: string, DB: string, TR: string}}
 */
const WEB_PAGE_ALIAS = {
  /** 险种加保 */
  AA: 'AA',
  /** 更改投保人 */
  AE: 'AE',
  /** 满期金领取 */
  AG: 'AG',
  /** 联系方式变更 */
  AM: 'AM',
  /** 垫交方式变更 */
  AP: 'AP',
  /** 基本资料变更 */
  BB: 'BB',
  /** 受益人变更 */
  BC: 'BC',
  /** 红利领取方式变更 */
  BM: 'BM',
  /** 合同效力确认 */
  BS: 'BS',
  /** 退保 */
  CT: 'CT',
  /** 红利领取 */
  DB: 'DB',
  /** 实名认证 */
  EI: 'EI',
  /** 续保方式变更 */
  EN: 'EN',
  /** 中英文投保证明 */
  EP: 'EP',
  /** 年金领取方式变更 */
  GM: 'GM',
  /** 补充告知 */
  HI: 'HI',
  /** 生存金领取与转账授权 */
  LG: 'LG',
  /** 追加保费 */
  WP: 'WP',
  /** 借款 */
  LN: 'LN',
  /** 保单补发 */
  LR: 'LR',
  /** 新增附加险 */
  NS: 'NS',
  /** 在线回访 */
  ONLINE_VISIT: 'ONLINE_VISIT',
  /** 万能险部分领取 */
  OP: 'OP',
  /** 交费方式变更 */
  PC: 'PC',
  /** 保单冻结 */
  PL: 'PL',
  /** 保单解冻 */
  PL_2: 'PL_2',
  /** 交费频率变更 */
  PM: 'PM',
  /** 保单迁移 */
  PR: 'PR',
  /** 减保 */
  PT: 'PT',
  /** 复效 */
  RE: 'RE',
  /** 还款 */
  RF: 'RF',
  /** 指定第二投保人 */
  SA: 'SA',
  /** 特别约定变更 */
  SC: 'SC',
  /** 保费自垫清偿 */
  TR: 'TR',
  /** 犹豫期退保 */
  WT: 'WT',
  /** 职业变更 */
  IO: 'IO',
  /** 函件回销 */
  LC: 'LC',
  /** 续期缴费 */
  XQJF: 'XQJF',
  /** 电子发票 */
  DZFP: 'DZFP',
  /** 保单回执 */
  BDHZ: 'BDHZ',
  /** 理赔申请 */
  LPSQ: 'LPSQ',
  /** 手术日期变更 */
  OD: 'OD',
};

/**
 * 页面别名和路径映射
 * @type {Map<string, string>}
 */
const WEB_PAGE_ALIAS_MAP = new Map([
  // 联系方式变更(跳过智能保全列表，直接进入身份验证)
  [
    WEB_PAGE_ALIAS.AM,
    {
      pathname: Envs.PATH.WECHAT_MY_CONTACT_CHANGE,
      policyNo: null,
      fromList: true,
    },
  ],
  // 续期缴费
  [WEB_PAGE_ALIAS.XQJF, Envs.PATH.WECHAT_MY_RENEWAL_MODE_PAYMENT],
  // 在线回访
  [WEB_PAGE_ALIAS.ONLINE_VISIT, Envs.PATH.WECHAT_MY_ONLINE_VISIT],
  // 电子发票
  [WEB_PAGE_ALIAS.DZFP, Envs.PATH.WECHAT_MY_E_INVOICE],
  // 理赔申请
  [WEB_PAGE_ALIAS.LPSQ, Envs.PATH.WECHAT_MY_PERSONAL_INSURANCE_CLAIMS],
]);

/**
 *
 * @param {string} to 页面别名
 * @return {null|string} 页面名称对应的路径
 */
function findWebPage(to) {
  let target;
  // 基本资料变更、交费方式变更、退保、犹豫期退保、联系方式变更（智能保全列表）
  if (
    [
      WEB_PAGE_ALIAS.BB,
      WEB_PAGE_ALIAS.PC,
      WEB_PAGE_ALIAS.CT,
      WEB_PAGE_ALIAS.WT,
      WEB_PAGE_ALIAS.AM,
    ].includes(to)
  ) {
    target = Envs.PATH.WECHAT_MY_INTELLIGENT_SECURITY_LIST.replace(
      ':securityType',
      to
    );
    return target;
  }
  target = WEB_PAGE_ALIAS_MAP.get(to);
  return target;
}

/**
 * 获取知识库类型
 * @param {string} pAlias 详见{@link WEB_PAGE_ALIAS}
 * @return {string}
 */
function findKnowledgeTypeByAlias(pAlias) {
  switch (pAlias) {
    case WEB_PAGE_ALIAS.AM:
      return 'LXBG';
    case WEB_PAGE_ALIAS.BB:
      return 'ZLBG';
    case WEB_PAGE_ALIAS.BC:
      return 'SGBG';
    case WEB_PAGE_ALIAS.LR:
      return 'BDBF';
    case WEB_PAGE_ALIAS.PC:
      return 'JFBG';
    case WEB_PAGE_ALIAS.RE:
      return 'BDFX';
    case WEB_PAGE_ALIAS.GM:
      return 'GM';
    case WEB_PAGE_ALIAS.HI:
      return 'QBBD-HI';
    // case WEB_PAGE_ALIAS.EI:
    // case WEB_PAGE_ALIAS.EN:
    // case WEB_PAGE_ALIAS.ONLINE_VISIT:
    // case WEB_PAGE_ALIAS.WT:
    default:
      return '';
  }
}

export {
  WEB_PAGE_ALIAS,
  WEB_PAGE_ALIAS_MAP,
  findWebPage,
  findKnowledgeTypeByAlias,
};
