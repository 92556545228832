import { React, ReactDOM, $, ClassNames } from '../../../common/3rd';
import { Utils, Lang } from '../../../common/common';

import './toast.scss';
import messages from './messages.json';

// install i18n messages
Lang.installMessages(messages, 'toast');

const icons = {
  I: 'fa fa-fw fa-info-circle',
  W: 'fa fa-fw fa-warning',
  S: 'fa fa-fw fa-check-circle',
  Q: 'fa fa-fw fa-question-circle',
  E: 'fa fa-fw fa-times-circle',
};

/**
 * Toast item
 */
class ToastItem extends React.Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState({ start: true });
    }, 10);
    Utils.transitionend({
      target: $(ReactDOM.findDOMNode(this.refs.me)).find('hr'),
      repeat: true,
      handler: this.onTransitionEnd,
    });
  }

  render() {
    const className = ClassNames('toast-item', {
      start: this.state.start,
    });
    return (
      <div className={className} ref="me">
        <span className={this.getIconClassName(this.props.icon)} />
        <span className="toast-text">{this.props.text}</span>
        <hr />
      </div>
    );
  }

  getIconClassName(icon) {
    if (icon) {
      if (icon.length === 1) {
        icon = icons[icon.toUpperCase()];
      }
      return ClassNames('toast-icon', icon);
    } else {
      return 'toast-icon toast-icon-hidden';
    }
  }

  onTransitionEnd = (evt) => {
    let propertyName = evt.originalEvent.propertyName;
    if (propertyName === 'right') {
      $(ReactDOM.findDOMNode(this.refs.me)).slideUp(() => {
        this.props.onRemove.call(this, this.props.afterHide);
      });
    }
  };
}

/**
 * Toast
 */
class Toast extends React.Component {
  INFO = 'I';
  WARN = 'W';
  SUCCESS = 'S';
  QUESTION = 'Q';
  ERROR = 'E';
  state = {
    text: [],
  };

  renderItem(text) {
    return (
      <ToastItem
        {...text}
        key={text.id}
        onRemove={this.onItemRemove.bind(this)}
      />
    );
  }

  onItemRemove(afterHide) {
    this.state.text.shift();
    this.forceUpdate(() => {
      if (afterHide) {
        afterHide.call(this);
      }
    });
  }

  renderMore(moreCount) {
    return (
      <div className="more-toast-items" key="render-more">
        <span className="toast-icon fa fa-fw fa-warning" />
        <span className="toast-text">
          {Lang.messages.toast.hold.replace('%count%', moreCount)}
        </span>
      </div>
    );
  }

  renderItems() {
    if (this.state.text.length > 3) {
      const moreCount = this.state.text.length - 3;
      return this.state.text
        .slice(0, 3)
        .map((text, index) => {
          return this.renderItem(text, index);
        })
        .concat(this.renderMore(moreCount));
    } else {
      return this.state.text.map((text, index) => {
        return this.renderItem(text, index);
      });
    }
  }

  render() {
    let text = this.getText();
    let className = ClassNames(
      'toast',
      {
        'toast-shown': text && text.length > 0,
      },
      this.state.icon ? `toast-type-${this.state.icon.toLowerCase()}` : ''
    );
    return (
      <div className={className} ref="me">
        {this.renderItems()}
      </div>
    );
  }

  getText() {
    return this.state.text;
  }

  show(text, icon, afterHide) {
    this.state.text.push({
      text: text,
      icon: icon,
      afterHide: afterHide,
      id: Date.now() | 0, // get timestamp in millisecond
    });
    this.forceUpdate();
  }

  showNotImpl() {
    this.show(Lang.messages.common['not-impl'], 'i');
  }

  showSuccess() {
    this.show(Lang.messages.common.success, 's');
  }

  showValidationFail() {
    this.show(Lang.messages.common.validate_fail, 'w');
  }

  showFillAtLeastOne() {
    this.show(Lang.messages.common.fillAtLeastOne, 'i');
  }

  showFillAll() {
    this.show(Lang.messages.common.fillAll, 'i');
  }

  showNoDataFound() {
    this.show(Lang.messages.common.no_data_found, 'i');
  }

  hide() {
    let afterHideHandlers = this.state.text
      .map((text) => {
        return text.afterHide;
      })
      .filter((func) => {
        return func != null;
      });
    this.setState(
      {
        text: [],
      },
      () => {
        afterHideHandlers.forEach((afterHide) => {
          afterHide.call(this);
        });
      }
    );
  }
}

let container = $('#toast-container');
if (container.length === 0) {
  container = $('<div id="toast-container">').appendTo(document.body);
}
let toast = ReactDOM.render(
  <Toast />,
  document.getElementById('toast-container')
);

export default toast;
