import { React } from '../../../common/3rd';
import { Envs, Utils } from '../../../common/common';
import './fcm.scss';
let iconfcm =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-fcm.png';
let iconfja =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-fja.png';
let iconmc =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-mc.png';
let iconfcmS =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-fcmS.png';
let iconfjaS =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-fjaS.png';
let iconmcS =
  'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/icon-mcS.png';
let parent;
class Fcm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabs: [
        { tabName: '热点资讯', id: 'fcm', image: iconfcm, imageS: iconfcmS },
        { tabName: '精彩活动', id: 'fja', image: iconfja, imageS: iconfjaS },
        { tabName: '会员中心', id: 'mc', image: iconmc, imageS: iconmcS },
      ],
    };
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    this.setState({
      current: this.props.numCnt,
    });
    parent = this.props.parent;
  }
  componentDidMount() {}
  itemNav = (index) => {
    let account = Envs.findAccount();
    console.log(account, 'account');
    if (index == '0') {
      parent.pushRedirect(Envs.PATH.WECHAT_MY_FCM);
    } else if (index == '1') {
      // if (!account.accountId) {
      //   sessionStorage.setItem('routerFrom', 'fja');
      //   parent.pushRedirect(Envs.PATH.WECHAT_SIGN.replace);
      // } else {
      parent.pushRedirect(Envs.PATH.WECHAT_MY_FJA);
      // }
    } else if (index == '2') {
      if (!account.accountId) {
        sessionStorage.setItem('routerFrom', 'mc');
        parent.pushRedirect(Envs.PATH.WECHAT_SIGN);
      } else {
        parent.pushRedirect(Envs.PATH.WECHAT_MY_MC);
      }
    }
  };
  render() {
    var tabsList = this.state.tabs.map((item, index) => {
      var tabUrl = index === this.state.current ? item.imageS : item.image;
      var tabClass = index === this.state.current ? 'tab-active' : '';
      return (
        <div
          className={`tab-item ${tabClass}`}
          onClick={this.itemNav.bind(this, index)}
        >
          <div className="tab-item-icon">
            <img src={tabUrl} />
          </div>
          <div className="tab-item-name">{item.tabName}</div>
        </div>
      );
    });
    return (
      <div className="tabbarWrap" ref="serviceContainer">
        <div className="tabBottom">
          <div className="tabbar">{tabsList}</div>
        </div>
      </div>
    );
  }
}

export default Fcm;
