import { React } from '../../../common/3rd';
import { Envs, Utils } from '../../../common/common';
import './tabbar.scss';
import iconhome from '../../../images/wechat/footer/icon-home.png';
import iconvip from '../../../images/wechat/footer/icon-vip.png';
import iconservice from '../../../images/wechat/footer/icon-service.png';
import iconmine from '../../../images/wechat/footer/icon-mine.png';
import iconhomeS from '../../../images/wechat/footer/icon-homeS.png';
import iconvipS from '../../../images/wechat/footer/icon-vipS.png';
import iconserviceS from '../../../images/wechat/footer/icon-serviceS.png';
import iconmineS from '../../../images/wechat/footer/icon-mineS.png';
import bjLogo from '../../../images/wechat/footer/icon-logo.png';

class Tabbar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabs: [
        { tabName: '首页', id: 'home', image: iconhome, imageS: iconhomeS },
        { tabName: '紫金荟', id: 'vip', image: iconvip, imageS: iconvipS },
        {
          tabName: '服务',
          id: 'service',
          image: iconservice,
          imageS: iconserviceS,
        },
        { tabName: '我的', id: 'mine', image: iconmine, imageS: iconmineS },
      ],
      current: 0,
    };
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    this.setState({
      current: this.props.numCnt,
    });
  }
  componentDidMount() {}
  itemNav = (index) => {
    if (index == 0) {
      Utils.setPoint(
        'click',
        'click_homepagefunctionmenu',
        '【点击】首页功能菜单',
        { function_name: '首页' }
      );
    } else if (index == 1) {
      Utils.setPoint(
        'click',
        'click_homepagefunctionmenu',
        '【点击】首页功能菜单',
        { function_name: '紫金荟' }
      );
    } else if (index == 2) {
      Utils.setPoint(
        'click',
        'click_homepagefunctionmenu',
        '【点击】首页功能菜单',
        { function_name: '服务' }
      );
    } else if (index == 3) {
      Utils.setPoint(
        'click',
        'click_homepagefunctionmenu',
        '【点击】首页功能菜单',
        { function_name: '我的' }
      );
    }
    this.setState({
      current: index,
    });
    this.props.getdata(index);
  };
  callPhone = () => {
    let titleName = '';
    if (this.state.current == '0') {
      titleName = '首页';
    } else if (this.state.current == '1') {
      titleName = '紫金荟';
    } else if (this.state.current == '2') {
      titleName = '服务';
    } else if (this.state.current == '3') {
      titleName = '我的';
    }
    window['_XFLOW_'].pushEvent([
      'click',
      'click_customer_service',
      '点击客服电话',
      { page_title: titleName },
      function () {},
    ]);
    window.location.href = 'tel:4008196677';
  };

  render() {
    var tabsList = this.state.tabs.map((item, index) => {
      var tabUrl = index === this.state.current ? item.imageS : item.image;
      var tabClass = index === this.state.current ? 'tab-active' : '';
      return (
        <div
          className={`tab-item ${tabClass}`}
          onClick={this.itemNav.bind(this, index)}
        >
          <div className="tab-item-icon">
            <img src={tabUrl} />
          </div>
          <div className="tab-item-name">{item.tabName}</div>
        </div>
      );
    });
    return (
      <div className="tabbarWrap" ref="serviceContainer">
        <div className="wrapFooter">
          <img className="footerLogo" src={bjLogo} alt="" />
          <div className="footerOne">
            客服电话{' '}
            <a className="footer-phone" onClick={this.callPhone}>
              400-81-96677
            </a>
          </div>
          <div className="footerTwo">
            ©北京人寿保险股份有限公司&nbsp;&nbsp;|&nbsp;&nbsp;京ICP备18011190号-1
          </div>
        </div>
        <div className="wrapBottom"></div>
        <div className="tabBottom">
          <div className="tabbar">{tabsList}</div>
        </div>
      </div>
    );
  }
}

export default Tabbar;
