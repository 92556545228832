import { Alert } from '@/component/components';
import { isAndroid, isIOS } from '@/util';

/**
 * 移动端通用分享接口
 * 调用移动平台原生接口实现分享链接的功能
 * 目前支持平台Android App和IOS App
 */
class Share {
  static share(title, desc, targetUrl, thumbUrl) {
    try {
      let json = {
        action: 'share', //action name
        type: 0, // 0链接分享； 1图片分享;  2音乐分享； 3视频分享
        title: title, //分享的标题
        desc: desc, //描述
        targetUrl: targetUrl, //点击进入的url
        thumbUrl: thumbUrl, //缩略图
        imageUrl: '', //分享的图片url
        musicUrl: '', //分享的可以直接播放的音乐url, 因为在微信qq分享音乐，是可以在当前聊天界面播放的，要求这个musicUrl必须要以.mp3等音乐格式结尾
        videoUrl: '', //分享的视频url。
      };
      //uncomment below if passing json directly to call native methods doesn't work
      json = JSON.stringify(json);
      if (isAndroid()) {
        window.__EBAO_JSSDK.callNative(json);
      } else if (isIOS()) {
        window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(json);
      }
    } catch (e) {
      Alert.message('当前客户端不支持此功能');
    }
  }
}

export default Share;
