import { React, ClassNames } from '../../../../common/3rd';
import ContainerThing from '../container';
import { Label } from '../component/label';

/**
 * 格子, 自带标签.
 */
class Cell extends ContainerThing {
    renderInput() {
        const layout = this.getLayout();
        const model = this.getModel();
        return React.createElement(this.matchComponentType(layout.type), {
            model : model,
            layout: layout,
            parent: this,
            key   : layout.id,
            ref   : layout.id
        });
    }

    renderUnit() {
        const layout = this.getLayout();
        if (layout.unit) {
            return <div className='sth-cell-unit'>{ layout.unit }</div>;
        }
    }

    render() {
        const className = ClassNames('sth-cell', `sth-cell-${ this.getColumnsGrabbed() }`, {
            'sth-cell-row-grabbed': this.isRowGrabbed()
        });
        return (
            <div className={ className }>
                <Label model={ this.getModel() }
                       layout={ { label: this.getLabel() } }
                       parent={ this }/>
                { this.renderInput() }
                { this.renderUnit() }
            </div>
        );
    }
}

export default Cell;