import { React, ReactDOM, $ } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 电子签名成功提交
 * 例：电子签名页面--分享
 * add by susan.wu 2017 Oct.19
 *
 */
class SubmitSignSuccess extends AtomicComponentThing {
  render() {
    let currentSignaturePerson =
      this.props.parent.props.parent.state.currentSignaturePerson;
    let className =
      currentSignaturePerson === 3 ? 'closePage' : 'closePage hidden';
    return (
      <div className="submitSignSuccess-container" ref="container">
        <div className="image-container">
          <i className="icon icon-success-2"></i>
        </div>
        <div className="success-text">电子签名已成功提交</div>
        <div className={className} onClick={this.backToSignatureList}>
          关闭页面
        </div>
      </div>
    );
  }
  componentDidMount() {
    $(ReactDOM.findDOMNode(this.refs['container']))
      .parents('.page-body')
      .css('padding-bottom', '0');
  }
  backToSignatureList = () => {
    this.getRootComponent().jumpTo('signatureList');
  };
}

export default SubmitSignSuccess;
