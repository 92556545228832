import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';
import IconLabels from '../component/icon-labels';

/**
 * 投保条件
 */
class QuoteConditions extends AtomicComponentThing {
    renderConditions() {
        return this.getLabels().map((label, labelIndex) => {
            return <IconLabels model={ this.getModel() }
                               layout={ label }
                               parent={ this }
                               key={ labelIndex }/>
        });
    }

    render() {
        return (
            <div { ...this.getPrototypes() }>
                { this.renderConditions() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-quote-conditions';
    }

    getLabels() {
        const labels = this.getLayout().labels;
        return labels ? (Array.isArray(labels) ? labels : [ labels ]) : [];
    }
}

export default QuoteConditions;
