import { React, ClassNames } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';

/**
 * 单行Label, 包含
 * 1. 最左边一个图标,
 * 		1.1 可以使用icon font, 需要使用i:开头
 * 		1.2 使用外链
 * 		1.3 可以指定图标的class
 * 2. 后面接多个label
 * 		2.1 label可以是字符串
 * 		2.2 可以是JSON对象 {class: '', label: ''}
 */
class IconLabels extends AtomicComponentThing {
  renderLabels() {
    return this.getLabels().map((label, index) => {
      if (typeof label === 'string') {
        return (
          <span className="sth-icon-labels-label" key={index}>
            {label}
          </span>
        );
      } else {
        const className = ClassNames('sth-icon-labels-label', label.class);
        return (
          <span className={className} key={index}>
            {label.label}
          </span>
        );
      }
    });
  }
  renderIcon() {
    const icon = this.getIcon();
    if (icon) {
      if (icon.startsWith('i:')) {
        // 使用icon font
        const className = ClassNames(
          this.getIconClassName(),
          icon.substring(2),
          'sth-icon-labels-icon'
        );
        return <span className={className} />;
      } else {
        // 外链
        const className = ClassNames(
          this.getIconClassName(),
          'sth-icon-labels-icon'
        );
        return (
          <span className={className}>
            <img src={icon} alt="" />
          </span>
        );
      }
    }
  }
  render() {
    return (
      <span {...this.getPrototypes()}>
        {this.renderIcon()}
        {this.renderLabels()}
      </span>
    );
  }

  getDefaultClassName() {
    return 'sth-icon-labels';
  }
  getIcon() {
    return this.getLayout().icon;
  }
  getIconClassName() {
    return this.getLayout().iconClass;
  }
  getLabels() {
    const labels = this.getLayout().labels;
    if (labels) {
      return Array.isArray(labels) ? labels : [labels];
    } else {
      return [];
    }
  }
}

export default IconLabels;
