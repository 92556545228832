import { React } from '../../common/3rd';

import { Widget } from '@/component/components';

/**
 * form row, 包含一个label.
 * 其他内容直接画children.
 * 目前仅用于移动端, 请勿使用到桌面端
 */
class FormRow extends Widget {
  renderLabel() {
    const label = this.getLabel();
    // const className = this.getLabelId() ? this.getLabelId() : 'label';
    if (this.getLabelId()) {
      return (
        <div className={this.getLabelId()}>
          <span>{label}</span>
        </div>
      );
    }
    if (label) {
      return <span className="label">{label}</span>;
    }
  }
  render() {
    return (
      <div className={this.getWidgetClassName('row-text')}>
        {this.renderLabel()}
        {this.props.children}
      </div>
    );
  }
  getLabel() {
    return this.getPropsValue('label');
  }
  getLabelId() {
    return this.getPropsValue('labelId');
  }
}

export default FormRow;
