// import { React } from '../../../../common/3rd';
import Button from '../component/button';

class AddOtherImage extends Button {
  needCheckBeforeAction() {
    return false;
  }

  doAction() {
    this.getRootComponent().addOtherImage();
    super.doAction();
  }

  getDefaultClassName() {
    return 'sth-add-other-image';
  }
}

export default AddOtherImage;
