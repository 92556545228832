import { React } from '../../common/3rd';
import { Widget } from '@/component/components';
import Toast from '../both/toast/toast';

/**
 * menu item
 */
class MenuItem extends Widget {
  render() {
    return (
      <div className="menu-item" onClick={this.onMenuItemClicked}>
        <span className={`fa fa-fw fa-${this.getIcon()} icon`} />
        <span className="text">{this.getTitle()}</span>
        <span className="fa fa-fw fa-angle-right go-deep" />
        <hr />
      </div>
    );
  }
  getTitle() {
    return this.getPropsValue('title');
  }
  getIcon() {
    return this.getPropsValue('icon');
  }
  onMenuItemClicked = (evt) => {
    let action = this.props.action;
    if (action) {
      action.call(this);
    } else {
      Toast.showNotImpl();
    }
  };
}

export default MenuItem;
