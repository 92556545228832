import { React, $ } from '../../../../common/3rd';
import { Lang } from '../../../../common/common';
import * as SthUtils from '../something-utils';

import AtomicComponentThing from '../atomic-component';
import Dropdown from './dropdown';
import { Label } from './label';
import { provsData, citysData, distsData } from '../../../../common/larea-data';

/**
 * 省
 */
class Province extends Dropdown {
  getDefaultClassName() {
    return 'sth-dropdown sth-address sth-province';
  }

  getPlaceholder() {
    return super.getPlaceholder() || Lang.messages.common.address.province;
  }
}

/**
 * 市
 */
class City extends Dropdown {
  getDefaultClassName() {
    return 'sth-dropdown sth-address sth-city';
  }

  getPlaceholder() {
    return super.getPlaceholder() || Lang.messages.common.address.city;
  }
}

/**
 * 区
 */
class Region extends Dropdown {
  getDefaultClassName() {
    return 'sth-dropdown sth-address sth-region';
  }

  getPlaceholder() {
    return super.getPlaceholder() || Lang.messages.common.address.region;
  }
}

/**
 * 省市区地址组件
 */
class Address extends AtomicComponentThing {
  shouldInstallDefaultIdToEventEmitter() {
    return false;
  }

  constructor(props, context) {
    super(props, context);
    const provinceCode = this.getValueFromModel(
      this.getLayout().provinceCodeId
    );
    const cityCode = this.getValueFromModel(this.getLayout().cityCodeId);
    this.state.address = {
      province: provsData,
      city: citysData[provinceCode],
      region: distsData[cityCode],
    };
  }

  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    /* const provinceCode = this.getValueFromModel(this.getLayout().provinceCodeId);
         const cityCode = this.getValueFromModel(this.getLayout().cityCodeId);
         console.log(provinceCode, cityCode)
         this.state.address = {
         province: provsData,
         city: citysData[provinceCode] || [],
         region: distsData[cityCode] || []
         } */
  }

  /**
   * TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
   * edit by cheng.fan
   * 传值更新
   *
   * @param {any} nextProps
   * @memberof Address
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const provinceCode = SthUtils.getValueFromModel(
      nextProps.model,
      this.getLayout().provinceCodeId
    );
    const cityCode = SthUtils.getValueFromModel(
      nextProps.model,
      this.getLayout().cityCodeId
    );
    this.state.address = {
      province: provsData,
      city: citysData[provinceCode] || [],
      region: distsData[cityCode] || [],
    };
  }

  componentDidMount() {}

  getAddress() {
    return this.state.address;
  }

  getProvince() {
    if (this.getAddress()) {
      return this.getAddress().province || [];
    }
    return [];
  }

  getCity() {
    if (this.getAddress()) {
      return this.getAddress().city || [];
    }
    return [];
  }

  getRegion() {
    if (this.getAddress()) {
      return this.getAddress().region || [];
    }
    return [];
  }

  setCityByProvince(provinceValue) {
    this.getAddress().city = citysData[provinceValue] || [];
    this.setState({
      address: this.getAddress(),
    });
  }

  setRegionByCity(cityValue) {
    this.getAddress().region = distsData[cityValue] || [];
    this.setValueToModel(
      '',
      function () {},
      false,
      this.getLayout().regionCodeId
    );
    this.setState({
      address: this.getAddress(),
    });
  }

  onChangeProvince() {
    const provinceValue = this.refs.province.getValueFromModel();
    this.setCityByProvince(provinceValue);
    this.setRegionByCity();
    console.log(this.getModel());
  }

  onChangeCity() {
    const cityValue = this.refs.city.getValueFromModel();
    this.setRegionByCity(cityValue);
  }

  render() {
    return (
      <div {...this.getPrototypes()}>
        <Label
          model={this.getModel()}
          layout={{ label: this.getLabel() }}
          parent={this}
        />
        <div className="sth-address">
          <Province
            model={this.getModel()}
            changeHandler={this.onChangeProvince.bind(this)}
            layout={this.getProvinceLayout()}
            ref="province"
            parent={this}
          />
          <City
            model={this.getModel()}
            changeHandler={this.onChangeCity.bind(this)}
            layout={this.getCityLayout()}
            ref="city"
            parent={this}
          />
          <Region
            model={this.getModel()}
            layout={this.getRegionLayout()}
            ref="region"
            parent={this}
          />
        </div>
      </div>
    );
  }

  getDefaultClassName() {
    return 'sth-cell';
  }

  getProvinceLayout() {
    const layout = this.getLayout();
    return $.extend({}, layout, {
      id: layout.provinceCodeId,
      placeholder: '请选择省',
      codes: this.getProvince(),
    });
  }

  getCityLayout() {
    const layout = this.getLayout();
    return $.extend({}, layout, {
      id: layout.cityCodeId,
      placeholder: '请选择市',
      codes: this.getCity(),
    });
  }

  getRegionLayout() {
    const layout = this.getLayout();
    return $.extend({}, layout, {
      id: layout.regionCodeId,
      placeholder: '请选择区',
      codes: this.getRegion(),
    });
  }
}

export default Address;
