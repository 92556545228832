import { React, ClassNames } from '../../common/3rd';
import Text from './text';

/**
 * password
 */
class Password extends Text {
  static PATTERN =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[0-9A-Za-z!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{6,20}$/;
  constructor(props) {
    super(props);
    this.state.passwordShown = false;
  }
  renderTogglePasswordShownButton() {
    const canShow = this.canShowPassword();
    if (canShow) {
      if (this.state.passwordShown) {
        return (
          <span
            className="pwd-btn fa fa-fw fa-eye-slash"
            onClick={this.onHidePasswordClicked}
            key="btn"
          />
        );
      } else {
        return (
          <span
            className="pwd-btn fa fa-fw fa-eye"
            onClick={this.onShowPasswordClicked}
            key="btn"
          />
        );
      }
    }
  }
  renderRealWidget(props) {
    props.key = 'input';
    return [
      super.renderRealWidget(props),
      this.renderTogglePasswordShownButton(),
    ];
  }
  getProps() {
    const props = super.getProps();
    props.className = ClassNames('password', props.className);
    return props;
  }
  getType() {
    return this.state.passwordShown ? 'text' : 'password';
  }
  getDefaultValidators() {
    return (value) => {
      const pass = Password.PATTERN.test(value);
      if (!pass) {
        this.raiseValidationFailure('pwd');
      }
      return pass;
    };
  }
  canShowPassword() {
    return this.getAdditionalPropValue('show-pwd', true);
  }
  onHidePasswordClicked = () => {
    this.setState({
      passwordShown: false,
    });
  };
  onShowPasswordClicked = () => {
    this.setState({
      passwordShown: true,
    });
  };
}

export default Password;
