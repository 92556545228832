import { React, ReactDOM, $ } from '../../../../common/3rd';
import { Lang } from '../../../../common/common';

import AtomicComponentThing from '../atomic-component';
import Dropdown from '../component/dropdown';
import { Label } from '../component/label';
import { lve1, lve2, lve3, lve4 } from '../../../../common/occupation';
import { occupationMapClass } from '../../../../common/occupation-map-class';

class JobCategory extends Dropdown {
    getDefaultClassName() {
        return 'sth-dropdown sth-job-category';
    }
}


class JobCategoryWarp extends AtomicComponentThing {
    shouldInstallDefaultIdToEventEmitter() {
        return false;
    }

    constructor(props, context) {
        super(props, context);
        const job1Code = this.getValueFromModel(this.getLayout().job1);
        const job2Code = this.getValueFromModel(this.getLayout().job2);
        const job3Code = this.getValueFromModel(this.getLayout().job3);
        const job4Code = this.getValueFromModel(this.getLayout().job4);
        this.state.jobCategory = {
            job1: lve1,
            job2: lve2[job1Code] || [],
            job3: lve3[job2Code] || [],
            job4: lve4[job3Code] || []
        };
    }

    // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        const job1Code = this.getValueFromModel(this.getLayout().job1);
        const job2Code = this.getValueFromModel(this.getLayout().job2);
        const job3Code = this.getValueFromModel(this.getLayout().job3);
        const job4Code = this.getValueFromModel(this.getLayout().job4);
        this.state.jobCategory = {
            job1: lve1,
            job2: lve2[job1Code] || [],
            job3: lve3[job2Code] || [],
            job4: lve4[job3Code] || []
        };
    }

    getJob(job) {
        if (this.state.jobCategory) {
            return this.state.jobCategory[job] || [];
        }
        return [];
    }

    onChangeJob(job) {
        let jobCode = this.refs[job].getValueFromModel();
        let jobWrap = JSON.parse(JSON.stringify(this.state.jobCategory));
        if (job === "job1") {
            jobWrap.job2 = lve2[jobCode];
            jobWrap.job3 = [];
            jobWrap.job4 = [];
        }
        if (job === "job2") {
            jobWrap.job3 = lve3[jobCode];
            jobWrap.job4 = [];
        }
        if (job === "job3") {
            jobWrap.job4 = lve4[jobCode];
        }
        if (job !== "job4") {
            this.setValueToModel("", function() {
            }, false, this.getLayout().job4);
            this.setValueToModel("", function() {
            }, false, this.getLayout().id);
        }
        if (job === "job4") {
            // console.log(jobCode, occupationMapClass[jobCode])
            let jobCategory = occupationMapClass[jobCode]["class"]
            this.setValueToModel(jobCategory, function() {
            }, false, this.getLayout().id);
        }
        this.setState({
            jobCategory: jobWrap
        }, () => {
            console.log(this.state.jobCategory);
        });
    }

    getJobLayout(job) {
        const layout = this.getLayout();
        let aaa = $.extend({}, layout, {
            id         : layout[job],
            placeholder: "请选择",
            codes      : this.getJob(job)
        });
        return aaa;
    }

    render() {
        // console.log(this.getModel());
        return (
            <div { ...this.getPrototypes() }>
                <Label model={ this.getModel() }
                       layout={ { label: this.getLabel() } }
                       parent={ this }/>
                <div className='sth-job-category-warp'>
                    <JobCategory model={ this.getModel() }
                                 changeHandler={ this.onChangeJob.bind(this, "job1") }
                                 layout={ this.getJobLayout("job1") }
                                 ref="job1"
                                 parent={ this }/>
                    <JobCategory model={ this.getModel() }
                                 changeHandler={ this.onChangeJob.bind(this, "job2") }
                                 layout={ this.getJobLayout("job2") }
                                 ref="job2"
                                 parent={ this }/>
                    <JobCategory model={ this.getModel() }
                                 changeHandler={ this.onChangeJob.bind(this, "job3") }
                                 layout={ this.getJobLayout("job3") }
                                 ref="job3"
                                 parent={ this }/>
                    <JobCategory model={ this.getModel() }
                                 changeHandler={ this.onChangeJob.bind(this, "job4") }
                                 layout={ this.getJobLayout("job4") }
                                 ref="job4"
                                 parent={ this }/>
                </div>
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-cell';
    }
}

export default JobCategoryWarp;