import { React, $, ReactDOM } from '../../../../common/3rd';
// import AtomicComponentThing from '../atomic-component'
import AtomicMutableComponentThing from '../atomic-mutable-component';

/**
 * 分享链接能否打开
 * 例：分享衔接
 * add by susan.wu 2017 Nov.22
 *
 */
class ShareUrlStatus extends AtomicMutableComponentThing {
  render() {
    // let currentSignaturePerson = this.props.parent.props.parent.state.currentSignaturePerson;
    // let className = currentSignaturePerson === 3 ? "closePage" : "closePage hidden"
    return (
      <div className="shareUrlStatus-container" ref="container">
        <div className="image-container">
          <i className="icon icon-fail"></i>
        </div>
        <div className="error-text">{this.getLayout().message}</div>
      </div>
    );
  }
  componentDidMount() {
    $(ReactDOM.findDOMNode(this.refs['container']))
      .parents('.page-body')
      .css('padding-bottom', '0');
  }
}

export default ShareUrlStatus;
