import { React, ClassNames, $ } from '../../common/3rd';

import { CheckableWidget } from './widget';

class Text extends CheckableWidget {
  renderRealWidget(props) {
    return <input {...props} ref="widget" />;
  }
  wrapOthers(props) {
    if (!props.type) {
      props.type = this.getType();
    }
    props.className = ClassNames(props.className, props.type);
    return this.wrapOnFocus(props).wrapKeyEnter(props).wrapPlaceholder(props);
  }
  wrapOnFocus(props) {
    const onFocus = props.onFocus;
    props.onFocus = (evt) => {
      this.onFocus(evt);
      if (onFocus) {
        onFocus.call(this, evt);
      }
    };
    return this;
  }
  wrapPlaceholder(props) {
    if (!props.placeholder) {
      props.placeholder = this.getDefaultPlaceholder();
    }
    return this;
  }
  wrapKeyEnter(props) {
    const onKeyEnter = this.getUnwrappedAdditionalPropValue('onKeyEnter');
    if (!onKeyEnter) {
      return this;
    }
    const onKeyUp = props.onKeyUp;
    props.onKeyUp = (evt) => {
      if (evt.keyCode === 13) {
        onKeyEnter.call(this, evt);
      }
      if (onKeyUp) {
        onKeyUp.call(this, evt);
      }
    };
    return this;
  }
  getType() {
    return this.getPropsValue('type', 'text');
  }
  getDefaultPlaceholder() {
    return null;
  }
  getValidateDelay() {
    return this.getPropsValue('a-validate-delay', 1000);
  }
  onFocus(evt) {
    // $(evt.target).select();
  }
}

export default Text;
