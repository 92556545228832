import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';
import IconLabels from '../component/icon-labels';

/**
 * 保障内容
 */
class GuardPoints extends AtomicComponentThing {
    renderConditions() {
        return this.getLabels().map((label, labelIndex) => {
            return <IconLabels model={ this.getModel() }
                               layout={ {
                                   icon  : 'i:fa fa-fw fa-check-square-o',
                                   labels: label
                               } }
                               parent={ this }
                               key={ labelIndex }/>
        });
    }

    render() {
        return (
            <div { ...this.getPrototypes() }>
                { this.renderConditions() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-guard-points';
    }

    getLabels() {
        const labels = this.getLayout().labels;
        return labels ? (Array.isArray(labels) ? labels : [ labels ]) : [];
    }
}

export default GuardPoints;
