import { React } from '../../../../common/3rd';
import { Utils, Envs } from '../../../../common/common';
import AtomicComponentThing from '../atomic-component';
// import PageLoading from './page-loading'
import UnderwritingFail from './underwritingFail';
import UnderwritingSuc from './underwritingSuc';
import StatusPage from './statusPage';
import { PageLoading, Something } from '../../../components';

const STATE_NA = 0; //查询中，无状态
const STATE_SUCCSS = 1; //自核成功
const STATE_FAILURE = 2; //自核失败
const STATE_ARITIFICIAL = 3; //进入人核
/**
 * 自动核保结果
 * 例：在线投保 自动核保结果页
 * add by wendy.gu 2017 Oct.19
 */
class Underwriting extends AtomicComponentThing {
  constructor(props, context) {
    super(props, context);
    this.initing = false; //是否正在请求核保结果接口
    //当前核保接口 0状态获取中   1成功   2失败  3进人核
    this.state.underwritingState = STATE_NA;
    this.state.reasons = []; //失败原因
  }
  componentDidMount() {
    //初次进入在线投保时，判断当前状态，>=79表示
    let currentIndex = this.props.parent.props.parent.state.currentIndex;
    if (
      parseInt(currentIndex) === 9 &&
      parseInt(this.props.model.policyStatus) === 79
    ) {
      this.setState({
        underwritingState: STATE_SUCCSS,
      });
    } else if (
      parseInt(currentIndex) === 9 &&
      parseInt(this.props.model.policyStatus) !== 79
    ) {
      this.setState({
        underwritingState: STATE_NA, //触发再次调用自核接口
      });
    }
  }
  componentDidUpdate() {
    let currentIndex = this.props.parent.props.parent.state.currentIndex;
    if (
      parseInt(currentIndex) === 9 &&
      this.initing === false &&
      this.state.underwritingState !== STATE_SUCCSS
    ) {
      this.initing = true;
      this.setState({
        underwritingState: STATE_NA,
      });
      PageLoading && PageLoading.start();
      let oModel = this.props.model;
      let value = oModel.policyCustomers[0];
      if (value.extensionData) {
        let declarations = value.extensionData.declarations;
        if (declarations && declarations.length > 0) {
          declarations.map((declaration, index) => {
            if (declaration.code == '10113-B') {
              let num = index - 1;
              declarations[num].confirm = declaration.confirm;
              declarations[num].remark =
                declarations[num].remark + '/' + declaration.remark;
              let remarkArray = declarations[num].remark.split('/');
              let iHeight = remarkArray[0];
              let iWeight = remarkArray[1];
              let sOther = remarkArray[2];
              if (declaration.confirm) {
                declarations[
                  num
                ].remark = `身高${iHeight}厘米/体重${iWeight}公斤/${sOther}`;
              } else {
                declarations[
                  num
                ].remark = `身高${iHeight}厘米/体重${iWeight}公斤/否`;
                declarations[num].confirm = true;
              }
              declarations.splice(index, 1);
              return false;
            }
          });
        }
      }
      this.doPost('/rest/v1/uw/automatic', this.props.model)
        .done((data) => {
          // 自核失败
          if (data.body && data.body.resultCode === '99999') {
            this.setState({
              underwritingState: STATE_FAILURE,
              reasons: data.body.reasons,
            });
          } else if (data.body && data.body.resultCode === '11111') {
            //自核成功
            this.setState({
              underwritingState: STATE_SUCCSS,
            });
          } else {
            alert('数据异常');
          }
          //2018-01-31 add by susan 自核后需要更新最新的订单状态
          let accountId = Envs.findAccount().accountId;
          let policyId = this.getModel().policyId;
          const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
          PageLoading && PageLoading.start();
          this.doGet(url)
            .done((data) => {
              let { quoteInitData, templateInitData } = data.body;
              let policyModel = Something.merge(
                templateInitData,
                quoteInitData
              );
              this.props.parent.props.parent.setModel(policyModel);
            })
            .always(() => {
              PageLoading.end();
            });
        })
        .always(() => {
          PageLoading.end();
        });
    }
    //属性重置
    if (parseInt(currentIndex) !== 9) {
      this.initing = false;
    }
  }
  renderFail = () => {
    let insuredsName = '';
    if (this.props.model.policyCustomers.length > 0) {
      insuredsName = this.props.model.policyCustomers[0].fullName;
    }
    if (this.state.underwritingState === STATE_FAILURE) {
      return (
        <UnderwritingFail
          insuredsName={insuredsName}
          reasons={this.state.reasons}
          parent={this}
          toArtificial={this.toArtificial}
        />
      );
    }
  };
  renderSuc = () => {
    let globalCodes = this.getGlobalCodes();
    let checkTypeArr = globalCodes.CHECKMETHOD || [{ v: 2, l: '短信验证' }];
    if (Utils.isThirdParty()) {
      checkTypeArr = globalCodes.CHECKMETHODTHIRDPARTY || [
        { v: 2, l: '短信验证' },
      ];
    }
    if (this.state.underwritingState === STATE_SUCCSS) {
      return (
        <UnderwritingSuc
          ref="checkSuccess"
          checkTypeArr={checkTypeArr}
          parent={this}
        />
      );
    }
  };
  //渲染进入人核页
  renderAritificial = () => {
    if (this.state.underwritingState === STATE_ARITIFICIAL) {
      //调用人核接口
      return <StatusPage stateType={1} parent={this} />;
    }
  };
  render() {
    return (
      <div className="underwriting-container">
        {this.renderFail()}
        {this.renderSuc()}
        {this.renderAritificial()}
      </div>
    );
  }
  //点击人工核保
  toArtificial = () => {
    let oModel = this.props.model;
    let value = oModel.policyCustomers[0];
    if (value.extensionData) {
      let declarations = value.extensionData.declarations;
      if (declarations && declarations.length > 0) {
        declarations.map((declaration, index) => {
          if (declaration.code == '10113-B') {
            let num = index - 1;
            declarations[num].confirm = declaration.confirm;
            declarations[num].remark =
              declarations[num].remark + '/' + declaration.remark;
            let remarkArray = declarations[num].remark.split('/');
            let iHeight = remarkArray[0];
            let iWeight = remarkArray[1];
            let sOther = remarkArray[2];
            if (declaration.confirm) {
              declarations[
                num
              ].remark = `身高${iHeight}厘米/体重${iWeight}公斤/${sOther}`;
            } else {
              declarations[
                num
              ].remark = `身高${iHeight}厘米/体重${iWeight}公斤/否`;
              declarations[num].confirm = true;
            }
            declarations.splice(index, 1);
            return false;
          }
        });
      }
    }
    this.doPost('/rest/v1/uw/manual', this.props.model).done((data) => {
      this.setState({
        underwritingState: STATE_ARITIFICIAL,
      });
    });
  };
}

export default Underwriting;
