import { React, ReactDOM, $ } from '../../../common/3rd';
import { Alert } from '../../components';
import './SearchBox.scss';
import { occupationJSON_LEVEL, occupationJSON } from './occupation_bjlife.js';

let optionsSource;
let position;
class occupationSearch extends React.Component {
  constructor(props) {
    super(props);
    position = '';
    this.state = {
      isShow: false,
      showValue: '',
      options: [],
      sureValue: false,
      defaultVal: '',
      defaultCode: '',
      defaultPos: '',
      jsonLev: JSON.parse(JSON.stringify(occupationJSON_LEVEL)),
    };
    this.handleClick = this.handleClick.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.searchNotes = this.searchNotes.bind(this);
    this.submitV = this.submitV.bind(this);
  }

  shouldComponentUpdate() {
    return true;
  }
  componentWillUnmount() {
    position = '';
    optionsSource = '';
  }
  UNSAFE_componentWillMount() {
    this.getItems();
  }

  componentDidMount() {
    //判断默认值是否存在
    //console.log(this.props.defaultVal)
    this.setState({
      defaultVal: this.props.defaultVal,
    });
    let position = '';
    let valArr = occupationJSON.filter((item, index) => {
      position = item.id == this.props.defaultVal ? index : '';
      return item.id == this.props.defaultVal;
    });
    if (valArr.length > 0) {
      //传的职业在code中
      this.setState({
        showValue: valArr[0].name,
        defaultCode: JSON.stringify(valArr),
        defaultPos: position,
      });
    }
  }
  getItems() {
    optionsSource = occupationJSON;
    this.setState({
      options: optionsSource,
    });
    // console.log( optionsSource);
  }
  handleClick() {
    //初始化 数据选择列表 第一次展示 一定量数据
    let options = this.state.options;
    // if (!options || options.length === 0) {
    //     this.props.dataInt();
    //     return
    // }
    let isShow = this.state.isShow;
    this.setState({
      isShow: isShow ? false : true,
      sureValue: false,
      options,
    });
  }
  closeWindow() {
    this.setState(
      {
        isShow: false,
        sureValue: false,
        showValue: '',
        defaultVal: '',
        defaultCode: '',
        defaultPos: '',
      },
      () => {
        //新增关闭窗口后的回调函数
        if (this.props.closeWindowCallBack) {
          this.props.closeWindowCallBack();
        }
      }
    );
  }

  handleOptionClick(id) {
    //console.log(id)
    let selectedItem = this.state.options.filter((item, index) => {
      if (item.id === id) {
        position = index;
      }
      return item.id === id;
    })[0];

    this.setState(
      {
        showValue: selectedItem.name,
        defaultVal: '',
        sureValue: true,
        isShow: false,
        defaultCode: '',
        defaultPos: '',
      },
      () => {
        //新增关闭窗口后的回调函数
        if (this.props.closeWindowCallBack) {
          this.props.closeWindowCallBack(id);
        }
      }
    );
  }

  // onChangeData(inputValue) {
  //     if (this.props.callBackFlag && this.props.callBackFlag == "true") {
  //         this.props.dataInt(inputValue);
  //     }
  // }
  handleInputChange(e) {
    let inputValue = e.target.value;
    //父组建回掉
    // this.onChangeData(inputValue);
    //只允许输入中文 字母 数字 ' 空格
    // let reg = /^[A-Za-z0-9\u4e00-\u9fa5\s\/']+$/

    // if (inputValue && !reg.test(inputValue)) {
    //     Alert.message("只可以输入中文 字母 和数字");
    //     return
    // }
    this.setState({
      showValue: inputValue,
    });
  }

  submitV() {
    //如果 选择过 或 有输入的值 则通过
    let matchOk = this.state.options.filter((item) => {
      return item.name === this.state.showValue;
    });

    if (this.state.sureValue || matchOk.length > 0) {
      this.setState({
        isShow: false,
        defaultVal: '',
        defaultPos: '',
        defaultCode: '',
      });
    } else {
      Alert.message('暂无该信息');
      return;
    }
  }
  clearInput() {
    position = '';
    this.setState({
      showValue: '',
    });
  }
  searchNotes() {
    if (!this.state.isShow && (position || position == '0')) {
      let aData = [];
      aData.push(this.state.options[position]);
      position = '';
      return aData;
    }
    // if (this.props.callBackFlag && this.props.callBackFlag === "true") {
    //     console.log("服务器搜索")
    // }
    let aData = [],
      data = this.state.options,
      k = 0,
      regStr = '',
      reg;

    let aSearch = this.state.showValue
      ? this.state.showValue.split(' ')
      : false;
    if (aSearch) {
    } else {
      return data;
    }
    //拼写正则
    for (let i = 0, lenR = aSearch.length; i < lenR; i++) {
      let sSearch = aSearch[i].replace(')', '\\)');
      sSearch = sSearch.replace('(', '\\(');
      regStr += '(' + sSearch.toString() + ')([\\s\\S]*)';
    }
    //console.log(regStr);
    let isShow = true; //是否进入职业组合
    reg = new RegExp(regStr);
    for (let i = 0, lenI = data.length; i < lenI; i++) {
      let name = data[i].name,
        regMatch = name.match(reg),
        searchData = {};
      k = 0;
      if (regMatch !== null && isShow) {
        // if (regMatch !== null && regMatch[0] == regMatch['input'] && isShow) {
        var replaceReturn = '';
        if (regMatch[1] == regMatch['input']) {
          //为防止重复名字职业导致赋值为空
          isShow = false;
        }
        for (let j = 1, lenJ = regMatch.length; j < lenJ; j++) {
          if (regMatch[j] === aSearch[k]) {
            replaceReturn += '<span class="hightlight">$' + j + '</span>';
            k++;
          } else {
            replaceReturn += '$' + j;
          }
        }

        for (let obj in data[i]) {
          if (data[i].hasOwnProperty(obj)) {
            searchData[obj] = data[i][obj];
          }
        }
        searchData.name = searchData.name.replace(reg, replaceReturn); //.replace(reg, replaceReturn);
        aData.push(searchData);
      }
    }

    return aData;
  }

  showLev(index) {
    let array = this.state.jsonLev;
    array[index].isopen = !array[index].isopen;
    this.setState({
      jsonLev: array,
    });
  }
  showLev2(index, index2) {
    let array = this.state.jsonLev;
    array[index].item[index2].isopen = !array[index].item[index2].isopen;
    this.setState({
      jsonLev: array,
    });
  }

  showLev3(index, index2, index3) {
    let array = this.state.jsonLev;
    array[index].item[index2].item[index3].isopen =
      !array[index].item[index2].item[index3].isopen;
    this.setState({
      jsonLev: array,
    });
  }
  /**
   * 带层级选择
   */
  renderSelectLevelList() {
    let array = [];
    return this.state.jsonLev.map((item, index) => {
      return (
        <ul
          className={item.isopen ? 'open lev1' : 'close lev1'}
          key={`lev1_${index}`}
        >
          <li
            onClick={() => {
              this.showLev(index);
            }}
          >
            {item.descr}
          </li>
          {item.item.map((item2, index2) => {
            return (
              <ul
                key={`lev2_${index2}`}
                className={item2.isopen ? 'open lev2' : 'close lev2'}
              >
                <li
                  onClick={() => {
                    this.showLev2(index, index2);
                  }}
                >
                  {item2.descr}
                </li>
                {item2.item.map((item3, index3) => {
                  return (
                    <ul
                      key={`lev3_${index3}`}
                      className={item3.isopen ? 'open lev3' : 'close lev3'}
                    >
                      <li
                        onClick={() => {
                          this.showLev3(index, index2, index3);
                        }}
                      >
                        {item3.descr}
                      </li>
                      {item3.item.map((item4, index4) => {
                        array.push(item4);
                        return (
                          <ul key={`lev4_${index4}`} className="lev4">
                            <li
                              onClick={() => {
                                this.handleOptionClick(item4.code);
                              }}
                            >
                              {item4.descr}
                            </li>
                          </ul>
                        );
                      })}
                    </ul>
                  );
                })}
              </ul>
            );
          })}
        </ul>
      );
    });
  }

  levelDoSelect() {}
  render() {
    let searchname = this.state.showValue.toLowerCase();
    let options = this.searchNotes();
    const menuItem = options.map((item) => (
      <li
        className="menuItem"
        key={item.id}
        onClick={this.handleOptionClick.bind(item, item.id)}
        dangerouslySetInnerHTML={{ __html: item.name }}
      ></li>
    ));
    // console.log(this.props.placeholder);
    let placeholder = this.state.showValue
      ? this.state.showValue
      : this.state.defaultVal || this.props.placeHolder;

    let fontColor = this.state.showValue ? 'focus' : 'blur';
    let code;
    if (options[0] && options.length === 1) {
      code = position;
    }
    let classArr = `${fontColor} searchClick`;
    //如果没输入 则显示取消按钮
    let subButton =
      this.state.showValue === '' ? (
        <span className="search-icon" onClick={this.closeWindow}>
          取消
        </span>
      ) : (
        <span className="search-icon" onClick={this.submitV}>
          确认
        </span>
      );
    // console.log(options, this.state.defaultCode)
    return (
      <div className="search-wrap select">
        {/* 点击呼出弹窗的按钮 */}
        <div>
          <div
            ref="getCode"
            className={classArr}
            onClick={this.handleClick}
            data-val={
              (options && options.length == 1 ? JSON.stringify(options) : '') ||
              this.state.defaultCode
            }
          >
            {placeholder}{' '}
          </div>
        </div>
        {/*end */}
        <div
          className={`search-box-window ${this.state.isShow ? 'show' : 'hide'}`}
        >
          <div className="search-input">
            <div>
              <input
                type="text"
                value={this.state.showValue}
                onChange={this.handleInputChange}
                placeholder={this.props.placeHolder}
              />
              <span
                className="clear-icon"
                onClick={this.clearInput.bind(this)}
              ></span>
            </div>
            {subButton}
          </div>

          {menuItem.length != 0 && menuItem.length != occupationJSON.length && (
            <ul className="menuList">{menuItem}</ul>
          )}
          {(menuItem.length == 0 ||
            menuItem.length == occupationJSON.length) && (
            <div className="selectList">{this.renderSelectLevelList()}</div>
          )}
        </div>
      </div>
    );
  }
}

export default occupationSearch;
