import { React } from '../../../common/3rd';
import Toast from '../../both/toast/toast';
import { ReactRouterContextWidget } from '@/component/components';

import './top-bar.scss';

class TopBar extends ReactRouterContextWidget {
  renderBackButton() {
    if (!this.isBackShown()) {
      return;
    }

    return (
      <div className="back btn" onClick={this.onBackButtonClicked}>
        <span className="fa fa-fw fa-angle-left" />
      </div>
    );
  }
  renderTitle() {
    return (
      <div className="title">
        <span>{this.getTitle()}</span>
      </div>
    );
  }
  renderButton(button, index) {
    return (
      <div
        className="btn"
        onClick={this.onButtonClicked.bind(this, button, index)}
        key={`button-${index}`}
      >
        <span className={button.class}>{button.value}</span>
      </div>
    );
  }
  renderRightButtons() {
    const buttons = this.getRightButtons();
    if (buttons.length === 0) {
      return;
    }
    return (
      <div className="right-btns">
        {buttons.map((button, index) => {
          return this.renderButton(button, index);
        })}
      </div>
    );
  }
  render() {
    return (
      <div className={this.getWidgetClassName('top-bar')}>
        <div className="left-btns">{this.renderBackButton()}</div>
        {this.renderTitle()}
        {this.renderRightButtons()}
      </div>
    );
  }
  isBackShown() {
    return this.getPropsValue('back', false);
  }
  getTitle() {
    return this.getPropsValue('title');
  }
  getRightButtons() {
    const buttons = this.getPropsValue('right');
    return buttons ? buttons : [];
  }
  onBackButtonClicked = (evt) => {
    const backAction = this.props.backAction;
    if (backAction) {
      backAction.call(this, evt);
    } else {
      this.historyBack();
    }
  };
  onButtonClicked(button, index) {
    const action = button.action;
    if (action) {
      action.call(this, button);
    } else {
      Toast.showNotImpl();
    }
  }
}

export default TopBar;
