import { React } from '../../common/3rd';

import { Widget } from '@/component/components';

class RowComments extends Widget {
  render() {
    return (
      <div className={this.getWidgetClassName('row-comments')}>
        {this.props.children}
      </div>
    );
  }
}

export default RowComments;
