import { React, $ } from '../../common/3rd';
import { Widget } from '@/component/components';

class BottomButton extends Widget {
  render() {
    return <a {...this.getProps()}>{this.props.children}</a>;
  }
  getProps() {
    let props = $.extend({}, this.props);
    props.className = this.getWidgetClassName('bottom-button');
    return props;
  }
}

export default BottomButton;
