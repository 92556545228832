/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description App路由模块
 * @author LiuH
 */
import { createBrowserHistory } from '@/3rd';
import { MINI_PAGE, generateFaceRoute } from '@/router/mini';
import {
  WEB_PAGE_ALIAS,
  WEB_PAGE_ALIAS_MAP,
  findWebPage,
  findKnowledgeTypeByAlias,
} from '@/router/web';

/**
 * @see https://github.com/remix-run/history/tree/main/docs/api-reference.md
 * [history v5](https://github.com/remix-run/history) 移除了 basename ，
 * 所以使用 react-router-dom v5 和 history v4 。
 */
const appHistory = createBrowserHistory({
  basename: process.env.REACT_APP_AJAX_CLIENT_CONTEXT || '/bjlife',
});

/**
 * TODO: -
 * 生成跳转路由
 * @param {string} requestType - 类型
 * @param {object} [params={}] 参数
 */
function generateToRoute(requestType, params = {}) {
  let toRoute = Object.create(null);
  switch (requestType) {
  }
  return toRoute;
}

export {
  appHistory,
  generateFaceRoute,
  MINI_PAGE,
  WEB_PAGE_ALIAS,
  WEB_PAGE_ALIAS_MAP,
  findWebPage,
  findKnowledgeTypeByAlias,
};
