var htmlStr = '<div>' +
    '    <div id="hintPage" width="100%" style="background: #FFFFFF;">' +
    '        <div id="hintContent" width="100%" style="background: #FFFFFF;">' +
    '        <div style="position: relative;" class="page-content">' +
    '               <div>' +
    '                    <table width="100%">' +
    '                        <tr>' +
    '                            <td valign="middle" width="25%" >' +
    '                                <img id="logImage" width="100%" src="" />' +
    '                            </td>' +
    '                            <td valign="middle" width="15%">' +
    '                            </td>' +
    '                            <td valign="middle" align="right"  width="45%">' +
    '                                <div id="personalInsuranceTips_policy_id" style="font-size: 20px;"></div>' +
    '                            </td>' +
    '                        </tr>' +
    '                    </table>' +
    '                </div>' +
    '            <div align="center"' +
    '                    style="width: 100%; font-size: 17px; height: 17px; line-height: 17px; position: absolute;margin-top: 15px">北京人寿保险股份有限公司</div>' +
    '            <div align="center"' +
    '                    style="width: 100%; font-size: 15px; height: 15px; line-height: 15px; position: absolute;margin-top: 60px">人身保险投保提示书</div>' +
    '            <br />' +
    '            <br />' +
    '            <!-- 保单正文 queryForm-->' +
    '            <div style="margin-top:60px;"> <b>尊敬的客户：</b><br />' +
    '            </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 感谢您选择北京人寿保险股份有限公司！人身保险是以人的寿命和身体为保险标的的保险。当被保险人发生死亡、伤残、疾病等风险事故时或者达到合同约定的年龄、期限时，保险公司按照保险合同约定给付保险金。人身保险具有保障和长期储蓄功能，可以用于为人们的生活进行长期财务规划。为帮助您更好地认识和购买人身保险产品，保护您的合法权益，中国保监会请您在填写投保单之前认真阅读以下内容：</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>一、请您确认保险机构和销售人员的合法资格</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 请您从持有中国保险监督管理委员会颁发《经营保险业务许可证》、《经营保险代理/经纪业务许可证》或《保险兼业代理许可证》的合法机构或持有《保险代理从业人员展业证书》的销售人员处办理保险业务。如需要查询销售人员的销售资格，您可以致电公司全国统一客服热线：400-81-96677、登录保险中介监督信息系统查询（网址：http://iir.circ.gov.cn）或拨打95001033查询。</div>' +
    '            <div style="width: 100%; text-indent: 2em">北京地区的客户还可以登录北京保监局网站（http://beijing.circ.gov.cn）。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>二、请您根据实际保险需求和支付能力选择人身保险产品</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 请您根据自身已有的保障水平和经济实力等实际情况，选择适合自身需求的保险产品。您若投保保单利益不确定的产品，包括分红型、万能型、投资连结型、变额型等人身保险产品时，建议您关注并了解您的交费能力及需承担的风险，确认了解自己购买的产品情况,同时建议您投保除分红型、万能型、投资连结型、变额型以外的保单利益确定的传统寿险产品。 </div>' +
    '            <div style="width: 100%; text-indent: 2em">在商业银行代理销售的情况下，若您存在下列情况：①趸交保费超过家庭年收入的4倍；②年期交保费超过家庭年收入的20%，或月期交保费超过家庭月收入的20%；③保费交费年限与您的年龄数字之和达到或超过60；④保费额度大于或等于投保人保费预算的150%，请您确认声明自愿承担保单利益的不确定性风险。若您填写的年收入低于当地省级统计部门公布的最近一年度城镇居民人均可支配收入或农村居民人均纯收入，或您的年龄超过65周岁，或购买期交产品时您的年龄超过60周岁，保险公司将进行人工核保，您的投保申请材料将递交至保险公司，经核保人员核保后，由保险公司出单。</div>' +
    '            <div style="width: 100%; text-indent: 2em">多数人身保险产品期限较长，如果需要分期交纳保费，请您选择合适的交费期限和交费金额并充分考虑是否有足够、稳定的财力长期支付保费，无法持续交纳保费可能导致合同效力中止或保险合同解除。建议您使用银行划账等非现金方式交纳保费。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>三、请您详细了解保险合同的条款内容</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 请您不要将保险产品的广告、公告、招贴画等宣传材料视同为保险合同，应当要求销售人员向您提供相关保险产品的条款。请您认真阅读条款内容，重点关注保险责任、责任免除、投保人及被保险人权利和义务、免赔额或免赔率的计算、申请赔款的手续、退保相关约定、费用扣除、产品期限等内容。您若对条款内容有疑问，您可以要求销售人员进行解释。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>四、请您了解“犹豫期”的有关约定</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 一年期以上的人身保险产品一般有犹豫期的有关约定，犹豫期为自您签收保险合同后的10个自然日（商业银行代理销售的一年期以上的人身保险产品的犹豫期为自您签收合同后的15个自然日）。除合同另有约定外，在犹豫期内，您可以无条件解除保险合同，但应退还保单，保险公司在扣除不超过10元的成本费以外，应退还您全部保费并不得对此收取其他任何费用。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>五、“犹豫期”后解除保险合同请您慎重</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 若您在犹豫期过后解除保险合同，您会有一定的损失。保险公司应当自收到解除合同通知之日起三十日内，按照合同约定退还保险单的现金价值（现金价值表附在正式保险合同之中，但如果您选择的产品没有现金价值或者现金价值无法事先确定，正式合同中则不附现金价值表）。若您存在疑问，可向我们咨询。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>六、请您充分认识分红保险、投资连结保险、万能保险等人身保险新型产品的风险和特点</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> （1）如果您选择购买分红保险产品，请您注意以下事项：分红水平主要取决于保险公司的实际经营成果。如果实际经营成果优于定价假设，我们会将部分盈余分配给您。如果实际经营成果差于定价假设，我们可能不会派发红利。产品说明书或保险利益测算书中关于未来保险合同利益的预测是基于公司精算假设，不能理解为对未来的预期，红利分配是不确定、不保证的。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> （2）如果您选择购买投资连结保险产品，请您注意以下事项：您应当详细了解投资连结保险的费用扣除情况，包括初始费用、买入卖出差价、死亡风险保险费、保单管理费、资产管理费、手续费、退保费用等。设有多个投资账户时，您有选择投资账户的权利。您可以要求我司代理人员解释投资连结保险账户价值的详细计算方法。投资连结保险产品的投资回报具有不确定性，投资风险完全由您承担。产品说明书或保险利益测算书中关于未来保险合同利益的预测是基于公司精算假设，不能理解为对未来的预期，实际投资可能赢利或出现亏损。如果您选择灵活交费方式的，请您要求我司代理人员将您停止交费可能产生的风险和不利后果对您进行解释。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> （3）如果您选择购买万能保险产品，请您注意以下事项：万能保险产品通常有最低保证利率的约定，最低保证利率仅针对投资账户中资金。您应当详细了解万能保险的费用扣除情况，包括初始费用、风险保险费、保单管理费、手续费、退保费用等。您可以要求我司代理人员将万能保险账户价值的计算方法对您进行解释。万能保险产品的投资回报具有不确定性，您要承担部分投资风险。保险公司每月公布的结算利率只能代表一个月的投资情况，不能理解为对全年的预期，结算利率仅针对投资账户中的资金，不针对全部保险费。产品说明书或保险利益测算书中关于未来保险合同利益的预测是基于公司精算假设，最低保证利率之上的投资收益是不确定的，不能理解为对未来的预期。如果您选择灵活交费方式的，请您要求我司代理人员将您停止交费可能产生的风险和不利后果对您进行解释。 </div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>七、请您正确认识人身保险新型产品与其他金融产品</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 分红保险、投资连结保险、万能保险等人身保险新型产品兼具保险保障功能和投资功能，不同保险产品对于保障功能和投资功能侧重不同，但本质上属于保险产品，产品经营主体是保险公司。您不宜将人身保险新型产品与银行存款、国债、基金等金融产品进行片面比较，更不要仅把它作为银行存款的替代品。' +
    '若销售人员假借我司名义向您推介或搭售非保险金融产品，请您注意购买和防范投资风险，并可致电公司反映（全国统一客服热线：400-81-96677）。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>八、选择健康保险产品时请您注意产品特性和条款具体约定</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 健康保险产品是具有较强风险保障功能的产品，既有定额给付性质的，也有费用补偿性质的。定额给付性质的健康保险按约定给付保险金，与被保险人是否获得其他医疗费用补偿无关；对于费用补偿性质的健康保险，保险公司给付的保险金可能会相应扣除被保险人从其他渠道所获的医疗费用补偿。请您注意条款中是否有免赔额或赔付比例的约定、是否有疾病观察期约定。如果保险公司以附加险形式销售无保证续保条款的健康保险产品，请您注意附加健康保险的保险期限应不小于主险保险期限。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>九、为未成年子女选择保险产品时保险金额应适当</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 如果您为未成年子女购买保险产品，因被保险人死亡给付的保险金总和应符合中国保监会的有关规定。其主要目的是为了保护未成年人权益，防止道德风险；同时，从整个家庭看，父母是家庭的主要经济来源和支柱，以父母为被保险人购买保险，可以使整个家庭获得更加全面的保险保障。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>十、请您如实填写投保资料、如实告知有关情况并亲笔签名</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> 我国《保险法》对投保人的如实告知行为进行了明确的规定。投保时，投保人对被保险人应当具有保险利益，您填写的投保单应当属实；对于代理人员询问的有关被保险人的问题，您也应当如实回答，否则可能影响您和被保险人的权益。为了有效保障您的权益，请您在投保提示书、投保单等相关文件亲笔签名。以死亡为给付保险金条件的合同，必须经被保险人同意并认可保险金额，否则合同无效（父母为其未成年子女投保的人身保险不受此限制）。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>十一、请您配合保险公司做好客户回访工作</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em"> （1）在审核投保申请过程中我司可能会要求投保人或被保险人进行体检、生存调查或补充其他资料，可能会做出增加保险费、减少保险金额、特别约定、延期或拒绝承保的决定。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> （2）我司将通过电话、信函或上门回访等形式对您进行回访。为确保自己的权益得到切实保障，您应对回访问题进行如实答复，不清楚的地方可以立即提出，要求公司进行详细解释。请您投保时准确、完整填写家庭住址、邮编、常用联系电话等个人信息，以便我司能够对您及时回访。</div>' +
    '            <div style="width: 100%; text-indent: 2em"> <b>十二、请您注意保护自身的合法权益</b> </div>' +
    '            <div style="width: 100%; text-indent: 2em">如果您发现销售人员在保险销售过程中存在误导销售行为，或认为自身权益受到侵犯，请注意保留书面证据或其他证据，可向我司反映（全国统一客服热线：400-81-96677）；也可以向当地保监局（或保险行业协会）投诉；必要时还可以根据合同约定，申请仲裁或向法院起诉。</div>' +
    '            <div style="width: 100%; text-indent: 2em">再次感谢您选择北京人寿保险股份有限公司！</div>' +
    '            <br />' +
    '            <div style="width: 100%"> <strong>特别信息：</strong></div>' +
    '            <div style="width: 100%; text-indent: 2em">1、我司偿付能力相关信息已披露于我司官网，具体详见www. beijinglife.com.cn（公开信息披露专栏）</div>' +
    '            <div style="width: 100%; text-indent: 2em">2、北京地区的客户在购买了一年期及一年期以下主险为意外险的产品或产品组合后，可到北京人身意外伤害保险信息平台查询相关保单信息（查询网址：www.biabii.org.cn）。如果您填写手机号码，该意外险平台将为您提供免费的投保短信提示。</div>' +
    '            <br />' +
    '            <div style="border:2px solid #000; border-radius:6px; padding:10px;">北京人寿保险股份有限公司及其代理人员已提供本人所投保产品条款、对条款进行说明，尤其对免除保险人责任条款、合同解除条款进行了明确说明。本人对投保产品条款及产品说明书已认真阅读并理解。</div>' +
    '            <br />' +
    '            <br />' +
    '            <div style="margin-left: 15px; width: 100%; text-indent: 2em"> <span id="signway">投保人签字:</span><img  id="imageApp1" style="width:6%;"/>' +
    '                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;日期：<span id="nian"></span>' +
    '            </div>' +
    '            <br />' +
    '            <br />' +
    '            <div style="margin-left: 15px; width: 100%; text-indent: 2em"> <span id="signway">代理人员签字:</span><img  id="imageApp2" style="width:6%;"/>' +
    '                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;代理人代码:<span id="agent_code"></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;代理人电话:<span id="agent_mobile"></span><br />' +
    '            </div>' +
    '        </div>' +
    '        <br />' +
    '        <br />' +
    '        <br />' +
    '        </div>' +
    '</div>';
export default htmlStr;