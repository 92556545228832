import { React, $, ReactDOM } from '../../../../common/3rd';
import { Utils } from '../../../../common/common';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import { PageBody, PageFooter, BottomButton, PageLoading, Alert } from '../../../components';
import NativeCalled from '../../../../util/NativeCalled';
import { birthdayFormat, getAgeByBirth, isAndroid, isIOS } from "@/util";

const R = require('ramda');

/**
 * 身份证扫描
 * // 03/01/2018 - TAG: by yanzhi.mo
 *
 * @export
 * @class NativeOcr
 * @extends {AtomicMutableComponentThing}
 */
export default class ScanIDCard extends AtomicMutableComponentThing {

    componentDidMount() {
        Utils.installToEventEmitter({
            event: "scanIDCardOCRCallback_PO",
            func : this.scanIDCardCallbackForPO
        });
        Utils.installToEventEmitter({
            event: "scanIDCardOCRCallback_IN",
            func : this.scanIDCardCallbackForIN
        });
    }

    componentWillUnmount() {
        Utils.uninstallFromEventEmitter({
            event: "scanIDCardOCRCallback_PO",
            func : this.scanIDCardCallbackForPO
        });
        Utils.uninstallFromEventEmitter({
            event: "scanIDCardOCRCallback_IN",
            func : this.scanIDCardCallbackForIN
        });
    }

    render() {
        return (
            <div className='sth-customer-import'>
                <span onClick={ this.scanIDCard }>身份证信息识别</span>
            </div>
        );
    }

    /**
     * 发起扫描
     *
     * @memberof ScanIDCard
     */
    scanIDCard = () => {
        var options = {
            action : "scanIDCard",
            success: this.props.id === 'policyHolder' ? "scanIDCardOCRCallback_PO" : "scanIDCardOCRCallback_IN"
        };
        var msg = JSON.stringify(options);

        try {
            if (isAndroid()) {
                window.__EBAO_JSSDK.callNative(msg);
            } else if (isIOS()) {
                window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(msg);
            }
        } catch (error) {
            alert(error);
        }
    };

    /**
     * 身份证扫描回调函数 For PolicyHolder
     *
     * @memberof NativeOcr
     */
    scanIDCardCallbackForPO = result => {
        let idCardInfo = typeof result === 'string' ? JSON.parse(result) : result;
        let model = this.getRootModel();
        // 11/01/2018 - TAG: by yanzhi.mo -
        if (!idCardInfo || idCardInfo.success == false) {
            Alert.message("OCR失败");
            return;
        }
        // 11/01/2018 - TAG: by yanzhi.mo - 解析返回报文
        idCardInfo = idCardInfo.msg;
        try {
            if (idCardInfo.name) {
                // 12/01/2018 - TAG: by yanzhi.mo - 框架的BUG，由于值不刷新，现在分开两个回调方法单独处理
                model.policyHolder.firstName = idCardInfo.name;
                model.policyHolder.gender = idCardInfo.gender === '男' ? 'M' : 'F';
                model.policyHolder.idNo = idCardInfo.cardNumber;
                model.policyHolder.dateOfBirth = birthdayFormat(idCardInfo.birthday);
                model.policyHolder.extensionData.age = getAgeByBirth(idCardInfo.birthday);
                model.policyHolder.idType = "0";
                // model = setPropsToModel(options); // 返回模型
            } else {
                if (idCardInfo.expiredDate === '长期') {
                    model.policyHolder.extensionData.idLongTermIndi = true;
                } else {
                    model.policyHolder.extensionData.idLongTermIndi = false;
                    model.policyHolder.extensionData.idValidTill = idCardInfo.expiredDate;
                }
            }
        } catch (error) {
            alert(error);
        }

        // 30/01/2018 - TAG: by yanzhi.mo - 强制调用render方法
        this.getRootComponent().forceUpdate();
    };

    /**
     * 身份证扫描回调函数 For PolicyInsured
     *
     * @memberof NativeOcr
     */
    scanIDCardCallbackForIN = result => {
        let idCardInfo = typeof result === 'string' ? JSON.parse(result) : result;
        let model = this.getRootModel();
        // 11/01/2018 - TAG: by yanzhi.mo -
        if (!idCardInfo || idCardInfo.success == false) {
            Alert.message("OCR失败");
            return;
        }
        // 11/01/2018 - TAG: by yanzhi.mo - 解析返回报文
        idCardInfo = idCardInfo.msg;
        try {
            // 12/01/2018 - TAG: by yanzhi.mo - 框架的BUG，由于值不刷新，现在分开两个回调方法单独处理
            const policyInsured = R.find(R.propEq('customerType', 2), model.policyCustomers);
            const policyBenef = R.find(R.propEq('customerType', 3), model.policyCustomers);
            if (idCardInfo.name) {
                policyInsured.firstName = idCardInfo.name;
                policyInsured.gender = idCardInfo.gender === '男' ? 'M' : 'F';
                policyInsured.idNo = idCardInfo.cardNumber;
                policyInsured.dateOfBirth = birthdayFormat(idCardInfo.birthday);
                policyInsured.extensionData.age = getAgeByBirth(idCardInfo.birthday);
                policyInsured.idType = "0";
                model = {
                    ...model,
                    policyCustomers: [ policyBenef, policyInsured ]
                };
                // model = setPropsToModel(options); // 返回模型
            } else {
                if (idCardInfo.expiredDate === '长期') {
                    policyInsured.extensionData.idLongTermIndi = true;
                } else {
                    policyInsured.extensionData.idLongTermIndi = false;
                    policyInsured.extensionData.idValidTill = idCardInfo.expiredDate;
                }
            }
        } catch (error) {
            alert(error);
        }

        // 30/01/2018 - TAG: by yanzhi.mo - 强制调用render方法
        this.getRootComponent().forceUpdate();
    }
}

const mockObverseData = {
    "address"   : "河南省固始县李店乡杜营村李庄组",//身份证住址
    "birthday"  : "1995-08-06",//出生日期
    "cardNumber": "41302619950xxxxxx7",//身份证号码
    "gender"    : "男",//性别
    "minority"  : "汉",//民族
    "name"      : "崔长城",//姓名
    "remark"    : ""//备注
};

const mockBackData = {
    "expiredDate"     : "2026-02-22",//截止时间。请勿用时间格式定义，因为有可能为“长期”
    "issuingAuthority": "固始县公安局",//发证机构
    "issuingDate"     : "2016-02-22",//发证日期
    "remark"          : ""
};