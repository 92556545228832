import { ReactDOM, $ } from '../../../../common/3rd'
import { Envs, Utils, Consts, Lang } from '../../../../common/common'
// import { format } from '../something-utils'
import { PageLoading } from '../../../components'
import BaseSignatureOneToMoreView from './baseSignatureOneToMoreView'
import tpl from '../../signatureHTML/insureOrderHtml'
import { provsData, citysData, distsData } from '../../../../common/larea-data'
import { lve1, lve4 } from '../../../../common/occupation'

const R = require('ramda')
// const mock = require('../../../../mock/mockData_dev.json')

/**
 *  签名单证
 * add by wendy.gu 2017 Nov.3
 * 
 */
class InsureOrder extends BaseSignatureOneToMoreView {
	constructor(props, context) {
		super(props, context);
		this.currentSignaturePerson = 3;//当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
		this.currentIndex = 0;
		this.state.myIndex = 21;
		this.documentListIndex = 23;
		this.beneficiaries = []; //受益人数组
		this.relations = []; //关系数组
		this.idTypes = []; //证件类型数组
		this.mainProduct = {}; //主险信息
		this.riderProductList = [];  //附加险信息
		this.paymentInfo = {};  //支付信息
		this.policyFee = null; //总保费
	}
	UNSAFE_componentWillMount() {
		let self = this;
		let wizard = self.props.parent.props.parent;
		self.model = self.props.model;
		self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
		self.currentIndex = wizard.state.currentIndex || 0;
		if (self.currentIndex !== self.state.myIndex && self.currentIndex !== self.documentListIndex) {
			return;
		}
		self.resetData();
		self.agentMsg = [self.agentType, "-5.1", true, "-1", "第一及第二业务人员签字：", "3", "10", "", ["-5.4", "-5.5", "-5.6"]];
		self.policyHolderMsg = [self.policyHolderType, "-5.1", true, "0", "投保人签名：", "3", "10", "", ["-5.5", "-5.6"]];
		self.insuredMsg = [self.insuredType, "-5.1", true, "1", "被保险人/ 其法定监护人签名：", "3", "10", "", ["-5.6"]];
		self.caContent = [{
			"keyWord": "投保人签名：",
			"keyWordIndex": "1",
			"offsetX": "50",
			"offsetY": "0",
			"key": "1"
		}, {
			"keyWord": "被保险人/ 其法定监护人签名：",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "2"
		}, {
			"keyWord": "第一及第二业务人员签字：",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "0"
		}];
		super.initData(tpl, self.props.model, self.currentSignaturePerson, self.currentIndex, self.state.myIndex);
	}
	// TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		let self = this;
		let wizard = self.props.parent.props.parent;
		self.model = self.props.model;
		self.currentSignaturePerson = wizard.state.currentSignaturePerson || 1;
		self.currentIndex = wizard.state.currentIndex || 0;
		if (self.currentIndex !== self.state.myIndex && self.currentIndex !== self.documentListIndex) {
			return;
		}
		self.resetData();
		self.agentMsg = [self.agentType, "-5.1", true, "-1", "第一及第二业务人员签字：", "3", "10", "", ["-5.4", "-5.5", "-5.6"]];
		self.policyHolderMsg = [self.policyHolderType, "-5.1", true, "0", "投保人签名：", "3", "10", "", ["-5.5", "-5.6"]];
		self.insuredMsg = [self.insuredType, "-5.1", true, "1", "被保险人/ 其法定监护人签名：", "3", "10", "", ["-5.6"]];
		self.caContent = [{
			"keyWord": "投保人签名：",
			"keyWordIndex": "1",
			"offsetX": "50",
			"offsetY": "0",
			"key": "1"
		}, {
			"keyWord": "被保险人/ 其法定监护人签名：",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "2"
		}, {
			"keyWord": "第一及第二业务人员签字：",
			"keyWordIndex": "1",
			"offsetX": "80",
			"offsetY": "0",
			"key": "0"
		}];
		super.initData(tpl, self.props.model, self.currentSignaturePerson, self.currentIndex, self.state.myIndex);
	}
	//render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
	componentDidUpdate() {
		if (this.currentIndex !== this.state.myIndex && this.currentIndex !== this.documentListIndex) {
			return;
		}
		this.resetUI();
		this.addEvent();
		super.componentDidUpdate();
	}
	//render结束后，初始化页面数据，初始化完毕后调用基类 进行sdk初始化
	componentDidMount() {
		if (this.currentIndex !== this.state.myIndex && this.currentIndex !== this.documentListIndex) {
			return;
		}
		this.resetUI();
		this.addEvent();
		super.componentDidMount();
	}
	//重置数据
	resetData() {
		let self = this;
		super.resetData(self.model);
	}
	//初始化页面数据
	resetUI() {
		let self = this;
		self.parent = $(ReactDOM.findDOMNode(self.refs['signatureHtml']));
		self.policyHolderImageApp = self.parent.find("#imageApp1");
		self.insuredImageApp = self.parent.find("#imageApp2");
		self.agentImageApp = self.parent.find("#imageAgentApp");
		self.beneficiaries = [];
		self.model.policyCustomers.map((item, index, arr) => {
			if (item.customerType === "3") {
				self.beneficiaries.push(item);
			}
			return null;
		})
		self.relations = this.props.parent.props.parent.props.parent.state.policyLayout._codes.RELATION;
		self.idTypes = this.props.parent.props.parent.props.parent.state.policyLayout._codes.IDTYPE;
		self.mainProduct = {};
		self.riderProductList = [];
		if(self.model.insureds && self.model.insureds[0].coverages) {
			if(self.model.insureds[0].coverages.length === 1) {
				self.mainProduct = self.model.insureds[0].coverages[0] || {};
			}else {
				self.model.insureds[0].coverages.map((coverage, index) => {
					if(coverage.extensionData.displayPremIndi) {
						if (coverage.extensionData.sequence === 1) {
							self.mainProduct = coverage;
						}else {
							self.riderProductList.push(coverage);
						}
					}
					return null;
				})
			}
		}
		self.paymentInfo = self.model.extensionData.paymentInfo || {};
		self.policyFee = self.model.policyFee.app || 0;
		self.parent.find("#logImage").attr("src", this.props.parent.props.parent.props.parent.state.policyLayout._logImage.bjlife);
		window.jQuery("#policy_id_t").barcode(self.proposalCode, "code128", { barWidth: 1, barHeight: 50, showHRI: true });
		self.setProposerMsg();
		self.setInsuredMsg();
		self.setBeneMsg();
		self.setProductMsg();
		self.setPayMsg();
		self.renderTell();
		self.setAgentMsg();
		self.resetBtn();
		super.resetUI();
	}
	/**
	 * 投保单告知内容
	 * 
	 * @memberof InsureOrder
	 */
	renderTell() {
		let self = this
		let dom = self.parent;
		let policyHolder = self.model.policyHolder
		let policyCustomers = self.model.policyCustomers
		// let policyHolder = mock.model.policyHolder
		// let policyCustomers = mock.model.policyCustomers

		/**
		 * DONE: 告知业务调研
		 * DONE: 使用克隆节点，尽量保持 f1 内部的函数块是纯净的
		 * DONE: 创建Mock数据，与后台分离
		 * TODO: declarations需要排序
		 * 
		 * 1. 区分投保人(1)、被保人(2)、受益人(3) 
		 * 2. 双选情况(n=2)
		 * 4. 描述补充，比如“怀孕______周” => 不需要
		 * 5. 类型是啥意思？详细看19.
		 * 6. "remark":"1/2/3"怎么理解？是否具有码表映射？ => 直接显示，不用care这么多
		 */

		 $(dom.find('#hintPage')).find('input[type=checkbox]').attr('disabled', 'true')

		// 获取被保人
		const getInsure = policyCustomers => {
			return R.find(R.propEq('customerType', '2'))(policyCustomers)
		}
		// 深度克隆
		const pure = options => {
			return R.clone(options)
		}
		// 禁用checkbox
		// const disabledAllCheckboxes = options => {
		// 	const { tell } = options
		// 	tell && $(tell).find('input[type=checkbox]').attr('disabled', 'true')
		// 	return options
		// }
		// 根据code排序
		const sortByCode = options => {
			let { declarations = [] } = options
			declarations = R.sortWith([R.ascend(R.prop('code'))])(declarations)
			return { ...options, declarations }
		}
		// 字段补充，防止问题和答案对应错位
		const supplement = (options) => {

			let { declarations = [] } = options

			if (R.findIndex(R.propEq('code', '10112'), declarations) === -1) {
				const startIndex = R.findIndex(R.propEq('code', '10111'), declarations)
				declarations = R.insert(startIndex + 1, { code: '10112', confirm: false, remark: '' }, declarations)
			}
			if (R.findIndex(R.propEq('code', '10113'), declarations) === -1) {
				const startIndex = R.findIndex(R.propEq('code', '10112'), declarations)
				declarations = R.insert(startIndex + 1, { code: '10113', confirm: false, remark: '' }, declarations)
			}
			return { ...options, declarations }
		}
		// 是或否
		const dualOptions = (options) => {
			const {declarations = [], tell,  trIndex } = options
            declarations.map((declaration,index) => {
                if(declaration.code == '10113-B'){
                    let num = index-1;
                    declarations[num].confirm = declaration.confirm;
                    declarations[num].remark = declarations[num].remark + '/' + declaration.remark;
                    declarations[num].parameters = declaration.parameters;
                    declarations.splice(index, 1);
                }
            });
			declarations.forEach((decl, key) => {
				let target = $(tell).find(`tr:nth-child(${key + 3})`)
				if (target.length > 0) {
					let tr = target
					let yesCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-last-child(2)')
					let noCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:last-child')
					decl.confirm ? ($(yesCheckbox)).attr('checked', 'true') : ($(noCheckbox)).attr('checked', 'true')
				}
			});
			return options
		}
		// 问题10112
		const handleQ10112 = function (options) {
			const { declarations = [], tell, trIndex, age, gender } = options
			declarations.forEach((decl, key) => {
				let target = $(tell).find(`tr:nth-child(${key + 3})`)
				if (target.length > 0) {
					let tr = target
					let yesCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(1)')
					let noCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(2)')
					let yesCheckbox_A = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(4)')
					let noCheckbox_A = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(5)')
					let yesCheckbox_B = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(7)')
					let noCheckbox_B = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-child(8)')
					if (decl.code === '10112') {
						if (decl.confirm && gender === 'F' && age >= 16) {
							let answers = decl.remark.split('/')
							// 选项 1
							$(yesCheckbox).attr('checked', 'true')
							$(noCheckbox).removeAttr('checked')
							// 选项 2
							!R.isNil(answers[1]) || !R.isEmpty(answers[1]) 
							? ($(yesCheckbox_A)).attr('checked', 'true') && $(noCheckbox_A).removeAttr('checked')  
							: ($(noCheckbox_A)).attr('checked', 'true') && $(yesCheckbox_A).removeAttr('checked')
							// 选项 3
							!R.isNil(answers[2]) || !R.isEmpty(answers[2]) 
							? ($(yesCheckbox_B)).attr('checked', 'true') && $(noCheckbox_B).removeAttr('checked')  
							: ($(noCheckbox_B)).attr('checked', 'true') && $(yesCheckbox_B).removeAttr('checked')
						} else {
							// 26/01/2018 - TAG: by yanzhi.mo - 如果为男性或未满足条件，不显示勾选
							$(tr).find(`td:nth-child(${trIndex})`).find('input[type=checkbox]').removeAttr('checked');
							$(noCheckbox).attr('checked', 'true');
							$(noCheckbox_A).attr('checked', 'true');
							$(noCheckbox_B).attr('checked', 'true'); 
						}
					}
				}
			})
			return options
		}
		// 问题10113
		const handleQ10113 = function (options) {
			const { declarations = [], tell, trIndex, age } = options
			declarations.forEach((decl, key) => {
				let target = $(tell).find(`tr:nth-child(${key + 3})`)
				if (target.length > 0) {
					let tr = target
					if (decl.code === '10113') {
						if (decl.confirm && age <= 2) {
							let yesCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:nth-last-child(2)')
							let noCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:last-child')
							$(yesCheckbox).attr('checked', 'true')
							$(noCheckbox).removeAttr("checked")
						} else {
							// 26/01/2018 - TAG: by yanzhi.mo - 如果未满足条件，不显示勾选
							$(tr).find(`td:nth-child(${trIndex})`).find('input[type=checkbox]').removeAttr('checked');
							let noCheckbox = $(tr).find(`td:nth-child(${trIndex})`).find('input:last-child')
							$(noCheckbox).attr('checked', 'true');
						}
					}
					if(decl.code === '10113-B'){

					}
				}
			})
			return options
		}
		// 问题10119
		const handleQ10119 = function (options) {
			const { declarations = [], tell, trIndex } = options			
			declarations.forEach((decl, key) => {
				let target = $(tell).find(`tr:nth-child(${key + 3})`)
				if (target.length > 0) {					
					let tr = target
					if (decl.code.startsWith('10119') && decl.code.endsWith('A')) {
						if(decl.confirm){
							let type = $(tr).find(`td:nth-child(${trIndex})`).find(':nth-child(1)')						
							$(type).html(decl.remark);
						}												
						options.declarations = R.remove(key, 1, declarations) // 移除A元素
					}
				}
			})
			return options
		}
		// 说明栏
		const withComment = function (options) {
			const { declarations = [], comment, tell, fullName } = options	
			declarations.forEach((decl, key) => {
				if(decl.code == '10113-B'){ //10113-B暂时应急处理修改
					decl.code = '10113';
					let aRemark = declarations[key-1].remark.split('/');//增加告知中婴儿身高体重
					if(decl.confirm){
						decl.remark = `身高${aRemark[0]}厘米/体重${aRemark[1]}公斤/${decl.remark}`;
					} 
					else{
						if(aRemark[0] && aRemark[1]){
                            decl.confirm = true;
                            decl.remark = `身高${aRemark[0]}厘米/体重${aRemark[1]}公斤/否`;
						}
					}
				}														
				if (decl.confirm) {						
					$(comment).find('tbody tr:last-child').after(`
					<tr style="text-align: center;">
						<td valign="middle" height="30px">
							<span id="familyName_3">
								${Number.parseInt(decl.code.substr(3, 2), 10)}${decl.code.match('-') ? `.${decl.code.substr(decl.code.lastIndexOf('-') + 1)}` : ''}
							</span>
						</td>
						<td valign="middle"><span id="problem_3">${fullName}</span></td>
						<td valign="middle"><span id="content_3">${decl.remark}</span></td>
					</tr>					
					`)
				}
			})
			return options
		}
		// 获取Tell节点
		const getTell = function (options) {
			return options.tell
		}
		// 获取Comment节点
		const getComment = function (options) {
			return options.comment
		}

		// 渲染投保人／被保人告知信息
		const f1 = R.pipe(
			pure,
			sortByCode,
			supplement,
			// disabledAllCheckboxes,
			handleQ10119,
			dualOptions,
			handleQ10112,
			handleQ10113,
			// withComment,
			// withDesc,
			// withType,
			getTell
		)

		// 说明栏
		const f2 = R.pipe(
			pure,
			sortByCode,
			supplement,
			withComment,
			getComment
		)

		// 启动渲染
		const run = function () {
			// 渲染投保人
			dom.find('#comment tbody tr:gt(0)').remove(); //为防止重复渲染，删除以前渲染的内容
			dom.find('#tell').replaceWith(f1({
				age: policyHolder.extensionData.age,
				gender: policyHolder.gender,
				declarations: policyHolder.extensionData.declarations,
				tell: dom.find('#tell').clone(),
				comment: dom.find('#comment').clone(),
				trIndex: 2
			}))
			dom.find('#comment').replaceWith(f2({
				gender: policyHolder.gender,
				fullName: policyHolder.fullName,
				declarations: policyHolder.extensionData.declarations,
				tell: dom.find('#tell').clone(),
				comment: dom.find('#comment').clone(),
			}))			
			let insure = getInsure(policyCustomers)			
			//投被保人不是同一人
			if(insure.extensionData.relationWithPH.toString() !== "00"){
				// 渲染被保人			
				dom.find('#tell').replaceWith(f1({
					age: insure.extensionData.age,
					gender: insure.gender,
					declarations: insure.extensionData.declarations,
					tell: dom.find('#tell').clone(),
					comment: dom.find('#comment').clone(),
					trIndex: 3
				}))
				dom.find('#comment').replaceWith(f2({
					gender: insure.gender,
					fullName: insure.fullName,
					declarations: insure.extensionData.declarations,
					tell: dom.find('#tell').clone(),
					comment: dom.find('#comment').clone(),
				}))
			}			
		}

		run.call(this)
	}


	getCertiType(idType) {
		let self = this;
		let code = self.idTypes.find(function (value, index, arr) {
			return value.v === idType;
		});
		return code ? code.l : "";
	}
	getLabelById(val, mapCodes) {
		if (!val || !mapCodes) {
			return ""
		}
		let code = mapCodes.find(function (value, index, arr) {
			return value.v === val;
		});
		return code ? code.l : "";
	}
	getRelation(relationId) {
		let self = this;
		let code = self.relations.find(function (value, index, arr) {
			return value.v === relationId;
		});
		return code ? code.l : "";
	}
	getCoveragePeriod(coveragePeriodCode) {
		let coveragePeriod = Object.keys(Consts.COVERAGE_PERIOD).filter(key => {
			return Consts.COVERAGE_PERIOD[key] === coveragePeriodCode + "";
		}).map(key => {
			return Lang.messages.common.coverage_period[key];
		})
		return coveragePeriod;
	}
	getChargePeriod(installment) {
		let chargePeriod = Object.keys(Consts.CHARGE_PERIOD).filter(key => {
			return Consts.CHARGE_PERIOD[key] === installment + "";
		}).map(key => {
			return Lang.messages.common.charge_period[key];
		})
		return chargePeriod;
	}
	getJob(jobcode, jobArr) {
		let job = jobArr.find(function (value, index, arr) {
			return value.v === jobcode;
		});
		return job ? job.l : "";
	}
	setProposerMsg() {
		let self = this;
		//todo 主要来源，负债金额与原因，学历
		//设置投被保人关系
		let relation = self.insured.extensionData.relationWithPH || "";
		switch (relation) {
			case "00": //本人
				self.parent.find("#app_rel_0").attr("checked", "checked");
				break;
			case "06": //配偶
				self.parent.find("#app_rel_1").attr("checked", "checked");
				break;
			case "21": //其他
				self.parent.find("#app_rel_other").attr("checked", "checked");
				// self.parent.find("#app_rel_other_text").html("其他");
				break;
			case "22": //父母
				self.parent.find("#app_rel_3").attr("checked", "checked");
				break;
			case "23": //子女
				self.parent.find("#app_rel_2").attr("checked", "checked");
				break;
			case "24": //成员
				self.parent.find("#app_rel_other").attr("checked", "checked");
				self.parent.find("#app_rel_other_text").html("成员");
				break;
			default:
				break;
		}
		//设置投报人姓名
		self.parent.find("#app_name").html(self.proposer.fullName);
		//设置投保人出生日期
		let dateOfBirth = self.proposer.dateOfBirth;
		if (dateOfBirth) {
			let year = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
			dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
			let month = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
			dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
			let day = dateOfBirth.substring(0, dateOfBirth.indexOf(" "));
			self.parent.find("#app_birth_year").html(year);
			self.parent.find("#app_birth_month").html(month);
			self.parent.find("#app_birth_day").html(day);
		}
		self.parent.find("#app_age").html(self.proposer.extensionData.age || "");
		//设置证件类型
		if (self.proposer.idType === "0") {
			self.parent.find("#app_shenfen").attr("checked", "checked");
		} else {
			self.parent.find("#app_card_s").attr("checked", "checked");
			self.parent.find("#app_card_text").html(self.getCertiType(self.proposer.idType));
		}
		//设置证件号码
		for (let i = 0; i < self.proposer.idNo.length; i++) {
			self.parent.find("#ac_" + i).html(self.proposer.idNo[i]);
		}
		//设置国籍
		self.parent.find("#app_country").html("中国");
		//设置性别
		if (self.proposer.gender === "M") {
			self.parent.find("#app_sex_man").attr("checked", "checked");
		} else if (self.proposer.gender === "F") {
			self.parent.find("#app_sex_woman").attr("checked", "checked");
		}
		//设置婚姻状态
		if (self.proposer.extensionData.maritalStatus.toString() === "1") {
			self.parent.find("#app_mal_y").attr("checked", "checked");
		} else if (self.proposer.extensionData.maritalStatus.toString() === "0") {
			self.parent.find("#app_mal_n").attr("checked", "checked");
		} else if (self.proposer.extensionData.maritalStatus.toString() === "2") {
			self.parent.find("#app_mal_d").attr("checked", "checked");
		} else if (self.proposer.extensionData.maritalStatus.toString() === "3") {
			self.parent.find("#app_mal_l").attr("checked", "checked");
		}
		//设置证件有效期
		if (self.proposer.extensionData.idLongTermIndi) {
			self.parent.find("#app_card_isLong").attr("checked", "checked");
		} else {
			self.parent.find("#app_card_time").attr("checked", "checked");
			let idValidTill = self.proposer.extensionData.idValidTill || "";
			let year = idValidTill.substring(0, idValidTill.indexOf("/"));
			idValidTill = idValidTill.substring(idValidTill.indexOf("/") + 1, idValidTill.length);
			let month = idValidTill.substring(0, idValidTill.indexOf("/"));
			idValidTill = idValidTill.substring(idValidTill.indexOf("/") + 1, idValidTill.length);
			let day = idValidTill.substring(0, idValidTill.indexOf(" "));
			self.parent.find("#app_card_end_year").html(year);
			self.parent.find("#app_card_end_moth").html(month);
			self.parent.find("#app_card_end_day").html(day);
		}
		//设置工作单位
		let workUnit = self.proposer.extensionData.workUnit || "";
		self.parent.find("#app_workUnit").html(workUnit);
		//设置职业
		let job4 = lve4[self.proposer.extensionData.job3] || [];
		let job = self.getJob(self.proposer.extensionData.job4, job4);
		self.parent.find("#app_job").html(job);
		//设置职业代码
		let jobCode = self.proposer.extensionData.job4 || "";
		for (let i = 0; i < jobCode.length; i++) {
			self.parent.find("#app_job_c" + i).html(jobCode[i]);
		}
		//设置收入
		let income = self.proposer.extensionData.annualIncome ? self.proposer.extensionData.annualIncome + "万元" : "";
		self.parent.find("#app_income").html(income);
		//设置收入来源
		self.parent.find("#app_income_resource_0").attr("checked", "checked");
		//设置身高
		self.parent.find("#app_height").html(self.proposer.extensionData.height || "");
		//设置体重
		self.parent.find("#app_weight").html(self.proposer.extensionData.weight || "");
		//设置联系地址
		if (self.proposer.provinceCode && self.proposer.cityCode && self.proposer.regionCode) {
			let provs = this.getLabelById(parseInt(self.proposer.provinceCode, 10), provsData);
			let city = this.getLabelById(parseInt(self.proposer.cityCode, 10), citysData[self.proposer.provinceCode]);
			let dist = this.getLabelById(parseInt(self.proposer.regionCode, 10), distsData[self.proposer.cityCode]);
			self.parent.find("#app_address_province").html(provs);
			self.parent.find("#app_address_city").html(city);
			self.parent.find("#app_address_region").html(dist);
			self.parent.find("#app_address_area").html(self.proposer.addressLine1 || "");
		}
		//设置邮编
		self.parent.find("#app_postCode").html(self.proposer.postCode || "");
		//设置移动电话
		for (let i = 0; i < self.proposer.mobile.length; i++) {
			self.parent.find("#app_mobile_" + i).html(self.proposer.mobile[i]);
		}
		//设置首选回访电话
		self.parent.find("#app_first_mobile").html(self.proposer.mobile || "");
		//设置e-mail
		self.parent.find("#app_email").html(self.proposer.email || "");
	}
	setInsuredMsg() {
		let self = this;
		//todo 主要来源，负债金额与原因，学历
		//设置投被保人关系
		let relation = self.insured.extensionData.relationWithPH || "";
		switch (relation) {
			case "00": //本人
				self.parent.find("#ins_rel_0").attr("checked", "checked");
				break;
			case "06": //配偶
				self.parent.find("#ins_rel_1").attr("checked", "checked");
				break;
			case "21": //其他
				self.parent.find("#ins_rel_other").attr("checked", "checked");
				// self.parent.find("#ins_rel_other_text").html("其他");
				break;
			case "22": //父母
				self.parent.find("#ins_rel_2").attr("checked", "checked");
				break;
			case "23": //子女
				self.parent.find("#ins_rel_3").attr("checked", "checked");
				break;
			case "24": //成员
				self.parent.find("#ins_rel_other").attr("checked", "checked");
				self.parent.find("#ins_rel_other_text").html("成员");
				break;
			default:
				break;
		}
		if (relation === "00") {
			return;
		}
		//设置投报人姓名
		self.parent.find("#ins_name").html(self.insured.fullName);
		//设置投保人出生日期
		let dateOfBirth = self.insured.dateOfBirth;
		if (dateOfBirth) {
			let year = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
			dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
			let month = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
			dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
			let day = dateOfBirth.substring(0, dateOfBirth.indexOf(" "));
			self.parent.find("#ins_birth_year").html(year);
			self.parent.find("#ins_birth_month").html(month);
			self.parent.find("#ins_birth_day").html(day);
		}
		self.parent.find("#ins_age").html(self.insured.extensionData.age || "");
		//设置证件类型
		if (self.insured.idType === "0") {
			self.parent.find("#ins_shenfen").attr("checked", "checked");
		} else {
			self.parent.find("#ins_card_s").attr("checked", "checked");
			self.parent.find("#ins_card_text").html(self.getCertiType(self.insured.idType));
		}
		//设置证件号码
		for (let i = 0; i < self.insured.idNo.length; i++) {
			self.parent.find("#ic_" + i).html(self.insured.idNo[i]);
		}
		//设置国籍
		let country = Object.keys(Consts.COUNTRY).filter(key => {
			return key === self.insured.countryCode + "";
		}).map(key => {
			return Lang.messages.common.country[key];
		})
		self.parent.find("#ins_country").html(country);
		//设置性别
		if (self.insured.gender === "M") {
			self.parent.find("#ins_sex_man").attr("checked", "checked");
		} else if (self.insured.gender === "F") {
			self.parent.find("#ins_sex_woman").attr("checked", "checked");
		}
		//设置婚姻状态
		if (self.insured.extensionData.maritalStatus.toString() === "1") {
			self.parent.find("#ins_mal_y").attr("checked", "checked");
		} else if (self.insured.extensionData.maritalStatus.toString() === "0") {
			self.parent.find("#ins_mal_n").attr("checked", "checked");
		} else if (self.insured.extensionData.maritalStatus.toString() === "2") {
			self.parent.find("#ins_mal_d").attr("checked", "checked");
		} else if (self.insured.extensionData.maritalStatus.toString() === "3") {
			self.parent.find("#ins_mal_l").attr("checked", "checked");
		}
		//设置证件有效期
		if (self.insured.extensionData.idLongTermIndi) {
			self.parent.find("#ins_card_isLong").attr("checked", "checked");
		} else {
			self.parent.find("#ins_card_time").attr("checked", "checked");
			let idValidTill = self.insured.extensionData.idValidTill || "";
			let year = idValidTill.substring(0, idValidTill.indexOf("/"));
			idValidTill = idValidTill.substring(idValidTill.indexOf("/") + 1, idValidTill.length);
			let month = idValidTill.substring(0, idValidTill.indexOf("/"));
			idValidTill = idValidTill.substring(idValidTill.indexOf("/") + 1, idValidTill.length);
			let day = idValidTill.substring(0, idValidTill.indexOf(" "));
			self.parent.find("#ins_card_end_year").html(year);
			self.parent.find("#ins_card_end_moth").html(month);
			self.parent.find("#ins_card_end_day").html(day);
		}
		//设置工作单位
		let workUnit = self.insured.extensionData.workUnit || "";
		self.parent.find("#ins_workUnit").html(workUnit);
		//设置职业
		let job4 = lve4[self.insured.extensionData.job3] || [];
		let job = self.getJob(self.insured.extensionData.job4, job4);
		self.parent.find("#ins_job").html(job);
		//设置职业代码
		let jobCode = self.insured.extensionData.job4 || "";
		for (let i = 0; i < jobCode.length; i++) {
			self.parent.find("#ins_job_c" + i).html(jobCode[i]);
		}
		//设置收入
		let income = self.insured.extensionData.annualIncome ? self.insured.extensionData.annualIncome + "万元" : "";
		self.parent.find("#ins_income").html(income);
		//设置收入来源
		self.parent.find("#ins_income_resource_0").attr("checked", "checked");
		//设置身高
		self.parent.find("#ins_height").html(self.insured.extensionData.height || "");
		//设置体重
		self.parent.find("#ins_weight").html(self.insured.extensionData.weight || "");
		//设置联系地址
		if (self.insured.provinceCode && self.insured.cityCode && self.insured.regionCode) {
			let provs = this.getLabelById(parseInt(self.insured.provinceCode, 10), provsData);
			let city = this.getLabelById(parseInt(self.insured.cityCode, 10), citysData[self.insured.provinceCode]);
			let dist = this.getLabelById(parseInt(self.insured.regionCode, 10), distsData[self.insured.cityCode]);
			self.parent.find("#ins_address_province").html(provs);
			self.parent.find("#ins_address_city").html(city);
			self.parent.find("#ins_address_region").html(dist);
			self.parent.find("#ins_address_area").html(self.insured.addressLine1 || "");
		}
		//设置邮编
		self.parent.find("#ins_postCode").html(self.insured.postCode || "");
		//设置移动电话
		for (let i = 0; i < self.insured.mobile.length; i++) {
			self.parent.find("#ins_mobile_" + i).html(self.insured.mobile[i]);
		}
		//设置首选回访电话
		self.parent.find("#ins_first_mobile").html(self.insured.mobile || "");
		//设置e-mail
		self.parent.find("#ins_email").html(self.insured.email || "");
	}
	setBeneMsg() {
		let self = this;
		self.parent.find("#beneficiary_table").html('<tr id="bene-first-tr" style="font-weight: bold;">'+
                            '<td height="30px">姓名</td>'+
                            '<td>性别</td>'+
                            '<td>出生日期</td>'+
                            '<td>证件类型</td>'+
                            '<td>证件号码</td>'+
                            '<td>是被保险人的（关系）</td>'+
                            '<td>受益顺序</td>'+
                            '<td>受益比例</td>'+
                            '<td>联系方式</td>'+
                        '</tr>');
		let beneficiaryHtml = "";
		//todo 缺少受益人姓名，联系电话
		self.beneficiaries.map((beneficiary, index) => {
			if (beneficiary.extensionData && beneficiary.extensionData.benefitOrder) {
				//设置性别
				let man = "";
				let women = "";
				if (beneficiary.gender === "M") {
					man = 'checked="checked"';
				} else if (beneficiary.gender === "F") {
					women = 'checked="checked"';
				}
				//设置出生日期
				let dateOfBirth = beneficiary.dateOfBirth;
				let year = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
				let month = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
				let day = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
				if (dateOfBirth) {
					year = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
					dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
					month = dateOfBirth.substring(0, dateOfBirth.indexOf("/"));
					dateOfBirth = dateOfBirth.substring(dateOfBirth.indexOf("/") + 1, dateOfBirth.length);
					day = dateOfBirth.substring(0, dateOfBirth.indexOf(" "));
				}
				//设置证件类型
				let sfCheck = "";
				let otherCheck = "";
				let otherCard = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
				if (beneficiary.idType === "0") {
					sfCheck = 'checked="checked"';
				} else {
					otherCheck = 'checked="checked"';
					otherCard = self.getCertiType(beneficiary.idType);
				}
				let relationWithInsured = beneficiary.extensionData && beneficiary.extensionData.relationWithInsured ? beneficiary.extensionData.relationWithInsured : "";
				let benefitOrder = beneficiary.extensionData && beneficiary.extensionData.benefitOrder ? beneficiary.extensionData.benefitOrder : "";
				let benefitRatio = beneficiary.extensionData && beneficiary.extensionData.benefitRatio ? parseFloat(beneficiary.extensionData.benefitRatio) * 100 : "";
				beneficiaryHtml = beneficiaryHtml + '<tr>'+
                            '<td height="30px;">'+beneficiary.fullName+'</td>'+
                            '<td>'+
                                '<input type="checkbox" '+man+' />男<br />'+
                                '<input type="checkbox" '+women+' />女'+
                            '</td>'+
                            '<td>'+
                                '<span style="text-decoration: underline;">'+year+'</span>年<br />'+
                                '<span style="text-decoration: underline;">'+month+'</span>月'+
                                '<span style="text-decoration: underline;">'+day+'</span>日'+
                            '</td>'+
                            '<td>'+
                                '<input type="checkbox" '+sfCheck+'/>身份证<br />'+
                                '<input type="checkbox" '+otherCheck+'/>其他'+
                                '<span style="text-decoration: underline;">'+otherCard+'</span>'+
                            '</td>'+
                            '<td>'+beneficiary.idNo+'</td>'+
                            '<td>'+self.getRelation(relationWithInsured)+'</td>'+
                            '<td>'+benefitOrder+'</td>'+
                            '<td><span>'+benefitRatio+'</span>%</td>'+
                            '<td id="ben_mobile_0"></td>'+
                        '</tr>'
			}
			return null;
		})
		self.parent.find("#bene-first-tr").after(beneficiaryHtml);
	}
	digitUppercase(n) {
		let fraction = ['角', '分'];
		let digit = [
			'零', '壹', '贰', '叁', '肆',
			'伍', '陆', '柒', '捌', '玖'
		];
		let unit = [
			['元', '万', '亿'],
			['', '拾', '佰', '仟']
		];
		let head = n < 0 ? '欠' : '';
		n = Math.abs(n);
		let s = '';
		let small = "";
		if(n.toString().indexOf(".") > 0) {
			let location = n.toString().indexOf(".");
			small = n.toString().substring(location+1,n.toString().length);
		}
		for (let i = 0; i < small.length; i++) {
			s += (digit[parseInt(small[i], 10)] + fraction[i]).replace(/零./, '');
		}
		s = s || '整';
		n = Math.floor(n);
		for (let i = 0; i < unit[0].length && n > 0; i++) {
			let p = '';
			for (let j = 0; j < unit[1].length && n > 0; j++) {
				p = digit[n % 10] + unit[1][j] + p;
				n = Math.floor(n / 10);
			}
			s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
		}
		return head + s.replace(/(零.)*零元/, '元')
			.replace(/(零.)+/g, '零')
			.replace(/^整$/, '零元整');
	}
	setProductMsg() {
		let self = this;
		self.parent.find("#product_table").html('<tr>'+
                            '<td rowspan="2"><b>主险</b></td>'+
                            '<td height="30px"><b>代码</b></td>'+
                            '<td><b>险种名称</b></td>'+
                            '<td><b>保险金额或份数</b></td>'+
                            '<td><b>保障期限</b></td>'+
                            '<td><b>保险费（元）</b></td>'+
                            '<td><b>交费期限</b></td>'+
                        '</tr>'+
                        '<tr id="main-product-tr">'+
                            '<td id="main_product_code" height="30px"></td>'+
                            '<td id="main_product_name"></td>'+
                            '<td id="main_product_sa"></td>'+
                            '<td id="main_product_coverage"></td>'+
                            '<td id="main_product_prem"></td>'+
                            '<td id="main_product_charge"></td>'+
                        '</tr>'+
						'<tr>'+
                            '<td colspan="5" height="30px">'+
                                '<b>合计保险费：</b>（大写）'+
                                '<span id="total_prem_big"></span>'+
                            '</td>'+
                            '<td colspan="2">'+
                                '<b>（小写）</b>¥ <span id="total_prem_small"></span>元'+
                            '</td>'+
                        '</tr>');
		//设置主险代码
		self.parent.find("#main_product_code").html(self.mainProduct.coverageCode);
		//设置主险名称
		self.parent.find("#main_product_name").html(self.mainProduct.coverageName);
		//设置主险保险金额或份数
		if(parseInt(self.mainProduct.extensionData.unit, 10) > 0) {
			self.parent.find("#main_product_sa").html(self.mainProduct.extensionData.unit + "份");
		} else {
			self.parent.find("#main_product_sa").html(self.mainProduct.extensionData.sumInsured + "元");
		}
		//设置主险保障期限
		self.parent.find("#main_product_coverage").html(self.getCoveragePeriod(self.mainProduct.extensionData.coveragePeriod));
		//设置保险费
		self.parent.find("#main_product_prem").html(self.mainProduct.coverageFee.app);
		//设置交费期限
		self.parent.find("#main_product_charge").html(self.getChargePeriod(self.mainProduct.extensionData.installment));
		self.parent.find("#main-product-tr").after("")
		var riderProductHtml = "";
		//设置附加险的信息
		self.riderProductList.map((riderProduct, index) => {
			let sa = "";
			if(parseInt(riderProduct.extensionData.unit, 10) > 0) {
				sa = riderProduct.extensionData.unit + "份";
			} else {
				sa = riderProduct.extensionData.sumInsured + "元";
			}
			if (index === 0) {
				riderProductHtml = '<tr><td rowspan="'+self.riderProductList.length+'">附加险</td><td height="30px">'+riderProduct.coverageCode+'</td>'+
				'<td>'+riderProduct.coverageName+'</td><td>'+sa+'</td><td>'+self.getCoveragePeriod(riderProduct.extensionData.coveragePeriod)+'</td><td>'+riderProduct.coverageFee.app+
				'</td><td>'+self.getChargePeriod(riderProduct.extensionData.installment)+'</td></tr>';
			} else {
				riderProductHtml = riderProductHtml + '<tr><td height="30px">'+riderProduct.coverageCode+'</td><td>'+riderProduct.coverageName+'</td>'+
				'<td>'+sa+'</td><td>'+self.getCoveragePeriod(riderProduct.extensionData.coveragePeriod)+'</td><td>'+riderProduct.coverageFee.app+'</td><td>'+self.getChargePeriod(riderProduct.extensionData.installment)+'</td></tr>'
			}
			return null;
		})
		self.parent.find("#main-product-tr").after(riderProductHtml);
		//设置总保费
		self.parent.find("#total_prem_big").html(self.digitUppercase(self.policyFee));
		self.parent.find("#total_prem_small").html(self.policyFee);
		//设置缴费频率
		switch (self.mainProduct.extensionData.paymentFreq.toString()) {
			case "1":
				self.parent.find("#charge_year").attr("checked", "checked");
				break;
			case "2":
				self.parent.find("#charge_other").attr("checked", "checked");
				self.parent.find("#charge_text").html("半年缴");
				break;
			case "3":
				self.parent.find("#charge_other").attr("checked", "checked");
				self.parent.find("#charge_text").html("季缴");
				break;
			case "4":
				self.parent.find("#charge_other").attr("checked", "checked");
				self.parent.find("#charge_text").html("月缴");
				break;
			case "5":
				self.parent.find("#charge_once").attr("checked", "checked");
				break;
			default:
				break;
		}
		//设置交费方式：
		self.parent.find("#charge_type_0").attr("checked", "checked");
		//设置保单寄送方式
		if (self.proposer.extensionData.deliveryMethod === "1") {
			self.parent.find("#send_service").attr("checked", "checked");
		}
	}
	setPayMsg() {
		let self = this;
		//设置投保人帐户名
		self.parent.find("#account_name").html(self.proposer.fullName);
		//设置开户银行
		self.parent.find("#bank_name").html(self.paymentInfo.bankName || "");
		//设置银行帐号
		let bankCard = self.paymentInfo.bankCard || "";
		for (let i = 0; i < bankCard.length; i++) {
			self.parent.find("#bank_card_" + i).html(bankCard[i]);
		}
	}
	setAgentMsg() {
		let self = this;
		//默认选“个人营销”
		self.parent.find("#sale_type_0").attr("checked", "checked");
		//设置代理人姓名

		//设置代理人代码
		let agentCode = "";
		let accountName = Envs.findAccount().accountName || "";
		if (!accountName && Utils.getParameter(window.location.href, "isShare") && Utils.getParameter(window.location.href, "agentName")) {
			accountName = Utils.getParameter(window.location.href, "agentName");
		}
		if (accountName) {
			let index = accountName.indexOf(".");
			agentCode = accountName.substring(index + 1, accountName.length);
		}
		self.parent.find("#agent_code").html(agentCode);
		//调用后端接口获取代理人机构信息
		PageLoading && PageLoading.start();
		this.doPost(`/rest/v1/account/queryAccountAbout?accountName=${accountName}`).done((data) => {
			if(data.body) { //校验成功
             	self.parent.find("#agent_name").html(data.body.fullName || "");
				self.parent.find("#manageCom").html(data.body.manageCom || "");
				self.parent.find("#manageComName").html(data.body.manageComName || "");
            }
		}).always(() => {
			PageLoading && PageLoading.end();
		});
		//设置单证日期
		let date = new Date();
		self.parent.find(".sign_year").html(date.getFullYear());
		self.parent.find(".sign_month").html(parseInt(date.getMonth(), 10) + 1);
		self.parent.find(".sign_day").html(date.getDate());
		//设置代理人电话
		let agentMobile = Envs.findAccount().mobile || "";
		if (!agentMobile && Utils.getParameter(window.location.href, "isShare") && Utils.getParameter(window.location.href, "agentMobile")) {
			agentMobile = Utils.getParameter(window.location.href, "agentMobile");
		}
		self.parent.find("#agent_mobile").html(agentMobile);
	}
	resetBtn() {
		let self = this;
		self.parent.find("#sig-button").attr("class", "onlyOneButton");
		self.policySigBtn = self.parent.find(".sig-button1");
		self.insuredSigBtn = self.parent.find(".sig-button2");
		self.agentSigBtn = self.parent.find(".sig-button3");
		if (self.currentSignaturePerson === self.policyHolderType) {
			self.agentSigBtn.css("display", "none");
			self.insuredSigBtn.css("display", "none");
		} else if (self.currentSignaturePerson === self.agentType) {
			self.policySigBtn.css("display", "none");
			self.insuredSigBtn.css("display", "none");
		} else if (self.currentSignaturePerson === self.insuredType) {
			self.agentSigBtn.css("display", "none");
			self.policySigBtn.css("display", "none");
		}
	}
	addEvent() {
		let self = this;
		self.parent.find(".sig-button1").on("click", self.clickPolicySigBtnHandler);
		self.parent.find(".sig-button2").on("click", self.clickInsuredSigBtnHandler);
		self.parent.find(".sig-button3").on("click", self.clickAgentSigBtnHandler);
	}
	//各单证根据各自签名完成情况自定义 docType
	getPdfType() {
		let self = this;
		let pdfType = null;
		if (self.currentSignaturePerson === self.agentType) {
			pdfType = "-5.4";
		}
		if (self.agentSaveSiged && self.policyHolderSaveSiged) {
			pdfType = "-5.5";
		}
		if (self.agentSaveSiged && self.policyHolderSaveSiged && self.insuredSaveSiged) {
			pdfType = "-5.6";
		}
		//todo delete
		// if(self.currentSignaturePerson === self.policyHolderType) {
		// 	pdfType = "-5.5";
		// }
		// if(self.currentSignaturePerson === self.insuredType) {
		// 	pdfType = "-5.6";
		// }
		return pdfType;
	}
	// setHtmlSize() {
	// 	let self = this;
	// 	self.parent.find(".fontSize13").css("font-size", "6px");
	// 	self.parent.find(".fontSize14").css("font-size", "7px");
	// 	self.parent.find(".fontSize15").css("font-size", "7.5px");
	// 	self.parent.find(".fontSize16").css("font-size", "8px");
	// 	self.parent.find(".fontSize18").css("font-size", "9px");
	// 	self.parent.find("#policy_id_t").css("font-size", "10px");
	// }
	getDefaultClassName() {
		return '';
	}
}

export default InsureOrder