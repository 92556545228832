/**
 * @file n22.js
 * @author LiuH
 * @description N22
 */
import { Account, Envs, Utils } from '../common/common';

const PROTOCOL_REGEXP = /^http(s)?:/i;
const PROD_CHNPT = 'prod.chnpt.beijinglife.com.cn';
const YS_CHNPT = 'ys-chnpt.beijinglife.com.cn';
const PRE_CHNPT = 'pre.chnpt.beijinglife.com.cn';
const UAT_CHNPT = 'uat.chnpt.beijinglife.com.cn';

class N22 {
  getZNBGConfig() {
    return this.isPROD()
      ? {
          gid: 'gh_8c8e016822e7',
          api: 'https://iia.beijinglife.com.cn',
          host: 'https://sc-zhinengbaogu-1253531169.cos.ap-shanghai-fsi.myqcloud.com',
          basePath: '/h5',
        }
      : {
          gid: 'gh_fb6be0fe190b',
          api: 'https://uat-iia.beijinglife.com.cn',
          host: 'https://ceshi-zhinengbaogu-1253531169.cos.ap-shanghai.myqcloud.com',
          basePath: '',
        };
  }

  getHostByEnv() {
    // 默认协议类型
    let protocol = 'http:';
    if (!this.isDEV() && PROTOCOL_REGEXP.test(window.location.protocol)) {
      protocol = window.location.protocol;
    }
    if (this.isPROD()) {
      return `${protocol}//${PROD_CHNPT}/`;
    }
    if (this.isYS()) {
      return `${protocol}//${YS_CHNPT}/`;
    }
    if (this.isSTA()) {
      return `${protocol}//${PRE_CHNPT}/`;
    }
    return `${protocol}//${UAT_CHNPT}/`;
  }

  /**
   * 消息中心
   * @returns {string|null}
   */
  getMessageCenter() {
    let params = this.generateParams();
    if (Utils.isBlank(params)) {
      return null;
    }
    let hostname = this.getHostByEnv();
    return `${hostname}proposal/#/account/messageCenter?params=${params}`;
  }

  /**
   * 健康管理服务协议
   * @returns {string|null}
   */
  getHealthMgr() {
    let params = this.generateParams();
    if (Utils.isBlank(params)) {
      return null;
    }
    let hostname = this.getHostByEnv();
    return `${hostname}proposal/#/account/healthServeList?params=${params}`;
  }

  getMicroCard() {
    let params = this.generateParams();
    if (Utils.isBlank(params)) {
      return null;
    }
    let hostname = this.getHostByEnv();
    return `${hostname}proposal/#/account/callingCard?params=${params}`;
  }

  /**
   * 返回'{"agentCode": ""}'
   * @returns {string|null}
   */
  generateParams() {
    let agentCode = Account.getAgentCode();
    if (Utils.isBlank(agentCode)) {
      return null;
    }
    let params = `{"agentCode":"${agentCode}"}`;
    return Utils.doEncode(params);
  }

  isDEV() {
    return Envs.is_b2a_dev() || Envs.is_b2c_dev_test() || Envs.is_local();
  }

  isSTA() {
    // B2C
    return (
      Envs.is_b2c_sta_test() ||
      // B2A
      Envs.is_b2a_sta_test()
    );
  }

  isYS() {
    return Envs.is_b2c_ys_test();
  }

  isPROD() {
    // B2C
    return (
      Envs.is_b2c_prod() ||
      // B2A
      Envs.is_b2a_prod()
    );
  }
}

let n22 = new N22();
export default n22;