import { React, $ } from '../../../../common/3rd';

import Conainering from '../container';
import bankCardImage from '../../../../images/bankcard.jpg';
import identityYImage from '../../../../images/identityY.jpg';
import identityNImage from '../../../../images/identityN.jpg';

/**
 * 资料上传页
 * 例：资料上传页
 * add by susan.wu 2017 Oct.22
 *
 */
let myLayout = [];
//证件类型支持身份证1，户口本2，出生证3,军官证5，中国护照6，台湾通行证8，
const layout_identity = [
  {
    label: '身份证正面',
    type: 'uploadArea',
    documentType: '0.1',
    front: 'Y',
    initialUri: identityYImage,
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传身份证正面',
    },
  },
  {
    label: '身份证反面',
    type: 'uploadArea',
    documentType: '0.2',
    front: 'N',
    initialUri: identityNImage,
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传身份证反面',
    },
  },
];

const layout_birth = [
  {
    label: '户口本被保险人页',
    type: 'uploadArea',
    documentType: '4.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传户口本被保险人页',
    },
  },
];

const layout_booklet = [
  {
    label: '出生证本页',
    type: 'uploadArea',
    documentType: '7.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传出生证本页',
    },
  },
];
const layout_officer = [
  {
    label: '军官证本页',
    type: 'uploadArea',
    documentType: '2.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传军官证本页',
    },
  },
];

const layout_taiwan = [
  {
    label: '台胞证本页',
    type: 'uploadArea',
    documentType: '69.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传台胞证本页',
    },
  },
];

const layout_home = [
  {
    label: '回乡证正面',
    type: 'uploadArea',
    documentType: '66.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传回乡证正面',
    },
  },
  {
    label: '回乡证反面',
    type: 'uploadArea',
    documentType: '66.2',
    front: 'N',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传回乡证反面',
    },
  },
];

const layout_passport = [
  {
    label: '护照正本页',
    type: 'uploadArea',
    documentType: '1.1',
    front: 'Y',
    initialUri: '',
    phase: '7',
    check: {
      do: 'uploadChecked',
      msg: '请上传护照正本页',
    },
  },
];

class UploadAreaListFavoree extends Conainering {
  synchronizeLayoutModel() {
    let idType = this.getValueFromModel('policyHolder').idType;
    let policyCustomers = this.getValueFromModel('policyCustomers');
    let policyHolder = this.getValueFromModel('policyHolder');
    myLayout = [];
    $.each(policyCustomers, function (key, value) {
      if (value.customerType != '2') {
        //被保人
        if (value.idType == '0') {
          //身份证
          if (value.idNo == policyHolder.idNo) {
            value.extensionData.documents = [];
            return;
          }
          myLayout.push(layout_identity[0]);
          myLayout.push(layout_identity[1]);
        } else if (value.idType == '4') {
          //户口本
          myLayout.push(layout_birth[0]);
        } else if (value.idType == '7') {
          //出生证
          myLayout.push(layout_booklet[0]);
        } else if (value.idType == '2') {
          //军官证
          myLayout.push(layout_officer[0]);
        } else if (value.idType == 'E') {
          //台胞证
          myLayout.push(layout_taiwan[0]);
        } else if (value.idType == 'B') {
          //回乡证
          myLayout.push(layout_home[0]);
          myLayout.push(layout_home[1]);
        } else if (value.idType == '1') {
          //护照
          myLayout.push(layout_passport[0]);
        }
      }
    });
    let dataArray = this.getValueFromModel();
    if (!dataArray) {
      dataArray = [];
      this.setValueToModel(dataArray);
    }
    let imgArray = [];
    //imgArray = $.extend(true,[],dataArray);
    const uploadItems = myLayout.map((uploadItem) => {
      //若myLayout的影像在影像model中不存在，则model插入对应该影像的json
      imgArray.push(
        $.extend(
          {},
          {
            documentType: uploadItem.documentType,
            coverageCode: '',
            uri: '',
            front: uploadItem.front,
            uploadTime: '',
          }
        )
      );
      if (
        dataArray.every(
          (dataItem) =>
            !(
              dataItem.documentType + '' === uploadItem.documentType + '' &&
              dataItem.front + '' === uploadItem.front + ''
            )
        )
      ) {
        dataArray.push(
          $.extend(
            {},
            {
              documentType: uploadItem.documentType,
              coverageCode: '',
              uri: '',
              front: uploadItem.front,
              uploadTime: '',
            }
          )
        );
      }
      return uploadItem;
    });

    //如果model里影像的documentType不存在于myLayout的documentType集合里，则删掉。
    for (let i = dataArray.length; i > 0; i--) {
      if (
        uploadItems.every(
          (uploadItem) =>
            !(
              uploadItem.documentType + '' ===
                dataArray[i - 1].documentType + '' &&
              uploadItem.front + '' === dataArray[i - 1].front + ''
            )
        )
      ) {
        if (parseFloat(dataArray[i - 1].documentType) > -2) {
          dataArray.splice(i - 1, 1);
        }
      }
    }

    /**
     * id0身份证，id4户口本，id7出生证，id2军官证，idE台胞证，idB回乡证，id1护照
     */
    let id0 = 0,
      id4 = 0,
      id7 = 0,
      id2 = 0,
      idE = 0,
      idB = 0,
      id1 = 0;
    imgArray.map((item) => {
      if (item.documentType == 0.1) {
        id0++;
      } else if (item.documentType == 4.1) {
        id4++;
      } else if (item.documentType == 7.1) {
        id7++;
      } else if (item.documentType == 2.1) {
        id2++;
      } else if (item.documentType == 69.1) {
        idE++;
      } else if (item.documentType == 66.1) {
        idB++;
      } else if (item.documentType == 1.1) {
        id1++;
      }
    });
    let id0_data = 0,
      id4_data = 0,
      id7_data = 0,
      id2_data = 0,
      idE_data = 0,
      idB_data = 0,
      id1_data = 0;
    dataArray.map((item) => {
      if (item.documentType == 0.1) {
        id0_data++;
      } else if (item.documentType == 4.1) {
        id4_data++;
      } else if (item.documentType == 7.1) {
        id7_data++;
      } else if (item.documentType == 2.1) {
        id2_data++;
      } else if (item.documentType == 69.1) {
        idE_data++;
      } else if (item.documentType == 66.1) {
        idB_data++;
      } else if (item.documentType == 1.1) {
        id1_data++;
      }
    });
    if (id0 != id0_data) {
      let num = parseInt(id0 - id0_data);
      this.addImgUpdate(num, '0.1', dataArray);
    }
    if (id4 != id4_data) {
      let num = parseInt(id4 - id4_data);
      this.addImgUpdate(num, '4.1', dataArray);
    }
    if (id7 != id7_data) {
      let num = parseInt(id7 - id7_data);
      this.addImgUpdate(num, '7.1', dataArray);
    }
    if (id2 != id2_data) {
      let num = parseInt(id2 - id2_data);
      this.addImgUpdate(num, '2.1', dataArray);
    }
    if (idE != idE_data) {
      let num = parseInt(idE - idE_data);
      this.addImgUpdate(num, '69.1', dataArray);
    }
    if (idB != idB_data) {
      let num = parseInt(idB - idB_data);
      this.addImgUpdate(num, '66.1', dataArray);
    }
    if (id1 != id1_data) {
      let num = parseInt(id1 - id1_data);
      this.addImgUpdate(num, '1.1', dataArray);
    }
  }
  addImgUpdate(num, typeId, dataArray) {
    if (num > 0) {
      for (let i = 0; i < num; i++) {
        dataArray.push(
          $.extend(
            {},
            {
              documentType: typeId,
              coverageCode: '',
              uri: '',
              front: 'Y',
              uploadTime: '',
            }
          )
        );
        if (typeId == 0.1) {
          // 身份证反面
          dataArray.push(
            $.extend(
              {},
              {
                documentType: '0.2',
                coverageCode: '',
                uri: '',
                front: 'N',
                uploadTime: '',
              }
            )
          );
        } else if (typeId == 66.1) {
          //回乡证反面
          dataArray.push(
            $.extend(
              {},
              {
                documentType: '66.2',
                coverageCode: '',
                uri: '',
                front: 'N',
                uploadTime: '',
              }
            )
          );
        }
      }
    } else if (num < 0) {
      let iNum = Math.abs(num);
      for (let j = 0; j < iNum; j++) {
        for (let i = dataArray.length - 1; i >= 0; i--) {
          if (dataArray[i].documentType == typeId) {
            dataArray.splice(i, 1);
            return;
          }
        }
      }
    }
  }
  render() {
    const model = this.getModel();
    const dataArray = this.getValueFromModel();
    this.synchronizeLayoutModel();
    const layout = myLayout;
    let indexArray = [];
    return (
      <div {...this.getPrototypes()} ref="me">
        {layout.map((cell, index) => {
          let dataIndex = null;
          dataArray.map((dataItem, index) => {
            if (
              dataItem.documentType === cell.documentType &&
              dataItem.front === cell.front &&
              dataIndex === null &&
              indexArray.indexOf(index) < 0
            ) {
              dataIndex = index;
              indexArray.push(index);
            }
            return null;
          });
          cell = $.extend({}, cell, {
            id: this.getPanelLayout().id + `[${dataIndex}]`,
          });
          return this.renderCell(model, cell);
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-uploadAreaOtherList';
  }
  getPanelLayout() {
    return this.getLayout();
  }
}

export default UploadAreaListFavoree;
