import { React, ReactDOM, $ } from '../../../../common/3rd';
import { Utils, Envs } from '../../../../common/common';
// import AtomicMutableComponentThing from '../atomic-mutable-component';
import AtomicComponentThing from '../atomic-component';
// import { format } from '../something-utils';
import { PageLoading } from '../../../components';
import utils from '../../../../common/utils';

// import AnysignView from '../../anysign/anysignView';

/**
 * 签名基类
 * add by wendy.gu 2017 Nov.4
 */
class BaseSignatureOneToMoreView extends AtomicComponentThing {
  constructor(props, context) {
    super(props, context);
    // this.state.verifySuccess = false; //身份校验成功
    this.policyHolderType = 1; //投保人
    this.insuredType = 2; //被保人
    this.agentType = 3; //代理人
    this.commentType = 4; //批注
    this.secInsuredType = 5; //第二被保人
    this.policyHolderMsg = [];
    this.insuredMsg = [];
    this.agentMsg = [];
    this.commentMsg = [];
    this.proposalId = ''; //订单ID
    this.proposalCode = ''; //投保单号
    this.proposer = {}; //投保人信息
    this.insureds = []; //被保人信息
    this.insured = {}; // 第一被保人信息
    this.agent = {}; //代理人信息
    this.agentName = ''; //代理人姓名
    this.agentCode = ''; //代理人证件号码
    this.vendorCode = '';
    this.certiType = '1';
    this.certiCode = '111111';
    this.sameWithProposer = false;

    this.userId = ''; //用户ID
    this.myCamera = '';
    this.initSDKResult = false; //是否初始化SDK
    this.myPolicySign = null; //投保人签名url
    this.myPolicyCamera = null; //投保人拍照影像
    this.isUploadDocument = false; //投保人影像是否上传成功
    this.policyHolderSaveSiged = false; //投保人签名是否保存成功
    this.initPolicyHolderSaveSiged = false; //投保人签名是否初始化
    this.myInsuredSign = null; //被保人签名url
    this.insuredSaveSiged = false; //被保人签名是否保存成功
    this.initInsuredSaveSiged = false; //被保人签名是否初始化
    this.myAgendSign = null; //代理人签名url
    this.agentSaveSiged = false; //代理人签名是否保存成功
    this.initAgentSaveSiged = false; //代理人签名是否初始化
    this.myCommentSign = null; //批注url
    this.commentSaveSiged = false; //批注是否保存成功
    this.initCommentSaveSiged = false; //批注是否初始化
    //以下DOM
    this.policyHolderImageApp = null;
    this.agentImageApp = null;
    this.commentImageApp = null;
    this.insuredImageApp = null;
    this.policySigBtn = null;
    this.agentSigBtn = null;
    this.insuredSigBtn = null;
  }

  getName(firstName, lastName) {
    if (Utils.hasOrientalChar(firstName) || Utils.hasOrientalChar(lastName)) {
      return (lastName || '') + (firstName || '');
    } else {
      return (firstName || '') + ' ' + (lastName || '');
    }
  }

  //render前设置数据
  resetData(model) {
    let self = this;
    self.model = model;
    self.proposalId = self.model.quotationNo || '';
    self.proposalCode = self.model.quotationNo || '';
    self.proposer = self.model.policyHolder || {};
    self.model.policyCustomers.map((item, index, arr) => {
      if (item.customerType === '2') {
        self.insureds.push(item);
        if (item.extensionData.relationWithPH === '00') {
          this.sameWithProposer = true;
        }
      }
      return null;
    });
    self.insured = self.insureds[0] ? self.insureds[0] : {};
    self.agent = self.model.agent || {};
    self.agentName = Envs.findAccount().nickname || '';
    self.agentCode = Envs.findAccount().idNo || '';
    self.vendorCode = self.model.vendorCode || '';
    let isShare = Utils.getParameter(window.location.href, 'isShare');
    if (isShare) {
      self.agentName = Utils.getParameter(window.location.href, 'agentName');
      self.agentCode = Utils.getParameter(window.location.href, 'agentCode');
    }
    //设置初始值
    self.initSDKResult = false; //是否初始化SDK
    self.myPolicySign = null; //投保人签名url
    self.myPolicyCamera = null; //投保人拍照影像
    self.isUploadDocument = false; //投保人影像是否上传成功
    self.policyHolderSaveSiged = false; //投保人签名是否保存成功
    self.initPolicyHolderSaveSiged = false; //投保人签名是否初始化
    self.myInsuredSign = null; //被保人签名url
    self.insuredSaveSiged = false; //被保人签名是否保存成功
    self.initInsuredSaveSiged = false; //被保人签名是否初始化
    self.myAgendSign = null; //代理人签名url
    self.agentSaveSiged = false; //代理人签名是否保存成功
    self.initAgentSaveSiged = false; //代理人签名是否初始化
    self.myCommentSign = null; //批注url
    self.commentSaveSiged = false; //批注是否保存成功
    self.initCommentSaveSiged = false; //批注是否初始化
    self.policyHolderImageApp = null;
    self.agentImageApp = null;
    self.commentImageApp = null;
    self.insuredImageApp = null;
    self.policySigBtn = null;
    self.agentSigBtn = null;
    self.insuredSigBtn = null;
  }

  //数据初始化
  initData(tpl, model, currentSignaturePerson, currentIndex, myIndex) {
    let self = this;
    self.tpl = tpl;
    self.model = model;
    self.currentSignaturePerson = currentSignaturePerson;
    self.currentIndex = currentIndex;
    self.myIndex = myIndex;
    //注意顺序 [投保人、被保人、批注、代理人]
    let proposerOps = null,
      insuredOps = null,
      commentOps = null,
      agentOps = null;
    if (
      self.policyHolderMsg.length > 0 &&
      self.proposer &&
      self.proposer.idNo
    ) {
      proposerOps = {
        name: self.proposer.fullName,
        // "certiType": self.proposer.idType,
        certiCode: self.proposer.idNo,
        keyWord: self.policyHolderMsg[4],
        direction: self.policyHolderMsg[5],
        directionNo: parseInt(self.policyHolderMsg[6], 10),
      };
    }
    if (self.insuredMsg.length > 0 && self.insured && self.insured.idNo) {
      insuredOps = {
        name: self.insured.fullName,
        // "certiType": self.insured.idType,
        certiCode: self.insured.idNo,
        keyWord: self.insuredMsg[4],
        direction: self.insuredMsg[5],
        directionNo: parseInt(self.insuredMsg[6], 10),
      };
    }
    if (self.commentMsg.length > 0 && self.proposer && self.proposer.idNo) {
      commentOps = {
        name: self.proposer.fullName,
        // "certiType": self.proposer.idType,
        certiCode: self.proposer.idNo,
        keyWord: self.commentMsg[4],
        direction: self.commentMsg[5],
        directionNo: self.commentMsg[6],
        commmentMsg: self.commentMsg[7],
      };
    }
    if (self.agentMsg.length > 0) {
      agentOps = {
        name: self.agentName,
        // "certiType": "1",
        certiCode: self.agentCode,
        keyWord: self.agentMsg[4],
        direction: self.agentMsg[5],
        directionNo: parseInt(self.agentMsg[6], 10),
      };
    }
    let signCfg = [proposerOps, insuredOps, commentOps, agentOps];
    self.signCfg = signCfg;
  }

  resetUI() {
    let self = this;
    self.getSignatureImg();
  }

  render() {
    let self = this;
    if (
      (self.currentIndex &&
        self.myIndex &&
        self.currentIndex === self.myIndex) ||
      self.noPolicyDocument ||
      self.currentIndex === 23
    ) {
      return (
        <div id="signature-html-container">
          <div
            id="signature-html-main"
            data-type={this.getLayout().htmlType}
            data-index={self.myIndex}
            data-name={this.getLayout().type}
            ref="signatureHtml"
            dangerouslySetInnerHTML={{ __html: this.tpl }}
          ></div>
          <div id="backBtn" className="insurer-message" onClick={this.goBack}>
            返回
          </div>
        </div>
      );
    } else {
      return <div id="signature-html-container"></div>;
    }
  }

  componentDidUpdate() {
    let self = this;
    if (self.currentIndex !== self.myIndex) {
      return;
    }
    $(ReactDOM.findDOMNode(self.refs['signatureHtml']))
      .parents('.page-body')
      .css('padding-bottom', '0');
    self.setHtmlSize();
  }

  componentDidMount() {
    let self = this;
    if (self.currentIndex !== self.myIndex) {
      return;
    }
    $(ReactDOM.findDOMNode(self.refs['signatureHtml']))
      .parents('.page-body')
      .css('padding-bottom', '0');
    self.setHtmlSize();
  }

  //获取已签名图片
  getSignature() {
    let self = this;
    //判断model中是否有相应的签字图片即可
    //代理人图片
    let agentDocuments = self.model.extensionData.documents || [];
    for (let i = 0; i < agentDocuments.length; i++) {
      if (agentDocuments[i].documentType === '-22') {
        self.getSignatureImg(self.agentMsg);
      }
    }
    //投保人图片
    let policyDocuments = self.model.policyHolder.extensionData.documents || [];
    for (let i = 0; i < policyDocuments.length; i++) {
      if (policyDocuments[i].documentType === '-23') {
        self.getSignatureImg(self.agentMsg);
      }
    }
    //代理人图片
    let insuredDocuments = self.insured.extensionData.documents || [];
    for (let i = 0; i < insuredDocuments.length; i++) {
      if (insuredDocuments[i].documentType === '-24') {
        self.getSignatureImg(self.agentMsg);
      }
    }
  }

  /**
   * 根据参数获取签名图片
   *   注：投被保人类型 1投保人  2被保人  3代理人  4风险语录  5第二被保人
   */
  getSignatureImg() {
    let self = this;
    //投被保人,代理人的documents
    let documents = self.model.extensionData.documents || [];
    let proposerDom = self.proposer.extensionData.documents || [];
    let insuredDom = self.insured.extensionData.documents || [];
    //投保人签字图片
    proposerDom.map((doc, index, arr) => {
      if (doc.documentType === '-23') {
        this.getImage(doc.uri, 1);
      }
      return null;
    });
    //被保人签字图片
    insuredDom.map((doc, index, arr) => {
      if (doc.documentType === '-24') {
        this.getImage(doc.uri, 2);
      }
      return null;
    });
    //代理人签字图片
    documents.map((doc, index, arr) => {
      if (doc.documentType === '-22') {
        this.getImage(doc.uri, 3);
      }
      return null;
    });
  }

  //根据图片uri获取图片base64
  getImage(uri, signatureType) {
    let self = this;
    let params = {
      imageUri: uri,
    };
    PageLoading && PageLoading.start();
    this.doPost('/rest/v1/policy/loadImage', params)
      .done((data) => {
        if (data && data.body) {
          self.setImage(data.body, signatureType);
        }
      })
      .always(() => {
        PageLoading && PageLoading.end();
      });
  }

  //设置图片
  setImage(imageData, signatureType) {
    let self = this;
    if (signatureType === self.policyHolderType && self.policyHolderImageApp) {
      self.initPolicyHolderSaveSiged = true;
      self.policyHolderSaveSiged = true;
      self.policyHolderImageApp.attr('src', imageData);
      if (self.sameWithProposer && self.insuredImageApp) {
        self.insuredSaveSiged = true;
        self.insuredImageApp.attr('src', imageData);
      }
    }
    if (signatureType === self.agentType && self.agentImageApp) {
      self.initAgentSaveSiged = true;
      self.agentSaveSiged = true;
      self.agentImageApp.attr('src', imageData);
    }
    if (signatureType === self.commentType && self.commentImageApp) {
      self.initCommentSaveSiged = true;
      self.commentSaveSiged = true;
      self.commentImageApp.attr('src', imageData);
    }
    if (signatureType === self.insuredType && self.insuredImageApp) {
      self.initInsuredSaveSiged = true;
      self.insuredSaveSiged = true;
      self.insuredImageApp.attr('src', imageData);
    }
  }

  setHtmlSize() {}

  //点击返回按钮
  goBack = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // let self = this;
    if (utils.clickLock()) return;
    this.getRootComponent().jumpTo('documentList');
  };

  getDefaultClassName() {
    return '';
  }
}

export default BaseSignatureOneToMoreView;
