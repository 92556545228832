import { React } from '../../../../common/3rd';

import AtomicMutableComponentThing from '../atomic-mutable-component';

class Image extends AtomicMutableComponentThing {
  render() {
    return (
      <div>
        {Array.isArray(this.getLayout().src) ? (
          this.getLayout().src.map((img, index) => {
            return (
              <img
                src={img || ''}
                key={`sth-img-${index}`}
                className="sth-image"
              />
            );
          })
        ) : (
          <img src={this.getLayout().src} className="sth-image" />
        )}
      </div>
    );
  }
}
export default Image;
