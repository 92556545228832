import { React } from '../../../../common/3rd';
//import { Utils } from '../../../../common/common'
import * as SthUtils from '../something-utils';
import Text from './text';
/**
 * 输入框
 */
class Number extends Text {
  render() {
    return (
      <input
        type="number"
        {...this.getPrototypes()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.doCheckAndAlert}
        pattern="[0-9]*"
        ref="me"
      />
    );
  }

  /**
   * 设置指定值到模型中. 如果指定force, 则一定会设置; 否则会比较模型值与被设置的值, 相等则忽略此操作
   * 如果操作被忽略, 返回false
   *
   * @param {any} value
   * @param {function} callback
   * @param {boolean} force
   * @param {string} id 可选项, 如果没有, 则使用getId()
   */
  setValueToModel(value, callback, force, id) {
    value = parseInt(value, 10);
    if (this.getLayout().toDecimal2) {
      if (/^(([1-9]\d?)|(100))$/.test(value)) {
        value = value + '9';
        value = value / 1000;
        value = value - 0.009;
        value = value.toFixed(2);
        this.state.toDecimal2 = true;
      } else {
        this.state.toDecimal2 = false;
      }
    } else {
      this.state.toDecimal2 = false;
    }
    if (this.getLayout().isBankCard === true) {
      value = value.replace(/\s/g, '');
    }
    const ret = SthUtils.setValueToModel(
      this.getModel(),
      id || this.getId(),
      value,
      force
    );
    if (ret !== false) {
      // 如果成功设置, 触发事件
      this.getEventEmitter().emit(id || this.getId(), {
        model: this.getModel(),
        from: this,
      });
    }
    if (callback) {
      // 回调
      callback.call(this);
    }
    this.getRootComponent().forceUpdate();
    return ret;
  }
}

export default Number;
