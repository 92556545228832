/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @file router/mini/index.js
 * @description 小程序路由页面（注意：路径必须以/开头，且路径可访问，否则无法跳转)
 * @author LiuH
 */

import { encrypt, DS_IV, DS_KEY } from '@/util/crypto';

/** 个人中心 */
const MINE = '/pages/mine/mine';
/** WebView */
const WEB_VIEW = '/pages/web/web';
/** 保单列表 */
const ORDER_LIST = '/pages/orderList/orderList';
/** 保单详情 */
const ORDER_DETAIL = '/pages/orderDetail/orderDetail';
/** 完善信息 */
const PERFECT_MSG = '/pages/perfectMsg/perfectMsg';
/** 人脸核身 */
const FACE = '/pages/face/face';

/** 小程序页面 */
const MINI_PAGE = {
  MINE,
  WEB_VIEW,
  ORDER_LIST,
  ORDER_DETAIL,
  PERFECT_MSG,
  FACE,
};

/**
 * 生成人脸识别跳转路径
 * @param {string} comeIn
 * @param {object} data
 * @param {string} data.policyNo 保单号
 * @param {string} data.bizToken
 * @param {string} data.name 姓名
 * @param {string} data.idNo 身份证号
 * @param {string} data.count 在线回访
 * @param {string} data.prtNo 在线回访
 * @returns {string}
 */
function generateFaceRoute(comeIn = '', data = {}) {
  let { policyNo, bizToken, name, idNo, count, prtNo } = data;
  policyNo = policyNo || '';
  bizToken = bizToken || '';
  name = encrypt(name || '', DS_KEY, DS_IV);
  idNo = encrypt(idNo || '', DS_KEY, DS_IV);
  count = count;
  prtNo = prtNo;
  // prettier-ignore
  return (
    MINI_PAGE.FACE +
    '?comeIn=' + comeIn +
    '&policyNo=' + policyNo +
    '&bizToken=' + bizToken +
    '&name=' + name +
    '&id=' + idNo +
    '&count=' + count +
    '&prtNo=' + prtNo
  );
}

export { generateFaceRoute, MINI_PAGE };
