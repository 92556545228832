import { React, $ } from '../../../../common/3rd';

import Conainering from '../container';

// import { Something } from '@/component/components'

/**
 * 资料上传页
 * 例：资料上传页
 * add by susan.wu 2017 Oct.22
 *
 */
let myLayout = null;

class UploadAreaOtherList extends Conainering {
  synchronizeLayoutModel() {
    myLayout = [];
    let dataArray = this.getValueFromModel();
    if (!dataArray) {
      dataArray = [];
      this.setValueToModel(dataArray);
    }
    myLayout = [];
    dataArray.map((dataItem) => {
      if (parseFloat(dataItem.documentType) < -99) {
        myLayout.push({
          label: '其他资料',
          type: 'uploadArea',
          documentType: dataItem.documentType,
          front: 'Y',
          initialUri: '',
          phase: '7',
          check: {
            do: 'uploadChecked',
            msg: '请上传其他资料',
          },
        });
        return null;
      }
      return null;
    });
  }
  render() {
    this.synchronizeLayoutModel();
    const layout = myLayout;
    const dataArray = this.getValueFromModel();
    const model = this.getModel();
    let indexArray = [];
    return (
      <div {...this.getPrototypes()} ref="me">
        {layout.map((cell, index) => {
          let dataIndex = null;
          dataArray.map((dataItem, index) => {
            if (
              dataItem.documentType === cell.documentType &&
              dataItem.front === cell.front &&
              dataIndex === null &&
              indexArray.indexOf(index) < 0
            ) {
              dataIndex = index;
              indexArray.push(index);
            }
            return null;
          });
          cell = $.extend({}, cell, {
            id: this.getPanelLayout().id + `[${dataIndex}]`,
          });
          return this.renderCell(model, cell);
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-uploadAreaOtherList';
  }
  getPanelLayout() {
    return this.getLayout();
  }
}

export default UploadAreaOtherList;
