import { React } from '../../../common/3rd';
import { Envs, Swiper } from '../../../common/common';
import './lead.scss';

//引导
import leadOne from '../../../images/wechat/footer/leadOne.png';
import leadOneX from '../../../images/wechat/footer/leadOneX.png';
import leadTwo from '../../../images/wechat/footer/leadTwo.png';
import leadTwoX from '../../../images/wechat/footer/leadTwoX.png';
import leadThree from '../../../images/wechat/footer/leadThree.png';
import leadThreeX from '../../../images/wechat/footer/leadThreeX.png';

class Lead extends React.Component {
  constructor(context) {
    super();
    this.state = {
      leadList: [
        { imageMax: leadOne, imageMin: leadOneX, exp: 1 },
        { imageMax: leadTwo, imageMin: leadTwoX, exp: 1 },
        { imageMax: leadThree, imageMin: leadThreeX, exp: 3 },
      ],
      innerHeight: window.innerHeight,
    };
  }
  componentWillUnmount() {}
  componentDidMount() {
    new Swiper('.swiper-container', {
      direction: 'horizontal', // 横向切换选项
      loop: false, //循环
      autoplay: false,
      // 如果需要分页器
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        slideChange: (event) => {},
      },
    });
  }
  render() {
    return (
      <div className="leadWrap">
        <div className="lead-banner">
          <div className="swiper-container swiperBanner">
            <div className="swiper-wrapper">
              {this.state.leadList.map((item, index) => {
                return (
                  <div className="swiper-slide">
                    <img
                      className="leftOne oneBom"
                      src={
                        this.state.innerHeight > 780
                          ? item.imageMax
                          : item.imageMin
                      }
                      alt=""
                    />
                    <div className="slideJump" onClick={this.gotoPhoto}>
                      跳过
                    </div>
                    <div
                      className={item.exp == 3 ? 'slideExp' : 'slideExpNo'}
                      onClick={this.gotoPhoto}
                    >
                      立即体验
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="swiper-pagination banner-pagination"></div>
          </div>
        </div>
        <div className="leadMsk"></div>
      </div>
    );
  }
  gotoPhoto = () => {
    this.props.getLead(2);
  };
}

export default Lead;
