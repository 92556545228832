import { React, ClassNames } from '../../common/3rd';

import Text from './text';

/**
 * date input
 */
class ChoseDate extends Text {
  componentDidMount() {
    this.refs.dateInput.addEventListener('focus', () => {
      //阻止键盘弹出
      document.activeElement.blur();
    });
    let selfi = this;
    super.componentDidMount();
    let datePicker = window.datePicker;
    let calendar = new datePicker();
    let thisc = this.props['data-val'];
    let minDate = this.props['min'];
    let maxDate = this.props['max'];
    let id = this.props['id'];
    id = '#' + id;
    calendar.init({
      trigger: id /*按钮选择器，用于触发弹出插件*/,
      type: 'date' /*模式：date日期；datetime日期时间；time时间；ym年月；*/,
      minDate: minDate ? minDate : '1900-01-01' /*最小日期*/,
      maxDate: maxDate ? maxDate : '2300-12-31' /*最大日期*/,
      onSubmit: function () {
        /*确认时触发事件*/
        selfi.props.onSubmit && selfi.props.onSubmit(calendar.value);
        let theSelectData = calendar.value;
        selfi.setValueIntoModel(theSelectData);
        if (thisc && typeof thisc.onAgeChange === 'function') {
          thisc.onAgeChange(theSelectData);
        }
        selfi.props.parent && selfi.props.parent.forceUpdate();
      },
      onClose: function () {
        /*取消时触发事件*/
        selfi.props.onClose && selfi.props.onClose();
      },
      onChange: function () {
        selfi.props.onChange && selfi.props.onChange(calendar.value);
      },
    });
  }
  renderRealWidget(props) {
    return (
      <div
        className={props.className}
        ref="widget"
        style={{ position: 'relative' }}
      >
        {props.value}
        <input
          id={props.id}
          className={props.className}
          ref="dateInput"
          value={props.value}
          style={{ opacity: 0, position: 'absolute', top: 0 }}
          readOnly={true}
        />
      </div>
    );
  }
  wrapOthers(props) {
    props.type = this.getType();
    props.className = ClassNames(props.className, props.type);
    return this;
  }
  getType() {
    return this.getPropsValue('type', 'text');
  }
  getMin() {
    let model = this.getModel();
    if (model) {
      let propName = this.props['a-minDate'];
      return (
        model[propName] && model[propName].split(' ')[0].replace(/\//g, '-')
      );
    } else {
      return null;
    }
  }
  getMax() {
    let model = this.getModel();
    if (model) {
      let propName = this.props['max'];
      return (
        model[propName] && model[propName].split(' ')[0].replace(/\//g, '-')
      );
    } else {
      return null;
    }
  }
  getValueFromModel() {
    let model = this.getModel();
    if (model) {
      let propName = this.getPropertyName();
      return (
        model[propName] && model[propName].split(' ')[0].replace(/\//g, '-')
      );
    } else {
      return null;
    }
  }
  setValueIntoModel(value) {
    value = value ? value.replace(/-/g, '/') + ' 00:00:00' : null;
    let model = this.getModel();
    if (model) {
      const currentValue = this.getValueFromModel();
      if (currentValue !== value) {
        let propName = this.getPropertyName();
        model[propName] = value;
        this.props['a-parent'] && this.props['a-parent'].forceUpdate();
      }
    }
  }
}

export default ChoseDate;