import { Storages } from './3rd';
import * as Consts from './consts';

/**
 * Storage utilities
 */
class Storage {
  constructor(namespace) {
    this.storage = Storages.initNamespaceStorage(namespace);
  }

  writeToSession(key, value) {
    this.storage.sessionStorage.set(key, value);
    return this;
  }

  getFromSession(key) {
    if (this.storage.sessionStorage.isSet(key)) {
      return this.storage.sessionStorage.get(key);
    } else {
      return undefined;
    }
  }

  removeFromSession(key) {
    this.storage.sessionStorage.remove(key);
    return this;
  }

  clearSession() {
    this.storage.sessionStorage.removeAll();
    return this;
  }

  clearAllSession() {
    Storages.sessionStorage.removeAll();
    return this;
  }

  writeToLocal(key, value) {
    this.storage.localStorage.set(key, value);
    return this;
  }

  getFromLocal(key) {
    if (this.storage.localStorage.isSet(key)) {
      return this.storage.localStorage.get(key);
    } else {
      return undefined;
    }
  }

  removeFromLocal(key) {
    this.storage.localStorage.remove(key);
    return this;
  }

  clearLocal() {
    this.storage.localStorage.removeAll();
    return this;
  }

  clearAllLocal() {
    Storages.localStorage.removeAll();
    return this;
  }

  writeToCookie(key, value) {
    this.storage.cookieStorage.set(key, value);
    return this;
  }

  getFromCookie(key) {
    if (this.storage.cookieStorage.isSet(key)) {
      return this.storage.cookieStorage.get(key);
    } else {
      return undefined;
    }
  }

  removeFromCookie(key) {
    this.storage.cookieStorage.remove(key);
    return this;
  }
}

class ToDetailStorage extends Storage {
  constructor(namespace) {
    super('CSMS-To-Detail');
  }

  hasLast() {
    return this.getFromSession(Consts.LAST_OPERATION_TYPE) != null;
  }

  lastOperationType(type) {
    if (type) {
      return this.writeToSession(Consts.LAST_OPERATION_TYPE, type);
    } else {
      return this.getFromSession(Consts.LAST_OPERATION_TYPE);
    }
  }

  lastCriteria(data) {
    if (data) {
      return this.writeToSession(Consts.LAST_CRITERIA, data);
    } else {
      return this.getFromSession(Consts.LAST_CRITERIA) || {};
    }
  }

  lastSnapshot(data) {
    if (data) {
      return this.writeToSession(Consts.LAST_SNAPSHOT, data);
    } else {
      return this.getFromSession(Consts.LAST_SNAPSHOT) || {};
    }
  }

  lastScrollPos(pos) {
    if (pos != null) {
      return this.writeToSession(Consts.LAST_SCROLL_POS, pos);
    } else {
      return this.getFromSession(Consts.LAST_SCROLL_POS) || 0;
    }
  }

  catchScrollPos() {
    this.lastScrollPos({
      x: window.scrollX,
      y: window.scrollY,
    });
  }

  lastAction(action) {
    if (action) {
      return this.writeToSession(Consts.LAST_ACTION, action);
    } else {
      return this.getFromSession(Consts.LAST_ACTION);
    }
  }

  lastDetailId(id) {
    if (id) {
      return this.writeToSession(Consts.LAST_DETAIL_ID, id);
    } else {
      return this.getFromSession(Consts.LAST_DETAIL_ID);
    }
  }

  readLast(component, func) {
    component.state.last = {
      criteria: this.lastCriteria(),
      snapshot: this.lastSnapshot(),
      action: this.lastAction(),
      detailId: this.lastDetailId(),
      scrollTop: this.lastScrollPos(),
    };
    if (func) {
      func.call(component);
    }
  }

  /**
   * 如果有参数, 则调用clearLast
   * @param {*} component
   */
  restoreScroll(component) {
    const pos = this.lastScrollPos();
    if (pos) {
      window.scrollTo(pos.x, pos.y);
    }

    if (component) {
      this.clearLast(component);
    }
  }

  /**
   * 清理session storage. 如果参数不是null, 则清理component.state.last
   * @param {*} component
   */
  clearLast(component) {
    [
      Consts.LAST_OPERATION_TYPE,
      Consts.LAST_CRITERIA,
      Consts.LAST_DETAIL_ID,
      Consts.LAST_SNAPSHOT,
      Consts.LAST_SCROLL_POS,
      Consts.LAST_ACTION,
    ].forEach((key) => {
      this.removeFromSession(key);
    });
    if (component) {
      delete component.state.last;
    }
  }
}

const storage = {
  Auth: new Storage('CSMS-Auth'),
  Account: new Storage('CSMS-Account'),
  Lang: new Storage('CSMS-Lang'),
  ToDetail: new ToDetailStorage(),
  clearAllSession: function () {
    storage.Auth.clearAllSession();
    storage.Account.clearAllSession();
    storage.ToDetail.clearAllSession();
  },
};

export default storage;
