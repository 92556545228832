import xss from 'xss';
// import EventEmitter from '@/component/both/something/event-emitter'
import { $ } from './3rd';
import * as Consts from './consts';
import Storage from './storage';
import utils from '@/common/utils';
import { base64Encode } from '@/util/crypto';

let events = require('events');
window.eventEmitter = new events.EventEmitter();

window.onAndroidReturn = function () {
  let url = window.location.href;
  if (
    url.lastIndexOf('/advice/create') > 0 &&
    url.lastIndexOf('/advice/creation') < 0
  ) {
    //制作计划书的返回
    window.eventEmitter.emit('advice');
  } else if (url.lastIndexOf('/quote') > 0) {
    //电投的返回
    window.eventEmitter.emit('quoteCE');
  } else if (
    url.lastIndexOf('/proposal-query') > 0 &&
    url.lastIndexOf('/proposal-query/result') < 0
  ) {
    //客户建议书查询页面的返回
    window.eventEmitter.emit('proposalQuery');
  } else if (url.lastIndexOf('/proposal-query/result') > 0) {
    //客户建议书查询结果页面的返回
    window.eventEmitter.emit('proposalQueryResult');
  } else if (
    url.lastIndexOf('/life/home') > 0 &&
    url.lastIndexOf('/life/home/') < 0
  ) {
    //主页
    let options = {
      action: 'exitSystem',
      success: '',
    };
    let msg = JSON.stringify(options);
    try {
      window.__EBAO_JSSDK.callNative(msg);
    } catch (e) {}
  } else if (
    url.lastIndexOf('/mobile/home') > 0 &&
    url.lastIndexOf('/mobile/home/') < 0
  ) {
    //主页
    let options = {
      action: 'exitSystem',
      success: '',
    };
    let msg = JSON.stringify(options);
    try {
      window.__EBAO_JSSDK.callNative(msg);
    } catch (e) {}
  } else if (url.lastIndexOf('/my-trainning') > 0) {
    // back of my trainning
    if (url.lastIndexOf('/my-trainning/') > 0) {
      window.history.back();
    } else {
      window.eventEmitter.emit('myTraining');
    }
  } else if (
    url.includes('/create') &&
    !url.includes('/advice/create') &&
    !url.includes('/quote/create')
  ) {
    //产品详情页
    window.eventEmitter.emit('introduction');
  } else if (url.lastIndexOf('/trainning/quiz') > 0) {
    // back of quiz
    window.eventEmitter.emit('quizBack');
  } else if (url.lastIndexOf('/online/trainning') > 0) {
    // back of online trainning
    if (url.lastIndexOf('/online/trainning/') > 0) {
      window.history.back();
    } else {
      window.eventEmitter.emit('onlineTraining');
    }
    // } else if (url.lastIndexOf("/my-guarantee") > 0) { // 我的保单
    // 	window.eventEmitter.emit("guarantee");
  } else if (url.includes('/advice/creation/')) {
    // 建议书展示
    window.eventEmitter.emit('adviceCreation');
  } else if (
    url.lastIndexOf('/mobile/me/claim-report') > 0 &&
    url.lastIndexOf('/mobile/me/claim-report/') < 0
  ) {
    // B2A理赔报案
    window.eventEmitter.emit('claimReport');
  } else if (url.lastIndexOf('/mobile/me/claim-report/end') > 0) {
    // B2A理赔报案完成页
    window.eventEmitter.emit('claimReportEnd');
  } else {
    window.history.back();
  }
};

window.onGetContactSuccess = function (result) {
  if (typeof result === 'object') {
  } else {
    result = JSON.parse(result);
  }
  window.eventEmitter.emit('getContactSuccess', result);
};
window.onGetContactFailure = function (result) {
  if (typeof result === 'object') {
  } else {
    result = JSON.parse(result);
  }
  window.eventEmitter.emit('getContactFailure', result);
};
//人脸识别原生回调 add by cheng
window.faceVerifyCallback = function (result) {
  if (typeof result === 'object') {
  } else {
    result = JSON.parse(result);
  }
  window.eventEmitter.emit('faceVerifyCallback', result);
};
//银行卡OCR原生回调 add by wendy.gu 2017 Dec.27
window.bankOCRCallback = function (result) {
  window.eventEmitter.emit('bankOCRCallback', result);
};

// 11/01/2018 - TAG: by yanzhi.mo
window.scanIDCardOCRCallback_PO = function (result) {
  window.eventEmitter.emit('scanIDCardOCRCallback_PO', result);
};

// 11/01/2018 - TAG: by yanzhi.mo
window.scanIDCardOCRCallback_IN = function (result) {
  window.eventEmitter.emit('scanIDCardOCRCallback_IN', result);
};

//理赔报案OCR身份证识别 add by susan.wu 2018/01/11
window.claimRepoerOCRCallback = function (result) {
  window.eventEmitter.emit('claimRepoerOCRCallback', result);
};

/**
 * Android京保通登录回调
 * @param result
 */
window.getLoginDataCallback = function (result) {
  window.eventEmitter.emit('getLoginDataCallback', result);
};

/**
 * 非B2C投保流程清除匿名用户登录信息
 * add by cheng.fan on Jan.16 2018
 * 匿名登录信息:product-store.js
 */
if (!window.location.href.includes('/product-store/')) {
  let account = Storage.Account.getFromLocal(Consts.ACCOUNT_SIGN_KEY) || {};
  if (account.accountId === 36) {
    Storage.clearAllSession();
    localStorage.clear();
  }
}

/**
 * environment, should be run in browser
 */
class Envs {
  constructor() {
    // detect browser environments
    $('html')
      .attr('class')
      .split(' ')
      .forEach((className) => {
        if (className.startsWith('env-')) {
          this[className.substring(4)] = true;
        }
      });
    this.app = true;

    // initialize paths
    this.PATH = Consts.PATH;
    this.rootApp = null;
    this._configuration = null;
    this.initXSS();
  }
  /**
   * Usage: this.xss.process(source) <br/>
   * @see https://github.com/leizongmin/js-xss
   */
  initXSS() {
    const whiteList = xss.getDefaultWhiteList();
    whiteList.span.push('style');
    whiteList.a.push('style');
    const options = {
      whiteList,
      // css: false,
    };
    utils.debug('xss', whiteList);
    this.xss = new xss.FilterXSS(options);
  }
  // get service location
  // append relative path to absolute url prefix
  // or return absolute url prefix if no parameter passed
  getServiceLocation(relativePath) {
    let url = window.location;

    let port = url.port;
    if (process.env.REACT_APP_AJAX_SERVER_PORT) {
      port = `:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
    } else if (port) {
      port = `:${port}`;
    }
    let hostname = url.hostname;
    if (process.env.REACT_APP_AJAX_SERVER_HOST) {
      hostname = process.env.REACT_APP_AJAX_SERVER_HOST;
    }

    // console.log('------------------------------')
    // --------------------
    // new configuration
    // --------------------
    // b2a: /sign
    // b2c: /wechat/sign
    if (process.env.REACT_APP_FOR_BACKEND === 'true') {
      let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || '/bjlife';
      let location = `${url.protocol}//${hostname}${port}${context}`;
      if (relativePath) {
        return location + relativePath;
      } else {
        return location;
      }
    } else {
      let isLocal =
        window.location.origin.includes('localhost') ||
        window.location.origin.includes('127.0.0.1');
      let currEnv = ''; //isLocal ? this.isDesk() ? '/b2c' : window.location.pathname.includes('wechat') ? '/b2c' : '/b2a' : ''
      let context =
        process.env.REACT_APP_AJAX_SERVER_CONTEXT + currEnv + relativePath ||
        '/bjlife' + currEnv + relativePath;
      let location = `${url.protocol}//${hostname}${port}${context}`;
      return location;
    }
    // console.log('------------------------------')

    // --------------------
    // old configuration
    // --------------------
    // let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || '/bjlife'
    // let location = `${url.protocol}//${hostname}${port}${context}`;
    // if (relativePath) {
    // 	return location + relativePath;
    // } else {
    // 	return location;
    // }
  }
  getContextLocation(relativePath) {
    let contextPath = null;
    let path = window.location.pathname;
    let secondIndex = window.location.pathname.indexOf('/', 1);
    if (secondIndex === -1) {
      // no context path
      contextPath = '';
    } else {
      contextPath = path.substring(0, secondIndex);
    }
    if (relativePath) {
      return `${contextPath}${relativePath}`;
    } else {
      return contextPath;
    }
  }
  getPageSize() {
    return 10;
  }
  getMobilePageTransitionTimeout() {
    return 400;
  }
  //获取验证码
  getVerificationCode(phoneNum, element) {}
  application() {
    if (arguments.length === 1) {
      this.rootApp = arguments[0];
    } else {
      return this.rootApp;
    }
  }
  isDesk() {
    return this.desktop || this.ipad || this.tablet;
  }
  // isSimulator() {
  // 	const platform = window.navigator.platform;
  // 	if ($('html').hasClass('env-mobile')) {
  // 		if (platform === 'MacIntel') {
  // 			return true;
  // 		} else if (platform.indexOf('Win') !== -1) {
  // 			// TODOD 权宜之计
  // 			return true;
  // 		}
  // 	}
  // 	return false;
  // }
  holdAccount(signinAccount, account) {
    if (this.app) {
      Storage.Account.writeToLocal(
        Consts.ACCOUNT_SIGN_KEY,
        $.extend(
          {
            menus: account.menus,
          },
          account.account
        )
      );
    }

    if (account.wxUser && account.wxUser.nickName) {
      Storage.Account.writeToLocal(
        Consts.ACCOUNT_SIGN_KEY,
        $.extend(
          {
            menus: account.menus,
            nickName: account.wxUser.nickName,
          },
          account.account
        )
      );
    }
    if (account.vipCustomerInfo) {
      Storage.Account.writeToLocal(
        Consts.ACCOUNT_SIGN_KEY,
        $.extend(
          {
            menus: account.menus,
            vipCustomerInfo: account.vipCustomerInfo,
          },
          account.account
        )
      );
    }
    Storage.Account.writeToSession(
      Consts.ACCOUNT_KEY,
      $.extend(
        {
          menus: account.menus,
        },
        account.account
      )
    );
  }
  findAccount() {
    let account = null;
    if (this.app) {
      account = Storage.Account.getFromLocal(Consts.ACCOUNT_SIGN_KEY);
    } else {
      account = Storage.Account.getFromSession(Consts.ACCOUNT_KEY);
    }
    return account || {};
  }
  isSystemOwnerAdmin(account) {
    account = account || this.findAccount();
    const tenantId = account.tenantId;
    return (
      tenantId + '' === Consts.TENANT_IDS.SYSTEM_OWNER &&
      this.isTenantAdmin(account)
    );
  }
  isTenantAdmin(account) {
    account = account || this.findAccount();
    return !account.organizationId && this.isAdmin(account);
  }
  isBizTenantAdmin() {
    const account = this.findAccount();
    const tenantId = account.tenantId + '';
    return (
      this.isTenantAdmin(account) &&
      tenantId !== Consts.TENANT_IDS.SYSTEM_OWNER &&
      tenantId !== Consts.TENANT_IDS.VIRTUAL
    );
  }
  isOrganAdmin(account) {
    account = account || this.findAccount();
    return account.organizationId && this.isAdmin(account);
  }
  isAdmin(account) {
    account = account || this.findAccount();
    return account.type + '' === Consts.ACCOUNT_TYPE.ADMIN;
  }
  isBizAdmin() {
    const account = this.findAccount();
    const tenantId = account.tenantId + '';
    return (
      this.isAdmin(account) &&
      tenantId !== Consts.TENANT_IDS.SYSTEM_OWNER &&
      tenantId !== Consts.TENANT_IDS.VIRTUAL
    );
  }
  isCurrentAccountUnderOrgan() {
    return this.findAccount().organizationId != null;
  }
  isVirtualTenant() {
    return this.findAccount().tenantId + '' === Consts.TENANT_IDS.VIRTUAL;
  }
  _iphone() {
    return $('html').hasClass('env-iphone');
  }
  _ipod() {
    return $('html').hasClass('env-ipod');
  }
  _ipad() {
    return $('html').hasClass('env-ipad');
  }
  isIOS() {
    return this._iphone() || this._ipod() || this._ipad();
  }
  isAndroid() {
    return $('html').attr('class').indexOf('android') !== -1;
  }

  /**
   * 判断是否是iOS
   * @returns {boolean} true：iOS
   */
  isiOSNew() {
    let ua = navigator.userAgent;
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  }

  /**
   * 判断是否是Android
   * @returns {boolean} true：Android
   */
  isAndroidNew() {
    let ua = navigator.userAgent;
    return ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
  }

  /**
   * 判断是否是京保通
   * @returns {boolean} true：京保通
   */
  isJingBaoTong() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.match(/JingBaoTong/i) == 'jingbaotong';
  }

  /**
   * 判断是否是京保通(UserAgent不包含JingBaoTong版本)
   * @returns {boolean} true：京保通
   */
  isEBao() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.match(/EBao/i) == 'ebao';
  }

  is_weixin() {
    let ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
  }
  //本地 add by wendy.gu 2018 Jan.4
  is_local() {
    let host = window.location.host;
    return host.includes('localhost') || host.includes('127.0.0.1');
  }
  //开发环境 add by wendy.gu 2018 Jan.4
  is_dev() {
    let host = window.location.host;
    if (
      host.includes('bjlife-dev') ||
      host.includes('bjlife-b2c.ebaocloud.com') ||
      host.includes('10.0.65.16')
    ) {
      return true;
    } else {
      return false;
    }
  }
  //测试环境 add by wendy.gu 2018 Jan.4
  is_tst() {
    let host = window.location.host;
    if (host.includes('bjlife-tst')) {
      return true;
    } else {
      return false;
    }
  }
  //UAT add by wendy.gu 2018 Jan.4
  is_uat() {
    let host = window.location.host;
    //if (host.includes('bjlifeuat-b2c') || host.includes('uat-b2a.beijinglife') || host.includes('10.0.66.47') || host.includes('10.0.65.16')) {
    if (
      host.includes('bjlifeuat-b2c') ||
      host.includes('uat-b2a.beijinglife') ||
      host.includes('uat-b2c.beijinglife') ||
      host.includes('10.0.66.47')
    ) {
      return true;
    } else {
      return false;
    }
  }
  is_prod() {
    return (
      window.location.hostname === 'b2a.beijinglife.com.cn' ||
      window.location.hostname === 'b2c.beijinglife.com.cn' ||
      window.location.hostname === 'b2e.beijinglife.com.cn'
    );
  }
  //判断B2A的sta测试环境
  is_b2a_sta_test() {
    return window.location.hostname === 'sta-b2a.beijinglife.com.cn';
  }
  //判断B2A的验收环境
  is_b2a_ys_test() {
    return window.location.hostname === 'ys-b2a.beijinglife.com.cn';
  }
  //判断B2C的验收环境
  is_b2c_ys_test() {
    return window.location.hostname === 'ys-b2c.beijinglife.com.cn';
  }
  // 判断B2A的开发环境
  is_b2a_dev() {
    let hostname = window.location.hostname;
    return (
      hostname === '211.159.242.105' ||
      hostname === '10.0.66.47' ||
      hostname === '10.12.2.124'
    );
  }
  //判断B2A的dev测试环境
  is_b2a_dev_test() {
    let host = window.location.host;
    if (host.includes('10.0.66.47')) {
      return true;
    } else {
      return false;
    }
  }
  //判断B2C的开发环境
  is_b2c_dev() {
    let host = window.location.host;
    if (host.includes('bjlife-b2c')) {
      return true;
    } else {
      return false;
    }
  }
  //判断B2C的UAT环境
  is_b2c_uat() {
    let host = window.location.host;
    if (host.includes('uat-b2c.beijinglife')) {
      return true;
    } else {
      return false;
    }
  }
  //判断B2C的开发测试环境
  is_b2c_dev_test() {
    let host = window.location.host;
    if (host.includes('dev-b2c.beijinglife')) {
      return true;
    } else {
      return false;
    }
  }
  //判断B2C的sta测试环境
  is_b2c_sta_test() {
    let host = window.location.host;
    if (host.includes('sta-b2c.beijinglife')) {
      return true;
    } else {
      return false;
    }
  }
  //判断B2A的生产环境
  is_b2a_prod() {
    let host = window.location.host;
    if (host == 'b2a.beijinglife.com.cn') {
      return true;
    } else {
      return false;
    }
  }
  //判断B2C生产环境
  is_b2c_prod() {
    let host = window.location.host;
    if (host == 'b2c.beijinglife.com.cn') {
      return true;
    } else {
      return false;
    }
  }

  pushMsgToApp(options) {
    if (options && this.isJingBaoTong()) {
      try {
        let msg = JSON.stringify(options);
        if (this.isAndroidNew()) {
          window.__EBAO_JSSDK.callNative(msg);
        } else if (this.isiOSNew()) {
          window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(msg);
        }
      } catch (e) {
        console.log('Push message to app failed!');
        console.log({ e });
      }
    }
  }

  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  getConfiguration() {
    if (!this._configuration) {
      this._configuration = Object.freeze({
        title: process.env.REACT_APP_TITLE,
        /** 后台服务器 */
        server: process.env.REACT_APP_AJAX_SERVER_HOST,
        /** 是否开启调试 */
        debug: process.env.REACT_APP_DEBUG_DISABLED === 'false',
      });
    }
    return this._configuration;
  }

  /**
   * 腾讯数据万象
   * @see https://cloud.tencent.com/document/product/436/59408
   */
  getCOSHTMLParams() {
    return base64Encode(
      JSON.stringify({
        mode: 'simple',
        commonOptions: {
          isShowHeader: false,
          isShowTopArea: false,
          // isBrowserViewFullscreen: true,
          // isIframeViewFullscreen: true,
        },
        wordOptions: {
          isShowDocMap: false,
          isShowBottomStatusBar: false,
        },
        pdfOptions: {
          isShowComment: false,
          // isInSafeMode: true,
          isShowBottomStatusBar: false,
        },
        pptOptions: {
          isSlidesStatusPlay: true,
          isShowBottomStatusBar: false,
        },
      })
    );
  }

  /**
   * 腾讯数据万象
   * @see https://cloud.tencent.com/document/product/436/54059
   */
  getCOSSearchParams() {
    const searchParams = new URLSearchParams();
    searchParams.append('ci-process', 'doc-preview');
    searchParams.append('dstType', 'html');
    searchParams.append('copyable', '0');
    searchParams.append('htmlParams', this.getCOSHTMLParams());
    return searchParams;
  }
}

let envs = new Envs();
export default envs;
