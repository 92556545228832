import { React } from '../../../../common/3rd';
import { Alert, PageLoading } from '../../../components';
import { ReactRouterContextWidget } from '@/component/components';
import { Envs, Utils } from '../../../../common/common';
class CancelInsurance extends ReactRouterContextWidget {
  render() {
    return (
      <div className="btn-con">
        <div className="btn btn1" onClick={this.cancel}>
          放弃投保
        </div>
      </div>
    );
  }
  cancel = () => {
    //放弃投保　修改保存状态为‘未核保’31
    let model = this.props.parent.props.parent.props.model;
    //2018-02-05 add by susan 若状态为88（已作废）则不用再修改状态
    if (model.policyStatus !== '88') {
      model.policyStatus = 31;
    }
    PageLoading && PageLoading.start();
    let self = this;
    //2018-01-11 update by susan 先调用先删除接口，删除成功后在调用update接口并跳转
    this.props.parent.props.parent
      .doGet(`/rest/v1/policy/deleteCore/${model.quotationNo}`, model)
      .done((data) => {
        if (data.body.resultCode === 'Y') {
          PageLoading && PageLoading.start();
          self.props.parent.props.parent
            .doPost('/rest/v1/policy/update', model)
            .done((data) => {
              if (Envs.findAccount().accountName.indexOf('B2E') > -1) {
                // self.pushRedirect(Envs.PATH.MOBILE_LIFE_HOMEDN);
              } else {
                self.pushRedirect(Envs.PATH.MOBILE_LIFE_HOME);
              }
            })
            .always(() => {
              PageLoading.end();
            });
        } else if (data.body.resultCode === 'N') {
          Alert.message([data.body.resultReason]);
        }
      })
      .always(() => {
        PageLoading.end();
      });
  };
}
/**
 * 加载中
 * 例：自动核保失败
 * add by wendy.gu 2017 Oct.19
 *
 */
class UnderwritingFail extends React.Component {
  render() {
    let policyStatus =
      this.props.parent.props.parent.props.parent.getModel().policyStatus;
    let enableClassName = policyStatus === '88' ? ' enable' : '';
    this.canModify = policyStatus === '88' ? false : true;
    let { thirdPartyReturnUrl } = Utils.getUrlProps();
    let insuedsName = this.props.insuredsName;
    let reasons = this.props.reasons || [];
    return (
      <div className="underwriting-fail">
        <div className="title">
          <span className="promptIcon"></span>
          本次投保违反了以下核保规则：
        </div>
        <div className="failCause">
          {reasons.map((item, index) => {
            return (
              <p className="causeItem" key={index}>
                {item}
              </p>
            );
          })}
        </div>
        <div className="btns-container">
          {thirdPartyReturnUrl ? null : <CancelInsurance parent={this} />}
          <div className={`btn-con ${enableClassName}`}>
            <div className="btn btn1" onClick={this.back}>
              返回修改
            </div>
          </div>
          {thirdPartyReturnUrl ? null : (
            <div className={`btn-con ${enableClassName}`}>
              <div className="btn btn2" onClick={this.artificial}>
                继续提交
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  back = () => {
    if (!this.canModify) {
      return;
    }
    this.props.parent.props.parent.props.parent.jumpTo('policyholderInput');
  };
  artificial = () => {
    if (!this.canModify) {
      return;
    }
    const self = this;
    Alert.ask('该保单即将转入线下，走人工核保流程！', function (type) {
      if (type === '2') {
        //2表示点击确认
        self.props.toArtificial();
      }
    });
  };
}

export default UnderwritingFail;
