import { React } from '../../../../common/3rd';
//import { PageLoading,Alert } from '../../../components'
import AtomicMutableComponentThing from '../atomic-mutable-component';
//import { format } from '../something-utils'

/**
 * 转帐声明
 * 例：支付信息录入页
 * add by wendy.gu 2017 Oct.17
 *
 */
class Statement extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.state.isRead = false;
  }
  render() {
    // let readClassName = this.state.isRead ? "read read-ck" : "read";
    let id = this.state.isRead ? 'read-ck' : '';
    return (
      <div className="statement">
        <div className="statement-title">- 转账信息支付声明 -</div>
        <div className="statement-content">
          <span className="statement-content-t">
            保险款项自动转帐授权与声明：
          </span>
          <br />
          本人（投保人）同意以银行自动转帐方式交纳首期及其他保险费，并且授权北京人寿保险有限公司及本人指定之银行自本人下列帐户划拨当期应交保险费及其他保险费。如有溢交、其他支付、退费或保险利益也退还至上述授权帐号。
        </div>
        <div className="statement-content-t" onClick={this.clickRead}>
          <span id={id} className="read"></span>
          本人已阅读并同意以上银行自动转帐授权声明
        </div>
      </div>
    );
  }
  clickRead = () => {
    this.setState({
      isRead: !this.state.isRead,
    });
  };

  getValueFromModel() {
    return this.state.isRead;
  }
}

export default Statement;
