import { React, $ } from '../../common/3rd';

import { Widget } from '@/component/components';

class Button extends Widget {
  render() {
    return <a {...this.getProps()}>{this.props.children}</a>;
  }
  getProps() {
    let props = $.extend({}, this.props);
    props.className = this.getWidgetClassName('button');
    return props;
  }
}

export default Button;
