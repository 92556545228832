import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  matchPath,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import $ from 'jquery';
import browser from 'jquery.browser';
import deparam from 'jquery-deparam';
import Storages from 'js-storage';
import classNames from 'classnames';

// detect browser agent
const detect = () => {
  return Object.keys(browser)
    .filter((key) => {
      return (
        ['version', 'versionNumber', 'name', 'platform', 'uaMatch'].indexOf(
          key
        ) === -1
      );
    })
    .reduce((classes, key) => {
      let className = `env-${key.replace(' ', '-')}`;

      classes[className] = browser[key];
      return classes;
    }, {});
};
$('html').addClass(classNames(detect()));

const detect2 = () => {
  let host = window.location.host;
  let className = host.includes('b2c') ? 'b2c' : '';
  return className;
};
$('html').addClass(detect2());

export {
  BrowserRouter,
  Route,
  Link,
  Switch,
  Redirect,
  matchPath,
  React,
  lazy as Lazy,
  Suspense,
  ReactDOM,
  PropTypes,
  TransitionGroup,
  CSSTransition,
  $,
  deparam as Deparam,
  classNames as ClassNames,
  Storages,
};
