import { React } from '../../../../common/3rd';
import * as SthUtils from '../something-utils';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import { Utils } from '../../../../common/common';
import { Alert } from '../../../components';
import { isAndroid, isIOS } from '@/util';

/**
 * 输入框 带图标
 * add by wendy.gu 2017 Dec.27
 */
class TextIcon extends AtomicMutableComponentThing {
  componentDidMount() {
    Utils.installToEventEmitter({
      event: 'bankOCRCallback',
      func: this.bankOCRCallback,
    });
  }
  componentWillUnmount() {
    Utils.uninstallFromEventEmitter({
      event: 'bankOCRCallback',
      func: this.bankOCRCallback,
    });
  }
  render() {
    //银行卡号
    let iconCls = this.getLayout().iconCls;
    let isThirdParty = Utils.isThirdParty();
    return (
      <div {...this.getPrototypes()} id="sth-text">
        <input
          type="text"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.doCheckAndAlert}
          ref="me"
          className="sth-input-icon"
          id="sth-input-icon"
        />
        {isThirdParty ? (
          ''
        ) : (
          <div className={iconCls} onClick={this.getOCR}></div>
        )}
      </div>
    );
  }
  //测试OCR
  getOCR = () => {
    let options = {
      action: 'scanBankCard',
      success: 'bankOCRCallback',
    };
    let msg = JSON.stringify(options);
    try {
      if (isAndroid()) {
        window.__EBAO_JSSDK.callNative(msg);
      } else if (isIOS()) {
        window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(msg);
        // this.bankOCRCallback('{"cardNumber":"111111111111"}');//test
        // window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(json);
      }
    } catch (e) {}
  };
  //银行卡OCR 回调处理
  bankOCRCallback = (result) => {
    result = typeof result === 'string' ? JSON.parse(result) : result;
    if (!result || result.success == false) {
      Alert.message('OCR失败');
      return;
    }
    result = result.msg ? result.msg : result;
    let model = this.getRootModel();
    model.extensionData.paymentInfo.bankCard =
      typeof result.cardNumber === 'string'
        ? result.cardNumber.replace(/\s/g, '')
        : result.cardNumber;
    this.getRootComponent().forceUpdate();
  };
  /**
   * 从模型中获取值
   * @param {string} id 可以不指定, 则使用getId()的返回值
   */
  getValueFromModel(id) {
    let value = SthUtils.getValueFromModel(this.getModel(), id || this.getId());
    if (this.getLayout().isBankCard === true && value) {
      value = value.replace(/(\d{4})(?=[^\s])/g, '$1 ');
    }
    return value;
  }
  /**
   * 设置指定值到模型中. 如果指定force, 则一定会设置; 否则会比较模型值与被设置的值, 相等则忽略此操作
   * 如果操作被忽略, 返回false
   *
   * @param {any} value
   * @param {function} callback
   * @param {boolean} force
   * @param {string} id 可选项, 如果没有, 则使用getId()
   */
  setValueToModel(value, callback, force, id) {
    if (this.getLayout().isBankCard === true) {
      value = value.replace(/\s/g, '');
    }
    const ret = SthUtils.setValueToModel(
      this.getModel(),
      id || this.getId(),
      value,
      force
    );
    if (ret !== false) {
      // 如果成功设置, 触发事件
      this.getEventEmitter().emit(id || this.getId(), {
        model: this.getModel(),
        from: this,
      });
    }
    if (callback) {
      // 回调
      callback.call(this);
    }
    return ret;
  }

  // 事件
  /**
   * 得到焦点
   */
  onFocus = (evt) => {
    this.selectAll();
  };
  getChangeHandlingDelay() {
    return 300;
  }
  /**
   * 全选
   */
  selectAll() {
    this.me().select();
  }
  /**
   * 获取支持的原生属性名称, 不包含事件
   */
  getPrototypeNames() {
    return ['placeholder', 'readOnly', 'disabled'];
  }
  getDefaultClassName() {
    return 'sth-text';
  }
}

export default TextIcon;
