import { React, ReactDOM } from '../../../../common/3rd';
// import AtomicComponentThing from '../atomic-component';
// import {provsData} from '../../../../common/larea-data';
import AtomicMutableComponentThing from '../atomic-mutable-component';

// import Text from '../component/text';

class Checkbox extends React.Component {
    constructor() {
        super();
        this.state = {
            remarkValue: "",
        };
    }

    UNSAFE_componentWillMount() {
        if (this.isActive()) {
            this.setState({
                remarkValue: this.isActive().split(":")[1] || ""
            });
            setTimeout(() => {
                if (this.refs.checkbox) {
                    ReactDOM.findDOMNode(this.refs.checkbox).checked = true;
                }

            }, 10);
        }

    }

    isActive() {
        let selectedValue = this.props.selectedValue.split("/");
        for (let i = 0; i < selectedValue.length; i++) {
            if (selectedValue[i].indexOf(this.getCheckboxValue()) >= 0) {
                return selectedValue[i];
            }
        }
        return false;
    }

    renderRemark() {
        if (!this.props.model.remarkName) {
            return null;
        }
        let remarkClass = 'sth-radio-remark';
        if (this.isActive()) {
            remarkClass += ' sth-radio-remark-show';
        } else {
            remarkClass += ' sth-radio-remark-hide';
        }
        return (
            <div className={ remarkClass }
                 onClick={ this.onRemarkClicked }>
                <div className='sth-radio-remark-name'>{ this.props.model.remarkName }</div>
                <input className='sth-radio-remark-input'
                       ref='checkboxRemark'
                       type='text'
                       value={ this.state.remarkValue }
                       onChange={ this.onCheckboxRemarkChanged }/>
            </div>
        );
    }

    onRemarkClicked = (e) => {
        e.stopPropagation();
    };
    onCheckboxRemarkChanged = (e) => {
        const targetValue = e.target.value;
        console.log(targetValue);
        this.setState({
            remarkValue: targetValue
        }, () => {
            this.props.onCheckboxWarpClicked();
        });
    };

    render() {
        return (
            <div className="sth-declara-radio-warp" onClick={ this.onCheckboxClicked }>
                <input className='sth-declara-radio-point'
                       onClick={ this.onInputClicked }
                       ref="checkbox" type='checkbox'
                       name={ this.props.name }
                       value={ this.props.model.checkboxValue }/>
                <div className='sth-declara-radio-label'>{ this.getCheckboxValue() }</div>
                { this.renderRemark() }
            </div>
        );
    }

    isSelected() {
        return ReactDOM.findDOMNode(this.refs.checkbox).checked;
    }

    getCheckboxValue() {
        return this.props.model.checkboxValue;
    }

    getCheckboxRemark() {
        return this.state.remarkValue;
    }

    onCheckboxClicked = () => {
        ReactDOM.findDOMNode(this.refs.checkbox).checked = !this.isSelected();
        this.props.onCheckboxWarpClicked();
    }
    onInputClicked = (e) => {
        e.stopPropagation();
        this.props.onCheckboxWarpClicked();
    }
}

class DeclaraCheckbox extends AtomicMutableComponentThing {
    constructor(props, context) {
        super(props, context);
        this.state.selectedValue = "";
    }

    UNSAFE_componentWillMount() {
        this.setState({
            selectedValue: this.getValueFromModel().remark,
        });
    }

    renderCheckBox() {
        const checkboxs = this.getLayout().checkboxs;
        const checkboxName = this.getLayout().code;
        // let policyDeclaraModel = this.getValueFromModel();
        if (!checkboxs) {
            return null;
        }
        return checkboxs.map((checkbox, index) => {
            return <Checkbox key={ `${ checkboxName }-${ index }` }
                             ref={ `${ checkboxName }-${ index }` }
                             model={ checkbox }
                             selectedValue={ this.state.selectedValue }
                             parent={ this }
                             onCheckboxWarpClicked={ this.onCheckboxWarpClicked }
                             name={ checkboxName }/>;
        });
    }

    render() {
        return (
            <div { ...this.getPrototypes() } ref='me'>
                <div className='sth-label-name'>{ this.getLayout().label }</div>
                <div className='sth-radio-warp'>
                    { this.renderCheckBox() }
                </div>
            </div>
        );
    }

    onCheckboxWarpClicked = () => {
        let selectedValue = "";
        const checkboxs = this.getLayout().checkboxs;
        const checkboxName = this.getLayout().code;
        for (let i = 0; i < checkboxs.length; i++) {
            const checkboxComponent = this.refs[`${ checkboxName }-${ i }`];
            if (checkboxComponent.isSelected()) {
                if (selectedValue) {
                    selectedValue += "/";
                }
                selectedValue += checkboxComponent.getCheckboxValue();
                if (checkboxComponent.getCheckboxRemark()) {
                    selectedValue += ":" + checkboxComponent.getCheckboxRemark();
                }
            }
        }
        this.setState({
            selectedValue: selectedValue
        });
        this.getValueFromModel().remark = selectedValue;
    };

    getDefaultClassName() {
        return 'sth-declara-radio';
    }
}


export default DeclaraCheckbox;