import { React } from '../../../common/3rd';
import { Utils } from '../../../common/common';
import { Widget } from '@/component/components';

import Policy from './policy';

import './policy.scss';

/**
 * policy list
 */
class PolicyList extends Widget {
  renderPolicy(policy, index) {
    return <Policy policy={policy} parent={this} key={`policy-${index}`} />;
  }
  render() {
    return (
      <div className="policy-list">
        {this.getItems().map((item, index) => {
          return this.renderPolicy(item, index);
        })}
      </div>
    );
  }
  getItems() {
    let items = this.getPropsValue('items');
    return items == null ? [] : items;
  }
  shouldGoToDetail() {
    Utils.notifyParentShouldGoToDetail(this);
  }
}

export default PolicyList;
