var htmlStr = '<div>' +
    '   <div id="hintPage" width="100%" style="background: #FFFFFF;">' +
    '        <div id="hintContent" width="100%" style="background: #FFFFFF;">' +
    '            <div style="position: relative; " class="page-content" >' +
    '               <div>' +
    '                    <table width="100%">' +
    '                        <tr>' +
    '                            <td valign="middle" width="25%" >' +
    '                                <img id="logImage" width="100%" src="" />' +
    '                            </td>' +
    '                            <td valign="middle" width="15%">' +
    '                            </td>' +
    '                            <td valign="middle" align="right"  width="45%">' +
    '                                <div id="insuranceConfirm_policy_id" style="font-size: 20px;"></div>' +
    '                            </td>' +
    '                        </tr>' +
    '                    </table>' +
    '                </div>' +
    '                <div align="center"' +
    '                     style="width: 100%; font-size: 17px; height: 17px; line-height: 17px; position: absolute;margin-top: 15px">电子投保申请确认书</div>' +
    '                <br />' +
    '                <br />' +
    '                <!-- 保单正文 queryForm-->' +
    '                <p style="width: 100%; text-indent: 2em">1.本人确认北京人寿保险股份有限公司（以下简称“贵公司”）及贵公司代理人已提供本人所投保产品的条款、并对条款进行说明，尤其对责任免除条款、合同解除条款进行了提示和明确说明。本人对所投保产品条款及产品说明书已认真阅读、理解并遵守。</p>' +
    '                <p style="width: 100%; text-indent: 2em">2.本人确认本次签署《电子投保申请确认书》信息与通过电子化投保提交贵公司的《人身保险单（电子版）》信息，均为本人自愿提供的真实信息且两者内容完全一致。如信息不一致，贵公司将保留重新审核本人此次投保申请的权利。重新审核后承保条件和生效时间可能会有改变，具体以保险单载明为准。</p>' +
    '                <p style="width: 100%; text-indent: 2em">3.如本人的保险合同在投保时经贵公司审核同意承保并收费、签发电子保单的，保险合同予保单签发后次日零时起生效，投保信息均以贵公司收到的《人身保险单（电子版）》信息及签发给本人的电子保单所载信息为准。</p>' +
    '                <p style="width: 100%; text-indent: 2em">4.本人确认已知晓：一年期主险和一年期附加险的保险期间为一年，选择自动申请续保方式时，如贵公司审核后同意续保，收取保险费后保险合同、附加险合同继续有效；如贵公司审核后不同意续保，不再收取保险费，保险合同、附加险合同满期终止；如本人决定终止续保，应于一年期主险和一年期附加险满期日前亲自办理，或委托贵公司服务人员办理终止续保手续。</p>' +
    '                <p style="width: 100%; text-indent: 2em">5.本人同意并授权贵公司查阅、复印任何与被保人健康、财务等情况有关的体检报告、诊断报告、病历、收入证明等资料；可以从任何单位、组织和个人就有关保险事宜查询、索取与本人有关的资料和证明，贵公司对本人的个人资料承担保密义务。</p>' +
    '                <p style="width: 100%; text-indent: 2em">6.本人确认已知晓：生存保险金在本人不申请领取的情况下留存贵公司进行累积生息，保险合同效力中止期间或合同效力终止后，生存金将停止计息。</p>' +
    '                <p style="width: 100%; text-indent: 2em">7.本人确认已知晓：本确认书及电子投保书均不作为收取现金的凭证。</p>' +
    '                <p style="width: 100%; text-indent: 2em">8.本人确认已知晓：如本人投保的险种为投资连结险，则本人已在《人身保险单（电子版）》中完成了贵公司的《投资连结保险风险承受能力调查问卷》调查，且结果显示最终风险承受能力类型不为保守型。</p>' +
    '                <p style="width: 100%; text-indent: 2em">9.本人确认已知晓：如本人需要获取《产品建议书》和《人身保险单（电子版）》，本人可以从贵公司代理人处获取上述文档的打印件、复印件或PDF格式文件。</p>' +
    '                <p style="width: 100%; text-indent: 2em">10.转账授权：</p>' +
    '                <p style="width: 100%; text-indent: 2em">（1）本投保单中所填开户银行及账号是本人的开户银行及账号，本人授权贵公司从该账户转账支付与贵公司约定的各期保险费。</p>' +
    '                <p style="width: 100%; text-indent: 2em">（2）本人同意，如果因授权账户错误、账户注销、账户金额不足或授权账户不符合贵公司对授权账户要求而导致转账不成功，致使合同不能成立或不能持续生效，贵公司无须承担责任。</p>' +
    '                <p style="width: 100%; text-indent: 2em">（3）本人同意，贵公司将多收的保险费及拒保、延期、撤单、退保等引起的退费通过该账户返还给本人。</p>' +
    '                <p style="width: 100%; text-indent: 2em">（4）本人同意，若投保的万能险有万能账户抵交保费功能，当续期保费欠缴时，贵公司可优先从该万能账户价值中抵交保费。</p>' +
    '                <p style="width: 100%; text-indent: 2em">（5）本授权为本人对贵公司从本人所提供的账号中扣款的授权证明，不作为收取现金的凭据。</p>' +
    '                <p style="width: 100%; text-indent: 2em">11.本电子投保申请确认书的内容均为本人真实意愿，本人确认条形码为<span id="proposalCode"></span>的《人身保险单（电子版）》内容中的投保人、被保人及身故受益人信息、投保事项信息均准确无误，健康、财务及其他告知内容属实，与本次投保相关的问卷、体检报告书及对体检医生的各项陈述确实无误。如有不实告知，贵公司有权依法解除保险合同，并对合同解除前发生的保险事故不承担保险责任。</p>' +
    '                <br />' +
    '                <div style="margin-left: 15px; width: 100%; text-indent: 2em">' +
    '                    <span >投保人签名:</span><img  id="imageApp" style="width:15%;"/>' +
    '                    <span >被保人/监护人签名:</span><img  id="imageIns" style="width:15%;"/>' +
    '                    <span >其他被保人/监护人签名:</span><img  id="imageInsOther" style="width:15%;"/>' +
    '                </div>' +
    '                <br />' +
    '                <br />' +
    '                <div style="margin-left: 15px; width: 100%; text-indent: 2em"> 投保申请日期:<span id="applyDate"></span>' +
    '                </div>' +
    '            </div>' +
    '            <br />' +
    '            <br />' +
    '            <br />' +
    '        </div>' +
    '    </div>' +
    '</div>';
export default htmlStr;