import { React, ReactDOM, $ } from '../../../common/3rd';
import Lang from '../../../common/lang';
import './Popup.scss';

import messages from './messages.json';
// install i18n messages
Lang.installMessages(messages, 'alert');

const Timer = require('../../../util/PaymentTimer');

const initState = {
  open: false,
  hasSent: false,
  done: false,
  traceNum: null,
  codeNo: '',
  errMsg: null,
  restTime: null,
};

/**
 * // 11/01/2018 - TAG: by yanzhi.mo - 短信验证（银行卡支付）
 *
 * @class SmsVerified
 * @extends {React.Component}
 */
class SmsVerified extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initState };
  }

  componentWillUnmount() {
    this._timer && this._timer.stop(this._timerKey);
  }

  init(ctx, options, callback) {
    this._ctx = ctx;
    this._options = options;
    this._callback = callback;
    this.setState({ open: true });
  }

  /**
   * 获取验证码
   */
  handleAuth() {
    let has5xxError = true;

    // 12/01/2018 - TAG: by yanzhi.mo - 获取计时
    const now = new Date();
    now.setSeconds(now.getSeconds() + 60); // 60s
    const expiration = now.getTime();
    const timer = (this._timer = new Timer(expiration));
    const timerKey = (this._timerKey = this._timer.run(({ secsRest, done }) => {
      this.setState({
        restTime: `${secsRest}s`,
        hasSent: true,
      });
      if (done) {
        this.setState({ hasSent: false });
      }
    }));

    // 12/01/2018 - TAG: by yanzhi.mo - 发起调用
    this._options.loading.start();
    this._ctx
      .doPost('/rest/v1/payment/auth', this._ctx.getModel())
      .done((data) => {
        has5xxError = false;

        if (data && data.code === '00000') {
          // 11/01/2018 - TAG: by yanzhi.mo - 获取成功
          this.setState({ traceNum: data.traceNum });
        } else {
          // 11/01/2018 - TAG: by yanzhi.mo - 获取失败
          this.setState({ errMsg: '获取验证码失败' });
        }
      })
      .always(() => {
        this._options.loading.end();
        if (has5xxError) {
          this._timer && this._timer.stop(this._timerKey);
        }
      });
  }

  /**
   * 验证码校验，若通过直接完成支付
   */
  handleVerification(traceNum, codeNo) {
    let has5xxError = true;

    if (!codeNo) {
      this.setState({ errMsg: '请输入验证码' });
      return;
    }

    if (codeNo.length !== 6) {
      this.setState({ errMsg: '验证码为6位数字' });
      return;
    }

    //初始设置支付状态为‘待支付’
    if (!this._ctx.getModel().policyStatus) {
      this._ctx.getModel().policyStatus = '3';
    }
    this._options.loading.start();
    this._ctx
      .doPost(
        `/rest/v1/payment/pay?traceNum=${traceNum}&codeNo=${codeNo}`,
        this._ctx.getModel()
      )
      .done((data) => {
        has5xxError = false;
        this._options.loading.end();

        if (data.paymentStatus == '0') {
          // 12/01/2018 - TAG: by yanzhi.mo - 支付失败
          var msg = Lang.messages.payment[data.paymentCode];
          this.setState(
            { ...initState },
            () => this._callback && this._callback(data.paymentStatus, msg)
          );
        } else if (data.paymentStatus == '2') {
          // 12/01/2018 - TAG: by yanzhi.mo - 支付中
          this.setState(
            { ...initState },
            () => this._callback && this._callback(data.paymentStatus, msg)
          );
        } else if (data.paymentStatus == '1') {
          // 12/01/2018 - TAG: by yanzhi.mo - 支付成功
          this.setState(
            { ...initState },
            () => this._callback && this._callback(data.paymentStatus, msg)
          );
        }
      })
      .always(() => {
        if (has5xxError) {
          this._options.loading.end();
        }
        // 16/01/2018 - TAG: by yanzhi.mo - 取消计时
        this._timer && this._timer.stop(this._timerKey);
      });
  }

  render() {
    return (
      <div style={{ display: this.state.open ? 'block' : 'none' }}>
        <div
          id="mask"
          onClick={() => {
            this.setState({
              ...initState,
              traceNum: this.state.traceNum,
            });
            // this._timer && this._timer.stop(this._timerKey)
          }}
          style={{ zIndex: 400 }}
        ></div>
        {this.state.open ? (
          <div>
            <div className="popup-alert" ref="alert" style={{ zIndex: 500 }}>
              <h2 className="alert-title">发送验证码</h2>
              <div className="alert-content">
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        width: '35%',
                        textAlign: 'right',
                        wordBreak: 'keep-all',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      验证码：
                    </div>
                    <div style={{ width: '65%' }}>
                      <input
                        value={this.state.codeNo}
                        onChange={(e) =>
                          this.setState({
                            codeNo:
                              e.target.value.length > 6
                                ? this.state.codeNo
                                : e.target.value,
                          })
                        }
                        type="text"
                        style={{
                          width: '100%',
                          border: '1px solid gray',
                          padding: 0,
                          height: '0.3rem',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  </div>
                  {this.state.errMsg ? (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '0.1rem',
                      }}
                    >
                      {this.state.errMsg}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="alert-btn">
                <div
                  className={this.state.hasSent ? 'button-no' : 'button-yes'}
                  onClick={
                    this.state.hasSent ? null : this.handleAuth.bind(this)
                  }
                  style={{
                    borderBottomLeftRadius: '4px',
                    borderBottomRightRadius: '0',
                    borderRight: '1px solid gray',
                  }}
                >
                  {this.state.hasSent
                    ? `${this.state.restTime}后重新获取`
                    : '发送验证码'}
                </div>
                <div
                  className="button-yes"
                  onClick={this.handleVerification.bind(
                    this,
                    this.state.traceNum,
                    this.state.codeNo
                  )}
                  style={{
                    borderBottomRightRadius: '4px',
                    borderBottomLeftRadius: '0',
                  }}
                >
                  验证
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

let container = $('#alert-verified-container');
if (container.length === 0) {
  container = $('<div id="alert-verified-container">').appendTo(document.body);
}
let smsVerified = ReactDOM.render(
  <SmsVerified />,
  document.getElementById('alert-verified-container')
);

smsVerified.SUCCESS = '1';
smsVerified.FAILED = '0';
smsVerified.PENDING = '2';

export default smsVerified;

const mockReqBody = {
  quotationNo: '10DZ01860000000007',
  premium: 0.01,
  productId: 326,
  extensionData: {
    paymentInfo: {
      bankCard: '1234123412341234',
      bankCardType: 1,
      bankName: '工商银行',
      mobile: '18107744157',
    },
  },
  policyHolder: {
    fullName: '',
    idNo: '',
    idType: '',
  },
};
