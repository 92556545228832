import { React } from '../../../../common/3rd';
import { Envs, Utils } from '../../../../common/common';
import {
    PageLoading,
    Alert
} from '../../../components';
import AtomicMutableComponentThing from '../atomic-mutable-component';

export default class BenefitPresentation extends AtomicMutableComponentThing {
    state = {
        recRec: false,
    };

    componentDidMount() {
        Utils.installToEventEmitter({
            event: "faceVerifyCallback",
            func : this.faceVerifyCallback
        });
    }

    componentWillUnmount() {
        Utils.uninstallFromEventEmitter({
            event: "faceVerifyCallback",
            func : this.faceVerifyCallback
        });
    }

    faceVerifyCallback = result => {
        if (result.success) {
            this.setState({ recRec: true });
        } else {
            Alert.message(result.msg);
            this.setState({ recRec: false });
        }
    };
    renderSucMsg = () => {
        if (this.state.recRec) {
            this.props.model.policyStatus = "80"; //身份验证成功 改变当前状态为‘待支付’ update by wendy.gu 2018 Jan.9
            return <div className="successPrompt"><span className="sucImg"></span>人脸识别成功，请继续操作</div>;
        }
    };

    render() {
        return (
            <div className="face-recognition">
                <div onClick={ () => this.queryFaceRecData() } className="face-button">
                    开始识别
                </div>
                { this.renderSucMsg() }
            </div>
        )
    }

    queryFaceRecData() {
        if (Envs.isIOS()) {
            var url = '/rest/v1/face/ios/params';
        } else if (Envs.isAndroid()) {
            var url = '/rest/v1/face/android/params';
        } else {
            Alert.message('pc terminal');
            var url = '/rest/v1/face/android/params';
            // return
        }
        // PageLoading.start()
        this.doPost(url, null).done((data) => {
            if (data.code === '0') {
                let { firstName, idNo } = this.getModel().policyHolder;
                let options = {
                    action  : 'faceVerify', //固定为”faceVerify”,
                    success : 'faceVerifyCallback', //回调的方法名 如：faceVerifyCallback,
                    nonce   : data.body.nonce, //直接从上面的JSON中获取,
                    appId   : data.body.appId,//直接从上面的JSON中获取,
                    sign    : data.body.sign,//直接从上面的JSON中获取,
                    license : data.body.license,//直接从上面的JSON中获取,
                    version : data.body.version,//直接从上面的JSON中获取,
                    userId  : data.body.userId,//直接从上面的JSON中获取,
                    name    : firstName,//待验证人的姓名,
                    idNumber: idNo //待验证人的身份证号码
                };
                let msg = JSON.stringify(options);
                // alert(msg); //todo del
                console.log(options);
                try {
                    if (Envs.isAndroid()) {
                        // alert("调用人脸识别")
                        window.__EBAO_JSSDK.callNative(msg);
                    } else if (Envs.isIOS()) {
                        window.webkit.messageHandlers.__EBAO_JSSDK.postMessage(msg);
                    }
                } catch (e) {
                    Alert.message(e.message);
                }
            } else {
                Alert.message(data.errMsg);
            }
        }).always(() => {
            PageLoading.end();
        })
    }

    getValueFromModel() {
        return this.state.recRec;
    }
}