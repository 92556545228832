import { React, ClassNames } from '../../common/3rd';

import { Widget } from '@/component/components';

/**
 * tab
 */
class Tab extends Widget {
  state = {
    activeIndex: 0,
  };
  renderItem(label, action, index) {
    let labelDom = null;
    if (typeof label === 'string') {
      labelDom = (
        <span
          className="tab-item-label"
          onClick={this.onItemClicked.bind(this, action, label, index)}
        >
          {label}
        </span>
      );
    } else if (typeof label === 'function') {
      labelDom = label.call(
        this,
        action,
        this.getActionParameters(index),
        index
      );
    }
    return (
      <div className="tab-item" key={`item-${index}`}>
        {labelDom}
      </div>
    );
  }
  renderItems() {
    let labels = this.getLabels();
    let actions = this.getActions();
    return labels.map((label, index) => {
      return this.renderItem(label, actions[index], index);
    });
  }
  render() {
    const className = ClassNames(this.getWidgetClassName('tab'), {
      'active-0': this.state.activeIndex === 0,
      'active-1': this.state.activeIndex === 1,
      'active-2': this.state.activeIndex === 2,
      'active-3': this.state.activeIndex === 3,
      'active-4': this.state.activeIndex === 4,
    });
    return (
      <div className={className} id={this.getDomId()}>
        {this.renderItems()}
        <hr />
      </div>
    );
  }
  getDomId() {
    return this.getPropsValue('id');
  }
  getLabels() {
    let labels = this.getPropsValue('labels');
    return labels == null ? [] : labels;
  }
  getActions() {
    return this.getPropsValue('actions');
  }
  getActionParameters(index) {
    let params = this.getPropsValue('actionParams');
    if (params) {
      return params[index];
    } else {
      return null;
    }
  }
  onItemClicked(action, label, index) {
    const params = this.getActionParameters(index);
    const prevIndex = this.state.activeIndex;
    action.call(this, params, label, index, prevIndex);
    this.setActiveIndex(index);
  }
  setActiveIndex(index) {
    this.setState({
      activeIndex: index,
    });
  }
  getActiveIndex() {
    return this.state.activeIndex;
  }
}

export default Tab;
