import { React, ClassNames } from '../../../common/3rd';
import { Lang } from '../../../common/common';

import { mergeJson } from './something-utils';
import EventEmitter from './event-emitter';
import ContainerThing from './container';
import {
  ComponentThingContext,
  CODES_KEY,
  DECLARATIONS_KEY,
} from './component';

// 组件
import Article from './component/article';
import { Age, Birth, BirthAge } from './component/birth-age';
import Button from './component/button';
import Date from './component/date';
import Dropdown from './component/dropdown';
import Address from './component/address';
import IconLabels from './component/icon-labels';
import { Label, VerticalLabels } from './component/label';
import { Radio, VerticalRadio, RadioButton } from './component/radio';
import { Spread } from './component/spread';
import {
  StepInRow,
  StepInRows,
  SingleStepInRow,
} from './component/step-in-row';
import Text from './component/text';
import Colunm from './component/colunm';
import Number from './component/number';
import TextIcon from './component/text-icon';
import TextArea from './component/text-area';
import { Toggle, LabelToggle } from './component/toggle';
import Statement from './component/statement';
import UploadPrompt from './component/uploadPrompt';
import UploadArea from './component/uploadArea';
import UploadAreaList from './component/uploadAreaList';
import UploadAreaListCustomer from './component/uploadAreaListCustomer';
import UploadAreaListFavoree from './component/uploadAreaListFavoree';
import UploadAreaOtherList from './component/uploadAreaOtherList';
import Image from './component/image';
import ShareSignatureButton from './component/shareSignatureButton';
import DocumentSignatureItem from './component/documentSignatureItem';
import SubmitSignSuccess from './component/submitSignSuccess';
import ShareUrlStatus from './component/shareUrlStatus';
import SignaturePrompt from './component/signaturePrompt';
import SignatureButton from './component/signatureButton';

// 容器
import Cell from './container/cell';

import {
  Panel,
  LeftPanel,
  LeftPanel80,
  LeftPanel80Currency,
  LeftPanel80ImportInfor,
  ClausePanel,
  ShiftPanel,
  DeclarationPanel,
} from './container/panel';
import { ArrayPanel, LeftArrayPanel } from './container/array-panel';
import Wizard from './container/wizard';

// 业务组件
import {
  AdditionalCoverage,
  AdditionalCoverageList,
} from './biz/additional-coverage';
import { AdviceTo } from './biz/advice-to';
import { Duty, Duties } from './biz/duties';
import GuardPoints from './biz/guard-points';
import { PremiumSpread } from './biz/premium-spread';
import ProductInfoHeader from './biz/product-info-header';
import QuoteConditions from './biz/quote-conditions';
import BenefitPresentation from './biz/benefit-presentation';
import ImportCustomer from './biz/import-customer';
import OCRImport from './biz/OCR';
import Declares from './biz/declares';
import DeclaraToggleRemark from './biz/declara-toggle-remark';
import AddBeneficiary from './biz/add-beneficiary';
import AddOtherImage from './biz/add-other-image';
import Underwriting from './component/underwriting';
import StatusPanel from './component/statusPanel';
import LabelText from './biz/label-text';
import LabelTextRight from './biz/label-text-r';
import DeclaraShow from './biz/declara-show';
import verifiMessage from './component/verifiMessage';
import DocumentsShow from './biz/documents-show';
import PersonalInsuranceTips from './component/personalInsuranceTips';
import InsuranceConfirm from './component/insuranceConfirm';
import AgentReport from './component/agentReport';
import DeclaraChildren from './biz/declara-children';
import DeclaraParent from './biz/declara-parent';
import DeclaraRadio from './biz/declara-radio';
import DeclaraCheckbox from './biz/declara-checkbox';
import PersonalTaxPaper from './component/personalTaxPaper';
import InsureOrder from './component/insureOrder';
import JobCategory from './biz/job-category';
import SimpleJob from './biz/simple-job-category';
import Declarations from './biz/declarations';
import Payment from './biz/payment';
import FaceRec from './biz/face-rec';
import PhotoVerification from './biz/photo-verification';
import Nationality from './biz/nationality';

// styles
import './something.scss';

import messages from './messages.json';
Lang.installMessages(messages, 'sth');

// 组件定义
const COMPONENTS = {
  // 基本组件
  age: Age,
  article: Article,
  birth: Birth,
  birthAge: BirthAge,
  button: Button,
  address: Address,
  date: Date,
  dd: Dropdown,
  iconLabels: IconLabels,
  image: Image,
  label: Label,
  radio: Radio,
  ssir: SingleStepInRow,
  sir: StepInRow,
  sirs: StepInRows,
  spread: Spread,
  text: Text,
  colunm: Colunm,
  number: Number,
  textIcon: TextIcon,
  ta: TextArea,
  toggle: Toggle,
  'toggle-l': LabelToggle,
  vlabels: VerticalLabels,
  vradio: VerticalRadio,
  radiobt: RadioButton,
  statement: Statement,
  underwriting: Underwriting,
  personalInsuranceTips: PersonalInsuranceTips,
  insuranceConfirm: InsuranceConfirm,
  agentReport: AgentReport,
  personalTaxPaper: PersonalTaxPaper,
  insureOrder: InsureOrder,
  status: StatusPanel,
  uploadPrompt: UploadPrompt,
  uploadArea: UploadArea,
  uploadAreaList: UploadAreaList,
  uploadAreaListCustomer: UploadAreaListCustomer,
  uploadAreaListFavoree: UploadAreaListFavoree,
  uploadAreaOtherList: UploadAreaOtherList,
  shareSignatureButton: ShareSignatureButton,
  documentSignatureItem: DocumentSignatureItem,
  submitSignSuccess: SubmitSignSuccess,
  shareUrlStatus: ShareUrlStatus,
  signaturePrompt: SignaturePrompt,
  signatureButton: SignatureButton,
  // 容器
  cell: Cell,
  'panel-btn': ClausePanel,
  panel: Panel,
  'panel-l': LeftPanel,
  'panel-l80': LeftPanel80,
  shiftPanel: ShiftPanel,
  declarationPanel: DeclarationPanel,
  'panel-l80-import-infor': LeftPanel80ImportInfor,
  'panel-l80c': LeftPanel80Currency,
  apanel: ArrayPanel,
  'apanel-l': LeftArrayPanel,
  wizard: Wizard,
  // 业务组件
  'ad-cov': AdditionalCoverage,
  'ad-covs': AdditionalCoverageList,
  adviceTo: AdviceTo,
  duty: Duty,
  duties: Duties,
  guardPoints: GuardPoints,
  pspread: PremiumSpread,
  quoteConditions: QuoteConditions,
  benefitPresentation: BenefitPresentation,
  faceRec: FaceRec,
  photoVerification: PhotoVerification,
  importCustomer: ImportCustomer,
  ocrImport: OCRImport,
  productInfoHeader: ProductInfoHeader,
  declares: Declares,
  'declara-toggle-remark': DeclaraToggleRemark,
  'add-beneficiary': AddBeneficiary,
  'label-text': LabelText,
  'label-text-r': LabelTextRight,
  'declara-show': DeclaraShow,
  addOtherImage: AddOtherImage,
  verifiMessage: verifiMessage,
  'documents-show': DocumentsShow,
  'declara-children': DeclaraChildren,
  'declara-parent': DeclaraParent,
  'declara-radio': DeclaraRadio,
  'declara-checkbox': DeclaraCheckbox,
  'job-category': JobCategory,
  'simple-job': SimpleJob,
  nationality: Nationality,
  declarations: Declarations, // added by yanzhi.mo
  payment: Payment, // added by yanzhi.mo
};
// 注册到Container中
ContainerThing.registerComponents(COMPONENTS);

/**
 * Something, for dynamically render page
 * properties:
 * data: a JSON object, cannot be null or undefined
 * layout: a JSON object, cannot be null or undefined
 */
class Something extends ContainerThing {
  static merge(template, special) {
    return mergeJson(template, special);
  }
  constructor(props, context) {
    super(props, context);
    this.state.emitter = this.state.emitter || new EventEmitter();
    this.state.phaseComponents = {
      '--all': [],
    };
    const componentThingContext = this.getComponentThingContext();
    this.state.componentThingContext = componentThingContext;
  }
  getComponentThingContext() {
    return {
      emitter: this.state.emitter,
      root: this.getModel(),
      codes: this.getGlobalCodes(),
      declarations: this.getGlobalDeclarations(),
      phases: this.state.phaseComponents,
      product: this.getProductModel(),
      rootComponent: this,
    };
  }
  renderProductInfoHeader() {
    if (this.isProductHeaderPaint()) {
      return (
        <ProductInfoHeader
          model={this.getProductModel()}
          layout={{}}
          parent={this}
        />
      );
    }
  }
  render() {
    const className = ClassNames('sth', this.getStyle());
    const model = this.getModel();
    return (
      <ComponentThingContext.Provider value={this.state.componentThingContext}>
        <div className={className} ref="me">
          {this.renderProductInfoHeader()}
          {this.getSortedLayout(this.getLayout()).map((cell) => {
            return this.renderCell(model, cell);
          })}
        </div>
      </ComponentThingContext.Provider>
    );
  }
  // 获取属性
  getProductModel() {
    return this.props.product;
  }
  getModel() {
    return super.getModel();
  }
  getLayout() {
    return super.getLayout();
  }
  getStyle() {
    return this.props.astyle || 'sth-default';
  }
  /**
   * 是否要画产品标题
   */
  isProductHeaderPaint() {
    return this.props.productHeader !== false;
  }
  /**
   * 获取事件发射器
   */
  getEventEmitter() {
    return this.state.emitter;
  }
  /**
   * 获取根模型
   */
  getRootModel() {
    return this.getModel();
  }
  /**
   * 获取全部代码表
   *
   * @param {*} name
   */
  getGlobalCodes(name) {
    const layout = this.getLayout();
    const globalCodes = layout[CODES_KEY] || {};
    return name ? globalCodes[name.toUpperCase()] : globalCodes;
  }
  /**
   * 获取全部告知表
   */
  getGlobalDeclarations(name) {
    const layout = this.getLayout();
    const globalDeclarations = layout[DECLARATIONS_KEY] || {};
    return name ? globalDeclarations[name.toUpperCase()] : globalDeclarations;
  }
  getPhaseComponents() {
    return this.state.phaseComponents;
  }
  /**
   * 是否需要parent
   */
  isParentRequired() {
    return false;
  }
}

export default Something;
