import { React, ReactDOM, $ } from '../../../../common/3rd';
import { Utils, Envs } from '../../../../common/common';
// import AtomicComponentThing from '../atomic-component'
import AtomicMutableComponentThing from '../atomic-mutable-component';
import { PageLoading, Alert, Something } from '../../../components';
import AnysignView from '../../anysign/anysignView';
import utils from '../../../../common/utils';

/**
 * 签字按钮
 * 例：业务员签名页面
 * add by susan.wu 2017 Dec.14
 *
 */
class SignatureButton extends AtomicMutableComponentThing {
  constructor(props, context) {
    super(props, context);
    this.currentSignaturePerson = 3; //当前进入签名人1投保人  2被保人  3代理人  4批注  5第二被保人
    this.currentIndex = 0;
    this.state.myIndex = 23;
    this.signChecked = false; //判断是否签名
    this.htmlData = [];
    //保单数据
    this.model = {};
    this.proposalCode = '';
    this.proposer = {};
    this.insureds = []; //被保人信息
    this.insured = {}; // 第一被保人信息
    this.sameWithProposer = false; //投被保人是否为同一人
    this.agentName = ''; //代理人姓名
    this.agentCode = ''; //代理人证件号码
    this.vendorCode = '';
    this.productId = '';

    this.initSDKResult = false; //是否初始化SDK
    this.myAgentSign = null; //代理人签名url
    this.myPolicySign = null; //投保人签名url
    this.myInsuredSign = null; //被保人签名url
    this.signSaved = false; //是否已生成pdf签名保存
    this.myJsonStr = '';
  }
  UNSAFE_componentWillMount() {
    this.currentSignaturePerson =
      this.props.parent.props.parent.state.currentSignaturePerson || 3;
    let wizard = this.props.parent.props.parent;
    this.currentIndex = wizard.state.currentIndex || 0;
    if (this.currentIndex !== this.state.myIndex) {
      return;
    }
    Utils.uninstallFromEventEmitter({
      event: 'genJsonData',
      func: this.generateCAPdf,
    });
    this.resetData();
  }
  // TODO: https://zh-hans.reactjs.org/docs/react-component.html#unsafe_componentwillupdate
  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.currentSignaturePerson =
      this.props.parent.props.parent.state.currentSignaturePerson || 3;
    let wizard = this.props.parent.props.parent;
    this.currentIndex = wizard.state.currentIndex || 0;
    if (this.currentIndex !== this.state.myIndex) {
      return;
    }
    Utils.uninstallFromEventEmitter({
      event: 'genJsonData',
      func: this.generateCAPdf,
    });
    this.resetData();
  }
  resetData() {
    let self = this;
    self.model = self.getModel();
    self.proposalCode = self.model.quotationNo || '';
    self.proposer = self.model.policyHolder || {};
    self.sameWithProposer = false;
    self.model.policyCustomers.map((item, index, arr) => {
      if (item.customerType === '2') {
        self.insureds.push(item);
        if (item.extensionData.relationWithPH === '00') {
          self.sameWithProposer = true;
        }
      }
      return null;
    });
    self.insured = self.insureds[0] ? self.insureds[0] : {};
    self.agentName = Envs.findAccount().fullName || '';
    self.agentCode = Envs.findAccount().idNo || '';
    let isShare = Utils.getParameter(window.location.href, 'isShare');
    if (isShare && !self.agentName) {
      self.agentName = Utils.getParameter(window.location.href, 'agentName');
    }
    if (isShare && !self.agentCode) {
      self.agentCode = Utils.getParameter(window.location.href, 'agentCode');
    }
    self.vendorCode = self.model.vendorCode || '';
    self.productId = self.model.productId || '';

    self.initSDKResult = false;
    self.myAgentSign = null;
    self.myPolicySign = null;
    self.myInsuredSign = null;
    self.signSaved = false;
    self.initAgentSaveSiged = false;
    self.initPolicyHolderSaveSiged = false;
    self.initInsuredSaveSiged = false;
    if (self.model.extensionData && self.model.extensionData.documents) {
      self.model.extensionData.documents.map((item, index) => {
        if (item.documentType === '-22') {
          self.initAgentSaveSiged = true;
        }
        return null;
      });
    }
    if (self.proposer.extensionData && self.proposer.extensionData.documents) {
      self.proposer.extensionData.documents.map((item, index) => {
        if (item.documentType === '-23') {
          self.initPolicyHolderSaveSiged = true;
        }
        return null;
      });
    }
    if (self.insured.extensionData && self.insured.extensionData.documents) {
      self.insured.extensionData.documents.map((item, index) => {
        if (item.documentType === '-24') {
          self.initInsuredSaveSiged = true;
        }
        return null;
      });
    }
    self.judgeSignStatus();
  }
  render() {
    let className =
      this.getLayout().target === this.currentSignaturePerson
        ? 'signButton signButton-show'
        : 'signButton';
    return (
      <div className={className} ref="signButtonContainer">
        <div
          className="signButton-content"
          ref="signButton"
          onClick={this.signButtonClick}
        >
          {this.getLayout().text}
        </div>
        {this.renderAnySignView()}
      </div>
    );
  }
  renderAnySignView() {
    if (this.getLayout().target === this.currentSignaturePerson) {
      return (
        <AnysignView
          ref="anysignView"
          initialSdkCall={this.initialSdk}
          agentSignCall={this.agentSignCall}
          policySignCall={this.policySignCall}
          insuredSignCall={this.insuredSignCall}
          jsonCall={this.jsonCall}
        />
      );
    } else {
      return <div className="anysign-container-hide"></div>;
    }
  }
  componentDidUpdate() {
    super.componentDidUpdate();
    let self = this;
    if (
      this.getLayout().target !== this.currentSignaturePerson ||
      this.currentIndex !== this.state.myIndex
    )
      return;
    Utils.installToEventEmitter({
      event: 'genJsonData',
      func: this.generateCAPdf,
    });
    let ops = {
      model: '<div></div>',
      no: Utils.caNo(),
      proposalCode: self.proposalCode,
    };
    //设置签字对象，注意顺序 [投保人、被保人、批注、代理人]
    let proposerOps = null,
      insuredOps = null,
      commentOps = null,
      agentOps = null;
    if (self.proposer && self.proposer.idNo) {
      proposerOps = {
        name: self.proposer.fullName,
        certiCode: self.proposer.idNo,
        keyWord: '投保人签名：',
        direction: '3',
        directionNo: 10,
      };
    }
    if (self.insured && self.insured.idNo) {
      insuredOps = {
        name: self.insured.fullName,
        certiCode: self.insured.idNo,
        keyWord: '被保险人/ 其法定监护人签名：',
        direction: '3',
        directionNo: 10,
      };
    }
    if (!self.agentName) {
      Alert.message('代理人姓名不能为空！');
    }
    if (!self.agentCode) {
      Alert.message('代理人证件号码不能为空！');
    }
    if (self.agentName && self.agentCode) {
      agentOps = {
        name: self.agentName,
        certiCode: self.agentCode,
        keyWord: '第一及第二业务人员签字',
        direction: '3',
        directionNo: 10,
      };
    }
    let signCfg = [proposerOps, insuredOps, commentOps, agentOps];
    ops.signCfg = signCfg;
    PageLoading && PageLoading.start();
    self.refs.anysignView.initAnySignSDK(ops);
  }
  componentDidMount() {
    super.componentDidMount();
    let self = this;
    if (
      this.getLayout().target !== this.currentSignaturePerson ||
      this.currentIndex !== this.state.myIndex
    )
      return;
    Utils.installToEventEmitter({
      event: 'genJsonData',
      func: this.generateCAPdf,
    });
    let ops = {
      model: '<div></div>',
      no: Utils.caNo(),
      proposalCode: self.proposalCode,
    };
    //设置签字对象，注意顺序 [投保人、被保人、批注、代理人]
    let proposerOps = null,
      insuredOps = null,
      commentOps = null,
      agentOps = null;
    if (self.proposer && self.proposer.idNo) {
      proposerOps = {
        name: self.proposer.fullName,
        certiCode: self.proposer.idNo,
        keyWord: '投保人签名：',
        direction: '3',
        directionNo: 10,
      };
    }
    if (self.insured && self.insured.idNo) {
      insuredOps = {
        name: self.insured.fullName,
        certiCode: self.insured.idNo,
        keyWord: '被保险人/ 其法定监护人签名：',
        direction: '3',
        directionNo: 10,
      };
    }
    if (self.agentName && self.agentCode) {
      agentOps = {
        name: self.agentName,
        certiCode: self.agentCode,
        keyWord: '第一及第二业务人员签字',
        direction: '3',
        directionNo: 10,
      };
    }
    let signCfg = [proposerOps, insuredOps, commentOps, agentOps];
    ops.signCfg = signCfg;
    PageLoading && PageLoading.start();
    self.refs.anysignView.initAnySignSDK(ops);
  }
  componentWillUnmount() {
    Utils.uninstallFromEventEmitter({
      event: 'genJsonData',
      func: this.generateCAPdf,
    });
  }
  //初始化sdk
  initialSdk = (obj) => {
    let self = this;
    PageLoading && PageLoading.end();
    if (obj && obj.status) {
      self.initSDKResult = true;
      // console.log("初始化SDK成功");
    } else {
      Alert.message('初始化SDK失败！');
      self.initSDKResult = false;
    }
    self.showButton();
  };
  //点击签字按钮
  signButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.currentSignaturePerson === 1) {
      //投保人签字
      this.clickPolicySigBtnHandler(e);
      return;
    } else if (this.currentSignaturePerson === 2) {
      //被保人签字
      this.clickInsuredSigBtnHandler(e);
      return;
    }
    //获取所有单证的模板信息
    if (this.signSaved) {
      Alert.message(this.getLayout().text + '已完成，不能再次签名！');
      return;
    }
    let me = $(ReactDOM.findDOMNode(this.props.parent.props.parent.refs.me));
    let wizardSteps = me.find('.sth-wizard-step');
    let caInfos = [];
    for (let i = 0; i < wizardSteps.length; i++) {
      let wizardStep = $(wizardSteps[i]);
      let htmlInfo = '';
      let documentType = '';
      let caContent = [];
      if (wizardStep.find('#signature-html-container').length > 0) {
        htmlInfo = wizardStep.find('#hintContent').html();
        documentType = wizardStep
          .find('#signature-html-main')
          .attr('data-type');
        let documentName = wizardStep
          .find('#signature-html-main')
          .attr('data-name');
        let stepIndex =
          'step' +
          (parseInt(
            wizardStep.find('#signature-html-main').attr('data-index'),
            10
          ) +
            1);
        let me = this.props.parent.props.parent.refs[stepIndex];
        caContent = me.refs[documentName].caContent;
        caInfos.push({
          businessNo: this.proposalCode,
          htmlInfo: htmlInfo,
          documentType: documentType,
          caContents: caContent,
        });
      }
    }
    //调用后端接口，保存模板信息
    let params = {
      caInfos: caInfos,
    };
    PageLoading && PageLoading.start();
    this.doPost('/rest/v1/policy/saveCaInfo', params)
      .done((data) => {
        if (data.returnCode === 'RC-00001') {
          if (this.currentSignaturePerson === 3) {
            //代理人签字
            this.clickAgentSigBtnHandler(e);
          } else if (this.currentSignaturePerson === 1) {
            //投保人签字
            this.clickPolicySigBtnHandler(e);
          } else if (this.currentSignaturePerson === 2) {
            //被保人签字
            this.clickInsuredSigBtnHandler(e);
          }
        }
      })
      .always(() => {
        PageLoading && PageLoading.end();
      });
  };
  //点击投保人签名
  clickPolicySigBtnHandler = (e) => {
    let self = this;
    if (utils.clickLock()) return;
    // if (!self.initPolicyHolderSaveSiged) {
    if (self.initSDKResult) {
      self.sDate = new Date();
      self.refs.anysignView.showSignatureDialog('25');
      $('#comment_dialog')
        .parents('.page')
        .find('.page-footer')
        .css('display', 'none');
    } else {
      Alert.message('初始化SDK失败，无法签字！');
    }
    // } else {
    // 	Alert.message("投保人已签字，无法再次签字！");
    // }
  };
  //点击代理人签名
  clickAgentSigBtnHandler = (e) => {
    let self = this;
    if (utils.clickLock()) return;
    let buttonEnable = $(ReactDOM.findDOMNode(this.refs.signButton)).hasClass(
      'enable'
    );
    if (!buttonEnable) {
      if (self.initSDKResult) {
        self.sDate = new Date();
        self.refs.anysignView.showSignatureDialog('27');
        $('#comment_dialog')
          .parents('.page')
          .find('.page-footer')
          .css('display', 'none');
      } else {
        Alert.message('初始化SDK失败，无法签字！');
      }
    } else {
      Alert.message('代理人已签字，无法再次签字！');
    }
  };
  //点击被保人签名
  clickInsuredSigBtnHandler = (e) => {
    let self = this;
    if (utils.clickLock()) return;
    // if (!self.initInsuredSaveSiged) {
    if (self.initSDKResult) {
      self.sDate = new Date();
      self.refs.anysignView.showSignatureDialog('26');
      $('#comment_dialog')
        .parents('.page')
        .find('.page-footer')
        .css('display', 'none');
    } else {
      Alert.message('初始化SDK失败，无法签字！');
    }
    // } else {
    // 	Alert.message("被保人已签字，无法再次签字！");
    // }
  };
  //代理人签字回调
  agentSignCall = (obj) => {
    let self = this;
    $('#comment_dialog')
      .parents('.page')
      .find('.page-footer')
      .css('display', 'flex');
    if (!obj || !obj.base) {
      Alert.message('当前签名无法完成，请重试！');
      return;
    }
    self.eDate = new Date();
    let distance = parseInt((self.eDate - self.sDate) / (1000 * 60), 10);
    if (distance > 5) {
      //超过五分钟
      Alert.message('未在时限内完成签字，请重新操作！');
      return;
    }
    self.myAgentSign = obj.base;
    if (self.myAgentSign) {
      self.saveAgentSign();
    }
  };
  //保存代理人签字
  saveAgentSign() {
    let self = this;
    let params = {
      businessNo: self.proposalCode,
      customerType: '-1',
      document: self.myAgentSign,
      documentType: '-22',
      isFront: '',
      productId: '',
      uplaodType: '2',
      vendorCode: self.vendorCode,
    };
    PageLoading && PageLoading.start();
    self
      .doPost('/rest/v1/policy/uploadImage', params)
      .done((data) => {
        if (data && data.body) {
          self.agentSaveSiged = true;
          //在model中添加数据
          if (!self.model.extensionData) {
            self.model.extensionData = {};
          }
          if (!self.model.extensionData.documents) {
            self.model.extensionData.documents = [];
          }
          self.model.extensionData.documents.map((existDocument, index) => {
            if (existDocument.documentType === '-22') {
              self.model.extensionData.documents.splice(index, 1);
            }
            return null;
          });
          self.model.extensionData.documents.push({
            documentType: '-22',
            coverageCode: '',
            uri: data.body.fileLocation,
            front: 'Y',
            uploadTime: data.body.uploadTime,
          });
          // console.log(self.model.extensionData.documents);
          this.judgeSignStatus();
          this.getJson();
        }
      })
      .always(() => {
        PageLoading && PageLoading.end();
      });
  }
  //投保人签字回调
  policySignCall = (obj) => {
    let self = this;
    $('#comment_dialog')
      .parents('.page')
      .find('.page-footer')
      .css('display', 'flex');
    if (!obj || !obj.base) {
      Alert.message('当前签名无法完成,请重试！');
      return;
    }
    self.eDate = new Date();
    let distance = parseInt((self.eDate - self.sDate) / (1000 * 60), 10);
    if (distance > 5) {
      //超过五分钟
      Alert.message('未在时限内完成签字，请重新操作！');
      return;
    }
    self.myPolicySign = obj.base;
    if (self.myPolicySign) {
      self.savePolicySign();
    }
  };
  //保存投保人签字
  savePolicySign = () => {
    let self = this;
    let params = {
      businessNo: self.proposalCode,
      customerType: '0',
      document: self.myPolicySign,
      documentType: '-23',
      isFront: 'Y',
      productId: '',
      uplaodType: '2',
      vendorCode: self.vendorCode,
    };
    PageLoading && PageLoading.start();
    self
      .doPost('/rest/v1/policy/uploadImage', params)
      .done((data) => {
        if (data && data.body) {
          self.policyHolderSaveSiged = true;
          //在model中添加相应数据
          if (!self.model.policyHolder.extensionData) {
            self.model.policyHolder.extensionData = {};
          }
          if (!self.model.policyHolder.extensionData.documents) {
            self.model.policyHolder.extensionData.documents = [];
          }
          //需要先判断是否已经有签字图片了
          self.model.policyHolder.extensionData.documents.map(
            (existDocument, index) => {
              if (existDocument.documentType === '-23') {
                self.model.policyHolder.extensionData.documents.splice(
                  index,
                  1
                );
              }
              return null;
            }
          );
          self.model.policyHolder.extensionData.documents.push({
            documentType: '-23',
            coverageCode: '',
            uri: data.body.fileLocation,
            front: 'Y',
            uploadTime: data.body.uploadTime,
          });
          // console.log(self.model.policyHolder.extensionData.documents);
          this.judgeSignStatus();
          this.getJson();
        }
      })
      .always(() => {
        PageLoading && PageLoading.end();
      });
  };
  //被保人签字回调
  insuredSignCall = (obj) => {
    let self = this;
    $('#comment_dialog')
      .parents('.page')
      .find('.page-footer')
      .css('display', 'flex');
    self.eDate = new Date();
    let distance = parseInt((self.eDate - self.sDate) / (1000 * 60), 10);
    if (distance > 5) {
      //超过五分钟
      Alert.message('未在时限内完成签字，请重新操作！');
      return;
    }
    if (!obj || !obj.base) {
      self.myInsuredSign = null;
      Alert.message('当前签名无法完成,请重试');
      return;
    }
    self.myInsuredSign = obj.base;
    if (self.myInsuredSign) {
      self.saveInsuredSignature();
    }
  };
  //保存被保人签字
  saveInsuredSignature = () => {
    let self = this;
    let params = {
      businessNo: self.proposalCode,
      customerType: '1',
      document: self.myInsuredSign,
      documentType: '-24',
      isFront: '',
      productId: '',
      uplaodType: '2',
      vendorCode: self.vendorCode,
    };
    PageLoading && PageLoading.start();
    self
      .doPost('/rest/v1/policy/uploadImage', params)
      .done((data) => {
        if (data && data.body) {
          self.insuredSaveSiged = true;
          //在model中添加数据
          self.model.policyCustomers.map((item, index, arr) => {
            if (item.customerType === '2') {
              if (!item.extensionData) {
                item.extensionData = {};
              }
              if (!item.extensionData.documents) {
                item.extensionData.documents = [];
              }
              //需要先判断是否已经有签字图片了
              item.extensionData.documents.map((existDocument, index) => {
                if (existDocument.documentType === '-24') {
                  item.extensionData.documents.splice(index, 1);
                }
                return null;
              });
              item.extensionData.documents.push({
                documentType: '-24',
                coverageCode: '',
                uri: data.body.fileLocation,
                front: 'Y',
                uploadTime: data.body.uploadTime,
              });
              // console.log(item.extensionData.documents);
            }
            return null;
          });
          this.judgeSignStatus();
          this.getJson();
        }
      })
      .always(() => {
        PageLoading && PageLoading.end();
      });
  };
  //判断签名状态
  getSignStatus() {
    let signedDocuments = this.model.extensionData.documents;
    let needSignedDocumentTypes = [];
    if (this.getModel().productId === 327) {
      needSignedDocumentTypes = this.getLayout().documentTypesToJAS;
    } else {
      needSignedDocumentTypes = this.getLayout().documentTypes;
    }
    let signedLength = 0;
    needSignedDocumentTypes.map((needSignedDocumentType) => {
      signedDocuments.map((signedItem) => {
        if (
          signedItem.documentType &&
          signedItem.documentType === needSignedDocumentType
        ) {
          signedLength = signedLength + 1;
        }
        return null;
      });
      return null;
    });
    if (signedLength >= needSignedDocumentTypes.length) {
      return true;
    } else {
      return false;
    }
  }
  //获取json串
  getJson = () => {
    let self = this;
    PageLoading && PageLoading.start();
    self.refs.anysignView.genEncData();
  };
  //json打包回调
  jsonCall = (obj) => {
    // let self = this;
    PageLoading && PageLoading.end();
    if (!obj || !obj.jsonStr || obj.jsonStr === 0 || obj.jsonStr === -1) {
      Alert.message('当前签名无法完成,请重试');
      return;
    }
    this.myJsonStr = obj.jsonStr;
  };
  //获取accountId
  getQuoteAccountId() {
    if (
      Utils.getParameter &&
      Utils.getParameter(window.location.href, 'isShare')
    ) {
      return Utils.getParameter(window.location.href, 'accountId');
    } else {
      return Envs.findAccount().accountId;
    }
  }
  //获取保单Id
  getQuotePolicyId() {
    return this.model.policyId;
  }
  //调用后端打包接口
  generateCAPdf = () => {
    let self = this;
    //判断是否已经生成pdf了
    let accountId = this.getQuoteAccountId();
    let policyId = this.getQuotePolicyId();
    const url = `/rest/v1/policy/loadQuote?accountId=${accountId}&policyId=${policyId}`;
    PageLoading && PageLoading.start();
    this.doGet(url)
      .done((data) => {
        let { quoteInitData, templateInitData } = data.body;
        let policyModel = Something.merge(templateInitData, quoteInitData);
        let addDocuments = $.extend(
          self.model.extensionData.documents,
          policyModel.extensionData.documents
        );
        self.model.extensionData.documents = addDocuments;
        // self.getRootComponent().setModel(policyModel);
        if (self.getSignStatus()) {
          Alert.message('已完成签名提交，不能再次提交！');
          return;
        }
        self.generatePolicyCAPdf();
      })
      .always(() => {
        // PageLoading && PageLoading.end();
      });
  };
  generatePolicyCAPdf() {
    let self = this;
    let documentTypes = [];
    if (this.getModel().productId === 327) {
      documentTypes = this.getLayout().documentTypesToJAS;
    } else {
      documentTypes = this.getLayout().documentTypes;
    }
    let params = {
      vendorCode: self.vendorCode,
      documentTypes: documentTypes,
      productId: self.productId,
      businessNo: self.proposalCode,
      keyWord: self.getKeyWord(),
      signContent: this.myJsonStr,
    };
    PageLoading && PageLoading.start();
    self
      .doPost('/rest/v1/policy/generateCaPdf', params)
      .done((data) => {
        if (data && data.body) {
          //在model中添加数据
          if (!self.model.extensionData) {
            self.model.extensionData = {};
          }
          if (!self.model.extensionData.documents) {
            self.model.extensionData.documents = [];
          }
          data.body.map((pdfItem) => {
            self.model.extensionData.documents.push({
              documentType: pdfItem.documentType,
              coverageCode: '',
              uri: pdfItem.fileLocation,
              front: 'Y',
              uploadTime: pdfItem.uploadTime,
            });
            return null;
          });
          //若投被保人是同一人，需要再次调用接口生成被保人的pdf
          if (self.getLayout().target === 1 && self.sameWithProposer) {
            //先调用update接口
            this.doPost('/rest/v1/policy/update', this.model)
              .done((data) => {
                self.generateInsuredCaPdf(this.myJsonStr);
              })
              .always(() => {
                // PageLoading.end();
              });
          } else {
            //update数据
            self.updateData();
          }
        }
      })
      .always(() => {
        // PageLoading && PageLoading.end();
      });
  }
  generateInsuredCaPdf(myJsonStr) {
    let self = this;
    let documentTypes = [];
    if (self.getModel().productId === 327) {
      documentTypes = self.getLayout().insuredDocumentTypesToJAS;
    } else {
      documentTypes = self.getLayout().insuredDocumentTypes;
    }
    let params = {
      vendorCode: self.vendorCode,
      documentTypes: documentTypes,
      productId: self.productId,
      businessNo: self.proposalCode,
      keyWord: '2',
      signContent: myJsonStr,
    };
    PageLoading && PageLoading.start();
    self
      .doPost('/rest/v1/policy/generateCaPdf', params)
      .done((data) => {
        if (data && data.body) {
          //在model中添加数据
          if (!self.model.extensionData) {
            self.model.extensionData = {};
          }
          if (!self.model.extensionData.documents) {
            self.model.extensionData.documents = [];
          }
          data.body.map((pdfItem) => {
            self.model.extensionData.documents.push({
              documentType: pdfItem.documentType,
              coverageCode: '',
              uri: pdfItem.fileLocation,
              front: 'Y',
              uploadTime: pdfItem.uploadTime,
            });
            return null;
          });
          //update数据
          self.updateData();
        }
      })
      .always(() => {
        // PageLoading && PageLoading.end();
      });
  }
  //update model数据
  updateData() {
    if (Utils.getParameter(window.location.href, 'isShare')) {
      //分享时验证电子签名页能否提交
      let shareUrl = window.location.href;
      let sign = Utils.getParameter(window.location.href, 'sign');
      let eIndex = shareUrl.indexOf('&sign=' + sign);
      if (eIndex > -1) {
        shareUrl = shareUrl.substring(0, eIndex);
      }
      let params = {
        shareUrl: shareUrl,
        sign: sign,
      };
      PageLoading && PageLoading.start();
      this.doPost('/rest/v1/share/validate', params)
        .done((data) => {
          if (data.resultCode === '0') {
            //若链接仍有效，则更新数据
            PageLoading && PageLoading.start();
            this.doPost('/rest/v1/policy/update', this.model)
              .done((data) => {
                let model = data.body;
                this.getRootComponent().setModel(model);
                this.getRootComponent().jumpTo('submitSignSuccess');
              })
              .always(() => {
                // PageLoading.end();
              });
          } else if (parseInt(data.resultCode, 10) > 0) {
            Alert.message('该链接已失效，不能提交！');
          }
        })
        .always(() => {
          // PageLoading.end();
        });
    } else {
      PageLoading && PageLoading.start();
      this.doPost('/rest/v1/policy/update', this.model)
        .done((data) => {
          let model = data.body;
          this.getRootComponent().setModel(model);
          this.getRootComponent().jumpTo('submitSignSuccess');
        })
        .always(() => {
          // PageLoading.end();
        });
    }
  }
  getKeyWord() {
    if (this.getLayout().target === 3) {
      return '0';
    } else if (this.getLayout().target === 1) {
      return '1';
    } else if (this.getLayout().target === 2) {
      return '2';
    }
  }
  showButton() {
    let self = this;
    let signButton = $(ReactDOM.findDOMNode(self.refs['signButton']));
    //所有按钮 默认不可点
    signButton.addClass('enable');
    if (!self.initSDKResult) return;
    //判断是否有对应的pdf生成，若已生成则不能再次点击签字按钮
    if (!self.getSignStatus()) {
      signButton.removeClass('enable');
    }
  }
  //判断是否签字
  judgeSignStatus() {
    this.signChecked = false;
    if (this.getLayout().target !== this.currentSignaturePerson) {
      this.signChecked = true;
      return;
    }
    let documents = this.model.extensionData.documents || [];
    let proposerDom = this.proposer.extensionData.documents || [];
    let insuredDom = this.insured.extensionData.documents || [];
    if (this.currentSignaturePerson === 1) {
      //判断投保人是否签字
      proposerDom.map((doc, index, arr) => {
        if (doc.documentType === this.getLayout().signDocumentType) {
          this.signChecked = true;
        }
        return null;
      });
    }
    if (this.currentSignaturePerson === 2) {
      //判断被保人是否签字
      insuredDom.map((doc, index, arr) => {
        if (doc.documentType === this.getLayout().signDocumentType) {
          this.signChecked = true;
        }
        return null;
      });
    }
    if (this.currentSignaturePerson === 3) {
      //判断代理人是否签字
      documents.map((doc, index, arr) => {
        if (doc.documentType === this.getLayout().signDocumentType) {
          this.signChecked = true;
        }
        return null;
      });
    }
  }
  getValueFromModel() {
    return this.signChecked;
  }
}

export default SignatureButton;
