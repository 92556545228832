import { React } from '../../../common/3rd';

import './logo.scss';

class Logo extends React.Component {
  render() {
    return (
      <div className="logo">
        <div className="logo-front" />
        <div className="logo-middle" />
        <div className="logo-back" />
        {this.props.text ? (
          <div className="logo-text">{this.getLogoText()}</div>
        ) : null}
      </div>
    );
  }

  getLogoText() {
    return this.props.text;
  }
}

export default Logo;
