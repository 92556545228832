/**
 * @licence
 * Copyright © 2022 Beijing Life. All rights reserved.
 * @description
 * @author
 * @date 2017/5/3
 */

import { React, ReactDOM } from '@/common/3rd';
import App from '@/app';
// import registerServiceWorker from '@/registerServiceWorker';
import '@/index.scss';

// noinspection JSCheckFunctionSignatures
ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();
