import { React } from '../../../../common/3rd';

import {
    AtomicArrayComponentThing,
    AtomicArrayItemThing
} from '../atomic-array-component';
import { Panel, LeftPanel, LeftPanel80ImportInfor } from './panel';

//const R = require('ramda');

let hideDeclare = true;

class InnerPanel extends AtomicArrayItemThing(Panel) {
    getDefaultClassName() {
        return 'sth-panel sth-in-array-panel';
    }
}

class InnerLeftPanel extends AtomicArrayItemThing(LeftPanel) {
    getDefaultClassName() {
        return 'sth-panel sth-in-array-panel';
    }
}


class ArrayPanel extends AtomicArrayComponentThing {
    renderPanel(model, index) {
        return <InnerPanel model={ model }
                           key={ index }
                           layout={ this.getPanelLayout() }
                           parent={ this }/>;
    }

    renderHeader() {
        const headerLayout = this.getPanelLayout().header;
        if (!headerLayout) {
            return null;
        }
        headerLayout.class = 'sth-array-panel-header';
        return <LeftPanel80ImportInfor { ...{
            parent: this,
            layout: headerLayout,
            model : this.getRootModel(),
        } } />;
    }

    renderPanels() {
        // 18/01/2018 - TAG: by yanzhi.mo - 移除_0003
        // let vfm = R.clone(this.getValueFromModel());
        // const spDeclIndex = R.findIndex(R.propEq('code', '_0003'), vfm);
        // if (spDeclIndex !== -1) {
        //    vfm = R.remove(spDeclIndex, 1, vfm);
        // }

        return this.getValueFromModel().map((item, itemIndex) => {
            // 18/01/2018 - TAG: by yanzhi.mo - 该项不显示
            // 2018/01/15 --- add by susan --已确认需要显示
            // if (item.code === '_0003') {
            // 	return null;
            // }
            if (item.code === '_0002') {
                if (item.remark === "仅为中国税收居民") {
                    hideDeclare = true;
                } else {
                    hideDeclare = false;
                }
            }
            if (item.code === '_0003' && hideDeclare) {
                return null;
            }
            return this.renderPanel(item, itemIndex);
        })
    }

    renderFooter() {
        return null;
    }

    render() {
        return (
            <div { ...this.getPrototypes() }>
                { this.renderHeader() }
                { this.renderPanels() }
                { this.renderFooter() }
            </div>
        );
    }

    getDefaultClassName() {
        return 'sth-array-panel';
    }

    getPanelLayout() {
        return this.getLayout();
    }
}

class LeftArrayPanel extends ArrayPanel {
    renderPanel(model, index) {
        return <InnerLeftPanel model={ model }
                               key={ index }
                               layout={ this.getPanelLayout() }
                               parent={ this }/>;
    }
}

export { ArrayPanel, LeftArrayPanel }