import { Envs, Utils } from './common';

/**
 * @file 账户工具
 * @author LiuH
 */

class Account {
  /**
   * 获取代理人编码
   * @return {string} agentCode
   */
  static getAgentCode() {
    const accountName = Envs.findAccount()?.accountName;
    return Account.getAgentCodeFromAccountName(accountName);
  }

  /**
   * 获取代理人编码
   * @param {string} accountName
   * @return {string} agentCode
   */
  static getAgentCodeFromAccountName(accountName) {
    if (
      Utils.isBlank(accountName) ||
      (accountName.indexOf('B2A.') < 0 && accountName.indexOf('B2C.') < 0)
    ) {
      return accountName;
    }
    return accountName.replace(/B2(A|C)\./, '');
  }

  /**
   * @returns {string} n22 token
   */
  static getToken() {
    return localStorage.getItem('ntoken') || '';
  }
}

export default Account;
