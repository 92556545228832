import { React } from '../../../../common/3rd';

import AtomicComponentThing from '../atomic-component';
import AtomicCodesComponentThing from '../atomic-codes-component';
import { format } from '../something-utils';

/**
 * 标签
 */
class Label extends AtomicCodesComponentThing {
  render() {
    return (
      <span {...this.getPrototypes()} ref="me">
        {this.formatText(this.getDisplayLabel())}
      </span>
    );
  }
  getDefaultClassName() {
    return 'sth-label';
  }
  getDisplayLabel() {
    const label = this.getLabel();
    const lay = this.getLayout();
    if (label) {
      return this.getLabel();
    } else if (lay.codes && lay.codes !== '') {
      //update by wendy.gu 2017 Oct.17
      //数据值映射
      let id = this.getValueFromModel(this.getId());
      return this.getLabelById(id, this.getCodes());
      //todo 如何获取全局layout的codes ????
    } else {
      return this.getValueFromModel(this.getId());
    }
  }
  //根据值返回对应label
  getLabelById(val, mapCodes) {
    var code = mapCodes.find(function (value, index, arr) {
      return value.v === val;
    });
    return code ? code.l : '';
  }
  getLabelFormat() {
    return this.getLayout().format;
  }

  formatText(value) {
    return format(value, this.getLabelFormat());
  }
  setValueToDOM(value) {
    // this.me().val(value);
  }
  getValueFromDOM() {
    // return this.me().val();
    return null;
  }
  getValueFromModel(id) {
    id = id || this.getId();
    if (id) {
      return super.getValueFromModel();
    } else {
      return null;
    }
  }
  setValueToModel(value, callback, force, id) {
    id = id || this.getId();
    if (id) {
      return super.setValueToModel(value, callback, force, id);
    } else {
      // do nothing
      return false;
    }
  }
}

/**
 * 纵向标签集
 */
class VerticalLabels extends AtomicComponentThing {
  shouldInstallDefaultIdToEventEmitter() {
    return false;
  }
  render() {
    return (
      <div {...this.getPrototypes()}>
        {this.getLabels().map((label, labelIndex) => {
          return (
            <Label
              model={this.getModel()}
              layout={{ label: label }}
              parent={this}
              key={labelIndex}
            />
          );
        })}
      </div>
    );
  }
  getDefaultClassName() {
    return 'sth-vertical-labels';
  }
  getLabels() {
    const labels = this.getLayout().labels;
    return labels ? (Array.isArray(labels) ? labels : [labels]) : [];
  }
}

export { Label, VerticalLabels };
