import { React } from '../../../../common/3rd';
import { Utils } from '../../../../common/common';
import * as SthUtils from '../something-utils';
import AtomicMutableComponentThing from '../atomic-mutable-component';
import Alert from '../../alert/alert';

/**
 * 输入框
 */
class Text extends AtomicMutableComponentThing {
  state = {
    toDecimal2: true,
  };
  render() {
    return (
      <input
        type="text"
        {...this.getPrototypes()}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.doCheckAndAlert}
        ref="me"
      />
    );
  }

  doCheckAndAlert = (evt) => {
    /**
     * 自动带出年龄性别
     */
    let value = evt.target.value;
    let ID = this.getId();
    let model = this.getModel();
    value = value.replace('x', 'X');
    if (ID.includes('idNo') && Utils.isCardNo(value)) {
      let info = Utils.getBirthAndGander(value);
      SthUtils.setValueToModel(
        model,
        ID.replace('idNo', 'dateOfBirth'),
        info[0].replace(/\-/g, '/') + ' 00:00:00'
      );
      SthUtils.setValueToModel(model, ID.replace('idNo', 'gender'), info[1]);
      SthUtils.setValueToModel(
        model,
        ID.replace('idNo', 'extensionData.age'),
        info[2]
      );
      this.getRootComponent().forceUpdate();
    } else if (
      ID.includes('idNo') &&
      (!Utils.isCardNo(value) || value === '')
    ) {
      SthUtils.setValueToModel(model, ID.replace('idNo', 'dateOfBirth'), '');
      SthUtils.setValueToModel(model, ID.replace('idNo', 'gender'), '');
      SthUtils.setValueToModel(
        model,
        ID.replace('idNo', 'extensionData.age'),
        ''
      );
      this.getRootComponent().forceUpdate();
    }
    const message = this.doCheck();
    if (message) {
      Alert.message(message);
    }
  };
  /**
   * 从模型中获取值
   * @param {string} id 可以不指定, 则使用getId()的返回值
   */
  getValueFromModel(id) {
    let value = SthUtils.getValueFromModel(this.getModel(), id || this.getId());
    if (this.getLayout().toDecimal2) {
      if (this.state.toDecimal2 && /^\d\.\d\d$/.test(value)) {
        value = parseInt(value * 100);
      }
    }
    if (this.getLayout().isBankCard === true && value) {
      value = value.replace(/(\d{4})(?=[^\s])/g, '$1 ');
    }
    if (this.getLayout().isIdNo === true && value) {
      value = value.toUpperCase();
    }
    return value;
  }
  /**
   * 设置指定值到模型中. 如果指定force, 则一定会设置; 否则会比较模型值与被设置的值, 相等则忽略此操作
   * 如果操作被忽略, 返回false
   *
   * @param {any} value
   * @param {function} callback
   * @param {boolean} force
   * @param {string} id 可选项, 如果没有, 则使用getId()
   */
  setValueToModel(value, callback, force, id) {
    if (this.getLayout().toDecimal2) {
      if (/^(([1-9]\d?)|(100))$/.test(value)) {
        value = value + '9';
        value = value / 1000;
        value = value - 0.009;
        value = value.toFixed(2);
        this.state.toDecimal2 = true;
      } else {
        this.state.toDecimal2 = false;
      }
    } else {
      this.state.toDecimal2 = false;
    }
    if (this.getLayout().isBankCard === true) {
      value = value.replace(/\s/g, '');
    }
    if (this.getLayout().isIdNo === true) {
      value = value.toUpperCase();
    }
    const ret = SthUtils.setValueToModel(
      this.getModel(),
      id || this.getId(),
      value,
      force
    );
    if (ret !== false) {
      // 如果成功设置, 触发事件
      this.getEventEmitter().emit(id || this.getId(), {
        model: this.getModel(),
        from: this,
      });
    }
    if (callback) {
      // 回调
      callback.call(this);
    }
    return ret;
  }

  // 事件
  /**
   * 得到焦点
   */
  onFocus = (evt) => {
    /**
     * 安卓手机移动输入框到视线内
     * add by cheng.fan on Jan.25 2018
     */
    /* if (isAndroid()) {
			evt.target.scrollIntoView()
		} */
    this.selectAll();
  };
  getChangeHandlingDelay() {
    return 300;
  }
  /**
   * 全选
   */
  selectAll() {
    this.me().select();
  }
  /**
   * 获取支持的原生属性名称, 不包含事件
   */
  getPrototypeNames() {
    return ['placeholder', 'readOnly', 'disabled'];
  }
  getDefaultClassName() {
    return 'sth-text';
  }
}

export default Text;
