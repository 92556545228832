const EXPIRE = Symbol('_expiration');

/**
 * 支付计时器
 * added by yanzhi.mo
 *
 * @class Timer
 */
class Timer {
  constructor(expiration) {
    this[EXPIRE] = expiration;
  }

  /**
   * 剩余时间
   *
   * @memberof Timer
   */
  restTime(time) {
    const expiration = new Date(this[EXPIRE]);
    const now = new Date(time);
    const hous_expire = expiration.getHours();
    const hous_now = now.getHours();
    let mins_expire = expiration.getMinutes();
    let mins_now = now.getMinutes();
    let secs_expire = expiration.getSeconds();
    let secs_now = now.getSeconds();

    // 计时算法
    let mins_rest;
    let secs_rest;
    let mins_inc;
    let ok = false;

    if (this[EXPIRE] > time) {
      if (hous_expire > hous_now) {
        mins_expire += mins_inc = 60;
      } else {
        mins_expire += mins_inc = 0;
      }

      if (mins_expire > mins_now) {
        if (secs_expire > secs_now) {
          mins_rest = mins_expire - mins_now;
          secs_rest = secs_expire - secs_now;
        } else {
          mins_rest = mins_expire - mins_now - 1;
          secs_rest = secs_expire - secs_now + 60;
        }
      } else if (mins_expire === mins_now) {
        mins_rest = 0;
        secs_rest = secs_expire - secs_now;
      } else {
        mins_rest = 0;
        secs_rest = 0;
        ok = true;
      }
    } else {
      mins_rest = 0;
      secs_rest = 0;
      ok = true;
    }

    return {
      minsRest: mins_rest,
      secsRest: secs_rest,
      done: ok,
    };
  }

  /**
   * 启动计时
   *
   * @param {any} callback
   * @memberof Timer
   */
  run(callback) {
    // 启动计时器
    let timerKey;
    timerKey = setInterval(() => {
      const result = this.restTime(Date.now());
      if (result.done) {
        clearInterval(timerKey);
        callback && callback({ ...result });
      } else {
        callback && callback({ ...result });
      }
    }, 1000);

    return timerKey;
  }

  /**
   * 停止计时
   *
   * @param {any} timerKey
   * @memberof Timer
   */
  stop(timerKey) {
    clearInterval(timerKey);
  }
}

export default Timer;
